/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    common.EmptyRequest = (function() {

        /**
         * Properties of an EmptyRequest.
         * @memberof common
         * @interface IEmptyRequest
         */

        /**
         * Constructs a new EmptyRequest.
         * @memberof common
         * @classdesc Represents an EmptyRequest.
         * @implements IEmptyRequest
         * @constructor
         * @param {common.IEmptyRequest=} [properties] Properties to set
         */
        function EmptyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new EmptyRequest instance using the specified properties.
         * @function create
         * @memberof common.EmptyRequest
         * @static
         * @param {common.IEmptyRequest=} [properties] Properties to set
         * @returns {common.EmptyRequest} EmptyRequest instance
         */
        EmptyRequest.create = function create(properties) {
            return new EmptyRequest(properties);
        };

        /**
         * Encodes the specified EmptyRequest message. Does not implicitly {@link common.EmptyRequest.verify|verify} messages.
         * @function encode
         * @memberof common.EmptyRequest
         * @static
         * @param {common.IEmptyRequest} message EmptyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified EmptyRequest message, length delimited. Does not implicitly {@link common.EmptyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.EmptyRequest
         * @static
         * @param {common.IEmptyRequest} message EmptyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmptyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof common.EmptyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.EmptyRequest} EmptyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.EmptyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmptyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.EmptyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.EmptyRequest} EmptyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmptyRequest message.
         * @function verify
         * @memberof common.EmptyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmptyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an EmptyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.EmptyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.EmptyRequest} EmptyRequest
         */
        EmptyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.common.EmptyRequest)
                return object;
            return new $root.common.EmptyRequest();
        };

        /**
         * Creates a plain object from an EmptyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.EmptyRequest
         * @static
         * @param {common.EmptyRequest} message EmptyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmptyRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this EmptyRequest to JSON.
         * @function toJSON
         * @memberof common.EmptyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmptyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmptyRequest
         * @function getTypeUrl
         * @memberof common.EmptyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmptyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.EmptyRequest";
        };

        return EmptyRequest;
    })();

    common.EmptySuccess = (function() {

        /**
         * Properties of an EmptySuccess.
         * @memberof common
         * @interface IEmptySuccess
         * @property {string|null} [status] EmptySuccess status
         * @property {string|null} [message] EmptySuccess message
         */

        /**
         * Constructs a new EmptySuccess.
         * @memberof common
         * @classdesc Represents an EmptySuccess.
         * @implements IEmptySuccess
         * @constructor
         * @param {common.IEmptySuccess=} [properties] Properties to set
         */
        function EmptySuccess(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmptySuccess status.
         * @member {string} status
         * @memberof common.EmptySuccess
         * @instance
         */
        EmptySuccess.prototype.status = "";

        /**
         * EmptySuccess message.
         * @member {string} message
         * @memberof common.EmptySuccess
         * @instance
         */
        EmptySuccess.prototype.message = "";

        /**
         * Creates a new EmptySuccess instance using the specified properties.
         * @function create
         * @memberof common.EmptySuccess
         * @static
         * @param {common.IEmptySuccess=} [properties] Properties to set
         * @returns {common.EmptySuccess} EmptySuccess instance
         */
        EmptySuccess.create = function create(properties) {
            return new EmptySuccess(properties);
        };

        /**
         * Encodes the specified EmptySuccess message. Does not implicitly {@link common.EmptySuccess.verify|verify} messages.
         * @function encode
         * @memberof common.EmptySuccess
         * @static
         * @param {common.IEmptySuccess} message EmptySuccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptySuccess.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified EmptySuccess message, length delimited. Does not implicitly {@link common.EmptySuccess.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.EmptySuccess
         * @static
         * @param {common.IEmptySuccess} message EmptySuccess message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptySuccess.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmptySuccess message from the specified reader or buffer.
         * @function decode
         * @memberof common.EmptySuccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.EmptySuccess} EmptySuccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptySuccess.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.EmptySuccess();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.string();
                        break;
                    }
                case 2: {
                        message.message = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmptySuccess message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.EmptySuccess
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.EmptySuccess} EmptySuccess
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptySuccess.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmptySuccess message.
         * @function verify
         * @memberof common.EmptySuccess
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmptySuccess.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates an EmptySuccess message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.EmptySuccess
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.EmptySuccess} EmptySuccess
         */
        EmptySuccess.fromObject = function fromObject(object) {
            if (object instanceof $root.common.EmptySuccess)
                return object;
            let message = new $root.common.EmptySuccess();
            if (object.status != null)
                message.status = String(object.status);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from an EmptySuccess message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.EmptySuccess
         * @static
         * @param {common.EmptySuccess} message EmptySuccess
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmptySuccess.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.message = "";
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this EmptySuccess to JSON.
         * @function toJSON
         * @memberof common.EmptySuccess
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmptySuccess.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmptySuccess
         * @function getTypeUrl
         * @memberof common.EmptySuccess
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmptySuccess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.EmptySuccess";
        };

        return EmptySuccess;
    })();

    common.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof common
         * @interface IError
         * @property {string|null} [code] Error code
         * @property {string|null} [message] Error message
         * @property {string|null} [userMessage] Error userMessage
         * @property {string|null} [stackTrace] Error stackTrace
         * @property {string|null} [traceId] Error traceId
         * @property {string|null} [detailCode] Error detailCode
         */

        /**
         * Constructs a new Error.
         * @memberof common
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {common.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error code.
         * @member {string} code
         * @memberof common.Error
         * @instance
         */
        Error.prototype.code = "";

        /**
         * Error message.
         * @member {string} message
         * @memberof common.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Error userMessage.
         * @member {string} userMessage
         * @memberof common.Error
         * @instance
         */
        Error.prototype.userMessage = "";

        /**
         * Error stackTrace.
         * @member {string} stackTrace
         * @memberof common.Error
         * @instance
         */
        Error.prototype.stackTrace = "";

        /**
         * Error traceId.
         * @member {string} traceId
         * @memberof common.Error
         * @instance
         */
        Error.prototype.traceId = "";

        /**
         * Error detailCode.
         * @member {string} detailCode
         * @memberof common.Error
         * @instance
         */
        Error.prototype.detailCode = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof common.Error
         * @static
         * @param {common.IError=} [properties] Properties to set
         * @returns {common.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encode
         * @memberof common.Error
         * @static
         * @param {common.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.userMessage != null && Object.hasOwnProperty.call(message, "userMessage"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userMessage);
            if (message.stackTrace != null && Object.hasOwnProperty.call(message, "stackTrace"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.stackTrace);
            if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.traceId);
            if (message.detailCode != null && Object.hasOwnProperty.call(message, "detailCode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.detailCode);
            return writer;
        };

        /**
         * Encodes the specified Error message, length delimited. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Error
         * @static
         * @param {common.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Error();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.string();
                        break;
                    }
                case 2: {
                        message.message = reader.string();
                        break;
                    }
                case 3: {
                        message.userMessage = reader.string();
                        break;
                    }
                case 4: {
                        message.stackTrace = reader.string();
                        break;
                    }
                case 5: {
                        message.traceId = reader.string();
                        break;
                    }
                case 6: {
                        message.detailCode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Error message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Error message.
         * @function verify
         * @memberof common.Error
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Error.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.userMessage != null && message.hasOwnProperty("userMessage"))
                if (!$util.isString(message.userMessage))
                    return "userMessage: string expected";
            if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                if (!$util.isString(message.stackTrace))
                    return "stackTrace: string expected";
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                if (!$util.isString(message.traceId))
                    return "traceId: string expected";
            if (message.detailCode != null && message.hasOwnProperty("detailCode"))
                if (!$util.isString(message.detailCode))
                    return "detailCode: string expected";
            return null;
        };

        /**
         * Creates an Error message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Error
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Error} Error
         */
        Error.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Error)
                return object;
            let message = new $root.common.Error();
            if (object.code != null)
                message.code = String(object.code);
            if (object.message != null)
                message.message = String(object.message);
            if (object.userMessage != null)
                message.userMessage = String(object.userMessage);
            if (object.stackTrace != null)
                message.stackTrace = String(object.stackTrace);
            if (object.traceId != null)
                message.traceId = String(object.traceId);
            if (object.detailCode != null)
                message.detailCode = String(object.detailCode);
            return message;
        };

        /**
         * Creates a plain object from an Error message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Error
         * @static
         * @param {common.Error} message Error
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Error.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = "";
                object.message = "";
                object.userMessage = "";
                object.stackTrace = "";
                object.traceId = "";
                object.detailCode = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.userMessage != null && message.hasOwnProperty("userMessage"))
                object.userMessage = message.userMessage;
            if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                object.stackTrace = message.stackTrace;
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                object.traceId = message.traceId;
            if (message.detailCode != null && message.hasOwnProperty("detailCode"))
                object.detailCode = message.detailCode;
            return object;
        };

        /**
         * Converts this Error to JSON.
         * @function toJSON
         * @memberof common.Error
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Error
         * @function getTypeUrl
         * @memberof common.Error
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Error.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Error";
        };

        return Error;
    })();

    /**
     * Role enum.
     * @name common.Role
     * @enum {number}
     * @property {number} UNKNOWN_ROLE=0 UNKNOWN_ROLE value
     * @property {number} OWNER=1 OWNER value
     * @property {number} GM=2 GM value
     * @property {number} TRUSTED_PLAYER=3 TRUSTED_PLAYER value
     * @property {number} PLAYER=4 PLAYER value
     * @property {number} OBSERVER=5 OBSERVER value
     */
    common.Role = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ROLE"] = 0;
        values[valuesById[1] = "OWNER"] = 1;
        values[valuesById[2] = "GM"] = 2;
        values[valuesById[3] = "TRUSTED_PLAYER"] = 3;
        values[valuesById[4] = "PLAYER"] = 4;
        values[valuesById[5] = "OBSERVER"] = 5;
        return values;
    })();

    /**
     * VisibilityLevel enum.
     * @name common.VisibilityLevel
     * @enum {number}
     * @property {number} UNKNOWN_VISIBILITY_LEVEL=0 UNKNOWN_VISIBILITY_LEVEL value
     * @property {number} SECRET=1 SECRET value
     * @property {number} PROTECTED=2 PROTECTED value
     * @property {number} PUBLIC=3 PUBLIC value
     */
    common.VisibilityLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_VISIBILITY_LEVEL"] = 0;
        values[valuesById[1] = "SECRET"] = 1;
        values[valuesById[2] = "PROTECTED"] = 2;
        values[valuesById[3] = "PUBLIC"] = 3;
        return values;
    })();

    /**
     * AccessLevel enum.
     * @name common.AccessLevel
     * @enum {number}
     * @property {number} UNKNOWN_ACCESS_LEVEL=0 UNKNOWN_ACCESS_LEVEL value
     * @property {number} CONTROL=1 CONTROL value
     * @property {number} VIEW_ALL=2 VIEW_ALL value
     * @property {number} VIEW_PROTECTED=3 VIEW_PROTECTED value
     * @property {number} VIEW_PUBLIC=4 VIEW_PUBLIC value
     */
    common.AccessLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ACCESS_LEVEL"] = 0;
        values[valuesById[1] = "CONTROL"] = 1;
        values[valuesById[2] = "VIEW_ALL"] = 2;
        values[valuesById[3] = "VIEW_PROTECTED"] = 3;
        values[valuesById[4] = "VIEW_PUBLIC"] = 4;
        return values;
    })();

    /**
     * AccessGrantType enum.
     * @name common.AccessGrantType
     * @enum {number}
     * @property {number} UNKNOWN_ACCESS_GRANT_TYPE=0 UNKNOWN_ACCESS_GRANT_TYPE value
     * @property {number} USER=1 USER value
     * @property {number} ALL_PLAYERS=2 ALL_PLAYERS value
     */
    common.AccessGrantType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ACCESS_GRANT_TYPE"] = 0;
        values[valuesById[1] = "USER"] = 1;
        values[valuesById[2] = "ALL_PLAYERS"] = 2;
        return values;
    })();

    common.AccessGrant = (function() {

        /**
         * Properties of an AccessGrant.
         * @memberof common
         * @interface IAccessGrant
         * @property {string|null} [grantId] AccessGrant grantId
         * @property {common.AccessGrantType|null} [authType] AccessGrant authType
         * @property {string|null} [authId] AccessGrant authId
         * @property {common.AccessLevel|null} [accessLevel] AccessGrant accessLevel
         * @property {string|null} [grantSource] AccessGrant grantSource
         */

        /**
         * Constructs a new AccessGrant.
         * @memberof common
         * @classdesc Represents an AccessGrant.
         * @implements IAccessGrant
         * @constructor
         * @param {common.IAccessGrant=} [properties] Properties to set
         */
        function AccessGrant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AccessGrant grantId.
         * @member {string} grantId
         * @memberof common.AccessGrant
         * @instance
         */
        AccessGrant.prototype.grantId = "";

        /**
         * AccessGrant authType.
         * @member {common.AccessGrantType} authType
         * @memberof common.AccessGrant
         * @instance
         */
        AccessGrant.prototype.authType = 0;

        /**
         * AccessGrant authId.
         * @member {string} authId
         * @memberof common.AccessGrant
         * @instance
         */
        AccessGrant.prototype.authId = "";

        /**
         * AccessGrant accessLevel.
         * @member {common.AccessLevel} accessLevel
         * @memberof common.AccessGrant
         * @instance
         */
        AccessGrant.prototype.accessLevel = 0;

        /**
         * AccessGrant grantSource.
         * @member {string} grantSource
         * @memberof common.AccessGrant
         * @instance
         */
        AccessGrant.prototype.grantSource = "";

        /**
         * Creates a new AccessGrant instance using the specified properties.
         * @function create
         * @memberof common.AccessGrant
         * @static
         * @param {common.IAccessGrant=} [properties] Properties to set
         * @returns {common.AccessGrant} AccessGrant instance
         */
        AccessGrant.create = function create(properties) {
            return new AccessGrant(properties);
        };

        /**
         * Encodes the specified AccessGrant message. Does not implicitly {@link common.AccessGrant.verify|verify} messages.
         * @function encode
         * @memberof common.AccessGrant
         * @static
         * @param {common.IAccessGrant} message AccessGrant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccessGrant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.grantId != null && Object.hasOwnProperty.call(message, "grantId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.grantId);
            if (message.authType != null && Object.hasOwnProperty.call(message, "authType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.authType);
            if (message.authId != null && Object.hasOwnProperty.call(message, "authId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.authId);
            if (message.accessLevel != null && Object.hasOwnProperty.call(message, "accessLevel"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.accessLevel);
            if (message.grantSource != null && Object.hasOwnProperty.call(message, "grantSource"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.grantSource);
            return writer;
        };

        /**
         * Encodes the specified AccessGrant message, length delimited. Does not implicitly {@link common.AccessGrant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.AccessGrant
         * @static
         * @param {common.IAccessGrant} message AccessGrant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AccessGrant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AccessGrant message from the specified reader or buffer.
         * @function decode
         * @memberof common.AccessGrant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.AccessGrant} AccessGrant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccessGrant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.AccessGrant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.grantId = reader.string();
                        break;
                    }
                case 2: {
                        message.authType = reader.int32();
                        break;
                    }
                case 3: {
                        message.authId = reader.string();
                        break;
                    }
                case 4: {
                        message.accessLevel = reader.int32();
                        break;
                    }
                case 5: {
                        message.grantSource = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AccessGrant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.AccessGrant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.AccessGrant} AccessGrant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AccessGrant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AccessGrant message.
         * @function verify
         * @memberof common.AccessGrant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AccessGrant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.grantId != null && message.hasOwnProperty("grantId"))
                if (!$util.isString(message.grantId))
                    return "grantId: string expected";
            if (message.authType != null && message.hasOwnProperty("authType"))
                switch (message.authType) {
                default:
                    return "authType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.authId != null && message.hasOwnProperty("authId"))
                if (!$util.isString(message.authId))
                    return "authId: string expected";
            if (message.accessLevel != null && message.hasOwnProperty("accessLevel"))
                switch (message.accessLevel) {
                default:
                    return "accessLevel: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.grantSource != null && message.hasOwnProperty("grantSource"))
                if (!$util.isString(message.grantSource))
                    return "grantSource: string expected";
            return null;
        };

        /**
         * Creates an AccessGrant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.AccessGrant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.AccessGrant} AccessGrant
         */
        AccessGrant.fromObject = function fromObject(object) {
            if (object instanceof $root.common.AccessGrant)
                return object;
            let message = new $root.common.AccessGrant();
            if (object.grantId != null)
                message.grantId = String(object.grantId);
            switch (object.authType) {
            default:
                if (typeof object.authType === "number") {
                    message.authType = object.authType;
                    break;
                }
                break;
            case "UNKNOWN_ACCESS_GRANT_TYPE":
            case 0:
                message.authType = 0;
                break;
            case "USER":
            case 1:
                message.authType = 1;
                break;
            case "ALL_PLAYERS":
            case 2:
                message.authType = 2;
                break;
            }
            if (object.authId != null)
                message.authId = String(object.authId);
            switch (object.accessLevel) {
            default:
                if (typeof object.accessLevel === "number") {
                    message.accessLevel = object.accessLevel;
                    break;
                }
                break;
            case "UNKNOWN_ACCESS_LEVEL":
            case 0:
                message.accessLevel = 0;
                break;
            case "CONTROL":
            case 1:
                message.accessLevel = 1;
                break;
            case "VIEW_ALL":
            case 2:
                message.accessLevel = 2;
                break;
            case "VIEW_PROTECTED":
            case 3:
                message.accessLevel = 3;
                break;
            case "VIEW_PUBLIC":
            case 4:
                message.accessLevel = 4;
                break;
            }
            if (object.grantSource != null)
                message.grantSource = String(object.grantSource);
            return message;
        };

        /**
         * Creates a plain object from an AccessGrant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.AccessGrant
         * @static
         * @param {common.AccessGrant} message AccessGrant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AccessGrant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.grantId = "";
                object.authType = options.enums === String ? "UNKNOWN_ACCESS_GRANT_TYPE" : 0;
                object.authId = "";
                object.accessLevel = options.enums === String ? "UNKNOWN_ACCESS_LEVEL" : 0;
                object.grantSource = "";
            }
            if (message.grantId != null && message.hasOwnProperty("grantId"))
                object.grantId = message.grantId;
            if (message.authType != null && message.hasOwnProperty("authType"))
                object.authType = options.enums === String ? $root.common.AccessGrantType[message.authType] === undefined ? message.authType : $root.common.AccessGrantType[message.authType] : message.authType;
            if (message.authId != null && message.hasOwnProperty("authId"))
                object.authId = message.authId;
            if (message.accessLevel != null && message.hasOwnProperty("accessLevel"))
                object.accessLevel = options.enums === String ? $root.common.AccessLevel[message.accessLevel] === undefined ? message.accessLevel : $root.common.AccessLevel[message.accessLevel] : message.accessLevel;
            if (message.grantSource != null && message.hasOwnProperty("grantSource"))
                object.grantSource = message.grantSource;
            return object;
        };

        /**
         * Converts this AccessGrant to JSON.
         * @function toJSON
         * @memberof common.AccessGrant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AccessGrant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AccessGrant
         * @function getTypeUrl
         * @memberof common.AccessGrant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AccessGrant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.AccessGrant";
        };

        return AccessGrant;
    })();

    common.UserRole = (function() {

        /**
         * Properties of a UserRole.
         * @memberof common
         * @interface IUserRole
         * @property {string|null} [userRoleId] UserRole userRoleId
         * @property {string|null} [userId] UserRole userId
         * @property {common.Role|null} [role] UserRole role
         */

        /**
         * Constructs a new UserRole.
         * @memberof common
         * @classdesc Represents a UserRole.
         * @implements IUserRole
         * @constructor
         * @param {common.IUserRole=} [properties] Properties to set
         */
        function UserRole(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserRole userRoleId.
         * @member {string} userRoleId
         * @memberof common.UserRole
         * @instance
         */
        UserRole.prototype.userRoleId = "";

        /**
         * UserRole userId.
         * @member {string} userId
         * @memberof common.UserRole
         * @instance
         */
        UserRole.prototype.userId = "";

        /**
         * UserRole role.
         * @member {common.Role} role
         * @memberof common.UserRole
         * @instance
         */
        UserRole.prototype.role = 0;

        /**
         * Creates a new UserRole instance using the specified properties.
         * @function create
         * @memberof common.UserRole
         * @static
         * @param {common.IUserRole=} [properties] Properties to set
         * @returns {common.UserRole} UserRole instance
         */
        UserRole.create = function create(properties) {
            return new UserRole(properties);
        };

        /**
         * Encodes the specified UserRole message. Does not implicitly {@link common.UserRole.verify|verify} messages.
         * @function encode
         * @memberof common.UserRole
         * @static
         * @param {common.IUserRole} message UserRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRole.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userRoleId != null && Object.hasOwnProperty.call(message, "userRoleId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userRoleId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.role);
            return writer;
        };

        /**
         * Encodes the specified UserRole message, length delimited. Does not implicitly {@link common.UserRole.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.UserRole
         * @static
         * @param {common.IUserRole} message UserRole message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserRole.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserRole message from the specified reader or buffer.
         * @function decode
         * @memberof common.UserRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.UserRole} UserRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRole.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.UserRole();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userRoleId = reader.string();
                        break;
                    }
                case 2: {
                        message.userId = reader.string();
                        break;
                    }
                case 3: {
                        message.role = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserRole message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.UserRole
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.UserRole} UserRole
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserRole.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserRole message.
         * @function verify
         * @memberof common.UserRole
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserRole.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userRoleId != null && message.hasOwnProperty("userRoleId"))
                if (!$util.isString(message.userRoleId))
                    return "userRoleId: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a UserRole message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.UserRole
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.UserRole} UserRole
         */
        UserRole.fromObject = function fromObject(object) {
            if (object instanceof $root.common.UserRole)
                return object;
            let message = new $root.common.UserRole();
            if (object.userRoleId != null)
                message.userRoleId = String(object.userRoleId);
            if (object.userId != null)
                message.userId = String(object.userId);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "UNKNOWN_ROLE":
            case 0:
                message.role = 0;
                break;
            case "OWNER":
            case 1:
                message.role = 1;
                break;
            case "GM":
            case 2:
                message.role = 2;
                break;
            case "TRUSTED_PLAYER":
            case 3:
                message.role = 3;
                break;
            case "PLAYER":
            case 4:
                message.role = 4;
                break;
            case "OBSERVER":
            case 5:
                message.role = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a UserRole message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.UserRole
         * @static
         * @param {common.UserRole} message UserRole
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserRole.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userRoleId = "";
                object.userId = "";
                object.role = options.enums === String ? "UNKNOWN_ROLE" : 0;
            }
            if (message.userRoleId != null && message.hasOwnProperty("userRoleId"))
                object.userRoleId = message.userRoleId;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.common.Role[message.role] === undefined ? message.role : $root.common.Role[message.role] : message.role;
            return object;
        };

        /**
         * Converts this UserRole to JSON.
         * @function toJSON
         * @memberof common.UserRole
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserRole.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserRole
         * @function getTypeUrl
         * @memberof common.UserRole
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserRole.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.UserRole";
        };

        return UserRole;
    })();

    common.Point2D = (function() {

        /**
         * Properties of a Point2D.
         * @memberof common
         * @interface IPoint2D
         * @property {boolean|null} [ex] Point2D ex
         * @property {number|null} [x] Point2D x
         * @property {number|null} [y] Point2D y
         */

        /**
         * Constructs a new Point2D.
         * @memberof common
         * @classdesc Represents a Point2D.
         * @implements IPoint2D
         * @constructor
         * @param {common.IPoint2D=} [properties] Properties to set
         */
        function Point2D(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Point2D ex.
         * @member {boolean} ex
         * @memberof common.Point2D
         * @instance
         */
        Point2D.prototype.ex = false;

        /**
         * Point2D x.
         * @member {number} x
         * @memberof common.Point2D
         * @instance
         */
        Point2D.prototype.x = 0;

        /**
         * Point2D y.
         * @member {number} y
         * @memberof common.Point2D
         * @instance
         */
        Point2D.prototype.y = 0;

        /**
         * Creates a new Point2D instance using the specified properties.
         * @function create
         * @memberof common.Point2D
         * @static
         * @param {common.IPoint2D=} [properties] Properties to set
         * @returns {common.Point2D} Point2D instance
         */
        Point2D.create = function create(properties) {
            return new Point2D(properties);
        };

        /**
         * Encodes the specified Point2D message. Does not implicitly {@link common.Point2D.verify|verify} messages.
         * @function encode
         * @memberof common.Point2D
         * @static
         * @param {common.IPoint2D} message Point2D message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point2D.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ex != null && Object.hasOwnProperty.call(message, "ex"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ex);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.y);
            return writer;
        };

        /**
         * Encodes the specified Point2D message, length delimited. Does not implicitly {@link common.Point2D.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Point2D
         * @static
         * @param {common.IPoint2D} message Point2D message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point2D.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Point2D message from the specified reader or buffer.
         * @function decode
         * @memberof common.Point2D
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Point2D} Point2D
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point2D.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Point2D();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ex = reader.bool();
                        break;
                    }
                case 2: {
                        message.x = reader.float();
                        break;
                    }
                case 3: {
                        message.y = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Point2D message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Point2D
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Point2D} Point2D
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point2D.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Point2D message.
         * @function verify
         * @memberof common.Point2D
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Point2D.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ex != null && message.hasOwnProperty("ex"))
                if (typeof message.ex !== "boolean")
                    return "ex: boolean expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (typeof message.x !== "number")
                    return "x: number expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (typeof message.y !== "number")
                    return "y: number expected";
            return null;
        };

        /**
         * Creates a Point2D message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Point2D
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Point2D} Point2D
         */
        Point2D.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Point2D)
                return object;
            let message = new $root.common.Point2D();
            if (object.ex != null)
                message.ex = Boolean(object.ex);
            if (object.x != null)
                message.x = Number(object.x);
            if (object.y != null)
                message.y = Number(object.y);
            return message;
        };

        /**
         * Creates a plain object from a Point2D message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Point2D
         * @static
         * @param {common.Point2D} message Point2D
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Point2D.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ex = false;
                object.x = 0;
                object.y = 0;
            }
            if (message.ex != null && message.hasOwnProperty("ex"))
                object.ex = message.ex;
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = options.json && !isFinite(message.x) ? String(message.x) : message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = options.json && !isFinite(message.y) ? String(message.y) : message.y;
            return object;
        };

        /**
         * Converts this Point2D to JSON.
         * @function toJSON
         * @memberof common.Point2D
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Point2D.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Point2D
         * @function getTypeUrl
         * @memberof common.Point2D
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Point2D.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Point2D";
        };

        return Point2D;
    })();

    common.Rect2D = (function() {

        /**
         * Properties of a Rect2D.
         * @memberof common
         * @interface IRect2D
         * @property {boolean|null} [ex] Rect2D ex
         * @property {number|null} [x1] Rect2D x1
         * @property {number|null} [y1] Rect2D y1
         * @property {number|null} [x2] Rect2D x2
         * @property {number|null} [y2] Rect2D y2
         */

        /**
         * Constructs a new Rect2D.
         * @memberof common
         * @classdesc Represents a Rect2D.
         * @implements IRect2D
         * @constructor
         * @param {common.IRect2D=} [properties] Properties to set
         */
        function Rect2D(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Rect2D ex.
         * @member {boolean} ex
         * @memberof common.Rect2D
         * @instance
         */
        Rect2D.prototype.ex = false;

        /**
         * Rect2D x1.
         * @member {number} x1
         * @memberof common.Rect2D
         * @instance
         */
        Rect2D.prototype.x1 = 0;

        /**
         * Rect2D y1.
         * @member {number} y1
         * @memberof common.Rect2D
         * @instance
         */
        Rect2D.prototype.y1 = 0;

        /**
         * Rect2D x2.
         * @member {number} x2
         * @memberof common.Rect2D
         * @instance
         */
        Rect2D.prototype.x2 = 0;

        /**
         * Rect2D y2.
         * @member {number} y2
         * @memberof common.Rect2D
         * @instance
         */
        Rect2D.prototype.y2 = 0;

        /**
         * Creates a new Rect2D instance using the specified properties.
         * @function create
         * @memberof common.Rect2D
         * @static
         * @param {common.IRect2D=} [properties] Properties to set
         * @returns {common.Rect2D} Rect2D instance
         */
        Rect2D.create = function create(properties) {
            return new Rect2D(properties);
        };

        /**
         * Encodes the specified Rect2D message. Does not implicitly {@link common.Rect2D.verify|verify} messages.
         * @function encode
         * @memberof common.Rect2D
         * @static
         * @param {common.IRect2D} message Rect2D message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rect2D.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ex != null && Object.hasOwnProperty.call(message, "ex"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ex);
            if (message.x1 != null && Object.hasOwnProperty.call(message, "x1"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.x1);
            if (message.y1 != null && Object.hasOwnProperty.call(message, "y1"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.y1);
            if (message.x2 != null && Object.hasOwnProperty.call(message, "x2"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.x2);
            if (message.y2 != null && Object.hasOwnProperty.call(message, "y2"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.y2);
            return writer;
        };

        /**
         * Encodes the specified Rect2D message, length delimited. Does not implicitly {@link common.Rect2D.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.Rect2D
         * @static
         * @param {common.IRect2D} message Rect2D message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rect2D.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Rect2D message from the specified reader or buffer.
         * @function decode
         * @memberof common.Rect2D
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.Rect2D} Rect2D
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rect2D.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.Rect2D();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ex = reader.bool();
                        break;
                    }
                case 2: {
                        message.x1 = reader.float();
                        break;
                    }
                case 3: {
                        message.y1 = reader.float();
                        break;
                    }
                case 4: {
                        message.x2 = reader.float();
                        break;
                    }
                case 5: {
                        message.y2 = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Rect2D message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.Rect2D
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.Rect2D} Rect2D
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rect2D.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Rect2D message.
         * @function verify
         * @memberof common.Rect2D
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Rect2D.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ex != null && message.hasOwnProperty("ex"))
                if (typeof message.ex !== "boolean")
                    return "ex: boolean expected";
            if (message.x1 != null && message.hasOwnProperty("x1"))
                if (typeof message.x1 !== "number")
                    return "x1: number expected";
            if (message.y1 != null && message.hasOwnProperty("y1"))
                if (typeof message.y1 !== "number")
                    return "y1: number expected";
            if (message.x2 != null && message.hasOwnProperty("x2"))
                if (typeof message.x2 !== "number")
                    return "x2: number expected";
            if (message.y2 != null && message.hasOwnProperty("y2"))
                if (typeof message.y2 !== "number")
                    return "y2: number expected";
            return null;
        };

        /**
         * Creates a Rect2D message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.Rect2D
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.Rect2D} Rect2D
         */
        Rect2D.fromObject = function fromObject(object) {
            if (object instanceof $root.common.Rect2D)
                return object;
            let message = new $root.common.Rect2D();
            if (object.ex != null)
                message.ex = Boolean(object.ex);
            if (object.x1 != null)
                message.x1 = Number(object.x1);
            if (object.y1 != null)
                message.y1 = Number(object.y1);
            if (object.x2 != null)
                message.x2 = Number(object.x2);
            if (object.y2 != null)
                message.y2 = Number(object.y2);
            return message;
        };

        /**
         * Creates a plain object from a Rect2D message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.Rect2D
         * @static
         * @param {common.Rect2D} message Rect2D
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Rect2D.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ex = false;
                object.x1 = 0;
                object.y1 = 0;
                object.x2 = 0;
                object.y2 = 0;
            }
            if (message.ex != null && message.hasOwnProperty("ex"))
                object.ex = message.ex;
            if (message.x1 != null && message.hasOwnProperty("x1"))
                object.x1 = options.json && !isFinite(message.x1) ? String(message.x1) : message.x1;
            if (message.y1 != null && message.hasOwnProperty("y1"))
                object.y1 = options.json && !isFinite(message.y1) ? String(message.y1) : message.y1;
            if (message.x2 != null && message.hasOwnProperty("x2"))
                object.x2 = options.json && !isFinite(message.x2) ? String(message.x2) : message.x2;
            if (message.y2 != null && message.hasOwnProperty("y2"))
                object.y2 = options.json && !isFinite(message.y2) ? String(message.y2) : message.y2;
            return object;
        };

        /**
         * Converts this Rect2D to JSON.
         * @function toJSON
         * @memberof common.Rect2D
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Rect2D.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Rect2D
         * @function getTypeUrl
         * @memberof common.Rect2D
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Rect2D.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Rect2D";
        };

        return Rect2D;
    })();

    common.GridLoc = (function() {

        /**
         * Properties of a GridLoc.
         * @memberof common
         * @interface IGridLoc
         * @property {boolean|null} [ex] GridLoc ex
         * @property {number|null} [row] GridLoc row
         * @property {number|null} [col] GridLoc col
         */

        /**
         * Constructs a new GridLoc.
         * @memberof common
         * @classdesc Represents a GridLoc.
         * @implements IGridLoc
         * @constructor
         * @param {common.IGridLoc=} [properties] Properties to set
         */
        function GridLoc(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridLoc ex.
         * @member {boolean} ex
         * @memberof common.GridLoc
         * @instance
         */
        GridLoc.prototype.ex = false;

        /**
         * GridLoc row.
         * @member {number} row
         * @memberof common.GridLoc
         * @instance
         */
        GridLoc.prototype.row = 0;

        /**
         * GridLoc col.
         * @member {number} col
         * @memberof common.GridLoc
         * @instance
         */
        GridLoc.prototype.col = 0;

        /**
         * Creates a new GridLoc instance using the specified properties.
         * @function create
         * @memberof common.GridLoc
         * @static
         * @param {common.IGridLoc=} [properties] Properties to set
         * @returns {common.GridLoc} GridLoc instance
         */
        GridLoc.create = function create(properties) {
            return new GridLoc(properties);
        };

        /**
         * Encodes the specified GridLoc message. Does not implicitly {@link common.GridLoc.verify|verify} messages.
         * @function encode
         * @memberof common.GridLoc
         * @static
         * @param {common.IGridLoc} message GridLoc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridLoc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ex != null && Object.hasOwnProperty.call(message, "ex"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ex);
            if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.row);
            if (message.col != null && Object.hasOwnProperty.call(message, "col"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.col);
            return writer;
        };

        /**
         * Encodes the specified GridLoc message, length delimited. Does not implicitly {@link common.GridLoc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.GridLoc
         * @static
         * @param {common.IGridLoc} message GridLoc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridLoc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridLoc message from the specified reader or buffer.
         * @function decode
         * @memberof common.GridLoc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.GridLoc} GridLoc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridLoc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.GridLoc();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ex = reader.bool();
                        break;
                    }
                case 2: {
                        message.row = reader.int32();
                        break;
                    }
                case 3: {
                        message.col = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridLoc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.GridLoc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.GridLoc} GridLoc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridLoc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridLoc message.
         * @function verify
         * @memberof common.GridLoc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridLoc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ex != null && message.hasOwnProperty("ex"))
                if (typeof message.ex !== "boolean")
                    return "ex: boolean expected";
            if (message.row != null && message.hasOwnProperty("row"))
                if (!$util.isInteger(message.row))
                    return "row: integer expected";
            if (message.col != null && message.hasOwnProperty("col"))
                if (!$util.isInteger(message.col))
                    return "col: integer expected";
            return null;
        };

        /**
         * Creates a GridLoc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.GridLoc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.GridLoc} GridLoc
         */
        GridLoc.fromObject = function fromObject(object) {
            if (object instanceof $root.common.GridLoc)
                return object;
            let message = new $root.common.GridLoc();
            if (object.ex != null)
                message.ex = Boolean(object.ex);
            if (object.row != null)
                message.row = object.row | 0;
            if (object.col != null)
                message.col = object.col | 0;
            return message;
        };

        /**
         * Creates a plain object from a GridLoc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.GridLoc
         * @static
         * @param {common.GridLoc} message GridLoc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridLoc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ex = false;
                object.row = 0;
                object.col = 0;
            }
            if (message.ex != null && message.hasOwnProperty("ex"))
                object.ex = message.ex;
            if (message.row != null && message.hasOwnProperty("row"))
                object.row = message.row;
            if (message.col != null && message.hasOwnProperty("col"))
                object.col = message.col;
            return object;
        };

        /**
         * Converts this GridLoc to JSON.
         * @function toJSON
         * @memberof common.GridLoc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridLoc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridLoc
         * @function getTypeUrl
         * @memberof common.GridLoc
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridLoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.GridLoc";
        };

        GridLoc.SubgridLoc = (function() {

            /**
             * Properties of a SubgridLoc.
             * @memberof common.GridLoc
             * @interface ISubgridLoc
             * @property {number|null} [rowLoc] SubgridLoc rowLoc
             * @property {number|null} [colLoc] SubgridLoc colLoc
             */

            /**
             * Constructs a new SubgridLoc.
             * @memberof common.GridLoc
             * @classdesc Represents a SubgridLoc.
             * @implements ISubgridLoc
             * @constructor
             * @param {common.GridLoc.ISubgridLoc=} [properties] Properties to set
             */
            function SubgridLoc(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubgridLoc rowLoc.
             * @member {number} rowLoc
             * @memberof common.GridLoc.SubgridLoc
             * @instance
             */
            SubgridLoc.prototype.rowLoc = 0;

            /**
             * SubgridLoc colLoc.
             * @member {number} colLoc
             * @memberof common.GridLoc.SubgridLoc
             * @instance
             */
            SubgridLoc.prototype.colLoc = 0;

            /**
             * Creates a new SubgridLoc instance using the specified properties.
             * @function create
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {common.GridLoc.ISubgridLoc=} [properties] Properties to set
             * @returns {common.GridLoc.SubgridLoc} SubgridLoc instance
             */
            SubgridLoc.create = function create(properties) {
                return new SubgridLoc(properties);
            };

            /**
             * Encodes the specified SubgridLoc message. Does not implicitly {@link common.GridLoc.SubgridLoc.verify|verify} messages.
             * @function encode
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {common.GridLoc.ISubgridLoc} message SubgridLoc message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubgridLoc.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.rowLoc != null && Object.hasOwnProperty.call(message, "rowLoc"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.rowLoc);
                if (message.colLoc != null && Object.hasOwnProperty.call(message, "colLoc"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.colLoc);
                return writer;
            };

            /**
             * Encodes the specified SubgridLoc message, length delimited. Does not implicitly {@link common.GridLoc.SubgridLoc.verify|verify} messages.
             * @function encodeDelimited
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {common.GridLoc.ISubgridLoc} message SubgridLoc message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubgridLoc.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SubgridLoc message from the specified reader or buffer.
             * @function decode
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {common.GridLoc.SubgridLoc} SubgridLoc
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubgridLoc.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.GridLoc.SubgridLoc();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.rowLoc = reader.float();
                            break;
                        }
                    case 2: {
                            message.colLoc = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SubgridLoc message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {common.GridLoc.SubgridLoc} SubgridLoc
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubgridLoc.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SubgridLoc message.
             * @function verify
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubgridLoc.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.rowLoc != null && message.hasOwnProperty("rowLoc"))
                    if (typeof message.rowLoc !== "number")
                        return "rowLoc: number expected";
                if (message.colLoc != null && message.hasOwnProperty("colLoc"))
                    if (typeof message.colLoc !== "number")
                        return "colLoc: number expected";
                return null;
            };

            /**
             * Creates a SubgridLoc message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {common.GridLoc.SubgridLoc} SubgridLoc
             */
            SubgridLoc.fromObject = function fromObject(object) {
                if (object instanceof $root.common.GridLoc.SubgridLoc)
                    return object;
                let message = new $root.common.GridLoc.SubgridLoc();
                if (object.rowLoc != null)
                    message.rowLoc = Number(object.rowLoc);
                if (object.colLoc != null)
                    message.colLoc = Number(object.colLoc);
                return message;
            };

            /**
             * Creates a plain object from a SubgridLoc message. Also converts values to other types if specified.
             * @function toObject
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {common.GridLoc.SubgridLoc} message SubgridLoc
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubgridLoc.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.rowLoc = 0;
                    object.colLoc = 0;
                }
                if (message.rowLoc != null && message.hasOwnProperty("rowLoc"))
                    object.rowLoc = options.json && !isFinite(message.rowLoc) ? String(message.rowLoc) : message.rowLoc;
                if (message.colLoc != null && message.hasOwnProperty("colLoc"))
                    object.colLoc = options.json && !isFinite(message.colLoc) ? String(message.colLoc) : message.colLoc;
                return object;
            };

            /**
             * Converts this SubgridLoc to JSON.
             * @function toJSON
             * @memberof common.GridLoc.SubgridLoc
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubgridLoc.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SubgridLoc
             * @function getTypeUrl
             * @memberof common.GridLoc.SubgridLoc
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SubgridLoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/common.GridLoc.SubgridLoc";
            };

            return SubgridLoc;
        })();

        return GridLoc;
    })();

    common.SubgridLoc = (function() {

        /**
         * Properties of a SubgridLoc.
         * @memberof common
         * @interface ISubgridLoc
         * @property {boolean|null} [ex] SubgridLoc ex
         * @property {number|null} [row] SubgridLoc row
         * @property {number|null} [col] SubgridLoc col
         * @property {number|null} [subrow] SubgridLoc subrow
         * @property {number|null} [subcol] SubgridLoc subcol
         */

        /**
         * Constructs a new SubgridLoc.
         * @memberof common
         * @classdesc Represents a SubgridLoc.
         * @implements ISubgridLoc
         * @constructor
         * @param {common.ISubgridLoc=} [properties] Properties to set
         */
        function SubgridLoc(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubgridLoc ex.
         * @member {boolean} ex
         * @memberof common.SubgridLoc
         * @instance
         */
        SubgridLoc.prototype.ex = false;

        /**
         * SubgridLoc row.
         * @member {number} row
         * @memberof common.SubgridLoc
         * @instance
         */
        SubgridLoc.prototype.row = 0;

        /**
         * SubgridLoc col.
         * @member {number} col
         * @memberof common.SubgridLoc
         * @instance
         */
        SubgridLoc.prototype.col = 0;

        /**
         * SubgridLoc subrow.
         * @member {number} subrow
         * @memberof common.SubgridLoc
         * @instance
         */
        SubgridLoc.prototype.subrow = 0;

        /**
         * SubgridLoc subcol.
         * @member {number} subcol
         * @memberof common.SubgridLoc
         * @instance
         */
        SubgridLoc.prototype.subcol = 0;

        /**
         * Creates a new SubgridLoc instance using the specified properties.
         * @function create
         * @memberof common.SubgridLoc
         * @static
         * @param {common.ISubgridLoc=} [properties] Properties to set
         * @returns {common.SubgridLoc} SubgridLoc instance
         */
        SubgridLoc.create = function create(properties) {
            return new SubgridLoc(properties);
        };

        /**
         * Encodes the specified SubgridLoc message. Does not implicitly {@link common.SubgridLoc.verify|verify} messages.
         * @function encode
         * @memberof common.SubgridLoc
         * @static
         * @param {common.ISubgridLoc} message SubgridLoc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubgridLoc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ex != null && Object.hasOwnProperty.call(message, "ex"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ex);
            if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.row);
            if (message.col != null && Object.hasOwnProperty.call(message, "col"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.col);
            if (message.subrow != null && Object.hasOwnProperty.call(message, "subrow"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.subrow);
            if (message.subcol != null && Object.hasOwnProperty.call(message, "subcol"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.subcol);
            return writer;
        };

        /**
         * Encodes the specified SubgridLoc message, length delimited. Does not implicitly {@link common.SubgridLoc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.SubgridLoc
         * @static
         * @param {common.ISubgridLoc} message SubgridLoc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubgridLoc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubgridLoc message from the specified reader or buffer.
         * @function decode
         * @memberof common.SubgridLoc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.SubgridLoc} SubgridLoc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubgridLoc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.SubgridLoc();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ex = reader.bool();
                        break;
                    }
                case 2: {
                        message.row = reader.int32();
                        break;
                    }
                case 3: {
                        message.col = reader.int32();
                        break;
                    }
                case 4: {
                        message.subrow = reader.int32();
                        break;
                    }
                case 5: {
                        message.subcol = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubgridLoc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.SubgridLoc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.SubgridLoc} SubgridLoc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubgridLoc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubgridLoc message.
         * @function verify
         * @memberof common.SubgridLoc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubgridLoc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ex != null && message.hasOwnProperty("ex"))
                if (typeof message.ex !== "boolean")
                    return "ex: boolean expected";
            if (message.row != null && message.hasOwnProperty("row"))
                if (!$util.isInteger(message.row))
                    return "row: integer expected";
            if (message.col != null && message.hasOwnProperty("col"))
                if (!$util.isInteger(message.col))
                    return "col: integer expected";
            if (message.subrow != null && message.hasOwnProperty("subrow"))
                if (!$util.isInteger(message.subrow))
                    return "subrow: integer expected";
            if (message.subcol != null && message.hasOwnProperty("subcol"))
                if (!$util.isInteger(message.subcol))
                    return "subcol: integer expected";
            return null;
        };

        /**
         * Creates a SubgridLoc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.SubgridLoc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.SubgridLoc} SubgridLoc
         */
        SubgridLoc.fromObject = function fromObject(object) {
            if (object instanceof $root.common.SubgridLoc)
                return object;
            let message = new $root.common.SubgridLoc();
            if (object.ex != null)
                message.ex = Boolean(object.ex);
            if (object.row != null)
                message.row = object.row | 0;
            if (object.col != null)
                message.col = object.col | 0;
            if (object.subrow != null)
                message.subrow = object.subrow | 0;
            if (object.subcol != null)
                message.subcol = object.subcol | 0;
            return message;
        };

        /**
         * Creates a plain object from a SubgridLoc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.SubgridLoc
         * @static
         * @param {common.SubgridLoc} message SubgridLoc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubgridLoc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.ex = false;
                object.row = 0;
                object.col = 0;
                object.subrow = 0;
                object.subcol = 0;
            }
            if (message.ex != null && message.hasOwnProperty("ex"))
                object.ex = message.ex;
            if (message.row != null && message.hasOwnProperty("row"))
                object.row = message.row;
            if (message.col != null && message.hasOwnProperty("col"))
                object.col = message.col;
            if (message.subrow != null && message.hasOwnProperty("subrow"))
                object.subrow = message.subrow;
            if (message.subcol != null && message.hasOwnProperty("subcol"))
                object.subcol = message.subcol;
            return object;
        };

        /**
         * Converts this SubgridLoc to JSON.
         * @function toJSON
         * @memberof common.SubgridLoc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubgridLoc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SubgridLoc
         * @function getTypeUrl
         * @memberof common.SubgridLoc
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SubgridLoc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.SubgridLoc";
        };

        return SubgridLoc;
    })();

    common.FieldPermissions = (function() {

        /**
         * Properties of a FieldPermissions.
         * @memberof common
         * @interface IFieldPermissions
         * @property {boolean|null} [canRead] FieldPermissions canRead
         * @property {boolean|null} [canWrite] FieldPermissions canWrite
         */

        /**
         * Constructs a new FieldPermissions.
         * @memberof common
         * @classdesc Represents a FieldPermissions.
         * @implements IFieldPermissions
         * @constructor
         * @param {common.IFieldPermissions=} [properties] Properties to set
         */
        function FieldPermissions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FieldPermissions canRead.
         * @member {boolean} canRead
         * @memberof common.FieldPermissions
         * @instance
         */
        FieldPermissions.prototype.canRead = false;

        /**
         * FieldPermissions canWrite.
         * @member {boolean} canWrite
         * @memberof common.FieldPermissions
         * @instance
         */
        FieldPermissions.prototype.canWrite = false;

        /**
         * Creates a new FieldPermissions instance using the specified properties.
         * @function create
         * @memberof common.FieldPermissions
         * @static
         * @param {common.IFieldPermissions=} [properties] Properties to set
         * @returns {common.FieldPermissions} FieldPermissions instance
         */
        FieldPermissions.create = function create(properties) {
            return new FieldPermissions(properties);
        };

        /**
         * Encodes the specified FieldPermissions message. Does not implicitly {@link common.FieldPermissions.verify|verify} messages.
         * @function encode
         * @memberof common.FieldPermissions
         * @static
         * @param {common.IFieldPermissions} message FieldPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldPermissions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canRead != null && Object.hasOwnProperty.call(message, "canRead"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canRead);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canWrite);
            return writer;
        };

        /**
         * Encodes the specified FieldPermissions message, length delimited. Does not implicitly {@link common.FieldPermissions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof common.FieldPermissions
         * @static
         * @param {common.IFieldPermissions} message FieldPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FieldPermissions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FieldPermissions message from the specified reader or buffer.
         * @function decode
         * @memberof common.FieldPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {common.FieldPermissions} FieldPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldPermissions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.common.FieldPermissions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.canRead = reader.bool();
                        break;
                    }
                case 2: {
                        message.canWrite = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FieldPermissions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof common.FieldPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {common.FieldPermissions} FieldPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FieldPermissions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FieldPermissions message.
         * @function verify
         * @memberof common.FieldPermissions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FieldPermissions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                if (typeof message.canRead !== "boolean")
                    return "canRead: boolean expected";
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            return null;
        };

        /**
         * Creates a FieldPermissions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof common.FieldPermissions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {common.FieldPermissions} FieldPermissions
         */
        FieldPermissions.fromObject = function fromObject(object) {
            if (object instanceof $root.common.FieldPermissions)
                return object;
            let message = new $root.common.FieldPermissions();
            if (object.canRead != null)
                message.canRead = Boolean(object.canRead);
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            return message;
        };

        /**
         * Creates a plain object from a FieldPermissions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof common.FieldPermissions
         * @static
         * @param {common.FieldPermissions} message FieldPermissions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FieldPermissions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.canRead = false;
                object.canWrite = false;
            }
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                object.canRead = message.canRead;
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            return object;
        };

        /**
         * Converts this FieldPermissions to JSON.
         * @function toJSON
         * @memberof common.FieldPermissions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FieldPermissions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FieldPermissions
         * @function getTypeUrl
         * @memberof common.FieldPermissions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FieldPermissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.FieldPermissions";
        };

        return FieldPermissions;
    })();

    return common;
})();

export const geometry = $root.geometry = (() => {

    /**
     * Namespace geometry.
     * @exports geometry
     * @namespace
     */
    const geometry = {};

    geometry.GridLocation = (function() {

        /**
         * Properties of a GridLocation.
         * @memberof geometry
         * @interface IGridLocation
         * @property {number|null} [row] GridLocation row
         * @property {number|null} [col] GridLocation col
         * @property {geometry.GridLocation.ISubLocation|null} [sub] GridLocation sub
         */

        /**
         * Constructs a new GridLocation.
         * @memberof geometry
         * @classdesc Represents a GridLocation.
         * @implements IGridLocation
         * @constructor
         * @param {geometry.IGridLocation=} [properties] Properties to set
         */
        function GridLocation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridLocation row.
         * @member {number} row
         * @memberof geometry.GridLocation
         * @instance
         */
        GridLocation.prototype.row = 0;

        /**
         * GridLocation col.
         * @member {number} col
         * @memberof geometry.GridLocation
         * @instance
         */
        GridLocation.prototype.col = 0;

        /**
         * GridLocation sub.
         * @member {geometry.GridLocation.ISubLocation|null|undefined} sub
         * @memberof geometry.GridLocation
         * @instance
         */
        GridLocation.prototype.sub = null;

        /**
         * Creates a new GridLocation instance using the specified properties.
         * @function create
         * @memberof geometry.GridLocation
         * @static
         * @param {geometry.IGridLocation=} [properties] Properties to set
         * @returns {geometry.GridLocation} GridLocation instance
         */
        GridLocation.create = function create(properties) {
            return new GridLocation(properties);
        };

        /**
         * Encodes the specified GridLocation message. Does not implicitly {@link geometry.GridLocation.verify|verify} messages.
         * @function encode
         * @memberof geometry.GridLocation
         * @static
         * @param {geometry.IGridLocation} message GridLocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridLocation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                writer.uint32(/* id 2, wireType 0 =*/16).sint32(message.row);
            if (message.col != null && Object.hasOwnProperty.call(message, "col"))
                writer.uint32(/* id 3, wireType 0 =*/24).sint32(message.col);
            if (message.sub != null && Object.hasOwnProperty.call(message, "sub"))
                $root.geometry.GridLocation.SubLocation.encode(message.sub, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GridLocation message, length delimited. Does not implicitly {@link geometry.GridLocation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.GridLocation
         * @static
         * @param {geometry.IGridLocation} message GridLocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridLocation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridLocation message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.GridLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.GridLocation} GridLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridLocation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.GridLocation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.row = reader.sint32();
                        break;
                    }
                case 3: {
                        message.col = reader.sint32();
                        break;
                    }
                case 4: {
                        message.sub = $root.geometry.GridLocation.SubLocation.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridLocation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.GridLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.GridLocation} GridLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridLocation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridLocation message.
         * @function verify
         * @memberof geometry.GridLocation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridLocation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.row != null && message.hasOwnProperty("row"))
                if (!$util.isInteger(message.row))
                    return "row: integer expected";
            if (message.col != null && message.hasOwnProperty("col"))
                if (!$util.isInteger(message.col))
                    return "col: integer expected";
            if (message.sub != null && message.hasOwnProperty("sub")) {
                let error = $root.geometry.GridLocation.SubLocation.verify(message.sub);
                if (error)
                    return "sub." + error;
            }
            return null;
        };

        /**
         * Creates a GridLocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.GridLocation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.GridLocation} GridLocation
         */
        GridLocation.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.GridLocation)
                return object;
            let message = new $root.geometry.GridLocation();
            if (object.row != null)
                message.row = object.row | 0;
            if (object.col != null)
                message.col = object.col | 0;
            if (object.sub != null) {
                if (typeof object.sub !== "object")
                    throw TypeError(".geometry.GridLocation.sub: object expected");
                message.sub = $root.geometry.GridLocation.SubLocation.fromObject(object.sub);
            }
            return message;
        };

        /**
         * Creates a plain object from a GridLocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.GridLocation
         * @static
         * @param {geometry.GridLocation} message GridLocation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridLocation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.row = 0;
                object.col = 0;
                object.sub = null;
            }
            if (message.row != null && message.hasOwnProperty("row"))
                object.row = message.row;
            if (message.col != null && message.hasOwnProperty("col"))
                object.col = message.col;
            if (message.sub != null && message.hasOwnProperty("sub"))
                object.sub = $root.geometry.GridLocation.SubLocation.toObject(message.sub, options);
            return object;
        };

        /**
         * Converts this GridLocation to JSON.
         * @function toJSON
         * @memberof geometry.GridLocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridLocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridLocation
         * @function getTypeUrl
         * @memberof geometry.GridLocation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridLocation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.GridLocation";
        };

        GridLocation.SubLocation = (function() {

            /**
             * Properties of a SubLocation.
             * @memberof geometry.GridLocation
             * @interface ISubLocation
             * @property {number|null} [row] SubLocation row
             * @property {number|null} [col] SubLocation col
             */

            /**
             * Constructs a new SubLocation.
             * @memberof geometry.GridLocation
             * @classdesc Represents a SubLocation.
             * @implements ISubLocation
             * @constructor
             * @param {geometry.GridLocation.ISubLocation=} [properties] Properties to set
             */
            function SubLocation(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubLocation row.
             * @member {number} row
             * @memberof geometry.GridLocation.SubLocation
             * @instance
             */
            SubLocation.prototype.row = 0;

            /**
             * SubLocation col.
             * @member {number} col
             * @memberof geometry.GridLocation.SubLocation
             * @instance
             */
            SubLocation.prototype.col = 0;

            /**
             * Creates a new SubLocation instance using the specified properties.
             * @function create
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {geometry.GridLocation.ISubLocation=} [properties] Properties to set
             * @returns {geometry.GridLocation.SubLocation} SubLocation instance
             */
            SubLocation.create = function create(properties) {
                return new SubLocation(properties);
            };

            /**
             * Encodes the specified SubLocation message. Does not implicitly {@link geometry.GridLocation.SubLocation.verify|verify} messages.
             * @function encode
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {geometry.GridLocation.ISubLocation} message SubLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubLocation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.row != null && Object.hasOwnProperty.call(message, "row"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.row);
                if (message.col != null && Object.hasOwnProperty.call(message, "col"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.col);
                return writer;
            };

            /**
             * Encodes the specified SubLocation message, length delimited. Does not implicitly {@link geometry.GridLocation.SubLocation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {geometry.GridLocation.ISubLocation} message SubLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubLocation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SubLocation message from the specified reader or buffer.
             * @function decode
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {geometry.GridLocation.SubLocation} SubLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubLocation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.GridLocation.SubLocation();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.row = reader.float();
                            break;
                        }
                    case 2: {
                            message.col = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SubLocation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {geometry.GridLocation.SubLocation} SubLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubLocation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SubLocation message.
             * @function verify
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubLocation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.row != null && message.hasOwnProperty("row"))
                    if (typeof message.row !== "number")
                        return "row: number expected";
                if (message.col != null && message.hasOwnProperty("col"))
                    if (typeof message.col !== "number")
                        return "col: number expected";
                return null;
            };

            /**
             * Creates a SubLocation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {geometry.GridLocation.SubLocation} SubLocation
             */
            SubLocation.fromObject = function fromObject(object) {
                if (object instanceof $root.geometry.GridLocation.SubLocation)
                    return object;
                let message = new $root.geometry.GridLocation.SubLocation();
                if (object.row != null)
                    message.row = Number(object.row);
                if (object.col != null)
                    message.col = Number(object.col);
                return message;
            };

            /**
             * Creates a plain object from a SubLocation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {geometry.GridLocation.SubLocation} message SubLocation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubLocation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.row = 0;
                    object.col = 0;
                }
                if (message.row != null && message.hasOwnProperty("row"))
                    object.row = options.json && !isFinite(message.row) ? String(message.row) : message.row;
                if (message.col != null && message.hasOwnProperty("col"))
                    object.col = options.json && !isFinite(message.col) ? String(message.col) : message.col;
                return object;
            };

            /**
             * Converts this SubLocation to JSON.
             * @function toJSON
             * @memberof geometry.GridLocation.SubLocation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubLocation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SubLocation
             * @function getTypeUrl
             * @memberof geometry.GridLocation.SubLocation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SubLocation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/geometry.GridLocation.SubLocation";
            };

            return SubLocation;
        })();

        return GridLocation;
    })();

    geometry.FullGrid = (function() {

        /**
         * Properties of a FullGrid.
         * @memberof geometry
         * @interface IFullGrid
         */

        /**
         * Constructs a new FullGrid.
         * @memberof geometry
         * @classdesc Represents a FullGrid.
         * @implements IFullGrid
         * @constructor
         * @param {geometry.IFullGrid=} [properties] Properties to set
         */
        function FullGrid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FullGrid instance using the specified properties.
         * @function create
         * @memberof geometry.FullGrid
         * @static
         * @param {geometry.IFullGrid=} [properties] Properties to set
         * @returns {geometry.FullGrid} FullGrid instance
         */
        FullGrid.create = function create(properties) {
            return new FullGrid(properties);
        };

        /**
         * Encodes the specified FullGrid message. Does not implicitly {@link geometry.FullGrid.verify|verify} messages.
         * @function encode
         * @memberof geometry.FullGrid
         * @static
         * @param {geometry.IFullGrid} message FullGrid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullGrid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FullGrid message, length delimited. Does not implicitly {@link geometry.FullGrid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.FullGrid
         * @static
         * @param {geometry.IFullGrid} message FullGrid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FullGrid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FullGrid message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.FullGrid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.FullGrid} FullGrid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullGrid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.FullGrid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FullGrid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.FullGrid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.FullGrid} FullGrid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FullGrid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FullGrid message.
         * @function verify
         * @memberof geometry.FullGrid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FullGrid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FullGrid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.FullGrid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.FullGrid} FullGrid
         */
        FullGrid.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.FullGrid)
                return object;
            return new $root.geometry.FullGrid();
        };

        /**
         * Creates a plain object from a FullGrid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.FullGrid
         * @static
         * @param {geometry.FullGrid} message FullGrid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FullGrid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FullGrid to JSON.
         * @function toJSON
         * @memberof geometry.FullGrid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FullGrid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FullGrid
         * @function getTypeUrl
         * @memberof geometry.FullGrid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FullGrid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.FullGrid";
        };

        return FullGrid;
    })();

    geometry.Point = (function() {

        /**
         * Properties of a Point.
         * @memberof geometry
         * @interface IPoint
         * @property {geometry.IGridLocation|null} [loc] Point loc
         */

        /**
         * Constructs a new Point.
         * @memberof geometry
         * @classdesc Represents a Point.
         * @implements IPoint
         * @constructor
         * @param {geometry.IPoint=} [properties] Properties to set
         */
        function Point(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Point loc.
         * @member {geometry.IGridLocation|null|undefined} loc
         * @memberof geometry.Point
         * @instance
         */
        Point.prototype.loc = null;

        /**
         * Creates a new Point instance using the specified properties.
         * @function create
         * @memberof geometry.Point
         * @static
         * @param {geometry.IPoint=} [properties] Properties to set
         * @returns {geometry.Point} Point instance
         */
        Point.create = function create(properties) {
            return new Point(properties);
        };

        /**
         * Encodes the specified Point message. Does not implicitly {@link geometry.Point.verify|verify} messages.
         * @function encode
         * @memberof geometry.Point
         * @static
         * @param {geometry.IPoint} message Point message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.loc != null && Object.hasOwnProperty.call(message, "loc"))
                $root.geometry.GridLocation.encode(message.loc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Point message, length delimited. Does not implicitly {@link geometry.Point.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Point
         * @static
         * @param {geometry.IPoint} message Point message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Point.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Point message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Point
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Point} Point
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Point();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.loc = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Point message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Point
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Point} Point
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Point.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Point message.
         * @function verify
         * @memberof geometry.Point
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Point.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.loc != null && message.hasOwnProperty("loc")) {
                let error = $root.geometry.GridLocation.verify(message.loc);
                if (error)
                    return "loc." + error;
            }
            return null;
        };

        /**
         * Creates a Point message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Point
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Point} Point
         */
        Point.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Point)
                return object;
            let message = new $root.geometry.Point();
            if (object.loc != null) {
                if (typeof object.loc !== "object")
                    throw TypeError(".geometry.Point.loc: object expected");
                message.loc = $root.geometry.GridLocation.fromObject(object.loc);
            }
            return message;
        };

        /**
         * Creates a plain object from a Point message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Point
         * @static
         * @param {geometry.Point} message Point
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Point.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.loc = null;
            if (message.loc != null && message.hasOwnProperty("loc"))
                object.loc = $root.geometry.GridLocation.toObject(message.loc, options);
            return object;
        };

        /**
         * Converts this Point to JSON.
         * @function toJSON
         * @memberof geometry.Point
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Point.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Point
         * @function getTypeUrl
         * @memberof geometry.Point
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Point.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Point";
        };

        return Point;
    })();

    geometry.LineSegment = (function() {

        /**
         * Properties of a LineSegment.
         * @memberof geometry
         * @interface ILineSegment
         * @property {geometry.IGridLocation|null} [start] LineSegment start
         * @property {geometry.IGridLocation|null} [end] LineSegment end
         */

        /**
         * Constructs a new LineSegment.
         * @memberof geometry
         * @classdesc Represents a LineSegment.
         * @implements ILineSegment
         * @constructor
         * @param {geometry.ILineSegment=} [properties] Properties to set
         */
        function LineSegment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LineSegment start.
         * @member {geometry.IGridLocation|null|undefined} start
         * @memberof geometry.LineSegment
         * @instance
         */
        LineSegment.prototype.start = null;

        /**
         * LineSegment end.
         * @member {geometry.IGridLocation|null|undefined} end
         * @memberof geometry.LineSegment
         * @instance
         */
        LineSegment.prototype.end = null;

        /**
         * Creates a new LineSegment instance using the specified properties.
         * @function create
         * @memberof geometry.LineSegment
         * @static
         * @param {geometry.ILineSegment=} [properties] Properties to set
         * @returns {geometry.LineSegment} LineSegment instance
         */
        LineSegment.create = function create(properties) {
            return new LineSegment(properties);
        };

        /**
         * Encodes the specified LineSegment message. Does not implicitly {@link geometry.LineSegment.verify|verify} messages.
         * @function encode
         * @memberof geometry.LineSegment
         * @static
         * @param {geometry.ILineSegment} message LineSegment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LineSegment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.geometry.GridLocation.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.geometry.GridLocation.encode(message.end, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LineSegment message, length delimited. Does not implicitly {@link geometry.LineSegment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.LineSegment
         * @static
         * @param {geometry.ILineSegment} message LineSegment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LineSegment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LineSegment message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.LineSegment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.LineSegment} LineSegment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LineSegment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.LineSegment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.end = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LineSegment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.LineSegment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.LineSegment} LineSegment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LineSegment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LineSegment message.
         * @function verify
         * @memberof geometry.LineSegment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LineSegment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.geometry.GridLocation.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.geometry.GridLocation.verify(message.end);
                if (error)
                    return "end." + error;
            }
            return null;
        };

        /**
         * Creates a LineSegment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.LineSegment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.LineSegment} LineSegment
         */
        LineSegment.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.LineSegment)
                return object;
            let message = new $root.geometry.LineSegment();
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".geometry.LineSegment.start: object expected");
                message.start = $root.geometry.GridLocation.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".geometry.LineSegment.end: object expected");
                message.end = $root.geometry.GridLocation.fromObject(object.end);
            }
            return message;
        };

        /**
         * Creates a plain object from a LineSegment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.LineSegment
         * @static
         * @param {geometry.LineSegment} message LineSegment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LineSegment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.start = null;
                object.end = null;
            }
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.geometry.GridLocation.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.geometry.GridLocation.toObject(message.end, options);
            return object;
        };

        /**
         * Converts this LineSegment to JSON.
         * @function toJSON
         * @memberof geometry.LineSegment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LineSegment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LineSegment
         * @function getTypeUrl
         * @memberof geometry.LineSegment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LineSegment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.LineSegment";
        };

        return LineSegment;
    })();

    geometry.GridCell = (function() {

        /**
         * Properties of a GridCell.
         * @memberof geometry
         * @interface IGridCell
         * @property {geometry.IGridLocation|null} [loc] GridCell loc
         */

        /**
         * Constructs a new GridCell.
         * @memberof geometry
         * @classdesc Represents a GridCell.
         * @implements IGridCell
         * @constructor
         * @param {geometry.IGridCell=} [properties] Properties to set
         */
        function GridCell(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridCell loc.
         * @member {geometry.IGridLocation|null|undefined} loc
         * @memberof geometry.GridCell
         * @instance
         */
        GridCell.prototype.loc = null;

        /**
         * Creates a new GridCell instance using the specified properties.
         * @function create
         * @memberof geometry.GridCell
         * @static
         * @param {geometry.IGridCell=} [properties] Properties to set
         * @returns {geometry.GridCell} GridCell instance
         */
        GridCell.create = function create(properties) {
            return new GridCell(properties);
        };

        /**
         * Encodes the specified GridCell message. Does not implicitly {@link geometry.GridCell.verify|verify} messages.
         * @function encode
         * @memberof geometry.GridCell
         * @static
         * @param {geometry.IGridCell} message GridCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridCell.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.loc != null && Object.hasOwnProperty.call(message, "loc"))
                $root.geometry.GridLocation.encode(message.loc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GridCell message, length delimited. Does not implicitly {@link geometry.GridCell.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.GridCell
         * @static
         * @param {geometry.IGridCell} message GridCell message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridCell.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridCell message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.GridCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.GridCell} GridCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridCell.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.GridCell();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.loc = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridCell message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.GridCell
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.GridCell} GridCell
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridCell.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridCell message.
         * @function verify
         * @memberof geometry.GridCell
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridCell.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.loc != null && message.hasOwnProperty("loc")) {
                let error = $root.geometry.GridLocation.verify(message.loc);
                if (error)
                    return "loc." + error;
            }
            return null;
        };

        /**
         * Creates a GridCell message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.GridCell
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.GridCell} GridCell
         */
        GridCell.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.GridCell)
                return object;
            let message = new $root.geometry.GridCell();
            if (object.loc != null) {
                if (typeof object.loc !== "object")
                    throw TypeError(".geometry.GridCell.loc: object expected");
                message.loc = $root.geometry.GridLocation.fromObject(object.loc);
            }
            return message;
        };

        /**
         * Creates a plain object from a GridCell message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.GridCell
         * @static
         * @param {geometry.GridCell} message GridCell
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridCell.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.loc = null;
            if (message.loc != null && message.hasOwnProperty("loc"))
                object.loc = $root.geometry.GridLocation.toObject(message.loc, options);
            return object;
        };

        /**
         * Converts this GridCell to JSON.
         * @function toJSON
         * @memberof geometry.GridCell
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridCell.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridCell
         * @function getTypeUrl
         * @memberof geometry.GridCell
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridCell.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.GridCell";
        };

        return GridCell;
    })();

    geometry.Rectangle = (function() {

        /**
         * Properties of a Rectangle.
         * @memberof geometry
         * @interface IRectangle
         * @property {geometry.IGridLocation|null} [corner] Rectangle corner
         * @property {number|null} [width] Rectangle width
         * @property {number|null} [height] Rectangle height
         */

        /**
         * Constructs a new Rectangle.
         * @memberof geometry
         * @classdesc Represents a Rectangle.
         * @implements IRectangle
         * @constructor
         * @param {geometry.IRectangle=} [properties] Properties to set
         */
        function Rectangle(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Rectangle corner.
         * @member {geometry.IGridLocation|null|undefined} corner
         * @memberof geometry.Rectangle
         * @instance
         */
        Rectangle.prototype.corner = null;

        /**
         * Rectangle width.
         * @member {number} width
         * @memberof geometry.Rectangle
         * @instance
         */
        Rectangle.prototype.width = 0;

        /**
         * Rectangle height.
         * @member {number} height
         * @memberof geometry.Rectangle
         * @instance
         */
        Rectangle.prototype.height = 0;

        /**
         * Creates a new Rectangle instance using the specified properties.
         * @function create
         * @memberof geometry.Rectangle
         * @static
         * @param {geometry.IRectangle=} [properties] Properties to set
         * @returns {geometry.Rectangle} Rectangle instance
         */
        Rectangle.create = function create(properties) {
            return new Rectangle(properties);
        };

        /**
         * Encodes the specified Rectangle message. Does not implicitly {@link geometry.Rectangle.verify|verify} messages.
         * @function encode
         * @memberof geometry.Rectangle
         * @static
         * @param {geometry.IRectangle} message Rectangle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rectangle.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.corner != null && Object.hasOwnProperty.call(message, "corner"))
                $root.geometry.GridLocation.encode(message.corner, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.height);
            return writer;
        };

        /**
         * Encodes the specified Rectangle message, length delimited. Does not implicitly {@link geometry.Rectangle.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Rectangle
         * @static
         * @param {geometry.IRectangle} message Rectangle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Rectangle.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Rectangle message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Rectangle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Rectangle} Rectangle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rectangle.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Rectangle();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.corner = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.width = reader.float();
                        break;
                    }
                case 3: {
                        message.height = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Rectangle message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Rectangle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Rectangle} Rectangle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Rectangle.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Rectangle message.
         * @function verify
         * @memberof geometry.Rectangle
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Rectangle.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.corner != null && message.hasOwnProperty("corner")) {
                let error = $root.geometry.GridLocation.verify(message.corner);
                if (error)
                    return "corner." + error;
            }
            if (message.width != null && message.hasOwnProperty("width"))
                if (typeof message.width !== "number")
                    return "width: number expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (typeof message.height !== "number")
                    return "height: number expected";
            return null;
        };

        /**
         * Creates a Rectangle message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Rectangle
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Rectangle} Rectangle
         */
        Rectangle.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Rectangle)
                return object;
            let message = new $root.geometry.Rectangle();
            if (object.corner != null) {
                if (typeof object.corner !== "object")
                    throw TypeError(".geometry.Rectangle.corner: object expected");
                message.corner = $root.geometry.GridLocation.fromObject(object.corner);
            }
            if (object.width != null)
                message.width = Number(object.width);
            if (object.height != null)
                message.height = Number(object.height);
            return message;
        };

        /**
         * Creates a plain object from a Rectangle message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Rectangle
         * @static
         * @param {geometry.Rectangle} message Rectangle
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Rectangle.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.corner = null;
                object.width = 0;
                object.height = 0;
            }
            if (message.corner != null && message.hasOwnProperty("corner"))
                object.corner = $root.geometry.GridLocation.toObject(message.corner, options);
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = options.json && !isFinite(message.height) ? String(message.height) : message.height;
            return object;
        };

        /**
         * Converts this Rectangle to JSON.
         * @function toJSON
         * @memberof geometry.Rectangle
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Rectangle.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Rectangle
         * @function getTypeUrl
         * @memberof geometry.Rectangle
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Rectangle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Rectangle";
        };

        return Rectangle;
    })();

    geometry.Circle = (function() {

        /**
         * Properties of a Circle.
         * @memberof geometry
         * @interface ICircle
         * @property {geometry.IGridLocation|null} [center] Circle center
         * @property {number|null} [radius] Circle radius
         */

        /**
         * Constructs a new Circle.
         * @memberof geometry
         * @classdesc Represents a Circle.
         * @implements ICircle
         * @constructor
         * @param {geometry.ICircle=} [properties] Properties to set
         */
        function Circle(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Circle center.
         * @member {geometry.IGridLocation|null|undefined} center
         * @memberof geometry.Circle
         * @instance
         */
        Circle.prototype.center = null;

        /**
         * Circle radius.
         * @member {number} radius
         * @memberof geometry.Circle
         * @instance
         */
        Circle.prototype.radius = 0;

        /**
         * Creates a new Circle instance using the specified properties.
         * @function create
         * @memberof geometry.Circle
         * @static
         * @param {geometry.ICircle=} [properties] Properties to set
         * @returns {geometry.Circle} Circle instance
         */
        Circle.create = function create(properties) {
            return new Circle(properties);
        };

        /**
         * Encodes the specified Circle message. Does not implicitly {@link geometry.Circle.verify|verify} messages.
         * @function encode
         * @memberof geometry.Circle
         * @static
         * @param {geometry.ICircle} message Circle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Circle.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.center != null && Object.hasOwnProperty.call(message, "center"))
                $root.geometry.GridLocation.encode(message.center, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.radius != null && Object.hasOwnProperty.call(message, "radius"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.radius);
            return writer;
        };

        /**
         * Encodes the specified Circle message, length delimited. Does not implicitly {@link geometry.Circle.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Circle
         * @static
         * @param {geometry.ICircle} message Circle message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Circle.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Circle message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Circle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Circle} Circle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Circle.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Circle();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.center = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.radius = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Circle message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Circle
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Circle} Circle
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Circle.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Circle message.
         * @function verify
         * @memberof geometry.Circle
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Circle.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.center != null && message.hasOwnProperty("center")) {
                let error = $root.geometry.GridLocation.verify(message.center);
                if (error)
                    return "center." + error;
            }
            if (message.radius != null && message.hasOwnProperty("radius"))
                if (typeof message.radius !== "number")
                    return "radius: number expected";
            return null;
        };

        /**
         * Creates a Circle message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Circle
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Circle} Circle
         */
        Circle.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Circle)
                return object;
            let message = new $root.geometry.Circle();
            if (object.center != null) {
                if (typeof object.center !== "object")
                    throw TypeError(".geometry.Circle.center: object expected");
                message.center = $root.geometry.GridLocation.fromObject(object.center);
            }
            if (object.radius != null)
                message.radius = Number(object.radius);
            return message;
        };

        /**
         * Creates a plain object from a Circle message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Circle
         * @static
         * @param {geometry.Circle} message Circle
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Circle.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.center = null;
                object.radius = 0;
            }
            if (message.center != null && message.hasOwnProperty("center"))
                object.center = $root.geometry.GridLocation.toObject(message.center, options);
            if (message.radius != null && message.hasOwnProperty("radius"))
                object.radius = options.json && !isFinite(message.radius) ? String(message.radius) : message.radius;
            return object;
        };

        /**
         * Converts this Circle to JSON.
         * @function toJSON
         * @memberof geometry.Circle
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Circle.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Circle
         * @function getTypeUrl
         * @memberof geometry.Circle
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Circle.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Circle";
        };

        return Circle;
    })();

    geometry.EffectLine = (function() {

        /**
         * Properties of an EffectLine.
         * @memberof geometry
         * @interface IEffectLine
         * @property {geometry.IGridLocation|null} [start] EffectLine start
         * @property {geometry.IGridLocation|null} [end] EffectLine end
         * @property {number|null} [width] EffectLine width
         */

        /**
         * Constructs a new EffectLine.
         * @memberof geometry
         * @classdesc Represents an EffectLine.
         * @implements IEffectLine
         * @constructor
         * @param {geometry.IEffectLine=} [properties] Properties to set
         */
        function EffectLine(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EffectLine start.
         * @member {geometry.IGridLocation|null|undefined} start
         * @memberof geometry.EffectLine
         * @instance
         */
        EffectLine.prototype.start = null;

        /**
         * EffectLine end.
         * @member {geometry.IGridLocation|null|undefined} end
         * @memberof geometry.EffectLine
         * @instance
         */
        EffectLine.prototype.end = null;

        /**
         * EffectLine width.
         * @member {number} width
         * @memberof geometry.EffectLine
         * @instance
         */
        EffectLine.prototype.width = 0;

        /**
         * Creates a new EffectLine instance using the specified properties.
         * @function create
         * @memberof geometry.EffectLine
         * @static
         * @param {geometry.IEffectLine=} [properties] Properties to set
         * @returns {geometry.EffectLine} EffectLine instance
         */
        EffectLine.create = function create(properties) {
            return new EffectLine(properties);
        };

        /**
         * Encodes the specified EffectLine message. Does not implicitly {@link geometry.EffectLine.verify|verify} messages.
         * @function encode
         * @memberof geometry.EffectLine
         * @static
         * @param {geometry.IEffectLine} message EffectLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EffectLine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.geometry.GridLocation.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.geometry.GridLocation.encode(message.end, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.width);
            return writer;
        };

        /**
         * Encodes the specified EffectLine message, length delimited. Does not implicitly {@link geometry.EffectLine.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.EffectLine
         * @static
         * @param {geometry.IEffectLine} message EffectLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EffectLine.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EffectLine message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.EffectLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.EffectLine} EffectLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EffectLine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.EffectLine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.end = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.width = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EffectLine message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.EffectLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.EffectLine} EffectLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EffectLine.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EffectLine message.
         * @function verify
         * @memberof geometry.EffectLine
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EffectLine.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.geometry.GridLocation.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.geometry.GridLocation.verify(message.end);
                if (error)
                    return "end." + error;
            }
            if (message.width != null && message.hasOwnProperty("width"))
                if (typeof message.width !== "number")
                    return "width: number expected";
            return null;
        };

        /**
         * Creates an EffectLine message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.EffectLine
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.EffectLine} EffectLine
         */
        EffectLine.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.EffectLine)
                return object;
            let message = new $root.geometry.EffectLine();
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".geometry.EffectLine.start: object expected");
                message.start = $root.geometry.GridLocation.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".geometry.EffectLine.end: object expected");
                message.end = $root.geometry.GridLocation.fromObject(object.end);
            }
            if (object.width != null)
                message.width = Number(object.width);
            return message;
        };

        /**
         * Creates a plain object from an EffectLine message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.EffectLine
         * @static
         * @param {geometry.EffectLine} message EffectLine
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EffectLine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.start = null;
                object.end = null;
                object.width = 0;
            }
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.geometry.GridLocation.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.geometry.GridLocation.toObject(message.end, options);
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = options.json && !isFinite(message.width) ? String(message.width) : message.width;
            return object;
        };

        /**
         * Converts this EffectLine to JSON.
         * @function toJSON
         * @memberof geometry.EffectLine
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EffectLine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EffectLine
         * @function getTypeUrl
         * @memberof geometry.EffectLine
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EffectLine.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.EffectLine";
        };

        return EffectLine;
    })();

    geometry.Polygon = (function() {

        /**
         * Properties of a Polygon.
         * @memberof geometry
         * @interface IPolygon
         * @property {Array.<geometry.IGridLocation>|null} [verticies] Polygon verticies
         */

        /**
         * Constructs a new Polygon.
         * @memberof geometry
         * @classdesc Represents a Polygon.
         * @implements IPolygon
         * @constructor
         * @param {geometry.IPolygon=} [properties] Properties to set
         */
        function Polygon(properties) {
            this.verticies = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Polygon verticies.
         * @member {Array.<geometry.IGridLocation>} verticies
         * @memberof geometry.Polygon
         * @instance
         */
        Polygon.prototype.verticies = $util.emptyArray;

        /**
         * Creates a new Polygon instance using the specified properties.
         * @function create
         * @memberof geometry.Polygon
         * @static
         * @param {geometry.IPolygon=} [properties] Properties to set
         * @returns {geometry.Polygon} Polygon instance
         */
        Polygon.create = function create(properties) {
            return new Polygon(properties);
        };

        /**
         * Encodes the specified Polygon message. Does not implicitly {@link geometry.Polygon.verify|verify} messages.
         * @function encode
         * @memberof geometry.Polygon
         * @static
         * @param {geometry.IPolygon} message Polygon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Polygon.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verticies != null && message.verticies.length)
                for (let i = 0; i < message.verticies.length; ++i)
                    $root.geometry.GridLocation.encode(message.verticies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Polygon message, length delimited. Does not implicitly {@link geometry.Polygon.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Polygon
         * @static
         * @param {geometry.IPolygon} message Polygon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Polygon.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Polygon message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Polygon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Polygon} Polygon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Polygon.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Polygon();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.verticies && message.verticies.length))
                            message.verticies = [];
                        message.verticies.push($root.geometry.GridLocation.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Polygon message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Polygon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Polygon} Polygon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Polygon.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Polygon message.
         * @function verify
         * @memberof geometry.Polygon
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Polygon.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verticies != null && message.hasOwnProperty("verticies")) {
                if (!Array.isArray(message.verticies))
                    return "verticies: array expected";
                for (let i = 0; i < message.verticies.length; ++i) {
                    let error = $root.geometry.GridLocation.verify(message.verticies[i]);
                    if (error)
                        return "verticies." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Polygon
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Polygon} Polygon
         */
        Polygon.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Polygon)
                return object;
            let message = new $root.geometry.Polygon();
            if (object.verticies) {
                if (!Array.isArray(object.verticies))
                    throw TypeError(".geometry.Polygon.verticies: array expected");
                message.verticies = [];
                for (let i = 0; i < object.verticies.length; ++i) {
                    if (typeof object.verticies[i] !== "object")
                        throw TypeError(".geometry.Polygon.verticies: object expected");
                    message.verticies[i] = $root.geometry.GridLocation.fromObject(object.verticies[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Polygon message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Polygon
         * @static
         * @param {geometry.Polygon} message Polygon
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Polygon.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.verticies = [];
            if (message.verticies && message.verticies.length) {
                object.verticies = [];
                for (let j = 0; j < message.verticies.length; ++j)
                    object.verticies[j] = $root.geometry.GridLocation.toObject(message.verticies[j], options);
            }
            return object;
        };

        /**
         * Converts this Polygon to JSON.
         * @function toJSON
         * @memberof geometry.Polygon
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Polygon.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Polygon
         * @function getTypeUrl
         * @memberof geometry.Polygon
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Polygon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Polygon";
        };

        return Polygon;
    })();

    geometry.Mask = (function() {

        /**
         * Properties of a Mask.
         * @memberof geometry
         * @interface IMask
         * @property {string|null} [id] Mask id
         * @property {geometry.IGridLocation|null} [loc] Mask loc
         * @property {number|null} [width] Mask width
         * @property {number|null} [height] Mask height
         * @property {number|null} [detailLevel] Mask detailLevel
         * @property {boolean|null} [empty] Mask empty
         * @property {number|null} [fill] Mask fill
         * @property {Uint8Array|null} [imageData] Mask imageData
         * @property {Uint8Array|null} [rgbaImageData] Mask rgbaImageData
         */

        /**
         * Constructs a new Mask.
         * @memberof geometry
         * @classdesc Represents a Mask.
         * @implements IMask
         * @constructor
         * @param {geometry.IMask=} [properties] Properties to set
         */
        function Mask(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Mask id.
         * @member {string} id
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.id = "";

        /**
         * Mask loc.
         * @member {geometry.IGridLocation|null|undefined} loc
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.loc = null;

        /**
         * Mask width.
         * @member {number} width
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.width = 0;

        /**
         * Mask height.
         * @member {number} height
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.height = 0;

        /**
         * Mask detailLevel.
         * @member {number} detailLevel
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.detailLevel = 0;

        /**
         * Mask empty.
         * @member {boolean|null|undefined} empty
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.empty = null;

        /**
         * Mask fill.
         * @member {number|null|undefined} fill
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.fill = null;

        /**
         * Mask imageData.
         * @member {Uint8Array|null|undefined} imageData
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.imageData = null;

        /**
         * Mask rgbaImageData.
         * @member {Uint8Array|null|undefined} rgbaImageData
         * @memberof geometry.Mask
         * @instance
         */
        Mask.prototype.rgbaImageData = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Mask data.
         * @member {"empty"|"fill"|"imageData"|"rgbaImageData"|undefined} data
         * @memberof geometry.Mask
         * @instance
         */
        Object.defineProperty(Mask.prototype, "data", {
            get: $util.oneOfGetter($oneOfFields = ["empty", "fill", "imageData", "rgbaImageData"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Mask instance using the specified properties.
         * @function create
         * @memberof geometry.Mask
         * @static
         * @param {geometry.IMask=} [properties] Properties to set
         * @returns {geometry.Mask} Mask instance
         */
        Mask.create = function create(properties) {
            return new Mask(properties);
        };

        /**
         * Encodes the specified Mask message. Does not implicitly {@link geometry.Mask.verify|verify} messages.
         * @function encode
         * @memberof geometry.Mask
         * @static
         * @param {geometry.IMask} message Mask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Mask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.loc != null && Object.hasOwnProperty.call(message, "loc"))
                $root.geometry.GridLocation.encode(message.loc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.height);
            if (message.detailLevel != null && Object.hasOwnProperty.call(message, "detailLevel"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.detailLevel);
            if (message.empty != null && Object.hasOwnProperty.call(message, "empty"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.empty);
            if (message.fill != null && Object.hasOwnProperty.call(message, "fill"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.fill);
            if (message.imageData != null && Object.hasOwnProperty.call(message, "imageData"))
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.imageData);
            if (message.rgbaImageData != null && Object.hasOwnProperty.call(message, "rgbaImageData"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.rgbaImageData);
            return writer;
        };

        /**
         * Encodes the specified Mask message, length delimited. Does not implicitly {@link geometry.Mask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Mask
         * @static
         * @param {geometry.IMask} message Mask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Mask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Mask message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Mask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Mask} Mask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Mask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Mask();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.loc = $root.geometry.GridLocation.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.width = reader.uint32();
                        break;
                    }
                case 4: {
                        message.height = reader.uint32();
                        break;
                    }
                case 5: {
                        message.detailLevel = reader.uint32();
                        break;
                    }
                case 6: {
                        message.empty = reader.bool();
                        break;
                    }
                case 7: {
                        message.fill = reader.uint32();
                        break;
                    }
                case 8: {
                        message.imageData = reader.bytes();
                        break;
                    }
                case 9: {
                        message.rgbaImageData = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Mask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Mask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Mask} Mask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Mask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Mask message.
         * @function verify
         * @memberof geometry.Mask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Mask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.loc != null && message.hasOwnProperty("loc")) {
                let error = $root.geometry.GridLocation.verify(message.loc);
                if (error)
                    return "loc." + error;
            }
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.detailLevel != null && message.hasOwnProperty("detailLevel"))
                if (!$util.isInteger(message.detailLevel))
                    return "detailLevel: integer expected";
            if (message.empty != null && message.hasOwnProperty("empty")) {
                properties.data = 1;
                if (typeof message.empty !== "boolean")
                    return "empty: boolean expected";
            }
            if (message.fill != null && message.hasOwnProperty("fill")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!$util.isInteger(message.fill))
                    return "fill: integer expected";
            }
            if (message.imageData != null && message.hasOwnProperty("imageData")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!(message.imageData && typeof message.imageData.length === "number" || $util.isString(message.imageData)))
                    return "imageData: buffer expected";
            }
            if (message.rgbaImageData != null && message.hasOwnProperty("rgbaImageData")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!(message.rgbaImageData && typeof message.rgbaImageData.length === "number" || $util.isString(message.rgbaImageData)))
                    return "rgbaImageData: buffer expected";
            }
            return null;
        };

        /**
         * Creates a Mask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Mask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Mask} Mask
         */
        Mask.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Mask)
                return object;
            let message = new $root.geometry.Mask();
            if (object.id != null)
                message.id = String(object.id);
            if (object.loc != null) {
                if (typeof object.loc !== "object")
                    throw TypeError(".geometry.Mask.loc: object expected");
                message.loc = $root.geometry.GridLocation.fromObject(object.loc);
            }
            if (object.width != null)
                message.width = object.width >>> 0;
            if (object.height != null)
                message.height = object.height >>> 0;
            if (object.detailLevel != null)
                message.detailLevel = object.detailLevel >>> 0;
            if (object.empty != null)
                message.empty = Boolean(object.empty);
            if (object.fill != null)
                message.fill = object.fill >>> 0;
            if (object.imageData != null)
                if (typeof object.imageData === "string")
                    $util.base64.decode(object.imageData, message.imageData = $util.newBuffer($util.base64.length(object.imageData)), 0);
                else if (object.imageData.length >= 0)
                    message.imageData = object.imageData;
            if (object.rgbaImageData != null)
                if (typeof object.rgbaImageData === "string")
                    $util.base64.decode(object.rgbaImageData, message.rgbaImageData = $util.newBuffer($util.base64.length(object.rgbaImageData)), 0);
                else if (object.rgbaImageData.length >= 0)
                    message.rgbaImageData = object.rgbaImageData;
            return message;
        };

        /**
         * Creates a plain object from a Mask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Mask
         * @static
         * @param {geometry.Mask} message Mask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Mask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.loc = null;
                object.width = 0;
                object.height = 0;
                object.detailLevel = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.loc != null && message.hasOwnProperty("loc"))
                object.loc = $root.geometry.GridLocation.toObject(message.loc, options);
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.detailLevel != null && message.hasOwnProperty("detailLevel"))
                object.detailLevel = message.detailLevel;
            if (message.empty != null && message.hasOwnProperty("empty")) {
                object.empty = message.empty;
                if (options.oneofs)
                    object.data = "empty";
            }
            if (message.fill != null && message.hasOwnProperty("fill")) {
                object.fill = message.fill;
                if (options.oneofs)
                    object.data = "fill";
            }
            if (message.imageData != null && message.hasOwnProperty("imageData")) {
                object.imageData = options.bytes === String ? $util.base64.encode(message.imageData, 0, message.imageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.imageData) : message.imageData;
                if (options.oneofs)
                    object.data = "imageData";
            }
            if (message.rgbaImageData != null && message.hasOwnProperty("rgbaImageData")) {
                object.rgbaImageData = options.bytes === String ? $util.base64.encode(message.rgbaImageData, 0, message.rgbaImageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.rgbaImageData) : message.rgbaImageData;
                if (options.oneofs)
                    object.data = "rgbaImageData";
            }
            return object;
        };

        /**
         * Converts this Mask to JSON.
         * @function toJSON
         * @memberof geometry.Mask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Mask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Mask
         * @function getTypeUrl
         * @memberof geometry.Mask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Mask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Mask";
        };

        return Mask;
    })();

    geometry.MaskPatch = (function() {

        /**
         * Properties of a MaskPatch.
         * @memberof geometry
         * @interface IMaskPatch
         * @property {string|null} [id] MaskPatch id
         * @property {number|null} [x] MaskPatch x
         * @property {number|null} [y] MaskPatch y
         * @property {number|null} [width] MaskPatch width
         * @property {number|null} [height] MaskPatch height
         * @property {boolean|null} [empty] MaskPatch empty
         * @property {number|null} [fill] MaskPatch fill
         * @property {Uint8Array|null} [imageData] MaskPatch imageData
         * @property {Uint8Array|null} [rgbaImageData] MaskPatch rgbaImageData
         */

        /**
         * Constructs a new MaskPatch.
         * @memberof geometry
         * @classdesc Represents a MaskPatch.
         * @implements IMaskPatch
         * @constructor
         * @param {geometry.IMaskPatch=} [properties] Properties to set
         */
        function MaskPatch(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MaskPatch id.
         * @member {string} id
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.id = "";

        /**
         * MaskPatch x.
         * @member {number} x
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.x = 0;

        /**
         * MaskPatch y.
         * @member {number} y
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.y = 0;

        /**
         * MaskPatch width.
         * @member {number} width
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.width = 0;

        /**
         * MaskPatch height.
         * @member {number} height
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.height = 0;

        /**
         * MaskPatch empty.
         * @member {boolean|null|undefined} empty
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.empty = null;

        /**
         * MaskPatch fill.
         * @member {number|null|undefined} fill
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.fill = null;

        /**
         * MaskPatch imageData.
         * @member {Uint8Array|null|undefined} imageData
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.imageData = null;

        /**
         * MaskPatch rgbaImageData.
         * @member {Uint8Array|null|undefined} rgbaImageData
         * @memberof geometry.MaskPatch
         * @instance
         */
        MaskPatch.prototype.rgbaImageData = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MaskPatch data.
         * @member {"empty"|"fill"|"imageData"|"rgbaImageData"|undefined} data
         * @memberof geometry.MaskPatch
         * @instance
         */
        Object.defineProperty(MaskPatch.prototype, "data", {
            get: $util.oneOfGetter($oneOfFields = ["empty", "fill", "imageData", "rgbaImageData"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MaskPatch instance using the specified properties.
         * @function create
         * @memberof geometry.MaskPatch
         * @static
         * @param {geometry.IMaskPatch=} [properties] Properties to set
         * @returns {geometry.MaskPatch} MaskPatch instance
         */
        MaskPatch.create = function create(properties) {
            return new MaskPatch(properties);
        };

        /**
         * Encodes the specified MaskPatch message. Does not implicitly {@link geometry.MaskPatch.verify|verify} messages.
         * @function encode
         * @memberof geometry.MaskPatch
         * @static
         * @param {geometry.IMaskPatch} message MaskPatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskPatch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.x != null && Object.hasOwnProperty.call(message, "x"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.x);
            if (message.y != null && Object.hasOwnProperty.call(message, "y"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.y);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.height);
            if (message.empty != null && Object.hasOwnProperty.call(message, "empty"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.empty);
            if (message.fill != null && Object.hasOwnProperty.call(message, "fill"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.fill);
            if (message.imageData != null && Object.hasOwnProperty.call(message, "imageData"))
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.imageData);
            if (message.rgbaImageData != null && Object.hasOwnProperty.call(message, "rgbaImageData"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.rgbaImageData);
            return writer;
        };

        /**
         * Encodes the specified MaskPatch message, length delimited. Does not implicitly {@link geometry.MaskPatch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.MaskPatch
         * @static
         * @param {geometry.IMaskPatch} message MaskPatch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MaskPatch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MaskPatch message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.MaskPatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.MaskPatch} MaskPatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskPatch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.MaskPatch();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.x = reader.int32();
                        break;
                    }
                case 3: {
                        message.y = reader.int32();
                        break;
                    }
                case 4: {
                        message.width = reader.uint32();
                        break;
                    }
                case 5: {
                        message.height = reader.uint32();
                        break;
                    }
                case 6: {
                        message.empty = reader.bool();
                        break;
                    }
                case 7: {
                        message.fill = reader.uint32();
                        break;
                    }
                case 8: {
                        message.imageData = reader.bytes();
                        break;
                    }
                case 9: {
                        message.rgbaImageData = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MaskPatch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.MaskPatch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.MaskPatch} MaskPatch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MaskPatch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MaskPatch message.
         * @function verify
         * @memberof geometry.MaskPatch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MaskPatch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.x != null && message.hasOwnProperty("x"))
                if (!$util.isInteger(message.x))
                    return "x: integer expected";
            if (message.y != null && message.hasOwnProperty("y"))
                if (!$util.isInteger(message.y))
                    return "y: integer expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.empty != null && message.hasOwnProperty("empty")) {
                properties.data = 1;
                if (typeof message.empty !== "boolean")
                    return "empty: boolean expected";
            }
            if (message.fill != null && message.hasOwnProperty("fill")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!$util.isInteger(message.fill))
                    return "fill: integer expected";
            }
            if (message.imageData != null && message.hasOwnProperty("imageData")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!(message.imageData && typeof message.imageData.length === "number" || $util.isString(message.imageData)))
                    return "imageData: buffer expected";
            }
            if (message.rgbaImageData != null && message.hasOwnProperty("rgbaImageData")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                if (!(message.rgbaImageData && typeof message.rgbaImageData.length === "number" || $util.isString(message.rgbaImageData)))
                    return "rgbaImageData: buffer expected";
            }
            return null;
        };

        /**
         * Creates a MaskPatch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.MaskPatch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.MaskPatch} MaskPatch
         */
        MaskPatch.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.MaskPatch)
                return object;
            let message = new $root.geometry.MaskPatch();
            if (object.id != null)
                message.id = String(object.id);
            if (object.x != null)
                message.x = object.x | 0;
            if (object.y != null)
                message.y = object.y | 0;
            if (object.width != null)
                message.width = object.width >>> 0;
            if (object.height != null)
                message.height = object.height >>> 0;
            if (object.empty != null)
                message.empty = Boolean(object.empty);
            if (object.fill != null)
                message.fill = object.fill >>> 0;
            if (object.imageData != null)
                if (typeof object.imageData === "string")
                    $util.base64.decode(object.imageData, message.imageData = $util.newBuffer($util.base64.length(object.imageData)), 0);
                else if (object.imageData.length >= 0)
                    message.imageData = object.imageData;
            if (object.rgbaImageData != null)
                if (typeof object.rgbaImageData === "string")
                    $util.base64.decode(object.rgbaImageData, message.rgbaImageData = $util.newBuffer($util.base64.length(object.rgbaImageData)), 0);
                else if (object.rgbaImageData.length >= 0)
                    message.rgbaImageData = object.rgbaImageData;
            return message;
        };

        /**
         * Creates a plain object from a MaskPatch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.MaskPatch
         * @static
         * @param {geometry.MaskPatch} message MaskPatch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MaskPatch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.x = 0;
                object.y = 0;
                object.width = 0;
                object.height = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.x != null && message.hasOwnProperty("x"))
                object.x = message.x;
            if (message.y != null && message.hasOwnProperty("y"))
                object.y = message.y;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.empty != null && message.hasOwnProperty("empty")) {
                object.empty = message.empty;
                if (options.oneofs)
                    object.data = "empty";
            }
            if (message.fill != null && message.hasOwnProperty("fill")) {
                object.fill = message.fill;
                if (options.oneofs)
                    object.data = "fill";
            }
            if (message.imageData != null && message.hasOwnProperty("imageData")) {
                object.imageData = options.bytes === String ? $util.base64.encode(message.imageData, 0, message.imageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.imageData) : message.imageData;
                if (options.oneofs)
                    object.data = "imageData";
            }
            if (message.rgbaImageData != null && message.hasOwnProperty("rgbaImageData")) {
                object.rgbaImageData = options.bytes === String ? $util.base64.encode(message.rgbaImageData, 0, message.rgbaImageData.length) : options.bytes === Array ? Array.prototype.slice.call(message.rgbaImageData) : message.rgbaImageData;
                if (options.oneofs)
                    object.data = "rgbaImageData";
            }
            return object;
        };

        /**
         * Converts this MaskPatch to JSON.
         * @function toJSON
         * @memberof geometry.MaskPatch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MaskPatch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MaskPatch
         * @function getTypeUrl
         * @memberof geometry.MaskPatch
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MaskPatch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.MaskPatch";
        };

        return MaskPatch;
    })();

    geometry.Shape = (function() {

        /**
         * Properties of a Shape.
         * @memberof geometry
         * @interface IShape
         * @property {geometry.IFullGrid|null} [fullGrid] Shape fullGrid
         * @property {geometry.IPoint|null} [point] Shape point
         * @property {geometry.ILineSegment|null} [lineSegment] Shape lineSegment
         * @property {geometry.IGridCell|null} [gridCell] Shape gridCell
         * @property {geometry.IRectangle|null} [rectangle] Shape rectangle
         * @property {geometry.ICircle|null} [circle] Shape circle
         * @property {geometry.IEffectLine|null} [effectLine] Shape effectLine
         * @property {geometry.IPolygon|null} [polygon] Shape polygon
         * @property {geometry.IMask|null} [mask] Shape mask
         */

        /**
         * Constructs a new Shape.
         * @memberof geometry
         * @classdesc Represents a Shape.
         * @implements IShape
         * @constructor
         * @param {geometry.IShape=} [properties] Properties to set
         */
        function Shape(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Shape fullGrid.
         * @member {geometry.IFullGrid|null|undefined} fullGrid
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.fullGrid = null;

        /**
         * Shape point.
         * @member {geometry.IPoint|null|undefined} point
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.point = null;

        /**
         * Shape lineSegment.
         * @member {geometry.ILineSegment|null|undefined} lineSegment
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.lineSegment = null;

        /**
         * Shape gridCell.
         * @member {geometry.IGridCell|null|undefined} gridCell
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.gridCell = null;

        /**
         * Shape rectangle.
         * @member {geometry.IRectangle|null|undefined} rectangle
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.rectangle = null;

        /**
         * Shape circle.
         * @member {geometry.ICircle|null|undefined} circle
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.circle = null;

        /**
         * Shape effectLine.
         * @member {geometry.IEffectLine|null|undefined} effectLine
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.effectLine = null;

        /**
         * Shape polygon.
         * @member {geometry.IPolygon|null|undefined} polygon
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.polygon = null;

        /**
         * Shape mask.
         * @member {geometry.IMask|null|undefined} mask
         * @memberof geometry.Shape
         * @instance
         */
        Shape.prototype.mask = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Shape shape.
         * @member {"fullGrid"|"point"|"lineSegment"|"gridCell"|"rectangle"|"circle"|"effectLine"|"polygon"|"mask"|undefined} shape
         * @memberof geometry.Shape
         * @instance
         */
        Object.defineProperty(Shape.prototype, "shape", {
            get: $util.oneOfGetter($oneOfFields = ["fullGrid", "point", "lineSegment", "gridCell", "rectangle", "circle", "effectLine", "polygon", "mask"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Shape instance using the specified properties.
         * @function create
         * @memberof geometry.Shape
         * @static
         * @param {geometry.IShape=} [properties] Properties to set
         * @returns {geometry.Shape} Shape instance
         */
        Shape.create = function create(properties) {
            return new Shape(properties);
        };

        /**
         * Encodes the specified Shape message. Does not implicitly {@link geometry.Shape.verify|verify} messages.
         * @function encode
         * @memberof geometry.Shape
         * @static
         * @param {geometry.IShape} message Shape message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Shape.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fullGrid != null && Object.hasOwnProperty.call(message, "fullGrid"))
                $root.geometry.FullGrid.encode(message.fullGrid, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.point != null && Object.hasOwnProperty.call(message, "point"))
                $root.geometry.Point.encode(message.point, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.lineSegment != null && Object.hasOwnProperty.call(message, "lineSegment"))
                $root.geometry.LineSegment.encode(message.lineSegment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.gridCell != null && Object.hasOwnProperty.call(message, "gridCell"))
                $root.geometry.GridCell.encode(message.gridCell, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.rectangle != null && Object.hasOwnProperty.call(message, "rectangle"))
                $root.geometry.Rectangle.encode(message.rectangle, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.circle != null && Object.hasOwnProperty.call(message, "circle"))
                $root.geometry.Circle.encode(message.circle, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.effectLine != null && Object.hasOwnProperty.call(message, "effectLine"))
                $root.geometry.EffectLine.encode(message.effectLine, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                $root.geometry.Polygon.encode(message.polygon, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.mask != null && Object.hasOwnProperty.call(message, "mask"))
                $root.geometry.Mask.encode(message.mask, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Shape message, length delimited. Does not implicitly {@link geometry.Shape.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.Shape
         * @static
         * @param {geometry.IShape} message Shape message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Shape.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Shape message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.Shape
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.Shape} Shape
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Shape.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.Shape();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fullGrid = $root.geometry.FullGrid.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.point = $root.geometry.Point.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.lineSegment = $root.geometry.LineSegment.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.gridCell = $root.geometry.GridCell.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.rectangle = $root.geometry.Rectangle.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.circle = $root.geometry.Circle.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.effectLine = $root.geometry.EffectLine.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.polygon = $root.geometry.Polygon.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.mask = $root.geometry.Mask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Shape message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.Shape
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.Shape} Shape
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Shape.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Shape message.
         * @function verify
         * @memberof geometry.Shape
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Shape.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.fullGrid != null && message.hasOwnProperty("fullGrid")) {
                properties.shape = 1;
                {
                    let error = $root.geometry.FullGrid.verify(message.fullGrid);
                    if (error)
                        return "fullGrid." + error;
                }
            }
            if (message.point != null && message.hasOwnProperty("point")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.Point.verify(message.point);
                    if (error)
                        return "point." + error;
                }
            }
            if (message.lineSegment != null && message.hasOwnProperty("lineSegment")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.LineSegment.verify(message.lineSegment);
                    if (error)
                        return "lineSegment." + error;
                }
            }
            if (message.gridCell != null && message.hasOwnProperty("gridCell")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.GridCell.verify(message.gridCell);
                    if (error)
                        return "gridCell." + error;
                }
            }
            if (message.rectangle != null && message.hasOwnProperty("rectangle")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.Rectangle.verify(message.rectangle);
                    if (error)
                        return "rectangle." + error;
                }
            }
            if (message.circle != null && message.hasOwnProperty("circle")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.Circle.verify(message.circle);
                    if (error)
                        return "circle." + error;
                }
            }
            if (message.effectLine != null && message.hasOwnProperty("effectLine")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.EffectLine.verify(message.effectLine);
                    if (error)
                        return "effectLine." + error;
                }
            }
            if (message.polygon != null && message.hasOwnProperty("polygon")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
            }
            if (message.mask != null && message.hasOwnProperty("mask")) {
                if (properties.shape === 1)
                    return "shape: multiple values";
                properties.shape = 1;
                {
                    let error = $root.geometry.Mask.verify(message.mask);
                    if (error)
                        return "mask." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Shape message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.Shape
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.Shape} Shape
         */
        Shape.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.Shape)
                return object;
            let message = new $root.geometry.Shape();
            if (object.fullGrid != null) {
                if (typeof object.fullGrid !== "object")
                    throw TypeError(".geometry.Shape.fullGrid: object expected");
                message.fullGrid = $root.geometry.FullGrid.fromObject(object.fullGrid);
            }
            if (object.point != null) {
                if (typeof object.point !== "object")
                    throw TypeError(".geometry.Shape.point: object expected");
                message.point = $root.geometry.Point.fromObject(object.point);
            }
            if (object.lineSegment != null) {
                if (typeof object.lineSegment !== "object")
                    throw TypeError(".geometry.Shape.lineSegment: object expected");
                message.lineSegment = $root.geometry.LineSegment.fromObject(object.lineSegment);
            }
            if (object.gridCell != null) {
                if (typeof object.gridCell !== "object")
                    throw TypeError(".geometry.Shape.gridCell: object expected");
                message.gridCell = $root.geometry.GridCell.fromObject(object.gridCell);
            }
            if (object.rectangle != null) {
                if (typeof object.rectangle !== "object")
                    throw TypeError(".geometry.Shape.rectangle: object expected");
                message.rectangle = $root.geometry.Rectangle.fromObject(object.rectangle);
            }
            if (object.circle != null) {
                if (typeof object.circle !== "object")
                    throw TypeError(".geometry.Shape.circle: object expected");
                message.circle = $root.geometry.Circle.fromObject(object.circle);
            }
            if (object.effectLine != null) {
                if (typeof object.effectLine !== "object")
                    throw TypeError(".geometry.Shape.effectLine: object expected");
                message.effectLine = $root.geometry.EffectLine.fromObject(object.effectLine);
            }
            if (object.polygon != null) {
                if (typeof object.polygon !== "object")
                    throw TypeError(".geometry.Shape.polygon: object expected");
                message.polygon = $root.geometry.Polygon.fromObject(object.polygon);
            }
            if (object.mask != null) {
                if (typeof object.mask !== "object")
                    throw TypeError(".geometry.Shape.mask: object expected");
                message.mask = $root.geometry.Mask.fromObject(object.mask);
            }
            return message;
        };

        /**
         * Creates a plain object from a Shape message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.Shape
         * @static
         * @param {geometry.Shape} message Shape
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Shape.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.fullGrid != null && message.hasOwnProperty("fullGrid")) {
                object.fullGrid = $root.geometry.FullGrid.toObject(message.fullGrid, options);
                if (options.oneofs)
                    object.shape = "fullGrid";
            }
            if (message.point != null && message.hasOwnProperty("point")) {
                object.point = $root.geometry.Point.toObject(message.point, options);
                if (options.oneofs)
                    object.shape = "point";
            }
            if (message.lineSegment != null && message.hasOwnProperty("lineSegment")) {
                object.lineSegment = $root.geometry.LineSegment.toObject(message.lineSegment, options);
                if (options.oneofs)
                    object.shape = "lineSegment";
            }
            if (message.gridCell != null && message.hasOwnProperty("gridCell")) {
                object.gridCell = $root.geometry.GridCell.toObject(message.gridCell, options);
                if (options.oneofs)
                    object.shape = "gridCell";
            }
            if (message.rectangle != null && message.hasOwnProperty("rectangle")) {
                object.rectangle = $root.geometry.Rectangle.toObject(message.rectangle, options);
                if (options.oneofs)
                    object.shape = "rectangle";
            }
            if (message.circle != null && message.hasOwnProperty("circle")) {
                object.circle = $root.geometry.Circle.toObject(message.circle, options);
                if (options.oneofs)
                    object.shape = "circle";
            }
            if (message.effectLine != null && message.hasOwnProperty("effectLine")) {
                object.effectLine = $root.geometry.EffectLine.toObject(message.effectLine, options);
                if (options.oneofs)
                    object.shape = "effectLine";
            }
            if (message.polygon != null && message.hasOwnProperty("polygon")) {
                object.polygon = $root.geometry.Polygon.toObject(message.polygon, options);
                if (options.oneofs)
                    object.shape = "polygon";
            }
            if (message.mask != null && message.hasOwnProperty("mask")) {
                object.mask = $root.geometry.Mask.toObject(message.mask, options);
                if (options.oneofs)
                    object.shape = "mask";
            }
            return object;
        };

        /**
         * Converts this Shape to JSON.
         * @function toJSON
         * @memberof geometry.Shape
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Shape.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Shape
         * @function getTypeUrl
         * @memberof geometry.Shape
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Shape.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.Shape";
        };

        return Shape;
    })();

    geometry.GridConfiguration = (function() {

        /**
         * Properties of a GridConfiguration.
         * @memberof geometry
         * @interface IGridConfiguration
         * @property {geometry.GridConfiguration.IRectangleGrid|null} [rectangleGrid] GridConfiguration rectangleGrid
         */

        /**
         * Constructs a new GridConfiguration.
         * @memberof geometry
         * @classdesc Represents a GridConfiguration.
         * @implements IGridConfiguration
         * @constructor
         * @param {geometry.IGridConfiguration=} [properties] Properties to set
         */
        function GridConfiguration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridConfiguration rectangleGrid.
         * @member {geometry.GridConfiguration.IRectangleGrid|null|undefined} rectangleGrid
         * @memberof geometry.GridConfiguration
         * @instance
         */
        GridConfiguration.prototype.rectangleGrid = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GridConfiguration details.
         * @member {"rectangleGrid"|undefined} details
         * @memberof geometry.GridConfiguration
         * @instance
         */
        Object.defineProperty(GridConfiguration.prototype, "details", {
            get: $util.oneOfGetter($oneOfFields = ["rectangleGrid"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GridConfiguration instance using the specified properties.
         * @function create
         * @memberof geometry.GridConfiguration
         * @static
         * @param {geometry.IGridConfiguration=} [properties] Properties to set
         * @returns {geometry.GridConfiguration} GridConfiguration instance
         */
        GridConfiguration.create = function create(properties) {
            return new GridConfiguration(properties);
        };

        /**
         * Encodes the specified GridConfiguration message. Does not implicitly {@link geometry.GridConfiguration.verify|verify} messages.
         * @function encode
         * @memberof geometry.GridConfiguration
         * @static
         * @param {geometry.IGridConfiguration} message GridConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridConfiguration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rectangleGrid != null && Object.hasOwnProperty.call(message, "rectangleGrid"))
                $root.geometry.GridConfiguration.RectangleGrid.encode(message.rectangleGrid, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GridConfiguration message, length delimited. Does not implicitly {@link geometry.GridConfiguration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof geometry.GridConfiguration
         * @static
         * @param {geometry.IGridConfiguration} message GridConfiguration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridConfiguration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridConfiguration message from the specified reader or buffer.
         * @function decode
         * @memberof geometry.GridConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {geometry.GridConfiguration} GridConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridConfiguration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.GridConfiguration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rectangleGrid = $root.geometry.GridConfiguration.RectangleGrid.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridConfiguration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof geometry.GridConfiguration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {geometry.GridConfiguration} GridConfiguration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridConfiguration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridConfiguration message.
         * @function verify
         * @memberof geometry.GridConfiguration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridConfiguration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.rectangleGrid != null && message.hasOwnProperty("rectangleGrid")) {
                properties.details = 1;
                {
                    let error = $root.geometry.GridConfiguration.RectangleGrid.verify(message.rectangleGrid);
                    if (error)
                        return "rectangleGrid." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GridConfiguration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof geometry.GridConfiguration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {geometry.GridConfiguration} GridConfiguration
         */
        GridConfiguration.fromObject = function fromObject(object) {
            if (object instanceof $root.geometry.GridConfiguration)
                return object;
            let message = new $root.geometry.GridConfiguration();
            if (object.rectangleGrid != null) {
                if (typeof object.rectangleGrid !== "object")
                    throw TypeError(".geometry.GridConfiguration.rectangleGrid: object expected");
                message.rectangleGrid = $root.geometry.GridConfiguration.RectangleGrid.fromObject(object.rectangleGrid);
            }
            return message;
        };

        /**
         * Creates a plain object from a GridConfiguration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof geometry.GridConfiguration
         * @static
         * @param {geometry.GridConfiguration} message GridConfiguration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridConfiguration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.rectangleGrid != null && message.hasOwnProperty("rectangleGrid")) {
                object.rectangleGrid = $root.geometry.GridConfiguration.RectangleGrid.toObject(message.rectangleGrid, options);
                if (options.oneofs)
                    object.details = "rectangleGrid";
            }
            return object;
        };

        /**
         * Converts this GridConfiguration to JSON.
         * @function toJSON
         * @memberof geometry.GridConfiguration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridConfiguration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridConfiguration
         * @function getTypeUrl
         * @memberof geometry.GridConfiguration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridConfiguration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/geometry.GridConfiguration";
        };

        GridConfiguration.RectangleGrid = (function() {

            /**
             * Properties of a RectangleGrid.
             * @memberof geometry.GridConfiguration
             * @interface IRectangleGrid
             * @property {number|null} [originX] RectangleGrid originX
             * @property {number|null} [originY] RectangleGrid originY
             * @property {number|null} [anchorX] RectangleGrid anchorX
             * @property {number|null} [anchorY] RectangleGrid anchorY
             * @property {number|null} [cellWidth] RectangleGrid cellWidth
             * @property {number|null} [cellHeight] RectangleGrid cellHeight
             * @property {number|null} [dpi] RectangleGrid dpi
             */

            /**
             * Constructs a new RectangleGrid.
             * @memberof geometry.GridConfiguration
             * @classdesc Represents a RectangleGrid.
             * @implements IRectangleGrid
             * @constructor
             * @param {geometry.GridConfiguration.IRectangleGrid=} [properties] Properties to set
             */
            function RectangleGrid(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RectangleGrid originX.
             * @member {number} originX
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.originX = 0;

            /**
             * RectangleGrid originY.
             * @member {number} originY
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.originY = 0;

            /**
             * RectangleGrid anchorX.
             * @member {number} anchorX
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.anchorX = 0;

            /**
             * RectangleGrid anchorY.
             * @member {number} anchorY
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.anchorY = 0;

            /**
             * RectangleGrid cellWidth.
             * @member {number} cellWidth
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.cellWidth = 0;

            /**
             * RectangleGrid cellHeight.
             * @member {number} cellHeight
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.cellHeight = 0;

            /**
             * RectangleGrid dpi.
             * @member {number} dpi
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             */
            RectangleGrid.prototype.dpi = 0;

            /**
             * Creates a new RectangleGrid instance using the specified properties.
             * @function create
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {geometry.GridConfiguration.IRectangleGrid=} [properties] Properties to set
             * @returns {geometry.GridConfiguration.RectangleGrid} RectangleGrid instance
             */
            RectangleGrid.create = function create(properties) {
                return new RectangleGrid(properties);
            };

            /**
             * Encodes the specified RectangleGrid message. Does not implicitly {@link geometry.GridConfiguration.RectangleGrid.verify|verify} messages.
             * @function encode
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {geometry.GridConfiguration.IRectangleGrid} message RectangleGrid message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RectangleGrid.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.originX != null && Object.hasOwnProperty.call(message, "originX"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.originX);
                if (message.originY != null && Object.hasOwnProperty.call(message, "originY"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.originY);
                if (message.anchorX != null && Object.hasOwnProperty.call(message, "anchorX"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.anchorX);
                if (message.anchorY != null && Object.hasOwnProperty.call(message, "anchorY"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.anchorY);
                if (message.cellWidth != null && Object.hasOwnProperty.call(message, "cellWidth"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.cellWidth);
                if (message.cellHeight != null && Object.hasOwnProperty.call(message, "cellHeight"))
                    writer.uint32(/* id 6, wireType 5 =*/53).float(message.cellHeight);
                if (message.dpi != null && Object.hasOwnProperty.call(message, "dpi"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.dpi);
                return writer;
            };

            /**
             * Encodes the specified RectangleGrid message, length delimited. Does not implicitly {@link geometry.GridConfiguration.RectangleGrid.verify|verify} messages.
             * @function encodeDelimited
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {geometry.GridConfiguration.IRectangleGrid} message RectangleGrid message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RectangleGrid.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RectangleGrid message from the specified reader or buffer.
             * @function decode
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {geometry.GridConfiguration.RectangleGrid} RectangleGrid
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RectangleGrid.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.geometry.GridConfiguration.RectangleGrid();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.originX = reader.float();
                            break;
                        }
                    case 2: {
                            message.originY = reader.float();
                            break;
                        }
                    case 3: {
                            message.anchorX = reader.float();
                            break;
                        }
                    case 4: {
                            message.anchorY = reader.float();
                            break;
                        }
                    case 5: {
                            message.cellWidth = reader.float();
                            break;
                        }
                    case 6: {
                            message.cellHeight = reader.float();
                            break;
                        }
                    case 7: {
                            message.dpi = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RectangleGrid message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {geometry.GridConfiguration.RectangleGrid} RectangleGrid
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RectangleGrid.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RectangleGrid message.
             * @function verify
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RectangleGrid.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.originX != null && message.hasOwnProperty("originX"))
                    if (typeof message.originX !== "number")
                        return "originX: number expected";
                if (message.originY != null && message.hasOwnProperty("originY"))
                    if (typeof message.originY !== "number")
                        return "originY: number expected";
                if (message.anchorX != null && message.hasOwnProperty("anchorX"))
                    if (typeof message.anchorX !== "number")
                        return "anchorX: number expected";
                if (message.anchorY != null && message.hasOwnProperty("anchorY"))
                    if (typeof message.anchorY !== "number")
                        return "anchorY: number expected";
                if (message.cellWidth != null && message.hasOwnProperty("cellWidth"))
                    if (typeof message.cellWidth !== "number")
                        return "cellWidth: number expected";
                if (message.cellHeight != null && message.hasOwnProperty("cellHeight"))
                    if (typeof message.cellHeight !== "number")
                        return "cellHeight: number expected";
                if (message.dpi != null && message.hasOwnProperty("dpi"))
                    if (!$util.isInteger(message.dpi))
                        return "dpi: integer expected";
                return null;
            };

            /**
             * Creates a RectangleGrid message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {geometry.GridConfiguration.RectangleGrid} RectangleGrid
             */
            RectangleGrid.fromObject = function fromObject(object) {
                if (object instanceof $root.geometry.GridConfiguration.RectangleGrid)
                    return object;
                let message = new $root.geometry.GridConfiguration.RectangleGrid();
                if (object.originX != null)
                    message.originX = Number(object.originX);
                if (object.originY != null)
                    message.originY = Number(object.originY);
                if (object.anchorX != null)
                    message.anchorX = Number(object.anchorX);
                if (object.anchorY != null)
                    message.anchorY = Number(object.anchorY);
                if (object.cellWidth != null)
                    message.cellWidth = Number(object.cellWidth);
                if (object.cellHeight != null)
                    message.cellHeight = Number(object.cellHeight);
                if (object.dpi != null)
                    message.dpi = object.dpi | 0;
                return message;
            };

            /**
             * Creates a plain object from a RectangleGrid message. Also converts values to other types if specified.
             * @function toObject
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {geometry.GridConfiguration.RectangleGrid} message RectangleGrid
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RectangleGrid.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.originX = 0;
                    object.originY = 0;
                    object.anchorX = 0;
                    object.anchorY = 0;
                    object.cellWidth = 0;
                    object.cellHeight = 0;
                    object.dpi = 0;
                }
                if (message.originX != null && message.hasOwnProperty("originX"))
                    object.originX = options.json && !isFinite(message.originX) ? String(message.originX) : message.originX;
                if (message.originY != null && message.hasOwnProperty("originY"))
                    object.originY = options.json && !isFinite(message.originY) ? String(message.originY) : message.originY;
                if (message.anchorX != null && message.hasOwnProperty("anchorX"))
                    object.anchorX = options.json && !isFinite(message.anchorX) ? String(message.anchorX) : message.anchorX;
                if (message.anchorY != null && message.hasOwnProperty("anchorY"))
                    object.anchorY = options.json && !isFinite(message.anchorY) ? String(message.anchorY) : message.anchorY;
                if (message.cellWidth != null && message.hasOwnProperty("cellWidth"))
                    object.cellWidth = options.json && !isFinite(message.cellWidth) ? String(message.cellWidth) : message.cellWidth;
                if (message.cellHeight != null && message.hasOwnProperty("cellHeight"))
                    object.cellHeight = options.json && !isFinite(message.cellHeight) ? String(message.cellHeight) : message.cellHeight;
                if (message.dpi != null && message.hasOwnProperty("dpi"))
                    object.dpi = message.dpi;
                return object;
            };

            /**
             * Converts this RectangleGrid to JSON.
             * @function toJSON
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RectangleGrid.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RectangleGrid
             * @function getTypeUrl
             * @memberof geometry.GridConfiguration.RectangleGrid
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RectangleGrid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/geometry.GridConfiguration.RectangleGrid";
            };

            return RectangleGrid;
        })();

        return GridConfiguration;
    })();

    return geometry;
})();

export const maps = $root.maps = (() => {

    /**
     * Namespace maps.
     * @exports maps
     * @namespace
     */
    const maps = {};

    maps.DeleteByIdsRequest = (function() {

        /**
         * Properties of a DeleteByIdsRequest.
         * @memberof maps
         * @interface IDeleteByIdsRequest
         * @property {Array.<string>|null} [ids] DeleteByIdsRequest ids
         */

        /**
         * Constructs a new DeleteByIdsRequest.
         * @memberof maps
         * @classdesc Represents a DeleteByIdsRequest.
         * @implements IDeleteByIdsRequest
         * @constructor
         * @param {maps.IDeleteByIdsRequest=} [properties] Properties to set
         */
        function DeleteByIdsRequest(properties) {
            this.ids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteByIdsRequest ids.
         * @member {Array.<string>} ids
         * @memberof maps.DeleteByIdsRequest
         * @instance
         */
        DeleteByIdsRequest.prototype.ids = $util.emptyArray;

        /**
         * Creates a new DeleteByIdsRequest instance using the specified properties.
         * @function create
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {maps.IDeleteByIdsRequest=} [properties] Properties to set
         * @returns {maps.DeleteByIdsRequest} DeleteByIdsRequest instance
         */
        DeleteByIdsRequest.create = function create(properties) {
            return new DeleteByIdsRequest(properties);
        };

        /**
         * Encodes the specified DeleteByIdsRequest message. Does not implicitly {@link maps.DeleteByIdsRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {maps.IDeleteByIdsRequest} message DeleteByIdsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteByIdsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ids != null && message.ids.length)
                for (let i = 0; i < message.ids.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.ids[i]);
            return writer;
        };

        /**
         * Encodes the specified DeleteByIdsRequest message, length delimited. Does not implicitly {@link maps.DeleteByIdsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {maps.IDeleteByIdsRequest} message DeleteByIdsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteByIdsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteByIdsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.DeleteByIdsRequest} DeleteByIdsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteByIdsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.DeleteByIdsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ids && message.ids.length))
                            message.ids = [];
                        message.ids.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteByIdsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.DeleteByIdsRequest} DeleteByIdsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteByIdsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteByIdsRequest message.
         * @function verify
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteByIdsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ids != null && message.hasOwnProperty("ids")) {
                if (!Array.isArray(message.ids))
                    return "ids: array expected";
                for (let i = 0; i < message.ids.length; ++i)
                    if (!$util.isString(message.ids[i]))
                        return "ids: string[] expected";
            }
            return null;
        };

        /**
         * Creates a DeleteByIdsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.DeleteByIdsRequest} DeleteByIdsRequest
         */
        DeleteByIdsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.DeleteByIdsRequest)
                return object;
            let message = new $root.maps.DeleteByIdsRequest();
            if (object.ids) {
                if (!Array.isArray(object.ids))
                    throw TypeError(".maps.DeleteByIdsRequest.ids: array expected");
                message.ids = [];
                for (let i = 0; i < object.ids.length; ++i)
                    message.ids[i] = String(object.ids[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteByIdsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {maps.DeleteByIdsRequest} message DeleteByIdsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteByIdsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ids = [];
            if (message.ids && message.ids.length) {
                object.ids = [];
                for (let j = 0; j < message.ids.length; ++j)
                    object.ids[j] = message.ids[j];
            }
            return object;
        };

        /**
         * Converts this DeleteByIdsRequest to JSON.
         * @function toJSON
         * @memberof maps.DeleteByIdsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteByIdsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteByIdsRequest
         * @function getTypeUrl
         * @memberof maps.DeleteByIdsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteByIdsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.DeleteByIdsRequest";
        };

        return DeleteByIdsRequest;
    })();

    maps.DeleteByIdsResponse = (function() {

        /**
         * Properties of a DeleteByIdsResponse.
         * @memberof maps
         * @interface IDeleteByIdsResponse
         */

        /**
         * Constructs a new DeleteByIdsResponse.
         * @memberof maps
         * @classdesc Represents a DeleteByIdsResponse.
         * @implements IDeleteByIdsResponse
         * @constructor
         * @param {maps.IDeleteByIdsResponse=} [properties] Properties to set
         */
        function DeleteByIdsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteByIdsResponse instance using the specified properties.
         * @function create
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {maps.IDeleteByIdsResponse=} [properties] Properties to set
         * @returns {maps.DeleteByIdsResponse} DeleteByIdsResponse instance
         */
        DeleteByIdsResponse.create = function create(properties) {
            return new DeleteByIdsResponse(properties);
        };

        /**
         * Encodes the specified DeleteByIdsResponse message. Does not implicitly {@link maps.DeleteByIdsResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {maps.IDeleteByIdsResponse} message DeleteByIdsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteByIdsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteByIdsResponse message, length delimited. Does not implicitly {@link maps.DeleteByIdsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {maps.IDeleteByIdsResponse} message DeleteByIdsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteByIdsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteByIdsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.DeleteByIdsResponse} DeleteByIdsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteByIdsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.DeleteByIdsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteByIdsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.DeleteByIdsResponse} DeleteByIdsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteByIdsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteByIdsResponse message.
         * @function verify
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteByIdsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteByIdsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.DeleteByIdsResponse} DeleteByIdsResponse
         */
        DeleteByIdsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.DeleteByIdsResponse)
                return object;
            return new $root.maps.DeleteByIdsResponse();
        };

        /**
         * Creates a plain object from a DeleteByIdsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {maps.DeleteByIdsResponse} message DeleteByIdsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteByIdsResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteByIdsResponse to JSON.
         * @function toJSON
         * @memberof maps.DeleteByIdsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteByIdsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteByIdsResponse
         * @function getTypeUrl
         * @memberof maps.DeleteByIdsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteByIdsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.DeleteByIdsResponse";
        };

        return DeleteByIdsResponse;
    })();

    maps.GetMapsRequest = (function() {

        /**
         * Properties of a GetMapsRequest.
         * @memberof maps
         * @interface IGetMapsRequest
         * @property {Array.<string>|null} [mapIds] GetMapsRequest mapIds
         * @property {Array.<maps.MapDataset>|null} [datasets] GetMapsRequest datasets
         */

        /**
         * Constructs a new GetMapsRequest.
         * @memberof maps
         * @classdesc Represents a GetMapsRequest.
         * @implements IGetMapsRequest
         * @constructor
         * @param {maps.IGetMapsRequest=} [properties] Properties to set
         */
        function GetMapsRequest(properties) {
            this.mapIds = [];
            this.datasets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMapsRequest mapIds.
         * @member {Array.<string>} mapIds
         * @memberof maps.GetMapsRequest
         * @instance
         */
        GetMapsRequest.prototype.mapIds = $util.emptyArray;

        /**
         * GetMapsRequest datasets.
         * @member {Array.<maps.MapDataset>} datasets
         * @memberof maps.GetMapsRequest
         * @instance
         */
        GetMapsRequest.prototype.datasets = $util.emptyArray;

        /**
         * Creates a new GetMapsRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetMapsRequest
         * @static
         * @param {maps.IGetMapsRequest=} [properties] Properties to set
         * @returns {maps.GetMapsRequest} GetMapsRequest instance
         */
        GetMapsRequest.create = function create(properties) {
            return new GetMapsRequest(properties);
        };

        /**
         * Encodes the specified GetMapsRequest message. Does not implicitly {@link maps.GetMapsRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMapsRequest
         * @static
         * @param {maps.IGetMapsRequest} message GetMapsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapIds != null && message.mapIds.length)
                for (let i = 0; i < message.mapIds.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapIds[i]);
            if (message.datasets != null && message.datasets.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.datasets.length; ++i)
                    writer.int32(message.datasets[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified GetMapsRequest message, length delimited. Does not implicitly {@link maps.GetMapsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMapsRequest
         * @static
         * @param {maps.IGetMapsRequest} message GetMapsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMapsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMapsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMapsRequest} GetMapsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMapsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mapIds && message.mapIds.length))
                            message.mapIds = [];
                        message.mapIds.push(reader.string());
                        break;
                    }
                case 2: {
                        if (!(message.datasets && message.datasets.length))
                            message.datasets = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.datasets.push(reader.int32());
                        } else
                            message.datasets.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMapsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMapsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMapsRequest} GetMapsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMapsRequest message.
         * @function verify
         * @memberof maps.GetMapsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMapsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapIds != null && message.hasOwnProperty("mapIds")) {
                if (!Array.isArray(message.mapIds))
                    return "mapIds: array expected";
                for (let i = 0; i < message.mapIds.length; ++i)
                    if (!$util.isString(message.mapIds[i]))
                        return "mapIds: string[] expected";
            }
            if (message.datasets != null && message.hasOwnProperty("datasets")) {
                if (!Array.isArray(message.datasets))
                    return "datasets: array expected";
                for (let i = 0; i < message.datasets.length; ++i)
                    switch (message.datasets[i]) {
                    default:
                        return "datasets: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a GetMapsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMapsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMapsRequest} GetMapsRequest
         */
        GetMapsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMapsRequest)
                return object;
            let message = new $root.maps.GetMapsRequest();
            if (object.mapIds) {
                if (!Array.isArray(object.mapIds))
                    throw TypeError(".maps.GetMapsRequest.mapIds: array expected");
                message.mapIds = [];
                for (let i = 0; i < object.mapIds.length; ++i)
                    message.mapIds[i] = String(object.mapIds[i]);
            }
            if (object.datasets) {
                if (!Array.isArray(object.datasets))
                    throw TypeError(".maps.GetMapsRequest.datasets: array expected");
                message.datasets = [];
                for (let i = 0; i < object.datasets.length; ++i)
                    switch (object.datasets[i]) {
                    default:
                        if (typeof object.datasets[i] === "number") {
                            message.datasets[i] = object.datasets[i];
                            break;
                        }
                    case "UNKNOWN_MAP_DATASET":
                    case 0:
                        message.datasets[i] = 0;
                        break;
                    case "BASIC_MAP_DATA":
                    case 1:
                        message.datasets[i] = 1;
                        break;
                    case "MAP_ZONES_DATA":
                    case 2:
                        message.datasets[i] = 2;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMapsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMapsRequest
         * @static
         * @param {maps.GetMapsRequest} message GetMapsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMapsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.mapIds = [];
                object.datasets = [];
            }
            if (message.mapIds && message.mapIds.length) {
                object.mapIds = [];
                for (let j = 0; j < message.mapIds.length; ++j)
                    object.mapIds[j] = message.mapIds[j];
            }
            if (message.datasets && message.datasets.length) {
                object.datasets = [];
                for (let j = 0; j < message.datasets.length; ++j)
                    object.datasets[j] = options.enums === String ? $root.maps.MapDataset[message.datasets[j]] === undefined ? message.datasets[j] : $root.maps.MapDataset[message.datasets[j]] : message.datasets[j];
            }
            return object;
        };

        /**
         * Converts this GetMapsRequest to JSON.
         * @function toJSON
         * @memberof maps.GetMapsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMapsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMapsRequest
         * @function getTypeUrl
         * @memberof maps.GetMapsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMapsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMapsRequest";
        };

        return GetMapsRequest;
    })();

    maps.GetMapsResponse = (function() {

        /**
         * Properties of a GetMapsResponse.
         * @memberof maps
         * @interface IGetMapsResponse
         * @property {Array.<maps.IMap>|null} [maps] GetMapsResponse maps
         */

        /**
         * Constructs a new GetMapsResponse.
         * @memberof maps
         * @classdesc Represents a GetMapsResponse.
         * @implements IGetMapsResponse
         * @constructor
         * @param {maps.IGetMapsResponse=} [properties] Properties to set
         */
        function GetMapsResponse(properties) {
            this.maps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMapsResponse maps.
         * @member {Array.<maps.IMap>} maps
         * @memberof maps.GetMapsResponse
         * @instance
         */
        GetMapsResponse.prototype.maps = $util.emptyArray;

        /**
         * Creates a new GetMapsResponse instance using the specified properties.
         * @function create
         * @memberof maps.GetMapsResponse
         * @static
         * @param {maps.IGetMapsResponse=} [properties] Properties to set
         * @returns {maps.GetMapsResponse} GetMapsResponse instance
         */
        GetMapsResponse.create = function create(properties) {
            return new GetMapsResponse(properties);
        };

        /**
         * Encodes the specified GetMapsResponse message. Does not implicitly {@link maps.GetMapsResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMapsResponse
         * @static
         * @param {maps.IGetMapsResponse} message GetMapsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maps != null && message.maps.length)
                for (let i = 0; i < message.maps.length; ++i)
                    $root.maps.Map.encode(message.maps[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetMapsResponse message, length delimited. Does not implicitly {@link maps.GetMapsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMapsResponse
         * @static
         * @param {maps.IGetMapsResponse} message GetMapsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMapsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMapsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMapsResponse} GetMapsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMapsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.maps && message.maps.length))
                            message.maps = [];
                        message.maps.push($root.maps.Map.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMapsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMapsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMapsResponse} GetMapsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMapsResponse message.
         * @function verify
         * @memberof maps.GetMapsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMapsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maps != null && message.hasOwnProperty("maps")) {
                if (!Array.isArray(message.maps))
                    return "maps: array expected";
                for (let i = 0; i < message.maps.length; ++i) {
                    let error = $root.maps.Map.verify(message.maps[i]);
                    if (error)
                        return "maps." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetMapsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMapsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMapsResponse} GetMapsResponse
         */
        GetMapsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMapsResponse)
                return object;
            let message = new $root.maps.GetMapsResponse();
            if (object.maps) {
                if (!Array.isArray(object.maps))
                    throw TypeError(".maps.GetMapsResponse.maps: array expected");
                message.maps = [];
                for (let i = 0; i < object.maps.length; ++i) {
                    if (typeof object.maps[i] !== "object")
                        throw TypeError(".maps.GetMapsResponse.maps: object expected");
                    message.maps[i] = $root.maps.Map.fromObject(object.maps[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMapsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMapsResponse
         * @static
         * @param {maps.GetMapsResponse} message GetMapsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMapsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.maps = [];
            if (message.maps && message.maps.length) {
                object.maps = [];
                for (let j = 0; j < message.maps.length; ++j)
                    object.maps[j] = $root.maps.Map.toObject(message.maps[j], options);
            }
            return object;
        };

        /**
         * Converts this GetMapsResponse to JSON.
         * @function toJSON
         * @memberof maps.GetMapsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMapsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMapsResponse
         * @function getTypeUrl
         * @memberof maps.GetMapsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMapsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMapsResponse";
        };

        return GetMapsResponse;
    })();

    maps.MapSearchRequest = (function() {

        /**
         * Properties of a MapSearchRequest.
         * @memberof maps
         * @interface IMapSearchRequest
         * @property {Array.<maps.MapDataset>|null} [datasets] MapSearchRequest datasets
         * @property {google.protobuf.IStringValue|null} [searchTerm] MapSearchRequest searchTerm
         * @property {google.protobuf.IStringValue|null} [campaignId] MapSearchRequest campaignId
         * @property {google.protobuf.IInt32Value|null} [limit] MapSearchRequest limit
         * @property {google.protobuf.IInt32Value|null} [offset] MapSearchRequest offset
         */

        /**
         * Constructs a new MapSearchRequest.
         * @memberof maps
         * @classdesc Represents a MapSearchRequest.
         * @implements IMapSearchRequest
         * @constructor
         * @param {maps.IMapSearchRequest=} [properties] Properties to set
         */
        function MapSearchRequest(properties) {
            this.datasets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapSearchRequest datasets.
         * @member {Array.<maps.MapDataset>} datasets
         * @memberof maps.MapSearchRequest
         * @instance
         */
        MapSearchRequest.prototype.datasets = $util.emptyArray;

        /**
         * MapSearchRequest searchTerm.
         * @member {google.protobuf.IStringValue|null|undefined} searchTerm
         * @memberof maps.MapSearchRequest
         * @instance
         */
        MapSearchRequest.prototype.searchTerm = null;

        /**
         * MapSearchRequest campaignId.
         * @member {google.protobuf.IStringValue|null|undefined} campaignId
         * @memberof maps.MapSearchRequest
         * @instance
         */
        MapSearchRequest.prototype.campaignId = null;

        /**
         * MapSearchRequest limit.
         * @member {google.protobuf.IInt32Value|null|undefined} limit
         * @memberof maps.MapSearchRequest
         * @instance
         */
        MapSearchRequest.prototype.limit = null;

        /**
         * MapSearchRequest offset.
         * @member {google.protobuf.IInt32Value|null|undefined} offset
         * @memberof maps.MapSearchRequest
         * @instance
         */
        MapSearchRequest.prototype.offset = null;

        /**
         * Creates a new MapSearchRequest instance using the specified properties.
         * @function create
         * @memberof maps.MapSearchRequest
         * @static
         * @param {maps.IMapSearchRequest=} [properties] Properties to set
         * @returns {maps.MapSearchRequest} MapSearchRequest instance
         */
        MapSearchRequest.create = function create(properties) {
            return new MapSearchRequest(properties);
        };

        /**
         * Encodes the specified MapSearchRequest message. Does not implicitly {@link maps.MapSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.MapSearchRequest
         * @static
         * @param {maps.IMapSearchRequest} message MapSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.datasets != null && message.datasets.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.datasets.length; ++i)
                    writer.int32(message.datasets[i]);
                writer.ldelim();
            }
            if (message.searchTerm != null && Object.hasOwnProperty.call(message, "searchTerm"))
                $root.google.protobuf.StringValue.encode(message.searchTerm, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                $root.google.protobuf.StringValue.encode(message.campaignId, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                $root.google.protobuf.Int32Value.encode(message.limit, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                $root.google.protobuf.Int32Value.encode(message.offset, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapSearchRequest message, length delimited. Does not implicitly {@link maps.MapSearchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapSearchRequest
         * @static
         * @param {maps.IMapSearchRequest} message MapSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapSearchRequest} MapSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapSearchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.datasets && message.datasets.length))
                            message.datasets = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.datasets.push(reader.int32());
                        } else
                            message.datasets.push(reader.int32());
                        break;
                    }
                case 2: {
                        message.searchTerm = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.campaignId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        message.limit = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.offset = $root.google.protobuf.Int32Value.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapSearchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapSearchRequest} MapSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapSearchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapSearchRequest message.
         * @function verify
         * @memberof maps.MapSearchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapSearchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.datasets != null && message.hasOwnProperty("datasets")) {
                if (!Array.isArray(message.datasets))
                    return "datasets: array expected";
                for (let i = 0; i < message.datasets.length; ++i)
                    switch (message.datasets[i]) {
                    default:
                        return "datasets: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm")) {
                let error = $root.google.protobuf.StringValue.verify(message.searchTerm);
                if (error)
                    return "searchTerm." + error;
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId")) {
                let error = $root.google.protobuf.StringValue.verify(message.campaignId);
                if (error)
                    return "campaignId." + error;
            }
            if (message.limit != null && message.hasOwnProperty("limit")) {
                let error = $root.google.protobuf.Int32Value.verify(message.limit);
                if (error)
                    return "limit." + error;
            }
            if (message.offset != null && message.hasOwnProperty("offset")) {
                let error = $root.google.protobuf.Int32Value.verify(message.offset);
                if (error)
                    return "offset." + error;
            }
            return null;
        };

        /**
         * Creates a MapSearchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapSearchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapSearchRequest} MapSearchRequest
         */
        MapSearchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapSearchRequest)
                return object;
            let message = new $root.maps.MapSearchRequest();
            if (object.datasets) {
                if (!Array.isArray(object.datasets))
                    throw TypeError(".maps.MapSearchRequest.datasets: array expected");
                message.datasets = [];
                for (let i = 0; i < object.datasets.length; ++i)
                    switch (object.datasets[i]) {
                    default:
                        if (typeof object.datasets[i] === "number") {
                            message.datasets[i] = object.datasets[i];
                            break;
                        }
                    case "UNKNOWN_MAP_DATASET":
                    case 0:
                        message.datasets[i] = 0;
                        break;
                    case "BASIC_MAP_DATA":
                    case 1:
                        message.datasets[i] = 1;
                        break;
                    case "MAP_ZONES_DATA":
                    case 2:
                        message.datasets[i] = 2;
                        break;
                    }
            }
            if (object.searchTerm != null) {
                if (typeof object.searchTerm !== "object")
                    throw TypeError(".maps.MapSearchRequest.searchTerm: object expected");
                message.searchTerm = $root.google.protobuf.StringValue.fromObject(object.searchTerm);
            }
            if (object.campaignId != null) {
                if (typeof object.campaignId !== "object")
                    throw TypeError(".maps.MapSearchRequest.campaignId: object expected");
                message.campaignId = $root.google.protobuf.StringValue.fromObject(object.campaignId);
            }
            if (object.limit != null) {
                if (typeof object.limit !== "object")
                    throw TypeError(".maps.MapSearchRequest.limit: object expected");
                message.limit = $root.google.protobuf.Int32Value.fromObject(object.limit);
            }
            if (object.offset != null) {
                if (typeof object.offset !== "object")
                    throw TypeError(".maps.MapSearchRequest.offset: object expected");
                message.offset = $root.google.protobuf.Int32Value.fromObject(object.offset);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapSearchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapSearchRequest
         * @static
         * @param {maps.MapSearchRequest} message MapSearchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapSearchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.datasets = [];
            if (options.defaults) {
                object.searchTerm = null;
                object.campaignId = null;
                object.limit = null;
                object.offset = null;
            }
            if (message.datasets && message.datasets.length) {
                object.datasets = [];
                for (let j = 0; j < message.datasets.length; ++j)
                    object.datasets[j] = options.enums === String ? $root.maps.MapDataset[message.datasets[j]] === undefined ? message.datasets[j] : $root.maps.MapDataset[message.datasets[j]] : message.datasets[j];
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm"))
                object.searchTerm = $root.google.protobuf.StringValue.toObject(message.searchTerm, options);
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = $root.google.protobuf.StringValue.toObject(message.campaignId, options);
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = $root.google.protobuf.Int32Value.toObject(message.limit, options);
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = $root.google.protobuf.Int32Value.toObject(message.offset, options);
            return object;
        };

        /**
         * Converts this MapSearchRequest to JSON.
         * @function toJSON
         * @memberof maps.MapSearchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapSearchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapSearchRequest
         * @function getTypeUrl
         * @memberof maps.MapSearchRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapSearchRequest";
        };

        return MapSearchRequest;
    })();

    maps.MapSearchResponse = (function() {

        /**
         * Properties of a MapSearchResponse.
         * @memberof maps
         * @interface IMapSearchResponse
         * @property {Array.<maps.IMap>|null} [maps] MapSearchResponse maps
         */

        /**
         * Constructs a new MapSearchResponse.
         * @memberof maps
         * @classdesc Represents a MapSearchResponse.
         * @implements IMapSearchResponse
         * @constructor
         * @param {maps.IMapSearchResponse=} [properties] Properties to set
         */
        function MapSearchResponse(properties) {
            this.maps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapSearchResponse maps.
         * @member {Array.<maps.IMap>} maps
         * @memberof maps.MapSearchResponse
         * @instance
         */
        MapSearchResponse.prototype.maps = $util.emptyArray;

        /**
         * Creates a new MapSearchResponse instance using the specified properties.
         * @function create
         * @memberof maps.MapSearchResponse
         * @static
         * @param {maps.IMapSearchResponse=} [properties] Properties to set
         * @returns {maps.MapSearchResponse} MapSearchResponse instance
         */
        MapSearchResponse.create = function create(properties) {
            return new MapSearchResponse(properties);
        };

        /**
         * Encodes the specified MapSearchResponse message. Does not implicitly {@link maps.MapSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.MapSearchResponse
         * @static
         * @param {maps.IMapSearchResponse} message MapSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maps != null && message.maps.length)
                for (let i = 0; i < message.maps.length; ++i)
                    $root.maps.Map.encode(message.maps[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapSearchResponse message, length delimited. Does not implicitly {@link maps.MapSearchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapSearchResponse
         * @static
         * @param {maps.IMapSearchResponse} message MapSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapSearchResponse} MapSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapSearchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.maps && message.maps.length))
                            message.maps = [];
                        message.maps.push($root.maps.Map.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapSearchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapSearchResponse} MapSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapSearchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapSearchResponse message.
         * @function verify
         * @memberof maps.MapSearchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapSearchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maps != null && message.hasOwnProperty("maps")) {
                if (!Array.isArray(message.maps))
                    return "maps: array expected";
                for (let i = 0; i < message.maps.length; ++i) {
                    let error = $root.maps.Map.verify(message.maps[i]);
                    if (error)
                        return "maps." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MapSearchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapSearchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapSearchResponse} MapSearchResponse
         */
        MapSearchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapSearchResponse)
                return object;
            let message = new $root.maps.MapSearchResponse();
            if (object.maps) {
                if (!Array.isArray(object.maps))
                    throw TypeError(".maps.MapSearchResponse.maps: array expected");
                message.maps = [];
                for (let i = 0; i < object.maps.length; ++i) {
                    if (typeof object.maps[i] !== "object")
                        throw TypeError(".maps.MapSearchResponse.maps: object expected");
                    message.maps[i] = $root.maps.Map.fromObject(object.maps[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MapSearchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapSearchResponse
         * @static
         * @param {maps.MapSearchResponse} message MapSearchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapSearchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.maps = [];
            if (message.maps && message.maps.length) {
                object.maps = [];
                for (let j = 0; j < message.maps.length; ++j)
                    object.maps[j] = $root.maps.Map.toObject(message.maps[j], options);
            }
            return object;
        };

        /**
         * Converts this MapSearchResponse to JSON.
         * @function toJSON
         * @memberof maps.MapSearchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapSearchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapSearchResponse
         * @function getTypeUrl
         * @memberof maps.MapSearchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapSearchResponse";
        };

        return MapSearchResponse;
    })();

    maps.GetZonesForMapRequest = (function() {

        /**
         * Properties of a GetZonesForMapRequest.
         * @memberof maps
         * @interface IGetZonesForMapRequest
         * @property {string|null} [mapId] GetZonesForMapRequest mapId
         */

        /**
         * Constructs a new GetZonesForMapRequest.
         * @memberof maps
         * @classdesc Represents a GetZonesForMapRequest.
         * @implements IGetZonesForMapRequest
         * @constructor
         * @param {maps.IGetZonesForMapRequest=} [properties] Properties to set
         */
        function GetZonesForMapRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetZonesForMapRequest mapId.
         * @member {string} mapId
         * @memberof maps.GetZonesForMapRequest
         * @instance
         */
        GetZonesForMapRequest.prototype.mapId = "";

        /**
         * Creates a new GetZonesForMapRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {maps.IGetZonesForMapRequest=} [properties] Properties to set
         * @returns {maps.GetZonesForMapRequest} GetZonesForMapRequest instance
         */
        GetZonesForMapRequest.create = function create(properties) {
            return new GetZonesForMapRequest(properties);
        };

        /**
         * Encodes the specified GetZonesForMapRequest message. Does not implicitly {@link maps.GetZonesForMapRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {maps.IGetZonesForMapRequest} message GetZonesForMapRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetZonesForMapRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
            return writer;
        };

        /**
         * Encodes the specified GetZonesForMapRequest message, length delimited. Does not implicitly {@link maps.GetZonesForMapRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {maps.IGetZonesForMapRequest} message GetZonesForMapRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetZonesForMapRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetZonesForMapRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetZonesForMapRequest} GetZonesForMapRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetZonesForMapRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetZonesForMapRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetZonesForMapRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetZonesForMapRequest} GetZonesForMapRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetZonesForMapRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetZonesForMapRequest message.
         * @function verify
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetZonesForMapRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                if (!$util.isString(message.mapId))
                    return "mapId: string expected";
            return null;
        };

        /**
         * Creates a GetZonesForMapRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetZonesForMapRequest} GetZonesForMapRequest
         */
        GetZonesForMapRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetZonesForMapRequest)
                return object;
            let message = new $root.maps.GetZonesForMapRequest();
            if (object.mapId != null)
                message.mapId = String(object.mapId);
            return message;
        };

        /**
         * Creates a plain object from a GetZonesForMapRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {maps.GetZonesForMapRequest} message GetZonesForMapRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetZonesForMapRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mapId = "";
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                object.mapId = message.mapId;
            return object;
        };

        /**
         * Converts this GetZonesForMapRequest to JSON.
         * @function toJSON
         * @memberof maps.GetZonesForMapRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetZonesForMapRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetZonesForMapRequest
         * @function getTypeUrl
         * @memberof maps.GetZonesForMapRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetZonesForMapRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetZonesForMapRequest";
        };

        return GetZonesForMapRequest;
    })();

    maps.GetZonesForMapResponse = (function() {

        /**
         * Properties of a GetZonesForMapResponse.
         * @memberof maps
         * @interface IGetZonesForMapResponse
         * @property {Array.<maps.IMapZone>|null} [mapZones] GetZonesForMapResponse mapZones
         */

        /**
         * Constructs a new GetZonesForMapResponse.
         * @memberof maps
         * @classdesc Represents a GetZonesForMapResponse.
         * @implements IGetZonesForMapResponse
         * @constructor
         * @param {maps.IGetZonesForMapResponse=} [properties] Properties to set
         */
        function GetZonesForMapResponse(properties) {
            this.mapZones = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetZonesForMapResponse mapZones.
         * @member {Array.<maps.IMapZone>} mapZones
         * @memberof maps.GetZonesForMapResponse
         * @instance
         */
        GetZonesForMapResponse.prototype.mapZones = $util.emptyArray;

        /**
         * Creates a new GetZonesForMapResponse instance using the specified properties.
         * @function create
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {maps.IGetZonesForMapResponse=} [properties] Properties to set
         * @returns {maps.GetZonesForMapResponse} GetZonesForMapResponse instance
         */
        GetZonesForMapResponse.create = function create(properties) {
            return new GetZonesForMapResponse(properties);
        };

        /**
         * Encodes the specified GetZonesForMapResponse message. Does not implicitly {@link maps.GetZonesForMapResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {maps.IGetZonesForMapResponse} message GetZonesForMapResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetZonesForMapResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZones != null && message.mapZones.length)
                for (let i = 0; i < message.mapZones.length; ++i)
                    $root.maps.MapZone.encode(message.mapZones[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetZonesForMapResponse message, length delimited. Does not implicitly {@link maps.GetZonesForMapResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {maps.IGetZonesForMapResponse} message GetZonesForMapResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetZonesForMapResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetZonesForMapResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetZonesForMapResponse} GetZonesForMapResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetZonesForMapResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetZonesForMapResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mapZones && message.mapZones.length))
                            message.mapZones = [];
                        message.mapZones.push($root.maps.MapZone.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetZonesForMapResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetZonesForMapResponse} GetZonesForMapResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetZonesForMapResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetZonesForMapResponse message.
         * @function verify
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetZonesForMapResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZones != null && message.hasOwnProperty("mapZones")) {
                if (!Array.isArray(message.mapZones))
                    return "mapZones: array expected";
                for (let i = 0; i < message.mapZones.length; ++i) {
                    let error = $root.maps.MapZone.verify(message.mapZones[i]);
                    if (error)
                        return "mapZones." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetZonesForMapResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetZonesForMapResponse} GetZonesForMapResponse
         */
        GetZonesForMapResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetZonesForMapResponse)
                return object;
            let message = new $root.maps.GetZonesForMapResponse();
            if (object.mapZones) {
                if (!Array.isArray(object.mapZones))
                    throw TypeError(".maps.GetZonesForMapResponse.mapZones: array expected");
                message.mapZones = [];
                for (let i = 0; i < object.mapZones.length; ++i) {
                    if (typeof object.mapZones[i] !== "object")
                        throw TypeError(".maps.GetZonesForMapResponse.mapZones: object expected");
                    message.mapZones[i] = $root.maps.MapZone.fromObject(object.mapZones[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetZonesForMapResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {maps.GetZonesForMapResponse} message GetZonesForMapResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetZonesForMapResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.mapZones = [];
            if (message.mapZones && message.mapZones.length) {
                object.mapZones = [];
                for (let j = 0; j < message.mapZones.length; ++j)
                    object.mapZones[j] = $root.maps.MapZone.toObject(message.mapZones[j], options);
            }
            return object;
        };

        /**
         * Converts this GetZonesForMapResponse to JSON.
         * @function toJSON
         * @memberof maps.GetZonesForMapResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetZonesForMapResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetZonesForMapResponse
         * @function getTypeUrl
         * @memberof maps.GetZonesForMapResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetZonesForMapResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetZonesForMapResponse";
        };

        return GetZonesForMapResponse;
    })();

    maps.GetMapZoneRequest = (function() {

        /**
         * Properties of a GetMapZoneRequest.
         * @memberof maps
         * @interface IGetMapZoneRequest
         * @property {string|null} [mapZoneId] GetMapZoneRequest mapZoneId
         * @property {boolean|null} [metadataOnly] GetMapZoneRequest metadataOnly
         */

        /**
         * Constructs a new GetMapZoneRequest.
         * @memberof maps
         * @classdesc Represents a GetMapZoneRequest.
         * @implements IGetMapZoneRequest
         * @constructor
         * @param {maps.IGetMapZoneRequest=} [properties] Properties to set
         */
        function GetMapZoneRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMapZoneRequest mapZoneId.
         * @member {string} mapZoneId
         * @memberof maps.GetMapZoneRequest
         * @instance
         */
        GetMapZoneRequest.prototype.mapZoneId = "";

        /**
         * GetMapZoneRequest metadataOnly.
         * @member {boolean} metadataOnly
         * @memberof maps.GetMapZoneRequest
         * @instance
         */
        GetMapZoneRequest.prototype.metadataOnly = false;

        /**
         * Creates a new GetMapZoneRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {maps.IGetMapZoneRequest=} [properties] Properties to set
         * @returns {maps.GetMapZoneRequest} GetMapZoneRequest instance
         */
        GetMapZoneRequest.create = function create(properties) {
            return new GetMapZoneRequest(properties);
        };

        /**
         * Encodes the specified GetMapZoneRequest message. Does not implicitly {@link maps.GetMapZoneRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {maps.IGetMapZoneRequest} message GetMapZoneRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapZoneRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapZoneId);
            if (message.metadataOnly != null && Object.hasOwnProperty.call(message, "metadataOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.metadataOnly);
            return writer;
        };

        /**
         * Encodes the specified GetMapZoneRequest message, length delimited. Does not implicitly {@link maps.GetMapZoneRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {maps.IGetMapZoneRequest} message GetMapZoneRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapZoneRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMapZoneRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMapZoneRequest} GetMapZoneRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapZoneRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMapZoneRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapZoneId = reader.string();
                        break;
                    }
                case 2: {
                        message.metadataOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMapZoneRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMapZoneRequest} GetMapZoneRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapZoneRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMapZoneRequest message.
         * @function verify
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMapZoneRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                if (!$util.isString(message.mapZoneId))
                    return "mapZoneId: string expected";
            if (message.metadataOnly != null && message.hasOwnProperty("metadataOnly"))
                if (typeof message.metadataOnly !== "boolean")
                    return "metadataOnly: boolean expected";
            return null;
        };

        /**
         * Creates a GetMapZoneRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMapZoneRequest} GetMapZoneRequest
         */
        GetMapZoneRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMapZoneRequest)
                return object;
            let message = new $root.maps.GetMapZoneRequest();
            if (object.mapZoneId != null)
                message.mapZoneId = String(object.mapZoneId);
            if (object.metadataOnly != null)
                message.metadataOnly = Boolean(object.metadataOnly);
            return message;
        };

        /**
         * Creates a plain object from a GetMapZoneRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {maps.GetMapZoneRequest} message GetMapZoneRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMapZoneRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.mapZoneId = "";
                object.metadataOnly = false;
            }
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = message.mapZoneId;
            if (message.metadataOnly != null && message.hasOwnProperty("metadataOnly"))
                object.metadataOnly = message.metadataOnly;
            return object;
        };

        /**
         * Converts this GetMapZoneRequest to JSON.
         * @function toJSON
         * @memberof maps.GetMapZoneRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMapZoneRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMapZoneRequest
         * @function getTypeUrl
         * @memberof maps.GetMapZoneRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMapZoneRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMapZoneRequest";
        };

        return GetMapZoneRequest;
    })();

    maps.GetMapZoneResponse = (function() {

        /**
         * Properties of a GetMapZoneResponse.
         * @memberof maps
         * @interface IGetMapZoneResponse
         * @property {maps.IMapZone|null} [mapZone] GetMapZoneResponse mapZone
         */

        /**
         * Constructs a new GetMapZoneResponse.
         * @memberof maps
         * @classdesc Represents a GetMapZoneResponse.
         * @implements IGetMapZoneResponse
         * @constructor
         * @param {maps.IGetMapZoneResponse=} [properties] Properties to set
         */
        function GetMapZoneResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMapZoneResponse mapZone.
         * @member {maps.IMapZone|null|undefined} mapZone
         * @memberof maps.GetMapZoneResponse
         * @instance
         */
        GetMapZoneResponse.prototype.mapZone = null;

        /**
         * Creates a new GetMapZoneResponse instance using the specified properties.
         * @function create
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {maps.IGetMapZoneResponse=} [properties] Properties to set
         * @returns {maps.GetMapZoneResponse} GetMapZoneResponse instance
         */
        GetMapZoneResponse.create = function create(properties) {
            return new GetMapZoneResponse(properties);
        };

        /**
         * Encodes the specified GetMapZoneResponse message. Does not implicitly {@link maps.GetMapZoneResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {maps.IGetMapZoneResponse} message GetMapZoneResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapZoneResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZone != null && Object.hasOwnProperty.call(message, "mapZone"))
                $root.maps.MapZone.encode(message.mapZone, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetMapZoneResponse message, length delimited. Does not implicitly {@link maps.GetMapZoneResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {maps.IGetMapZoneResponse} message GetMapZoneResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMapZoneResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMapZoneResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMapZoneResponse} GetMapZoneResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapZoneResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMapZoneResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapZone = $root.maps.MapZone.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMapZoneResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMapZoneResponse} GetMapZoneResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMapZoneResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMapZoneResponse message.
         * @function verify
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMapZoneResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZone != null && message.hasOwnProperty("mapZone")) {
                let error = $root.maps.MapZone.verify(message.mapZone);
                if (error)
                    return "mapZone." + error;
            }
            return null;
        };

        /**
         * Creates a GetMapZoneResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMapZoneResponse} GetMapZoneResponse
         */
        GetMapZoneResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMapZoneResponse)
                return object;
            let message = new $root.maps.GetMapZoneResponse();
            if (object.mapZone != null) {
                if (typeof object.mapZone !== "object")
                    throw TypeError(".maps.GetMapZoneResponse.mapZone: object expected");
                message.mapZone = $root.maps.MapZone.fromObject(object.mapZone);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMapZoneResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {maps.GetMapZoneResponse} message GetMapZoneResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMapZoneResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mapZone = null;
            if (message.mapZone != null && message.hasOwnProperty("mapZone"))
                object.mapZone = $root.maps.MapZone.toObject(message.mapZone, options);
            return object;
        };

        /**
         * Converts this GetMapZoneResponse to JSON.
         * @function toJSON
         * @memberof maps.GetMapZoneResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMapZoneResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMapZoneResponse
         * @function getTypeUrl
         * @memberof maps.GetMapZoneResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMapZoneResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMapZoneResponse";
        };

        return GetMapZoneResponse;
    })();

    maps.ImageSearchRequest = (function() {

        /**
         * Properties of an ImageSearchRequest.
         * @memberof maps
         * @interface IImageSearchRequest
         * @property {string|null} [userId] ImageSearchRequest userId
         * @property {string|null} [campaignId] ImageSearchRequest campaignId
         * @property {string|null} [mapId] ImageSearchRequest mapId
         * @property {string|null} [searchTerm] ImageSearchRequest searchTerm
         * @property {string|null} [imageId] ImageSearchRequest imageId
         * @property {maps.ImageSearchRequest.IBulkImageIds|null} [bulk] ImageSearchRequest bulk
         * @property {Array.<string>|null} [categories] ImageSearchRequest categories
         * @property {number|null} [offset] ImageSearchRequest offset
         * @property {number|null} [limit] ImageSearchRequest limit
         * @property {string|null} [sorting] ImageSearchRequest sorting
         */

        /**
         * Constructs a new ImageSearchRequest.
         * @memberof maps
         * @classdesc Represents an ImageSearchRequest.
         * @implements IImageSearchRequest
         * @constructor
         * @param {maps.IImageSearchRequest=} [properties] Properties to set
         */
        function ImageSearchRequest(properties) {
            this.categories = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageSearchRequest userId.
         * @member {string|null|undefined} userId
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.userId = null;

        /**
         * ImageSearchRequest campaignId.
         * @member {string|null|undefined} campaignId
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.campaignId = null;

        /**
         * ImageSearchRequest mapId.
         * @member {string|null|undefined} mapId
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.mapId = null;

        /**
         * ImageSearchRequest searchTerm.
         * @member {string|null|undefined} searchTerm
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.searchTerm = null;

        /**
         * ImageSearchRequest imageId.
         * @member {string|null|undefined} imageId
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.imageId = null;

        /**
         * ImageSearchRequest bulk.
         * @member {maps.ImageSearchRequest.IBulkImageIds|null|undefined} bulk
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.bulk = null;

        /**
         * ImageSearchRequest categories.
         * @member {Array.<string>} categories
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.categories = $util.emptyArray;

        /**
         * ImageSearchRequest offset.
         * @member {number} offset
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.offset = 0;

        /**
         * ImageSearchRequest limit.
         * @member {number} limit
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.limit = 0;

        /**
         * ImageSearchRequest sorting.
         * @member {string} sorting
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        ImageSearchRequest.prototype.sorting = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ImageSearchRequest parentId.
         * @member {"userId"|"campaignId"|"mapId"|undefined} parentId
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        Object.defineProperty(ImageSearchRequest.prototype, "parentId", {
            get: $util.oneOfGetter($oneOfFields = ["userId", "campaignId", "mapId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ImageSearchRequest query.
         * @member {"searchTerm"|"imageId"|"bulk"|undefined} query
         * @memberof maps.ImageSearchRequest
         * @instance
         */
        Object.defineProperty(ImageSearchRequest.prototype, "query", {
            get: $util.oneOfGetter($oneOfFields = ["searchTerm", "imageId", "bulk"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ImageSearchRequest instance using the specified properties.
         * @function create
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {maps.IImageSearchRequest=} [properties] Properties to set
         * @returns {maps.ImageSearchRequest} ImageSearchRequest instance
         */
        ImageSearchRequest.create = function create(properties) {
            return new ImageSearchRequest(properties);
        };

        /**
         * Encodes the specified ImageSearchRequest message. Does not implicitly {@link maps.ImageSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {maps.IImageSearchRequest} message ImageSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.campaignId);
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.mapId);
            if (message.searchTerm != null && Object.hasOwnProperty.call(message, "searchTerm"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.searchTerm);
            if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.imageId);
            if (message.bulk != null && Object.hasOwnProperty.call(message, "bulk"))
                $root.maps.ImageSearchRequest.BulkImageIds.encode(message.bulk, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.categories != null && message.categories.length)
                for (let i = 0; i < message.categories.length; ++i)
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.categories[i]);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 22, wireType 0 =*/176).int32(message.offset);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 23, wireType 0 =*/184).int32(message.limit);
            if (message.sorting != null && Object.hasOwnProperty.call(message, "sorting"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.sorting);
            return writer;
        };

        /**
         * Encodes the specified ImageSearchRequest message, length delimited. Does not implicitly {@link maps.ImageSearchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {maps.IImageSearchRequest} message ImageSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ImageSearchRequest} ImageSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ImageSearchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userId = reader.string();
                        break;
                    }
                case 2: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 3: {
                        message.mapId = reader.string();
                        break;
                    }
                case 11: {
                        message.searchTerm = reader.string();
                        break;
                    }
                case 12: {
                        message.imageId = reader.string();
                        break;
                    }
                case 13: {
                        message.bulk = $root.maps.ImageSearchRequest.BulkImageIds.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        if (!(message.categories && message.categories.length))
                            message.categories = [];
                        message.categories.push(reader.string());
                        break;
                    }
                case 22: {
                        message.offset = reader.int32();
                        break;
                    }
                case 23: {
                        message.limit = reader.int32();
                        break;
                    }
                case 24: {
                        message.sorting = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageSearchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ImageSearchRequest} ImageSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageSearchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageSearchRequest message.
         * @function verify
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageSearchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userId != null && message.hasOwnProperty("userId")) {
                properties.parentId = 1;
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId")) {
                if (properties.parentId === 1)
                    return "parentId: multiple values";
                properties.parentId = 1;
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            }
            if (message.mapId != null && message.hasOwnProperty("mapId")) {
                if (properties.parentId === 1)
                    return "parentId: multiple values";
                properties.parentId = 1;
                if (!$util.isString(message.mapId))
                    return "mapId: string expected";
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm")) {
                properties.query = 1;
                if (!$util.isString(message.searchTerm))
                    return "searchTerm: string expected";
            }
            if (message.imageId != null && message.hasOwnProperty("imageId")) {
                if (properties.query === 1)
                    return "query: multiple values";
                properties.query = 1;
                if (!$util.isString(message.imageId))
                    return "imageId: string expected";
            }
            if (message.bulk != null && message.hasOwnProperty("bulk")) {
                if (properties.query === 1)
                    return "query: multiple values";
                properties.query = 1;
                {
                    let error = $root.maps.ImageSearchRequest.BulkImageIds.verify(message.bulk);
                    if (error)
                        return "bulk." + error;
                }
            }
            if (message.categories != null && message.hasOwnProperty("categories")) {
                if (!Array.isArray(message.categories))
                    return "categories: array expected";
                for (let i = 0; i < message.categories.length; ++i)
                    if (!$util.isString(message.categories[i]))
                        return "categories: string[] expected";
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.sorting != null && message.hasOwnProperty("sorting"))
                if (!$util.isString(message.sorting))
                    return "sorting: string expected";
            return null;
        };

        /**
         * Creates an ImageSearchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ImageSearchRequest} ImageSearchRequest
         */
        ImageSearchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ImageSearchRequest)
                return object;
            let message = new $root.maps.ImageSearchRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.mapId != null)
                message.mapId = String(object.mapId);
            if (object.searchTerm != null)
                message.searchTerm = String(object.searchTerm);
            if (object.imageId != null)
                message.imageId = String(object.imageId);
            if (object.bulk != null) {
                if (typeof object.bulk !== "object")
                    throw TypeError(".maps.ImageSearchRequest.bulk: object expected");
                message.bulk = $root.maps.ImageSearchRequest.BulkImageIds.fromObject(object.bulk);
            }
            if (object.categories) {
                if (!Array.isArray(object.categories))
                    throw TypeError(".maps.ImageSearchRequest.categories: array expected");
                message.categories = [];
                for (let i = 0; i < object.categories.length; ++i)
                    message.categories[i] = String(object.categories[i]);
            }
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.sorting != null)
                message.sorting = String(object.sorting);
            return message;
        };

        /**
         * Creates a plain object from an ImageSearchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {maps.ImageSearchRequest} message ImageSearchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageSearchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.categories = [];
            if (options.defaults) {
                object.offset = 0;
                object.limit = 0;
                object.sorting = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId")) {
                object.userId = message.userId;
                if (options.oneofs)
                    object.parentId = "userId";
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId")) {
                object.campaignId = message.campaignId;
                if (options.oneofs)
                    object.parentId = "campaignId";
            }
            if (message.mapId != null && message.hasOwnProperty("mapId")) {
                object.mapId = message.mapId;
                if (options.oneofs)
                    object.parentId = "mapId";
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm")) {
                object.searchTerm = message.searchTerm;
                if (options.oneofs)
                    object.query = "searchTerm";
            }
            if (message.imageId != null && message.hasOwnProperty("imageId")) {
                object.imageId = message.imageId;
                if (options.oneofs)
                    object.query = "imageId";
            }
            if (message.bulk != null && message.hasOwnProperty("bulk")) {
                object.bulk = $root.maps.ImageSearchRequest.BulkImageIds.toObject(message.bulk, options);
                if (options.oneofs)
                    object.query = "bulk";
            }
            if (message.categories && message.categories.length) {
                object.categories = [];
                for (let j = 0; j < message.categories.length; ++j)
                    object.categories[j] = message.categories[j];
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.sorting != null && message.hasOwnProperty("sorting"))
                object.sorting = message.sorting;
            return object;
        };

        /**
         * Converts this ImageSearchRequest to JSON.
         * @function toJSON
         * @memberof maps.ImageSearchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageSearchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageSearchRequest
         * @function getTypeUrl
         * @memberof maps.ImageSearchRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ImageSearchRequest";
        };

        ImageSearchRequest.BulkImageIds = (function() {

            /**
             * Properties of a BulkImageIds.
             * @memberof maps.ImageSearchRequest
             * @interface IBulkImageIds
             * @property {Array.<string>|null} [imageIds] BulkImageIds imageIds
             */

            /**
             * Constructs a new BulkImageIds.
             * @memberof maps.ImageSearchRequest
             * @classdesc Represents a BulkImageIds.
             * @implements IBulkImageIds
             * @constructor
             * @param {maps.ImageSearchRequest.IBulkImageIds=} [properties] Properties to set
             */
            function BulkImageIds(properties) {
                this.imageIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BulkImageIds imageIds.
             * @member {Array.<string>} imageIds
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @instance
             */
            BulkImageIds.prototype.imageIds = $util.emptyArray;

            /**
             * Creates a new BulkImageIds instance using the specified properties.
             * @function create
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {maps.ImageSearchRequest.IBulkImageIds=} [properties] Properties to set
             * @returns {maps.ImageSearchRequest.BulkImageIds} BulkImageIds instance
             */
            BulkImageIds.create = function create(properties) {
                return new BulkImageIds(properties);
            };

            /**
             * Encodes the specified BulkImageIds message. Does not implicitly {@link maps.ImageSearchRequest.BulkImageIds.verify|verify} messages.
             * @function encode
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {maps.ImageSearchRequest.IBulkImageIds} message BulkImageIds message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkImageIds.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.imageIds != null && message.imageIds.length)
                    for (let i = 0; i < message.imageIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageIds[i]);
                return writer;
            };

            /**
             * Encodes the specified BulkImageIds message, length delimited. Does not implicitly {@link maps.ImageSearchRequest.BulkImageIds.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {maps.ImageSearchRequest.IBulkImageIds} message BulkImageIds message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BulkImageIds.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BulkImageIds message from the specified reader or buffer.
             * @function decode
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.ImageSearchRequest.BulkImageIds} BulkImageIds
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkImageIds.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ImageSearchRequest.BulkImageIds();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.imageIds && message.imageIds.length))
                                message.imageIds = [];
                            message.imageIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BulkImageIds message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.ImageSearchRequest.BulkImageIds} BulkImageIds
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BulkImageIds.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BulkImageIds message.
             * @function verify
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BulkImageIds.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.imageIds != null && message.hasOwnProperty("imageIds")) {
                    if (!Array.isArray(message.imageIds))
                        return "imageIds: array expected";
                    for (let i = 0; i < message.imageIds.length; ++i)
                        if (!$util.isString(message.imageIds[i]))
                            return "imageIds: string[] expected";
                }
                return null;
            };

            /**
             * Creates a BulkImageIds message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.ImageSearchRequest.BulkImageIds} BulkImageIds
             */
            BulkImageIds.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.ImageSearchRequest.BulkImageIds)
                    return object;
                let message = new $root.maps.ImageSearchRequest.BulkImageIds();
                if (object.imageIds) {
                    if (!Array.isArray(object.imageIds))
                        throw TypeError(".maps.ImageSearchRequest.BulkImageIds.imageIds: array expected");
                    message.imageIds = [];
                    for (let i = 0; i < object.imageIds.length; ++i)
                        message.imageIds[i] = String(object.imageIds[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a BulkImageIds message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {maps.ImageSearchRequest.BulkImageIds} message BulkImageIds
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BulkImageIds.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.imageIds = [];
                if (message.imageIds && message.imageIds.length) {
                    object.imageIds = [];
                    for (let j = 0; j < message.imageIds.length; ++j)
                        object.imageIds[j] = message.imageIds[j];
                }
                return object;
            };

            /**
             * Converts this BulkImageIds to JSON.
             * @function toJSON
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BulkImageIds.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BulkImageIds
             * @function getTypeUrl
             * @memberof maps.ImageSearchRequest.BulkImageIds
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BulkImageIds.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.ImageSearchRequest.BulkImageIds";
            };

            return BulkImageIds;
        })();

        return ImageSearchRequest;
    })();

    maps.ImageSearchResponse = (function() {

        /**
         * Properties of an ImageSearchResponse.
         * @memberof maps
         * @interface IImageSearchResponse
         * @property {Array.<maps.IMapImage>|null} [images] ImageSearchResponse images
         */

        /**
         * Constructs a new ImageSearchResponse.
         * @memberof maps
         * @classdesc Represents an ImageSearchResponse.
         * @implements IImageSearchResponse
         * @constructor
         * @param {maps.IImageSearchResponse=} [properties] Properties to set
         */
        function ImageSearchResponse(properties) {
            this.images = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImageSearchResponse images.
         * @member {Array.<maps.IMapImage>} images
         * @memberof maps.ImageSearchResponse
         * @instance
         */
        ImageSearchResponse.prototype.images = $util.emptyArray;

        /**
         * Creates a new ImageSearchResponse instance using the specified properties.
         * @function create
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {maps.IImageSearchResponse=} [properties] Properties to set
         * @returns {maps.ImageSearchResponse} ImageSearchResponse instance
         */
        ImageSearchResponse.create = function create(properties) {
            return new ImageSearchResponse(properties);
        };

        /**
         * Encodes the specified ImageSearchResponse message. Does not implicitly {@link maps.ImageSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {maps.IImageSearchResponse} message ImageSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.images != null && message.images.length)
                for (let i = 0; i < message.images.length; ++i)
                    $root.maps.MapImage.encode(message.images[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ImageSearchResponse message, length delimited. Does not implicitly {@link maps.ImageSearchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {maps.IImageSearchResponse} message ImageSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImageSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImageSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ImageSearchResponse} ImageSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ImageSearchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.images && message.images.length))
                            message.images = [];
                        message.images.push($root.maps.MapImage.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImageSearchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ImageSearchResponse} ImageSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImageSearchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImageSearchResponse message.
         * @function verify
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImageSearchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.images != null && message.hasOwnProperty("images")) {
                if (!Array.isArray(message.images))
                    return "images: array expected";
                for (let i = 0; i < message.images.length; ++i) {
                    let error = $root.maps.MapImage.verify(message.images[i]);
                    if (error)
                        return "images." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ImageSearchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ImageSearchResponse} ImageSearchResponse
         */
        ImageSearchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ImageSearchResponse)
                return object;
            let message = new $root.maps.ImageSearchResponse();
            if (object.images) {
                if (!Array.isArray(object.images))
                    throw TypeError(".maps.ImageSearchResponse.images: array expected");
                message.images = [];
                for (let i = 0; i < object.images.length; ++i) {
                    if (typeof object.images[i] !== "object")
                        throw TypeError(".maps.ImageSearchResponse.images: object expected");
                    message.images[i] = $root.maps.MapImage.fromObject(object.images[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ImageSearchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {maps.ImageSearchResponse} message ImageSearchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImageSearchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.images = [];
            if (message.images && message.images.length) {
                object.images = [];
                for (let j = 0; j < message.images.length; ++j)
                    object.images[j] = $root.maps.MapImage.toObject(message.images[j], options);
            }
            return object;
        };

        /**
         * Converts this ImageSearchResponse to JSON.
         * @function toJSON
         * @memberof maps.ImageSearchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImageSearchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ImageSearchResponse
         * @function getTypeUrl
         * @memberof maps.ImageSearchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImageSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ImageSearchResponse";
        };

        return ImageSearchResponse;
    })();

    maps.SetBoundariesRequest = (function() {

        /**
         * Properties of a SetBoundariesRequest.
         * @memberof maps
         * @interface ISetBoundariesRequest
         * @property {string|null} [mapZoneId] SetBoundariesRequest mapZoneId
         * @property {maps.IBoundaryDetails|null} [boundaries] SetBoundariesRequest boundaries
         */

        /**
         * Constructs a new SetBoundariesRequest.
         * @memberof maps
         * @classdesc Represents a SetBoundariesRequest.
         * @implements ISetBoundariesRequest
         * @constructor
         * @param {maps.ISetBoundariesRequest=} [properties] Properties to set
         */
        function SetBoundariesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetBoundariesRequest mapZoneId.
         * @member {string} mapZoneId
         * @memberof maps.SetBoundariesRequest
         * @instance
         */
        SetBoundariesRequest.prototype.mapZoneId = "";

        /**
         * SetBoundariesRequest boundaries.
         * @member {maps.IBoundaryDetails|null|undefined} boundaries
         * @memberof maps.SetBoundariesRequest
         * @instance
         */
        SetBoundariesRequest.prototype.boundaries = null;

        /**
         * Creates a new SetBoundariesRequest instance using the specified properties.
         * @function create
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {maps.ISetBoundariesRequest=} [properties] Properties to set
         * @returns {maps.SetBoundariesRequest} SetBoundariesRequest instance
         */
        SetBoundariesRequest.create = function create(properties) {
            return new SetBoundariesRequest(properties);
        };

        /**
         * Encodes the specified SetBoundariesRequest message. Does not implicitly {@link maps.SetBoundariesRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {maps.ISetBoundariesRequest} message SetBoundariesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetBoundariesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapZoneId);
            if (message.boundaries != null && Object.hasOwnProperty.call(message, "boundaries"))
                $root.maps.BoundaryDetails.encode(message.boundaries, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetBoundariesRequest message, length delimited. Does not implicitly {@link maps.SetBoundariesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {maps.ISetBoundariesRequest} message SetBoundariesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetBoundariesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetBoundariesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.SetBoundariesRequest} SetBoundariesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetBoundariesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.SetBoundariesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapZoneId = reader.string();
                        break;
                    }
                case 2: {
                        message.boundaries = $root.maps.BoundaryDetails.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetBoundariesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.SetBoundariesRequest} SetBoundariesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetBoundariesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetBoundariesRequest message.
         * @function verify
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetBoundariesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                if (!$util.isString(message.mapZoneId))
                    return "mapZoneId: string expected";
            if (message.boundaries != null && message.hasOwnProperty("boundaries")) {
                let error = $root.maps.BoundaryDetails.verify(message.boundaries);
                if (error)
                    return "boundaries." + error;
            }
            return null;
        };

        /**
         * Creates a SetBoundariesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.SetBoundariesRequest} SetBoundariesRequest
         */
        SetBoundariesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.SetBoundariesRequest)
                return object;
            let message = new $root.maps.SetBoundariesRequest();
            if (object.mapZoneId != null)
                message.mapZoneId = String(object.mapZoneId);
            if (object.boundaries != null) {
                if (typeof object.boundaries !== "object")
                    throw TypeError(".maps.SetBoundariesRequest.boundaries: object expected");
                message.boundaries = $root.maps.BoundaryDetails.fromObject(object.boundaries);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetBoundariesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {maps.SetBoundariesRequest} message SetBoundariesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetBoundariesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.mapZoneId = "";
                object.boundaries = null;
            }
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = message.mapZoneId;
            if (message.boundaries != null && message.hasOwnProperty("boundaries"))
                object.boundaries = $root.maps.BoundaryDetails.toObject(message.boundaries, options);
            return object;
        };

        /**
         * Converts this SetBoundariesRequest to JSON.
         * @function toJSON
         * @memberof maps.SetBoundariesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetBoundariesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetBoundariesRequest
         * @function getTypeUrl
         * @memberof maps.SetBoundariesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetBoundariesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.SetBoundariesRequest";
        };

        return SetBoundariesRequest;
    })();

    maps.SetVisibilityRequest = (function() {

        /**
         * Properties of a SetVisibilityRequest.
         * @memberof maps
         * @interface ISetVisibilityRequest
         * @property {string|null} [mapZoneId] SetVisibilityRequest mapZoneId
         * @property {maps.IVisibilityMasks|null} [visibility] SetVisibilityRequest visibility
         */

        /**
         * Constructs a new SetVisibilityRequest.
         * @memberof maps
         * @classdesc Represents a SetVisibilityRequest.
         * @implements ISetVisibilityRequest
         * @constructor
         * @param {maps.ISetVisibilityRequest=} [properties] Properties to set
         */
        function SetVisibilityRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetVisibilityRequest mapZoneId.
         * @member {string} mapZoneId
         * @memberof maps.SetVisibilityRequest
         * @instance
         */
        SetVisibilityRequest.prototype.mapZoneId = "";

        /**
         * SetVisibilityRequest visibility.
         * @member {maps.IVisibilityMasks|null|undefined} visibility
         * @memberof maps.SetVisibilityRequest
         * @instance
         */
        SetVisibilityRequest.prototype.visibility = null;

        /**
         * Creates a new SetVisibilityRequest instance using the specified properties.
         * @function create
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {maps.ISetVisibilityRequest=} [properties] Properties to set
         * @returns {maps.SetVisibilityRequest} SetVisibilityRequest instance
         */
        SetVisibilityRequest.create = function create(properties) {
            return new SetVisibilityRequest(properties);
        };

        /**
         * Encodes the specified SetVisibilityRequest message. Does not implicitly {@link maps.SetVisibilityRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {maps.ISetVisibilityRequest} message SetVisibilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetVisibilityRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapZoneId);
            if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                $root.maps.VisibilityMasks.encode(message.visibility, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetVisibilityRequest message, length delimited. Does not implicitly {@link maps.SetVisibilityRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {maps.ISetVisibilityRequest} message SetVisibilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetVisibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetVisibilityRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.SetVisibilityRequest} SetVisibilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetVisibilityRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.SetVisibilityRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapZoneId = reader.string();
                        break;
                    }
                case 2: {
                        message.visibility = $root.maps.VisibilityMasks.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetVisibilityRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.SetVisibilityRequest} SetVisibilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetVisibilityRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetVisibilityRequest message.
         * @function verify
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetVisibilityRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                if (!$util.isString(message.mapZoneId))
                    return "mapZoneId: string expected";
            if (message.visibility != null && message.hasOwnProperty("visibility")) {
                let error = $root.maps.VisibilityMasks.verify(message.visibility);
                if (error)
                    return "visibility." + error;
            }
            return null;
        };

        /**
         * Creates a SetVisibilityRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.SetVisibilityRequest} SetVisibilityRequest
         */
        SetVisibilityRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.SetVisibilityRequest)
                return object;
            let message = new $root.maps.SetVisibilityRequest();
            if (object.mapZoneId != null)
                message.mapZoneId = String(object.mapZoneId);
            if (object.visibility != null) {
                if (typeof object.visibility !== "object")
                    throw TypeError(".maps.SetVisibilityRequest.visibility: object expected");
                message.visibility = $root.maps.VisibilityMasks.fromObject(object.visibility);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetVisibilityRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {maps.SetVisibilityRequest} message SetVisibilityRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetVisibilityRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.mapZoneId = "";
                object.visibility = null;
            }
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = message.mapZoneId;
            if (message.visibility != null && message.hasOwnProperty("visibility"))
                object.visibility = $root.maps.VisibilityMasks.toObject(message.visibility, options);
            return object;
        };

        /**
         * Converts this SetVisibilityRequest to JSON.
         * @function toJSON
         * @memberof maps.SetVisibilityRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetVisibilityRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetVisibilityRequest
         * @function getTypeUrl
         * @memberof maps.SetVisibilityRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetVisibilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.SetVisibilityRequest";
        };

        return SetVisibilityRequest;
    })();

    maps.PatchVisibilityRequest = (function() {

        /**
         * Properties of a PatchVisibilityRequest.
         * @memberof maps
         * @interface IPatchVisibilityRequest
         * @property {string|null} [mapZoneId] PatchVisibilityRequest mapZoneId
         * @property {geometry.IMaskPatch|null} [patchData] PatchVisibilityRequest patchData
         * @property {maps.PatchVisibilityRequest.PatchTarget|null} [target] PatchVisibilityRequest target
         */

        /**
         * Constructs a new PatchVisibilityRequest.
         * @memberof maps
         * @classdesc Represents a PatchVisibilityRequest.
         * @implements IPatchVisibilityRequest
         * @constructor
         * @param {maps.IPatchVisibilityRequest=} [properties] Properties to set
         */
        function PatchVisibilityRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PatchVisibilityRequest mapZoneId.
         * @member {string} mapZoneId
         * @memberof maps.PatchVisibilityRequest
         * @instance
         */
        PatchVisibilityRequest.prototype.mapZoneId = "";

        /**
         * PatchVisibilityRequest patchData.
         * @member {geometry.IMaskPatch|null|undefined} patchData
         * @memberof maps.PatchVisibilityRequest
         * @instance
         */
        PatchVisibilityRequest.prototype.patchData = null;

        /**
         * PatchVisibilityRequest target.
         * @member {maps.PatchVisibilityRequest.PatchTarget} target
         * @memberof maps.PatchVisibilityRequest
         * @instance
         */
        PatchVisibilityRequest.prototype.target = 0;

        /**
         * Creates a new PatchVisibilityRequest instance using the specified properties.
         * @function create
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {maps.IPatchVisibilityRequest=} [properties] Properties to set
         * @returns {maps.PatchVisibilityRequest} PatchVisibilityRequest instance
         */
        PatchVisibilityRequest.create = function create(properties) {
            return new PatchVisibilityRequest(properties);
        };

        /**
         * Encodes the specified PatchVisibilityRequest message. Does not implicitly {@link maps.PatchVisibilityRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {maps.IPatchVisibilityRequest} message PatchVisibilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchVisibilityRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapZoneId);
            if (message.patchData != null && Object.hasOwnProperty.call(message, "patchData"))
                $root.geometry.MaskPatch.encode(message.patchData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.target);
            return writer;
        };

        /**
         * Encodes the specified PatchVisibilityRequest message, length delimited. Does not implicitly {@link maps.PatchVisibilityRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {maps.IPatchVisibilityRequest} message PatchVisibilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchVisibilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PatchVisibilityRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.PatchVisibilityRequest} PatchVisibilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchVisibilityRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.PatchVisibilityRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mapZoneId = reader.string();
                        break;
                    }
                case 2: {
                        message.patchData = $root.geometry.MaskPatch.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.target = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PatchVisibilityRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.PatchVisibilityRequest} PatchVisibilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchVisibilityRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PatchVisibilityRequest message.
         * @function verify
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PatchVisibilityRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                if (!$util.isString(message.mapZoneId))
                    return "mapZoneId: string expected";
            if (message.patchData != null && message.hasOwnProperty("patchData")) {
                let error = $root.geometry.MaskPatch.verify(message.patchData);
                if (error)
                    return "patchData." + error;
            }
            if (message.target != null && message.hasOwnProperty("target"))
                switch (message.target) {
                default:
                    return "target: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a PatchVisibilityRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.PatchVisibilityRequest} PatchVisibilityRequest
         */
        PatchVisibilityRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.PatchVisibilityRequest)
                return object;
            let message = new $root.maps.PatchVisibilityRequest();
            if (object.mapZoneId != null)
                message.mapZoneId = String(object.mapZoneId);
            if (object.patchData != null) {
                if (typeof object.patchData !== "object")
                    throw TypeError(".maps.PatchVisibilityRequest.patchData: object expected");
                message.patchData = $root.geometry.MaskPatch.fromObject(object.patchData);
            }
            switch (object.target) {
            default:
                if (typeof object.target === "number") {
                    message.target = object.target;
                    break;
                }
                break;
            case "UNKNOWN_PATCH_TARGET":
            case 0:
                message.target = 0;
                break;
            case "EXPLORED_PATCH_TARGET":
            case 1:
                message.target = 1;
                break;
            case "HIDDEN_PATCH_TARGET":
            case 2:
                message.target = 2;
                break;
            case "VISIBLE_PATCH_TARGET":
            case 3:
                message.target = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PatchVisibilityRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {maps.PatchVisibilityRequest} message PatchVisibilityRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatchVisibilityRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.mapZoneId = "";
                object.patchData = null;
                object.target = options.enums === String ? "UNKNOWN_PATCH_TARGET" : 0;
            }
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = message.mapZoneId;
            if (message.patchData != null && message.hasOwnProperty("patchData"))
                object.patchData = $root.geometry.MaskPatch.toObject(message.patchData, options);
            if (message.target != null && message.hasOwnProperty("target"))
                object.target = options.enums === String ? $root.maps.PatchVisibilityRequest.PatchTarget[message.target] === undefined ? message.target : $root.maps.PatchVisibilityRequest.PatchTarget[message.target] : message.target;
            return object;
        };

        /**
         * Converts this PatchVisibilityRequest to JSON.
         * @function toJSON
         * @memberof maps.PatchVisibilityRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatchVisibilityRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PatchVisibilityRequest
         * @function getTypeUrl
         * @memberof maps.PatchVisibilityRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PatchVisibilityRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.PatchVisibilityRequest";
        };

        /**
         * PatchTarget enum.
         * @name maps.PatchVisibilityRequest.PatchTarget
         * @enum {number}
         * @property {number} UNKNOWN_PATCH_TARGET=0 UNKNOWN_PATCH_TARGET value
         * @property {number} EXPLORED_PATCH_TARGET=1 EXPLORED_PATCH_TARGET value
         * @property {number} HIDDEN_PATCH_TARGET=2 HIDDEN_PATCH_TARGET value
         * @property {number} VISIBLE_PATCH_TARGET=3 VISIBLE_PATCH_TARGET value
         */
        PatchVisibilityRequest.PatchTarget = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_PATCH_TARGET"] = 0;
            values[valuesById[1] = "EXPLORED_PATCH_TARGET"] = 1;
            values[valuesById[2] = "HIDDEN_PATCH_TARGET"] = 2;
            values[valuesById[3] = "VISIBLE_PATCH_TARGET"] = 3;
            return values;
        })();

        return PatchVisibilityRequest;
    })();

    maps.PatchVisibilityResponse = (function() {

        /**
         * Properties of a PatchVisibilityResponse.
         * @memberof maps
         * @interface IPatchVisibilityResponse
         */

        /**
         * Constructs a new PatchVisibilityResponse.
         * @memberof maps
         * @classdesc Represents a PatchVisibilityResponse.
         * @implements IPatchVisibilityResponse
         * @constructor
         * @param {maps.IPatchVisibilityResponse=} [properties] Properties to set
         */
        function PatchVisibilityResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PatchVisibilityResponse instance using the specified properties.
         * @function create
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {maps.IPatchVisibilityResponse=} [properties] Properties to set
         * @returns {maps.PatchVisibilityResponse} PatchVisibilityResponse instance
         */
        PatchVisibilityResponse.create = function create(properties) {
            return new PatchVisibilityResponse(properties);
        };

        /**
         * Encodes the specified PatchVisibilityResponse message. Does not implicitly {@link maps.PatchVisibilityResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {maps.IPatchVisibilityResponse} message PatchVisibilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchVisibilityResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PatchVisibilityResponse message, length delimited. Does not implicitly {@link maps.PatchVisibilityResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {maps.IPatchVisibilityResponse} message PatchVisibilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatchVisibilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PatchVisibilityResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.PatchVisibilityResponse} PatchVisibilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchVisibilityResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.PatchVisibilityResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PatchVisibilityResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.PatchVisibilityResponse} PatchVisibilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatchVisibilityResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PatchVisibilityResponse message.
         * @function verify
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PatchVisibilityResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PatchVisibilityResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.PatchVisibilityResponse} PatchVisibilityResponse
         */
        PatchVisibilityResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.PatchVisibilityResponse)
                return object;
            return new $root.maps.PatchVisibilityResponse();
        };

        /**
         * Creates a plain object from a PatchVisibilityResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {maps.PatchVisibilityResponse} message PatchVisibilityResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatchVisibilityResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PatchVisibilityResponse to JSON.
         * @function toJSON
         * @memberof maps.PatchVisibilityResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatchVisibilityResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PatchVisibilityResponse
         * @function getTypeUrl
         * @memberof maps.PatchVisibilityResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PatchVisibilityResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.PatchVisibilityResponse";
        };

        return PatchVisibilityResponse;
    })();

    maps.GetCampaignByIdRequest = (function() {

        /**
         * Properties of a GetCampaignByIdRequest.
         * @memberof maps
         * @interface IGetCampaignByIdRequest
         * @property {string|null} [campaignId] GetCampaignByIdRequest campaignId
         */

        /**
         * Constructs a new GetCampaignByIdRequest.
         * @memberof maps
         * @classdesc Represents a GetCampaignByIdRequest.
         * @implements IGetCampaignByIdRequest
         * @constructor
         * @param {maps.IGetCampaignByIdRequest=} [properties] Properties to set
         */
        function GetCampaignByIdRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCampaignByIdRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.GetCampaignByIdRequest
         * @instance
         */
        GetCampaignByIdRequest.prototype.campaignId = "";

        /**
         * Creates a new GetCampaignByIdRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {maps.IGetCampaignByIdRequest=} [properties] Properties to set
         * @returns {maps.GetCampaignByIdRequest} GetCampaignByIdRequest instance
         */
        GetCampaignByIdRequest.create = function create(properties) {
            return new GetCampaignByIdRequest(properties);
        };

        /**
         * Encodes the specified GetCampaignByIdRequest message. Does not implicitly {@link maps.GetCampaignByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {maps.IGetCampaignByIdRequest} message GetCampaignByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            return writer;
        };

        /**
         * Encodes the specified GetCampaignByIdRequest message, length delimited. Does not implicitly {@link maps.GetCampaignByIdRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {maps.IGetCampaignByIdRequest} message GetCampaignByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignByIdRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCampaignByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetCampaignByIdRequest} GetCampaignByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetCampaignByIdRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCampaignByIdRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetCampaignByIdRequest} GetCampaignByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignByIdRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCampaignByIdRequest message.
         * @function verify
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCampaignByIdRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            return null;
        };

        /**
         * Creates a GetCampaignByIdRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetCampaignByIdRequest} GetCampaignByIdRequest
         */
        GetCampaignByIdRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetCampaignByIdRequest)
                return object;
            let message = new $root.maps.GetCampaignByIdRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            return message;
        };

        /**
         * Creates a plain object from a GetCampaignByIdRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {maps.GetCampaignByIdRequest} message GetCampaignByIdRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCampaignByIdRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.campaignId = "";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            return object;
        };

        /**
         * Converts this GetCampaignByIdRequest to JSON.
         * @function toJSON
         * @memberof maps.GetCampaignByIdRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCampaignByIdRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetCampaignByIdRequest
         * @function getTypeUrl
         * @memberof maps.GetCampaignByIdRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCampaignByIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetCampaignByIdRequest";
        };

        return GetCampaignByIdRequest;
    })();

    maps.GetMyCampaignsRequest = (function() {

        /**
         * Properties of a GetMyCampaignsRequest.
         * @memberof maps
         * @interface IGetMyCampaignsRequest
         */

        /**
         * Constructs a new GetMyCampaignsRequest.
         * @memberof maps
         * @classdesc Represents a GetMyCampaignsRequest.
         * @implements IGetMyCampaignsRequest
         * @constructor
         * @param {maps.IGetMyCampaignsRequest=} [properties] Properties to set
         */
        function GetMyCampaignsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMyCampaignsRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {maps.IGetMyCampaignsRequest=} [properties] Properties to set
         * @returns {maps.GetMyCampaignsRequest} GetMyCampaignsRequest instance
         */
        GetMyCampaignsRequest.create = function create(properties) {
            return new GetMyCampaignsRequest(properties);
        };

        /**
         * Encodes the specified GetMyCampaignsRequest message. Does not implicitly {@link maps.GetMyCampaignsRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {maps.IGetMyCampaignsRequest} message GetMyCampaignsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMyCampaignsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMyCampaignsRequest message, length delimited. Does not implicitly {@link maps.GetMyCampaignsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {maps.IGetMyCampaignsRequest} message GetMyCampaignsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMyCampaignsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMyCampaignsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMyCampaignsRequest} GetMyCampaignsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMyCampaignsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMyCampaignsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMyCampaignsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMyCampaignsRequest} GetMyCampaignsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMyCampaignsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMyCampaignsRequest message.
         * @function verify
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMyCampaignsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMyCampaignsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMyCampaignsRequest} GetMyCampaignsRequest
         */
        GetMyCampaignsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMyCampaignsRequest)
                return object;
            return new $root.maps.GetMyCampaignsRequest();
        };

        /**
         * Creates a plain object from a GetMyCampaignsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {maps.GetMyCampaignsRequest} message GetMyCampaignsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMyCampaignsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMyCampaignsRequest to JSON.
         * @function toJSON
         * @memberof maps.GetMyCampaignsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMyCampaignsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMyCampaignsRequest
         * @function getTypeUrl
         * @memberof maps.GetMyCampaignsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMyCampaignsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMyCampaignsRequest";
        };

        return GetMyCampaignsRequest;
    })();

    maps.GetMyCampaignsResponse = (function() {

        /**
         * Properties of a GetMyCampaignsResponse.
         * @memberof maps
         * @interface IGetMyCampaignsResponse
         * @property {Array.<maps.ICampaign>|null} [campaigns] GetMyCampaignsResponse campaigns
         */

        /**
         * Constructs a new GetMyCampaignsResponse.
         * @memberof maps
         * @classdesc Represents a GetMyCampaignsResponse.
         * @implements IGetMyCampaignsResponse
         * @constructor
         * @param {maps.IGetMyCampaignsResponse=} [properties] Properties to set
         */
        function GetMyCampaignsResponse(properties) {
            this.campaigns = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMyCampaignsResponse campaigns.
         * @member {Array.<maps.ICampaign>} campaigns
         * @memberof maps.GetMyCampaignsResponse
         * @instance
         */
        GetMyCampaignsResponse.prototype.campaigns = $util.emptyArray;

        /**
         * Creates a new GetMyCampaignsResponse instance using the specified properties.
         * @function create
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {maps.IGetMyCampaignsResponse=} [properties] Properties to set
         * @returns {maps.GetMyCampaignsResponse} GetMyCampaignsResponse instance
         */
        GetMyCampaignsResponse.create = function create(properties) {
            return new GetMyCampaignsResponse(properties);
        };

        /**
         * Encodes the specified GetMyCampaignsResponse message. Does not implicitly {@link maps.GetMyCampaignsResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {maps.IGetMyCampaignsResponse} message GetMyCampaignsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMyCampaignsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaigns != null && message.campaigns.length)
                for (let i = 0; i < message.campaigns.length; ++i)
                    $root.maps.Campaign.encode(message.campaigns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetMyCampaignsResponse message, length delimited. Does not implicitly {@link maps.GetMyCampaignsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {maps.IGetMyCampaignsResponse} message GetMyCampaignsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMyCampaignsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMyCampaignsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetMyCampaignsResponse} GetMyCampaignsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMyCampaignsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetMyCampaignsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.campaigns && message.campaigns.length))
                            message.campaigns = [];
                        message.campaigns.push($root.maps.Campaign.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMyCampaignsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetMyCampaignsResponse} GetMyCampaignsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMyCampaignsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMyCampaignsResponse message.
         * @function verify
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMyCampaignsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaigns != null && message.hasOwnProperty("campaigns")) {
                if (!Array.isArray(message.campaigns))
                    return "campaigns: array expected";
                for (let i = 0; i < message.campaigns.length; ++i) {
                    let error = $root.maps.Campaign.verify(message.campaigns[i]);
                    if (error)
                        return "campaigns." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetMyCampaignsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetMyCampaignsResponse} GetMyCampaignsResponse
         */
        GetMyCampaignsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetMyCampaignsResponse)
                return object;
            let message = new $root.maps.GetMyCampaignsResponse();
            if (object.campaigns) {
                if (!Array.isArray(object.campaigns))
                    throw TypeError(".maps.GetMyCampaignsResponse.campaigns: array expected");
                message.campaigns = [];
                for (let i = 0; i < object.campaigns.length; ++i) {
                    if (typeof object.campaigns[i] !== "object")
                        throw TypeError(".maps.GetMyCampaignsResponse.campaigns: object expected");
                    message.campaigns[i] = $root.maps.Campaign.fromObject(object.campaigns[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMyCampaignsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {maps.GetMyCampaignsResponse} message GetMyCampaignsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMyCampaignsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.campaigns = [];
            if (message.campaigns && message.campaigns.length) {
                object.campaigns = [];
                for (let j = 0; j < message.campaigns.length; ++j)
                    object.campaigns[j] = $root.maps.Campaign.toObject(message.campaigns[j], options);
            }
            return object;
        };

        /**
         * Converts this GetMyCampaignsResponse to JSON.
         * @function toJSON
         * @memberof maps.GetMyCampaignsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMyCampaignsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMyCampaignsResponse
         * @function getTypeUrl
         * @memberof maps.GetMyCampaignsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMyCampaignsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetMyCampaignsResponse";
        };

        return GetMyCampaignsResponse;
    })();

    maps.ModifyCampaignMapsRequest = (function() {

        /**
         * Properties of a ModifyCampaignMapsRequest.
         * @memberof maps
         * @interface IModifyCampaignMapsRequest
         * @property {string|null} [campaignId] ModifyCampaignMapsRequest campaignId
         * @property {Array.<string>|null} [addMapIds] ModifyCampaignMapsRequest addMapIds
         * @property {Array.<string>|null} [removeMapIds] ModifyCampaignMapsRequest removeMapIds
         */

        /**
         * Constructs a new ModifyCampaignMapsRequest.
         * @memberof maps
         * @classdesc Represents a ModifyCampaignMapsRequest.
         * @implements IModifyCampaignMapsRequest
         * @constructor
         * @param {maps.IModifyCampaignMapsRequest=} [properties] Properties to set
         */
        function ModifyCampaignMapsRequest(properties) {
            this.addMapIds = [];
            this.removeMapIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModifyCampaignMapsRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.ModifyCampaignMapsRequest
         * @instance
         */
        ModifyCampaignMapsRequest.prototype.campaignId = "";

        /**
         * ModifyCampaignMapsRequest addMapIds.
         * @member {Array.<string>} addMapIds
         * @memberof maps.ModifyCampaignMapsRequest
         * @instance
         */
        ModifyCampaignMapsRequest.prototype.addMapIds = $util.emptyArray;

        /**
         * ModifyCampaignMapsRequest removeMapIds.
         * @member {Array.<string>} removeMapIds
         * @memberof maps.ModifyCampaignMapsRequest
         * @instance
         */
        ModifyCampaignMapsRequest.prototype.removeMapIds = $util.emptyArray;

        /**
         * Creates a new ModifyCampaignMapsRequest instance using the specified properties.
         * @function create
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {maps.IModifyCampaignMapsRequest=} [properties] Properties to set
         * @returns {maps.ModifyCampaignMapsRequest} ModifyCampaignMapsRequest instance
         */
        ModifyCampaignMapsRequest.create = function create(properties) {
            return new ModifyCampaignMapsRequest(properties);
        };

        /**
         * Encodes the specified ModifyCampaignMapsRequest message. Does not implicitly {@link maps.ModifyCampaignMapsRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {maps.IModifyCampaignMapsRequest} message ModifyCampaignMapsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMapsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            if (message.addMapIds != null && message.addMapIds.length)
                for (let i = 0; i < message.addMapIds.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.addMapIds[i]);
            if (message.removeMapIds != null && message.removeMapIds.length)
                for (let i = 0; i < message.removeMapIds.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.removeMapIds[i]);
            return writer;
        };

        /**
         * Encodes the specified ModifyCampaignMapsRequest message, length delimited. Does not implicitly {@link maps.ModifyCampaignMapsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {maps.IModifyCampaignMapsRequest} message ModifyCampaignMapsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMapsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyCampaignMapsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ModifyCampaignMapsRequest} ModifyCampaignMapsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMapsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ModifyCampaignMapsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.addMapIds && message.addMapIds.length))
                            message.addMapIds = [];
                        message.addMapIds.push(reader.string());
                        break;
                    }
                case 3: {
                        if (!(message.removeMapIds && message.removeMapIds.length))
                            message.removeMapIds = [];
                        message.removeMapIds.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyCampaignMapsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ModifyCampaignMapsRequest} ModifyCampaignMapsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMapsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyCampaignMapsRequest message.
         * @function verify
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyCampaignMapsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.addMapIds != null && message.hasOwnProperty("addMapIds")) {
                if (!Array.isArray(message.addMapIds))
                    return "addMapIds: array expected";
                for (let i = 0; i < message.addMapIds.length; ++i)
                    if (!$util.isString(message.addMapIds[i]))
                        return "addMapIds: string[] expected";
            }
            if (message.removeMapIds != null && message.hasOwnProperty("removeMapIds")) {
                if (!Array.isArray(message.removeMapIds))
                    return "removeMapIds: array expected";
                for (let i = 0; i < message.removeMapIds.length; ++i)
                    if (!$util.isString(message.removeMapIds[i]))
                        return "removeMapIds: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ModifyCampaignMapsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ModifyCampaignMapsRequest} ModifyCampaignMapsRequest
         */
        ModifyCampaignMapsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ModifyCampaignMapsRequest)
                return object;
            let message = new $root.maps.ModifyCampaignMapsRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.addMapIds) {
                if (!Array.isArray(object.addMapIds))
                    throw TypeError(".maps.ModifyCampaignMapsRequest.addMapIds: array expected");
                message.addMapIds = [];
                for (let i = 0; i < object.addMapIds.length; ++i)
                    message.addMapIds[i] = String(object.addMapIds[i]);
            }
            if (object.removeMapIds) {
                if (!Array.isArray(object.removeMapIds))
                    throw TypeError(".maps.ModifyCampaignMapsRequest.removeMapIds: array expected");
                message.removeMapIds = [];
                for (let i = 0; i < object.removeMapIds.length; ++i)
                    message.removeMapIds[i] = String(object.removeMapIds[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ModifyCampaignMapsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {maps.ModifyCampaignMapsRequest} message ModifyCampaignMapsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyCampaignMapsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.addMapIds = [];
                object.removeMapIds = [];
            }
            if (options.defaults)
                object.campaignId = "";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.addMapIds && message.addMapIds.length) {
                object.addMapIds = [];
                for (let j = 0; j < message.addMapIds.length; ++j)
                    object.addMapIds[j] = message.addMapIds[j];
            }
            if (message.removeMapIds && message.removeMapIds.length) {
                object.removeMapIds = [];
                for (let j = 0; j < message.removeMapIds.length; ++j)
                    object.removeMapIds[j] = message.removeMapIds[j];
            }
            return object;
        };

        /**
         * Converts this ModifyCampaignMapsRequest to JSON.
         * @function toJSON
         * @memberof maps.ModifyCampaignMapsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyCampaignMapsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ModifyCampaignMapsRequest
         * @function getTypeUrl
         * @memberof maps.ModifyCampaignMapsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ModifyCampaignMapsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ModifyCampaignMapsRequest";
        };

        return ModifyCampaignMapsRequest;
    })();

    maps.ModifyCampaignMapsResponse = (function() {

        /**
         * Properties of a ModifyCampaignMapsResponse.
         * @memberof maps
         * @interface IModifyCampaignMapsResponse
         */

        /**
         * Constructs a new ModifyCampaignMapsResponse.
         * @memberof maps
         * @classdesc Represents a ModifyCampaignMapsResponse.
         * @implements IModifyCampaignMapsResponse
         * @constructor
         * @param {maps.IModifyCampaignMapsResponse=} [properties] Properties to set
         */
        function ModifyCampaignMapsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ModifyCampaignMapsResponse instance using the specified properties.
         * @function create
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {maps.IModifyCampaignMapsResponse=} [properties] Properties to set
         * @returns {maps.ModifyCampaignMapsResponse} ModifyCampaignMapsResponse instance
         */
        ModifyCampaignMapsResponse.create = function create(properties) {
            return new ModifyCampaignMapsResponse(properties);
        };

        /**
         * Encodes the specified ModifyCampaignMapsResponse message. Does not implicitly {@link maps.ModifyCampaignMapsResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {maps.IModifyCampaignMapsResponse} message ModifyCampaignMapsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMapsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ModifyCampaignMapsResponse message, length delimited. Does not implicitly {@link maps.ModifyCampaignMapsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {maps.IModifyCampaignMapsResponse} message ModifyCampaignMapsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMapsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyCampaignMapsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ModifyCampaignMapsResponse} ModifyCampaignMapsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMapsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ModifyCampaignMapsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyCampaignMapsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ModifyCampaignMapsResponse} ModifyCampaignMapsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMapsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyCampaignMapsResponse message.
         * @function verify
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyCampaignMapsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ModifyCampaignMapsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ModifyCampaignMapsResponse} ModifyCampaignMapsResponse
         */
        ModifyCampaignMapsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ModifyCampaignMapsResponse)
                return object;
            return new $root.maps.ModifyCampaignMapsResponse();
        };

        /**
         * Creates a plain object from a ModifyCampaignMapsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {maps.ModifyCampaignMapsResponse} message ModifyCampaignMapsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyCampaignMapsResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ModifyCampaignMapsResponse to JSON.
         * @function toJSON
         * @memberof maps.ModifyCampaignMapsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyCampaignMapsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ModifyCampaignMapsResponse
         * @function getTypeUrl
         * @memberof maps.ModifyCampaignMapsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ModifyCampaignMapsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ModifyCampaignMapsResponse";
        };

        return ModifyCampaignMapsResponse;
    })();

    maps.GetCampaignMembersRequest = (function() {

        /**
         * Properties of a GetCampaignMembersRequest.
         * @memberof maps
         * @interface IGetCampaignMembersRequest
         * @property {string|null} [campaignId] GetCampaignMembersRequest campaignId
         */

        /**
         * Constructs a new GetCampaignMembersRequest.
         * @memberof maps
         * @classdesc Represents a GetCampaignMembersRequest.
         * @implements IGetCampaignMembersRequest
         * @constructor
         * @param {maps.IGetCampaignMembersRequest=} [properties] Properties to set
         */
        function GetCampaignMembersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCampaignMembersRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.GetCampaignMembersRequest
         * @instance
         */
        GetCampaignMembersRequest.prototype.campaignId = "";

        /**
         * Creates a new GetCampaignMembersRequest instance using the specified properties.
         * @function create
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {maps.IGetCampaignMembersRequest=} [properties] Properties to set
         * @returns {maps.GetCampaignMembersRequest} GetCampaignMembersRequest instance
         */
        GetCampaignMembersRequest.create = function create(properties) {
            return new GetCampaignMembersRequest(properties);
        };

        /**
         * Encodes the specified GetCampaignMembersRequest message. Does not implicitly {@link maps.GetCampaignMembersRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {maps.IGetCampaignMembersRequest} message GetCampaignMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignMembersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            return writer;
        };

        /**
         * Encodes the specified GetCampaignMembersRequest message, length delimited. Does not implicitly {@link maps.GetCampaignMembersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {maps.IGetCampaignMembersRequest} message GetCampaignMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignMembersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCampaignMembersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetCampaignMembersRequest} GetCampaignMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignMembersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetCampaignMembersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCampaignMembersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetCampaignMembersRequest} GetCampaignMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignMembersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCampaignMembersRequest message.
         * @function verify
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCampaignMembersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            return null;
        };

        /**
         * Creates a GetCampaignMembersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetCampaignMembersRequest} GetCampaignMembersRequest
         */
        GetCampaignMembersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetCampaignMembersRequest)
                return object;
            let message = new $root.maps.GetCampaignMembersRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            return message;
        };

        /**
         * Creates a plain object from a GetCampaignMembersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {maps.GetCampaignMembersRequest} message GetCampaignMembersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCampaignMembersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.campaignId = "";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            return object;
        };

        /**
         * Converts this GetCampaignMembersRequest to JSON.
         * @function toJSON
         * @memberof maps.GetCampaignMembersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCampaignMembersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetCampaignMembersRequest
         * @function getTypeUrl
         * @memberof maps.GetCampaignMembersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCampaignMembersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetCampaignMembersRequest";
        };

        return GetCampaignMembersRequest;
    })();

    maps.GetCampaignMembersResponse = (function() {

        /**
         * Properties of a GetCampaignMembersResponse.
         * @memberof maps
         * @interface IGetCampaignMembersResponse
         * @property {Array.<maps.ICampaignMember>|null} [campaignMembers] GetCampaignMembersResponse campaignMembers
         */

        /**
         * Constructs a new GetCampaignMembersResponse.
         * @memberof maps
         * @classdesc Represents a GetCampaignMembersResponse.
         * @implements IGetCampaignMembersResponse
         * @constructor
         * @param {maps.IGetCampaignMembersResponse=} [properties] Properties to set
         */
        function GetCampaignMembersResponse(properties) {
            this.campaignMembers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCampaignMembersResponse campaignMembers.
         * @member {Array.<maps.ICampaignMember>} campaignMembers
         * @memberof maps.GetCampaignMembersResponse
         * @instance
         */
        GetCampaignMembersResponse.prototype.campaignMembers = $util.emptyArray;

        /**
         * Creates a new GetCampaignMembersResponse instance using the specified properties.
         * @function create
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {maps.IGetCampaignMembersResponse=} [properties] Properties to set
         * @returns {maps.GetCampaignMembersResponse} GetCampaignMembersResponse instance
         */
        GetCampaignMembersResponse.create = function create(properties) {
            return new GetCampaignMembersResponse(properties);
        };

        /**
         * Encodes the specified GetCampaignMembersResponse message. Does not implicitly {@link maps.GetCampaignMembersResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {maps.IGetCampaignMembersResponse} message GetCampaignMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignMembersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignMembers != null && message.campaignMembers.length)
                for (let i = 0; i < message.campaignMembers.length; ++i)
                    $root.maps.CampaignMember.encode(message.campaignMembers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetCampaignMembersResponse message, length delimited. Does not implicitly {@link maps.GetCampaignMembersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {maps.IGetCampaignMembersResponse} message GetCampaignMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCampaignMembersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCampaignMembersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GetCampaignMembersResponse} GetCampaignMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignMembersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GetCampaignMembersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.campaignMembers && message.campaignMembers.length))
                            message.campaignMembers = [];
                        message.campaignMembers.push($root.maps.CampaignMember.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCampaignMembersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GetCampaignMembersResponse} GetCampaignMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCampaignMembersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCampaignMembersResponse message.
         * @function verify
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCampaignMembersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignMembers != null && message.hasOwnProperty("campaignMembers")) {
                if (!Array.isArray(message.campaignMembers))
                    return "campaignMembers: array expected";
                for (let i = 0; i < message.campaignMembers.length; ++i) {
                    let error = $root.maps.CampaignMember.verify(message.campaignMembers[i]);
                    if (error)
                        return "campaignMembers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetCampaignMembersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GetCampaignMembersResponse} GetCampaignMembersResponse
         */
        GetCampaignMembersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GetCampaignMembersResponse)
                return object;
            let message = new $root.maps.GetCampaignMembersResponse();
            if (object.campaignMembers) {
                if (!Array.isArray(object.campaignMembers))
                    throw TypeError(".maps.GetCampaignMembersResponse.campaignMembers: array expected");
                message.campaignMembers = [];
                for (let i = 0; i < object.campaignMembers.length; ++i) {
                    if (typeof object.campaignMembers[i] !== "object")
                        throw TypeError(".maps.GetCampaignMembersResponse.campaignMembers: object expected");
                    message.campaignMembers[i] = $root.maps.CampaignMember.fromObject(object.campaignMembers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetCampaignMembersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {maps.GetCampaignMembersResponse} message GetCampaignMembersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCampaignMembersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.campaignMembers = [];
            if (message.campaignMembers && message.campaignMembers.length) {
                object.campaignMembers = [];
                for (let j = 0; j < message.campaignMembers.length; ++j)
                    object.campaignMembers[j] = $root.maps.CampaignMember.toObject(message.campaignMembers[j], options);
            }
            return object;
        };

        /**
         * Converts this GetCampaignMembersResponse to JSON.
         * @function toJSON
         * @memberof maps.GetCampaignMembersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCampaignMembersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetCampaignMembersResponse
         * @function getTypeUrl
         * @memberof maps.GetCampaignMembersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCampaignMembersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GetCampaignMembersResponse";
        };

        return GetCampaignMembersResponse;
    })();

    maps.AddCampaignMemberRequest = (function() {

        /**
         * Properties of an AddCampaignMemberRequest.
         * @memberof maps
         * @interface IAddCampaignMemberRequest
         * @property {string|null} [campaignId] AddCampaignMemberRequest campaignId
         * @property {string|null} [userId] AddCampaignMemberRequest userId
         * @property {string|null} [playerName] AddCampaignMemberRequest playerName
         * @property {string|null} [playerEmail] AddCampaignMemberRequest playerEmail
         * @property {common.Role|null} [role] AddCampaignMemberRequest role
         */

        /**
         * Constructs a new AddCampaignMemberRequest.
         * @memberof maps
         * @classdesc Represents an AddCampaignMemberRequest.
         * @implements IAddCampaignMemberRequest
         * @constructor
         * @param {maps.IAddCampaignMemberRequest=} [properties] Properties to set
         */
        function AddCampaignMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddCampaignMemberRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         */
        AddCampaignMemberRequest.prototype.campaignId = "";

        /**
         * AddCampaignMemberRequest userId.
         * @member {string} userId
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         */
        AddCampaignMemberRequest.prototype.userId = "";

        /**
         * AddCampaignMemberRequest playerName.
         * @member {string} playerName
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         */
        AddCampaignMemberRequest.prototype.playerName = "";

        /**
         * AddCampaignMemberRequest playerEmail.
         * @member {string} playerEmail
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         */
        AddCampaignMemberRequest.prototype.playerEmail = "";

        /**
         * AddCampaignMemberRequest role.
         * @member {common.Role} role
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         */
        AddCampaignMemberRequest.prototype.role = 0;

        /**
         * Creates a new AddCampaignMemberRequest instance using the specified properties.
         * @function create
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {maps.IAddCampaignMemberRequest=} [properties] Properties to set
         * @returns {maps.AddCampaignMemberRequest} AddCampaignMemberRequest instance
         */
        AddCampaignMemberRequest.create = function create(properties) {
            return new AddCampaignMemberRequest(properties);
        };

        /**
         * Encodes the specified AddCampaignMemberRequest message. Does not implicitly {@link maps.AddCampaignMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {maps.IAddCampaignMemberRequest} message AddCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddCampaignMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.playerName != null && Object.hasOwnProperty.call(message, "playerName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.playerName);
            if (message.playerEmail != null && Object.hasOwnProperty.call(message, "playerEmail"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.playerEmail);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.role);
            return writer;
        };

        /**
         * Encodes the specified AddCampaignMemberRequest message, length delimited. Does not implicitly {@link maps.AddCampaignMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {maps.IAddCampaignMemberRequest} message AddCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddCampaignMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddCampaignMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.AddCampaignMemberRequest} AddCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddCampaignMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.AddCampaignMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 2: {
                        message.userId = reader.string();
                        break;
                    }
                case 3: {
                        message.playerName = reader.string();
                        break;
                    }
                case 4: {
                        message.playerEmail = reader.string();
                        break;
                    }
                case 5: {
                        message.role = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddCampaignMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.AddCampaignMemberRequest} AddCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddCampaignMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddCampaignMemberRequest message.
         * @function verify
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddCampaignMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.playerName != null && message.hasOwnProperty("playerName"))
                if (!$util.isString(message.playerName))
                    return "playerName: string expected";
            if (message.playerEmail != null && message.hasOwnProperty("playerEmail"))
                if (!$util.isString(message.playerEmail))
                    return "playerEmail: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates an AddCampaignMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.AddCampaignMemberRequest} AddCampaignMemberRequest
         */
        AddCampaignMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.AddCampaignMemberRequest)
                return object;
            let message = new $root.maps.AddCampaignMemberRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.playerName != null)
                message.playerName = String(object.playerName);
            if (object.playerEmail != null)
                message.playerEmail = String(object.playerEmail);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "UNKNOWN_ROLE":
            case 0:
                message.role = 0;
                break;
            case "OWNER":
            case 1:
                message.role = 1;
                break;
            case "GM":
            case 2:
                message.role = 2;
                break;
            case "TRUSTED_PLAYER":
            case 3:
                message.role = 3;
                break;
            case "PLAYER":
            case 4:
                message.role = 4;
                break;
            case "OBSERVER":
            case 5:
                message.role = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an AddCampaignMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {maps.AddCampaignMemberRequest} message AddCampaignMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddCampaignMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.campaignId = "";
                object.userId = "";
                object.playerName = "";
                object.playerEmail = "";
                object.role = options.enums === String ? "UNKNOWN_ROLE" : 0;
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.playerName != null && message.hasOwnProperty("playerName"))
                object.playerName = message.playerName;
            if (message.playerEmail != null && message.hasOwnProperty("playerEmail"))
                object.playerEmail = message.playerEmail;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.common.Role[message.role] === undefined ? message.role : $root.common.Role[message.role] : message.role;
            return object;
        };

        /**
         * Converts this AddCampaignMemberRequest to JSON.
         * @function toJSON
         * @memberof maps.AddCampaignMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddCampaignMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AddCampaignMemberRequest
         * @function getTypeUrl
         * @memberof maps.AddCampaignMemberRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddCampaignMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.AddCampaignMemberRequest";
        };

        return AddCampaignMemberRequest;
    })();

    maps.AddCampaignMemberResponse = (function() {

        /**
         * Properties of an AddCampaignMemberResponse.
         * @memberof maps
         * @interface IAddCampaignMemberResponse
         * @property {maps.ICampaignMember|null} [campaignMember] AddCampaignMemberResponse campaignMember
         */

        /**
         * Constructs a new AddCampaignMemberResponse.
         * @memberof maps
         * @classdesc Represents an AddCampaignMemberResponse.
         * @implements IAddCampaignMemberResponse
         * @constructor
         * @param {maps.IAddCampaignMemberResponse=} [properties] Properties to set
         */
        function AddCampaignMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddCampaignMemberResponse campaignMember.
         * @member {maps.ICampaignMember|null|undefined} campaignMember
         * @memberof maps.AddCampaignMemberResponse
         * @instance
         */
        AddCampaignMemberResponse.prototype.campaignMember = null;

        /**
         * Creates a new AddCampaignMemberResponse instance using the specified properties.
         * @function create
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {maps.IAddCampaignMemberResponse=} [properties] Properties to set
         * @returns {maps.AddCampaignMemberResponse} AddCampaignMemberResponse instance
         */
        AddCampaignMemberResponse.create = function create(properties) {
            return new AddCampaignMemberResponse(properties);
        };

        /**
         * Encodes the specified AddCampaignMemberResponse message. Does not implicitly {@link maps.AddCampaignMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {maps.IAddCampaignMemberResponse} message AddCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddCampaignMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignMember != null && Object.hasOwnProperty.call(message, "campaignMember"))
                $root.maps.CampaignMember.encode(message.campaignMember, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddCampaignMemberResponse message, length delimited. Does not implicitly {@link maps.AddCampaignMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {maps.IAddCampaignMemberResponse} message AddCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddCampaignMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddCampaignMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.AddCampaignMemberResponse} AddCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddCampaignMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.AddCampaignMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignMember = $root.maps.CampaignMember.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddCampaignMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.AddCampaignMemberResponse} AddCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddCampaignMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddCampaignMemberResponse message.
         * @function verify
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddCampaignMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignMember != null && message.hasOwnProperty("campaignMember")) {
                let error = $root.maps.CampaignMember.verify(message.campaignMember);
                if (error)
                    return "campaignMember." + error;
            }
            return null;
        };

        /**
         * Creates an AddCampaignMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.AddCampaignMemberResponse} AddCampaignMemberResponse
         */
        AddCampaignMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.AddCampaignMemberResponse)
                return object;
            let message = new $root.maps.AddCampaignMemberResponse();
            if (object.campaignMember != null) {
                if (typeof object.campaignMember !== "object")
                    throw TypeError(".maps.AddCampaignMemberResponse.campaignMember: object expected");
                message.campaignMember = $root.maps.CampaignMember.fromObject(object.campaignMember);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddCampaignMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {maps.AddCampaignMemberResponse} message AddCampaignMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddCampaignMemberResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.campaignMember = null;
            if (message.campaignMember != null && message.hasOwnProperty("campaignMember"))
                object.campaignMember = $root.maps.CampaignMember.toObject(message.campaignMember, options);
            return object;
        };

        /**
         * Converts this AddCampaignMemberResponse to JSON.
         * @function toJSON
         * @memberof maps.AddCampaignMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddCampaignMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AddCampaignMemberResponse
         * @function getTypeUrl
         * @memberof maps.AddCampaignMemberResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddCampaignMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.AddCampaignMemberResponse";
        };

        return AddCampaignMemberResponse;
    })();

    maps.ModifyCampaignMemberRequest = (function() {

        /**
         * Properties of a ModifyCampaignMemberRequest.
         * @memberof maps
         * @interface IModifyCampaignMemberRequest
         * @property {string|null} [campaignId] ModifyCampaignMemberRequest campaignId
         * @property {string|null} [userId] ModifyCampaignMemberRequest userId
         * @property {string|null} [playerName] ModifyCampaignMemberRequest playerName
         * @property {string|null} [playerEmail] ModifyCampaignMemberRequest playerEmail
         * @property {common.Role|null} [role] ModifyCampaignMemberRequest role
         */

        /**
         * Constructs a new ModifyCampaignMemberRequest.
         * @memberof maps
         * @classdesc Represents a ModifyCampaignMemberRequest.
         * @implements IModifyCampaignMemberRequest
         * @constructor
         * @param {maps.IModifyCampaignMemberRequest=} [properties] Properties to set
         */
        function ModifyCampaignMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModifyCampaignMemberRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         */
        ModifyCampaignMemberRequest.prototype.campaignId = "";

        /**
         * ModifyCampaignMemberRequest userId.
         * @member {string} userId
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         */
        ModifyCampaignMemberRequest.prototype.userId = "";

        /**
         * ModifyCampaignMemberRequest playerName.
         * @member {string} playerName
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         */
        ModifyCampaignMemberRequest.prototype.playerName = "";

        /**
         * ModifyCampaignMemberRequest playerEmail.
         * @member {string} playerEmail
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         */
        ModifyCampaignMemberRequest.prototype.playerEmail = "";

        /**
         * ModifyCampaignMemberRequest role.
         * @member {common.Role} role
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         */
        ModifyCampaignMemberRequest.prototype.role = 0;

        /**
         * Creates a new ModifyCampaignMemberRequest instance using the specified properties.
         * @function create
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {maps.IModifyCampaignMemberRequest=} [properties] Properties to set
         * @returns {maps.ModifyCampaignMemberRequest} ModifyCampaignMemberRequest instance
         */
        ModifyCampaignMemberRequest.create = function create(properties) {
            return new ModifyCampaignMemberRequest(properties);
        };

        /**
         * Encodes the specified ModifyCampaignMemberRequest message. Does not implicitly {@link maps.ModifyCampaignMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {maps.IModifyCampaignMemberRequest} message ModifyCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.playerName != null && Object.hasOwnProperty.call(message, "playerName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.playerName);
            if (message.playerEmail != null && Object.hasOwnProperty.call(message, "playerEmail"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.playerEmail);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.role);
            return writer;
        };

        /**
         * Encodes the specified ModifyCampaignMemberRequest message, length delimited. Does not implicitly {@link maps.ModifyCampaignMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {maps.IModifyCampaignMemberRequest} message ModifyCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyCampaignMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ModifyCampaignMemberRequest} ModifyCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ModifyCampaignMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 2: {
                        message.userId = reader.string();
                        break;
                    }
                case 3: {
                        message.playerName = reader.string();
                        break;
                    }
                case 4: {
                        message.playerEmail = reader.string();
                        break;
                    }
                case 5: {
                        message.role = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyCampaignMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ModifyCampaignMemberRequest} ModifyCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyCampaignMemberRequest message.
         * @function verify
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyCampaignMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.playerName != null && message.hasOwnProperty("playerName"))
                if (!$util.isString(message.playerName))
                    return "playerName: string expected";
            if (message.playerEmail != null && message.hasOwnProperty("playerEmail"))
                if (!$util.isString(message.playerEmail))
                    return "playerEmail: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a ModifyCampaignMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ModifyCampaignMemberRequest} ModifyCampaignMemberRequest
         */
        ModifyCampaignMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ModifyCampaignMemberRequest)
                return object;
            let message = new $root.maps.ModifyCampaignMemberRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.playerName != null)
                message.playerName = String(object.playerName);
            if (object.playerEmail != null)
                message.playerEmail = String(object.playerEmail);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "UNKNOWN_ROLE":
            case 0:
                message.role = 0;
                break;
            case "OWNER":
            case 1:
                message.role = 1;
                break;
            case "GM":
            case 2:
                message.role = 2;
                break;
            case "TRUSTED_PLAYER":
            case 3:
                message.role = 3;
                break;
            case "PLAYER":
            case 4:
                message.role = 4;
                break;
            case "OBSERVER":
            case 5:
                message.role = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ModifyCampaignMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {maps.ModifyCampaignMemberRequest} message ModifyCampaignMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyCampaignMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.campaignId = "";
                object.userId = "";
                object.playerName = "";
                object.playerEmail = "";
                object.role = options.enums === String ? "UNKNOWN_ROLE" : 0;
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.playerName != null && message.hasOwnProperty("playerName"))
                object.playerName = message.playerName;
            if (message.playerEmail != null && message.hasOwnProperty("playerEmail"))
                object.playerEmail = message.playerEmail;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.common.Role[message.role] === undefined ? message.role : $root.common.Role[message.role] : message.role;
            return object;
        };

        /**
         * Converts this ModifyCampaignMemberRequest to JSON.
         * @function toJSON
         * @memberof maps.ModifyCampaignMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyCampaignMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ModifyCampaignMemberRequest
         * @function getTypeUrl
         * @memberof maps.ModifyCampaignMemberRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ModifyCampaignMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ModifyCampaignMemberRequest";
        };

        return ModifyCampaignMemberRequest;
    })();

    maps.ModifyCampaignMemberResponse = (function() {

        /**
         * Properties of a ModifyCampaignMemberResponse.
         * @memberof maps
         * @interface IModifyCampaignMemberResponse
         * @property {maps.ICampaignMember|null} [campaignMember] ModifyCampaignMemberResponse campaignMember
         */

        /**
         * Constructs a new ModifyCampaignMemberResponse.
         * @memberof maps
         * @classdesc Represents a ModifyCampaignMemberResponse.
         * @implements IModifyCampaignMemberResponse
         * @constructor
         * @param {maps.IModifyCampaignMemberResponse=} [properties] Properties to set
         */
        function ModifyCampaignMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModifyCampaignMemberResponse campaignMember.
         * @member {maps.ICampaignMember|null|undefined} campaignMember
         * @memberof maps.ModifyCampaignMemberResponse
         * @instance
         */
        ModifyCampaignMemberResponse.prototype.campaignMember = null;

        /**
         * Creates a new ModifyCampaignMemberResponse instance using the specified properties.
         * @function create
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {maps.IModifyCampaignMemberResponse=} [properties] Properties to set
         * @returns {maps.ModifyCampaignMemberResponse} ModifyCampaignMemberResponse instance
         */
        ModifyCampaignMemberResponse.create = function create(properties) {
            return new ModifyCampaignMemberResponse(properties);
        };

        /**
         * Encodes the specified ModifyCampaignMemberResponse message. Does not implicitly {@link maps.ModifyCampaignMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {maps.IModifyCampaignMemberResponse} message ModifyCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignMember != null && Object.hasOwnProperty.call(message, "campaignMember"))
                $root.maps.CampaignMember.encode(message.campaignMember, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ModifyCampaignMemberResponse message, length delimited. Does not implicitly {@link maps.ModifyCampaignMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {maps.IModifyCampaignMemberResponse} message ModifyCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyCampaignMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyCampaignMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.ModifyCampaignMemberResponse} ModifyCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.ModifyCampaignMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignMember = $root.maps.CampaignMember.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyCampaignMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.ModifyCampaignMemberResponse} ModifyCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyCampaignMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyCampaignMemberResponse message.
         * @function verify
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyCampaignMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignMember != null && message.hasOwnProperty("campaignMember")) {
                let error = $root.maps.CampaignMember.verify(message.campaignMember);
                if (error)
                    return "campaignMember." + error;
            }
            return null;
        };

        /**
         * Creates a ModifyCampaignMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.ModifyCampaignMemberResponse} ModifyCampaignMemberResponse
         */
        ModifyCampaignMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.ModifyCampaignMemberResponse)
                return object;
            let message = new $root.maps.ModifyCampaignMemberResponse();
            if (object.campaignMember != null) {
                if (typeof object.campaignMember !== "object")
                    throw TypeError(".maps.ModifyCampaignMemberResponse.campaignMember: object expected");
                message.campaignMember = $root.maps.CampaignMember.fromObject(object.campaignMember);
            }
            return message;
        };

        /**
         * Creates a plain object from a ModifyCampaignMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {maps.ModifyCampaignMemberResponse} message ModifyCampaignMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyCampaignMemberResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.campaignMember = null;
            if (message.campaignMember != null && message.hasOwnProperty("campaignMember"))
                object.campaignMember = $root.maps.CampaignMember.toObject(message.campaignMember, options);
            return object;
        };

        /**
         * Converts this ModifyCampaignMemberResponse to JSON.
         * @function toJSON
         * @memberof maps.ModifyCampaignMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyCampaignMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ModifyCampaignMemberResponse
         * @function getTypeUrl
         * @memberof maps.ModifyCampaignMemberResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ModifyCampaignMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.ModifyCampaignMemberResponse";
        };

        return ModifyCampaignMemberResponse;
    })();

    maps.RemoveCampaignMemberRequest = (function() {

        /**
         * Properties of a RemoveCampaignMemberRequest.
         * @memberof maps
         * @interface IRemoveCampaignMemberRequest
         * @property {string|null} [campaignId] RemoveCampaignMemberRequest campaignId
         * @property {string|null} [userId] RemoveCampaignMemberRequest userId
         */

        /**
         * Constructs a new RemoveCampaignMemberRequest.
         * @memberof maps
         * @classdesc Represents a RemoveCampaignMemberRequest.
         * @implements IRemoveCampaignMemberRequest
         * @constructor
         * @param {maps.IRemoveCampaignMemberRequest=} [properties] Properties to set
         */
        function RemoveCampaignMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveCampaignMemberRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.RemoveCampaignMemberRequest
         * @instance
         */
        RemoveCampaignMemberRequest.prototype.campaignId = "";

        /**
         * RemoveCampaignMemberRequest userId.
         * @member {string} userId
         * @memberof maps.RemoveCampaignMemberRequest
         * @instance
         */
        RemoveCampaignMemberRequest.prototype.userId = "";

        /**
         * Creates a new RemoveCampaignMemberRequest instance using the specified properties.
         * @function create
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {maps.IRemoveCampaignMemberRequest=} [properties] Properties to set
         * @returns {maps.RemoveCampaignMemberRequest} RemoveCampaignMemberRequest instance
         */
        RemoveCampaignMemberRequest.create = function create(properties) {
            return new RemoveCampaignMemberRequest(properties);
        };

        /**
         * Encodes the specified RemoveCampaignMemberRequest message. Does not implicitly {@link maps.RemoveCampaignMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {maps.IRemoveCampaignMemberRequest} message RemoveCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCampaignMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified RemoveCampaignMemberRequest message, length delimited. Does not implicitly {@link maps.RemoveCampaignMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {maps.IRemoveCampaignMemberRequest} message RemoveCampaignMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCampaignMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveCampaignMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.RemoveCampaignMemberRequest} RemoveCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCampaignMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.RemoveCampaignMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 2: {
                        message.userId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveCampaignMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.RemoveCampaignMemberRequest} RemoveCampaignMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCampaignMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveCampaignMemberRequest message.
         * @function verify
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveCampaignMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            return null;
        };

        /**
         * Creates a RemoveCampaignMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.RemoveCampaignMemberRequest} RemoveCampaignMemberRequest
         */
        RemoveCampaignMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.RemoveCampaignMemberRequest)
                return object;
            let message = new $root.maps.RemoveCampaignMemberRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.userId != null)
                message.userId = String(object.userId);
            return message;
        };

        /**
         * Creates a plain object from a RemoveCampaignMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {maps.RemoveCampaignMemberRequest} message RemoveCampaignMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveCampaignMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.campaignId = "";
                object.userId = "";
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            return object;
        };

        /**
         * Converts this RemoveCampaignMemberRequest to JSON.
         * @function toJSON
         * @memberof maps.RemoveCampaignMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveCampaignMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveCampaignMemberRequest
         * @function getTypeUrl
         * @memberof maps.RemoveCampaignMemberRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveCampaignMemberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.RemoveCampaignMemberRequest";
        };

        return RemoveCampaignMemberRequest;
    })();

    maps.RemoveCampaignMemberResponse = (function() {

        /**
         * Properties of a RemoveCampaignMemberResponse.
         * @memberof maps
         * @interface IRemoveCampaignMemberResponse
         */

        /**
         * Constructs a new RemoveCampaignMemberResponse.
         * @memberof maps
         * @classdesc Represents a RemoveCampaignMemberResponse.
         * @implements IRemoveCampaignMemberResponse
         * @constructor
         * @param {maps.IRemoveCampaignMemberResponse=} [properties] Properties to set
         */
        function RemoveCampaignMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RemoveCampaignMemberResponse instance using the specified properties.
         * @function create
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {maps.IRemoveCampaignMemberResponse=} [properties] Properties to set
         * @returns {maps.RemoveCampaignMemberResponse} RemoveCampaignMemberResponse instance
         */
        RemoveCampaignMemberResponse.create = function create(properties) {
            return new RemoveCampaignMemberResponse(properties);
        };

        /**
         * Encodes the specified RemoveCampaignMemberResponse message. Does not implicitly {@link maps.RemoveCampaignMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {maps.IRemoveCampaignMemberResponse} message RemoveCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCampaignMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RemoveCampaignMemberResponse message, length delimited. Does not implicitly {@link maps.RemoveCampaignMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {maps.IRemoveCampaignMemberResponse} message RemoveCampaignMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveCampaignMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveCampaignMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.RemoveCampaignMemberResponse} RemoveCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCampaignMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.RemoveCampaignMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveCampaignMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.RemoveCampaignMemberResponse} RemoveCampaignMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveCampaignMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveCampaignMemberResponse message.
         * @function verify
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveCampaignMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RemoveCampaignMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.RemoveCampaignMemberResponse} RemoveCampaignMemberResponse
         */
        RemoveCampaignMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.RemoveCampaignMemberResponse)
                return object;
            return new $root.maps.RemoveCampaignMemberResponse();
        };

        /**
         * Creates a plain object from a RemoveCampaignMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {maps.RemoveCampaignMemberResponse} message RemoveCampaignMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveCampaignMemberResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RemoveCampaignMemberResponse to JSON.
         * @function toJSON
         * @memberof maps.RemoveCampaignMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveCampaignMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RemoveCampaignMemberResponse
         * @function getTypeUrl
         * @memberof maps.RemoveCampaignMemberResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RemoveCampaignMemberResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.RemoveCampaignMemberResponse";
        };

        return RemoveCampaignMemberResponse;
    })();

    maps.MapObjectSearchRequest = (function() {

        /**
         * Properties of a MapObjectSearchRequest.
         * @memberof maps
         * @interface IMapObjectSearchRequest
         * @property {string|null} [campaignId] MapObjectSearchRequest campaignId
         * @property {google.protobuf.IStringValue|null} [mapZoneId] MapObjectSearchRequest mapZoneId
         * @property {Array.<string>|null} [objectTypes] MapObjectSearchRequest objectTypes
         * @property {boolean|null} [canWrite] MapObjectSearchRequest canWrite
         * @property {boolean|null} [canChangeLocation] MapObjectSearchRequest canChangeLocation
         * @property {boolean|null} [all] MapObjectSearchRequest all
         * @property {string|null} [searchTerm] MapObjectSearchRequest searchTerm
         * @property {string|null} [mapObjectId] MapObjectSearchRequest mapObjectId
         * @property {number|null} [offset] MapObjectSearchRequest offset
         * @property {number|null} [limit] MapObjectSearchRequest limit
         * @property {string|null} [sorting] MapObjectSearchRequest sorting
         */

        /**
         * Constructs a new MapObjectSearchRequest.
         * @memberof maps
         * @classdesc Represents a MapObjectSearchRequest.
         * @implements IMapObjectSearchRequest
         * @constructor
         * @param {maps.IMapObjectSearchRequest=} [properties] Properties to set
         */
        function MapObjectSearchRequest(properties) {
            this.objectTypes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapObjectSearchRequest campaignId.
         * @member {string} campaignId
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.campaignId = "";

        /**
         * MapObjectSearchRequest mapZoneId.
         * @member {google.protobuf.IStringValue|null|undefined} mapZoneId
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.mapZoneId = null;

        /**
         * MapObjectSearchRequest objectTypes.
         * @member {Array.<string>} objectTypes
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.objectTypes = $util.emptyArray;

        /**
         * MapObjectSearchRequest canWrite.
         * @member {boolean} canWrite
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.canWrite = false;

        /**
         * MapObjectSearchRequest canChangeLocation.
         * @member {boolean} canChangeLocation
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.canChangeLocation = false;

        /**
         * MapObjectSearchRequest all.
         * @member {boolean|null|undefined} all
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.all = null;

        /**
         * MapObjectSearchRequest searchTerm.
         * @member {string|null|undefined} searchTerm
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.searchTerm = null;

        /**
         * MapObjectSearchRequest mapObjectId.
         * @member {string|null|undefined} mapObjectId
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.mapObjectId = null;

        /**
         * MapObjectSearchRequest offset.
         * @member {number} offset
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.offset = 0;

        /**
         * MapObjectSearchRequest limit.
         * @member {number} limit
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.limit = 0;

        /**
         * MapObjectSearchRequest sorting.
         * @member {string} sorting
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        MapObjectSearchRequest.prototype.sorting = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MapObjectSearchRequest query.
         * @member {"all"|"searchTerm"|"mapObjectId"|undefined} query
         * @memberof maps.MapObjectSearchRequest
         * @instance
         */
        Object.defineProperty(MapObjectSearchRequest.prototype, "query", {
            get: $util.oneOfGetter($oneOfFields = ["all", "searchTerm", "mapObjectId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MapObjectSearchRequest instance using the specified properties.
         * @function create
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {maps.IMapObjectSearchRequest=} [properties] Properties to set
         * @returns {maps.MapObjectSearchRequest} MapObjectSearchRequest instance
         */
        MapObjectSearchRequest.create = function create(properties) {
            return new MapObjectSearchRequest(properties);
        };

        /**
         * Encodes the specified MapObjectSearchRequest message. Does not implicitly {@link maps.MapObjectSearchRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {maps.IMapObjectSearchRequest} message MapObjectSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectSearchRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                $root.google.protobuf.StringValue.encode(message.mapZoneId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.objectTypes != null && message.objectTypes.length)
                for (let i = 0; i < message.objectTypes.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.objectTypes[i]);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.canWrite);
            if (message.canChangeLocation != null && Object.hasOwnProperty.call(message, "canChangeLocation"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.canChangeLocation);
            if (message.all != null && Object.hasOwnProperty.call(message, "all"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.all);
            if (message.searchTerm != null && Object.hasOwnProperty.call(message, "searchTerm"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.searchTerm);
            if (message.mapObjectId != null && Object.hasOwnProperty.call(message, "mapObjectId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.mapObjectId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.offset);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 22, wireType 0 =*/176).int32(message.limit);
            if (message.sorting != null && Object.hasOwnProperty.call(message, "sorting"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.sorting);
            return writer;
        };

        /**
         * Encodes the specified MapObjectSearchRequest message, length delimited. Does not implicitly {@link maps.MapObjectSearchRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {maps.IMapObjectSearchRequest} message MapObjectSearchRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectSearchRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapObjectSearchRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapObjectSearchRequest} MapObjectSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectSearchRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObjectSearchRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 2: {
                        message.mapZoneId = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.objectTypes && message.objectTypes.length))
                            message.objectTypes = [];
                        message.objectTypes.push(reader.string());
                        break;
                    }
                case 4: {
                        message.canWrite = reader.bool();
                        break;
                    }
                case 5: {
                        message.canChangeLocation = reader.bool();
                        break;
                    }
                case 11: {
                        message.all = reader.bool();
                        break;
                    }
                case 12: {
                        message.searchTerm = reader.string();
                        break;
                    }
                case 13: {
                        message.mapObjectId = reader.string();
                        break;
                    }
                case 21: {
                        message.offset = reader.int32();
                        break;
                    }
                case 22: {
                        message.limit = reader.int32();
                        break;
                    }
                case 23: {
                        message.sorting = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapObjectSearchRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapObjectSearchRequest} MapObjectSearchRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectSearchRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapObjectSearchRequest message.
         * @function verify
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapObjectSearchRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId")) {
                let error = $root.google.protobuf.StringValue.verify(message.mapZoneId);
                if (error)
                    return "mapZoneId." + error;
            }
            if (message.objectTypes != null && message.hasOwnProperty("objectTypes")) {
                if (!Array.isArray(message.objectTypes))
                    return "objectTypes: array expected";
                for (let i = 0; i < message.objectTypes.length; ++i)
                    if (!$util.isString(message.objectTypes[i]))
                        return "objectTypes: string[] expected";
            }
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            if (message.canChangeLocation != null && message.hasOwnProperty("canChangeLocation"))
                if (typeof message.canChangeLocation !== "boolean")
                    return "canChangeLocation: boolean expected";
            if (message.all != null && message.hasOwnProperty("all")) {
                properties.query = 1;
                if (typeof message.all !== "boolean")
                    return "all: boolean expected";
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm")) {
                if (properties.query === 1)
                    return "query: multiple values";
                properties.query = 1;
                if (!$util.isString(message.searchTerm))
                    return "searchTerm: string expected";
            }
            if (message.mapObjectId != null && message.hasOwnProperty("mapObjectId")) {
                if (properties.query === 1)
                    return "query: multiple values";
                properties.query = 1;
                if (!$util.isString(message.mapObjectId))
                    return "mapObjectId: string expected";
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.sorting != null && message.hasOwnProperty("sorting"))
                if (!$util.isString(message.sorting))
                    return "sorting: string expected";
            return null;
        };

        /**
         * Creates a MapObjectSearchRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapObjectSearchRequest} MapObjectSearchRequest
         */
        MapObjectSearchRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapObjectSearchRequest)
                return object;
            let message = new $root.maps.MapObjectSearchRequest();
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.mapZoneId != null) {
                if (typeof object.mapZoneId !== "object")
                    throw TypeError(".maps.MapObjectSearchRequest.mapZoneId: object expected");
                message.mapZoneId = $root.google.protobuf.StringValue.fromObject(object.mapZoneId);
            }
            if (object.objectTypes) {
                if (!Array.isArray(object.objectTypes))
                    throw TypeError(".maps.MapObjectSearchRequest.objectTypes: array expected");
                message.objectTypes = [];
                for (let i = 0; i < object.objectTypes.length; ++i)
                    message.objectTypes[i] = String(object.objectTypes[i]);
            }
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            if (object.canChangeLocation != null)
                message.canChangeLocation = Boolean(object.canChangeLocation);
            if (object.all != null)
                message.all = Boolean(object.all);
            if (object.searchTerm != null)
                message.searchTerm = String(object.searchTerm);
            if (object.mapObjectId != null)
                message.mapObjectId = String(object.mapObjectId);
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.sorting != null)
                message.sorting = String(object.sorting);
            return message;
        };

        /**
         * Creates a plain object from a MapObjectSearchRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {maps.MapObjectSearchRequest} message MapObjectSearchRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapObjectSearchRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.objectTypes = [];
            if (options.defaults) {
                object.campaignId = "";
                object.mapZoneId = null;
                object.canWrite = false;
                object.canChangeLocation = false;
                object.offset = 0;
                object.limit = 0;
                object.sorting = "";
            }
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = $root.google.protobuf.StringValue.toObject(message.mapZoneId, options);
            if (message.objectTypes && message.objectTypes.length) {
                object.objectTypes = [];
                for (let j = 0; j < message.objectTypes.length; ++j)
                    object.objectTypes[j] = message.objectTypes[j];
            }
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            if (message.canChangeLocation != null && message.hasOwnProperty("canChangeLocation"))
                object.canChangeLocation = message.canChangeLocation;
            if (message.all != null && message.hasOwnProperty("all")) {
                object.all = message.all;
                if (options.oneofs)
                    object.query = "all";
            }
            if (message.searchTerm != null && message.hasOwnProperty("searchTerm")) {
                object.searchTerm = message.searchTerm;
                if (options.oneofs)
                    object.query = "searchTerm";
            }
            if (message.mapObjectId != null && message.hasOwnProperty("mapObjectId")) {
                object.mapObjectId = message.mapObjectId;
                if (options.oneofs)
                    object.query = "mapObjectId";
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.sorting != null && message.hasOwnProperty("sorting"))
                object.sorting = message.sorting;
            return object;
        };

        /**
         * Converts this MapObjectSearchRequest to JSON.
         * @function toJSON
         * @memberof maps.MapObjectSearchRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapObjectSearchRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapObjectSearchRequest
         * @function getTypeUrl
         * @memberof maps.MapObjectSearchRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapObjectSearchRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapObjectSearchRequest";
        };

        return MapObjectSearchRequest;
    })();

    maps.MapObjectSearchResponse = (function() {

        /**
         * Properties of a MapObjectSearchResponse.
         * @memberof maps
         * @interface IMapObjectSearchResponse
         * @property {Array.<maps.IMapObject>|null} [mapObjects] MapObjectSearchResponse mapObjects
         */

        /**
         * Constructs a new MapObjectSearchResponse.
         * @memberof maps
         * @classdesc Represents a MapObjectSearchResponse.
         * @implements IMapObjectSearchResponse
         * @constructor
         * @param {maps.IMapObjectSearchResponse=} [properties] Properties to set
         */
        function MapObjectSearchResponse(properties) {
            this.mapObjects = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapObjectSearchResponse mapObjects.
         * @member {Array.<maps.IMapObject>} mapObjects
         * @memberof maps.MapObjectSearchResponse
         * @instance
         */
        MapObjectSearchResponse.prototype.mapObjects = $util.emptyArray;

        /**
         * Creates a new MapObjectSearchResponse instance using the specified properties.
         * @function create
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {maps.IMapObjectSearchResponse=} [properties] Properties to set
         * @returns {maps.MapObjectSearchResponse} MapObjectSearchResponse instance
         */
        MapObjectSearchResponse.create = function create(properties) {
            return new MapObjectSearchResponse(properties);
        };

        /**
         * Encodes the specified MapObjectSearchResponse message. Does not implicitly {@link maps.MapObjectSearchResponse.verify|verify} messages.
         * @function encode
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {maps.IMapObjectSearchResponse} message MapObjectSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectSearchResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mapObjects != null && message.mapObjects.length)
                for (let i = 0; i < message.mapObjects.length; ++i)
                    $root.maps.MapObject.encode(message.mapObjects[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapObjectSearchResponse message, length delimited. Does not implicitly {@link maps.MapObjectSearchResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {maps.IMapObjectSearchResponse} message MapObjectSearchResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectSearchResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapObjectSearchResponse message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapObjectSearchResponse} MapObjectSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectSearchResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObjectSearchResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.mapObjects && message.mapObjects.length))
                            message.mapObjects = [];
                        message.mapObjects.push($root.maps.MapObject.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapObjectSearchResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapObjectSearchResponse} MapObjectSearchResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectSearchResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapObjectSearchResponse message.
         * @function verify
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapObjectSearchResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mapObjects != null && message.hasOwnProperty("mapObjects")) {
                if (!Array.isArray(message.mapObjects))
                    return "mapObjects: array expected";
                for (let i = 0; i < message.mapObjects.length; ++i) {
                    let error = $root.maps.MapObject.verify(message.mapObjects[i]);
                    if (error)
                        return "mapObjects." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MapObjectSearchResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapObjectSearchResponse} MapObjectSearchResponse
         */
        MapObjectSearchResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapObjectSearchResponse)
                return object;
            let message = new $root.maps.MapObjectSearchResponse();
            if (object.mapObjects) {
                if (!Array.isArray(object.mapObjects))
                    throw TypeError(".maps.MapObjectSearchResponse.mapObjects: array expected");
                message.mapObjects = [];
                for (let i = 0; i < object.mapObjects.length; ++i) {
                    if (typeof object.mapObjects[i] !== "object")
                        throw TypeError(".maps.MapObjectSearchResponse.mapObjects: object expected");
                    message.mapObjects[i] = $root.maps.MapObject.fromObject(object.mapObjects[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MapObjectSearchResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {maps.MapObjectSearchResponse} message MapObjectSearchResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapObjectSearchResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.mapObjects = [];
            if (message.mapObjects && message.mapObjects.length) {
                object.mapObjects = [];
                for (let j = 0; j < message.mapObjects.length; ++j)
                    object.mapObjects[j] = $root.maps.MapObject.toObject(message.mapObjects[j], options);
            }
            return object;
        };

        /**
         * Converts this MapObjectSearchResponse to JSON.
         * @function toJSON
         * @memberof maps.MapObjectSearchResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapObjectSearchResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapObjectSearchResponse
         * @function getTypeUrl
         * @memberof maps.MapObjectSearchResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapObjectSearchResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapObjectSearchResponse";
        };

        return MapObjectSearchResponse;
    })();

    maps.WebSocketClientRequest = (function() {

        /**
         * Properties of a WebSocketClientRequest.
         * @memberof maps
         * @interface IWebSocketClientRequest
         * @property {string|null} [id] WebSocketClientRequest id
         */

        /**
         * Constructs a new WebSocketClientRequest.
         * @memberof maps
         * @classdesc Represents a WebSocketClientRequest.
         * @implements IWebSocketClientRequest
         * @constructor
         * @param {maps.IWebSocketClientRequest=} [properties] Properties to set
         */
        function WebSocketClientRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebSocketClientRequest id.
         * @member {string} id
         * @memberof maps.WebSocketClientRequest
         * @instance
         */
        WebSocketClientRequest.prototype.id = "";

        /**
         * Creates a new WebSocketClientRequest instance using the specified properties.
         * @function create
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {maps.IWebSocketClientRequest=} [properties] Properties to set
         * @returns {maps.WebSocketClientRequest} WebSocketClientRequest instance
         */
        WebSocketClientRequest.create = function create(properties) {
            return new WebSocketClientRequest(properties);
        };

        /**
         * Encodes the specified WebSocketClientRequest message. Does not implicitly {@link maps.WebSocketClientRequest.verify|verify} messages.
         * @function encode
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {maps.IWebSocketClientRequest} message WebSocketClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebSocketClientRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified WebSocketClientRequest message, length delimited. Does not implicitly {@link maps.WebSocketClientRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {maps.IWebSocketClientRequest} message WebSocketClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebSocketClientRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebSocketClientRequest message from the specified reader or buffer.
         * @function decode
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.WebSocketClientRequest} WebSocketClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebSocketClientRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.WebSocketClientRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WebSocketClientRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.WebSocketClientRequest} WebSocketClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebSocketClientRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebSocketClientRequest message.
         * @function verify
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebSocketClientRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a WebSocketClientRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.WebSocketClientRequest} WebSocketClientRequest
         */
        WebSocketClientRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.WebSocketClientRequest)
                return object;
            let message = new $root.maps.WebSocketClientRequest();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a WebSocketClientRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {maps.WebSocketClientRequest} message WebSocketClientRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebSocketClientRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this WebSocketClientRequest to JSON.
         * @function toJSON
         * @memberof maps.WebSocketClientRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebSocketClientRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebSocketClientRequest
         * @function getTypeUrl
         * @memberof maps.WebSocketClientRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebSocketClientRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.WebSocketClientRequest";
        };

        return WebSocketClientRequest;
    })();

    maps.WebSocketServerData = (function() {

        /**
         * Properties of a WebSocketServerData.
         * @memberof maps
         * @interface IWebSocketServerData
         * @property {string|null} [id] WebSocketServerData id
         * @property {common.IEmptySuccess|null} [successResponse] WebSocketServerData successResponse
         * @property {common.IError|null} [errorResponse] WebSocketServerData errorResponse
         */

        /**
         * Constructs a new WebSocketServerData.
         * @memberof maps
         * @classdesc Represents a WebSocketServerData.
         * @implements IWebSocketServerData
         * @constructor
         * @param {maps.IWebSocketServerData=} [properties] Properties to set
         */
        function WebSocketServerData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebSocketServerData id.
         * @member {string} id
         * @memberof maps.WebSocketServerData
         * @instance
         */
        WebSocketServerData.prototype.id = "";

        /**
         * WebSocketServerData successResponse.
         * @member {common.IEmptySuccess|null|undefined} successResponse
         * @memberof maps.WebSocketServerData
         * @instance
         */
        WebSocketServerData.prototype.successResponse = null;

        /**
         * WebSocketServerData errorResponse.
         * @member {common.IError|null|undefined} errorResponse
         * @memberof maps.WebSocketServerData
         * @instance
         */
        WebSocketServerData.prototype.errorResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * WebSocketServerData data.
         * @member {"successResponse"|"errorResponse"|undefined} data
         * @memberof maps.WebSocketServerData
         * @instance
         */
        Object.defineProperty(WebSocketServerData.prototype, "data", {
            get: $util.oneOfGetter($oneOfFields = ["successResponse", "errorResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WebSocketServerData instance using the specified properties.
         * @function create
         * @memberof maps.WebSocketServerData
         * @static
         * @param {maps.IWebSocketServerData=} [properties] Properties to set
         * @returns {maps.WebSocketServerData} WebSocketServerData instance
         */
        WebSocketServerData.create = function create(properties) {
            return new WebSocketServerData(properties);
        };

        /**
         * Encodes the specified WebSocketServerData message. Does not implicitly {@link maps.WebSocketServerData.verify|verify} messages.
         * @function encode
         * @memberof maps.WebSocketServerData
         * @static
         * @param {maps.IWebSocketServerData} message WebSocketServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebSocketServerData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.successResponse != null && Object.hasOwnProperty.call(message, "successResponse"))
                $root.common.EmptySuccess.encode(message.successResponse, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.errorResponse != null && Object.hasOwnProperty.call(message, "errorResponse"))
                $root.common.Error.encode(message.errorResponse, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WebSocketServerData message, length delimited. Does not implicitly {@link maps.WebSocketServerData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.WebSocketServerData
         * @static
         * @param {maps.IWebSocketServerData} message WebSocketServerData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WebSocketServerData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WebSocketServerData message from the specified reader or buffer.
         * @function decode
         * @memberof maps.WebSocketServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.WebSocketServerData} WebSocketServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebSocketServerData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.WebSocketServerData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.successResponse = $root.common.EmptySuccess.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.errorResponse = $root.common.Error.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WebSocketServerData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.WebSocketServerData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.WebSocketServerData} WebSocketServerData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WebSocketServerData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WebSocketServerData message.
         * @function verify
         * @memberof maps.WebSocketServerData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WebSocketServerData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.successResponse != null && message.hasOwnProperty("successResponse")) {
                properties.data = 1;
                {
                    let error = $root.common.EmptySuccess.verify(message.successResponse);
                    if (error)
                        return "successResponse." + error;
                }
            }
            if (message.errorResponse != null && message.hasOwnProperty("errorResponse")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.common.Error.verify(message.errorResponse);
                    if (error)
                        return "errorResponse." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WebSocketServerData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.WebSocketServerData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.WebSocketServerData} WebSocketServerData
         */
        WebSocketServerData.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.WebSocketServerData)
                return object;
            let message = new $root.maps.WebSocketServerData();
            if (object.id != null)
                message.id = String(object.id);
            if (object.successResponse != null) {
                if (typeof object.successResponse !== "object")
                    throw TypeError(".maps.WebSocketServerData.successResponse: object expected");
                message.successResponse = $root.common.EmptySuccess.fromObject(object.successResponse);
            }
            if (object.errorResponse != null) {
                if (typeof object.errorResponse !== "object")
                    throw TypeError(".maps.WebSocketServerData.errorResponse: object expected");
                message.errorResponse = $root.common.Error.fromObject(object.errorResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from a WebSocketServerData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.WebSocketServerData
         * @static
         * @param {maps.WebSocketServerData} message WebSocketServerData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WebSocketServerData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.successResponse != null && message.hasOwnProperty("successResponse")) {
                object.successResponse = $root.common.EmptySuccess.toObject(message.successResponse, options);
                if (options.oneofs)
                    object.data = "successResponse";
            }
            if (message.errorResponse != null && message.hasOwnProperty("errorResponse")) {
                object.errorResponse = $root.common.Error.toObject(message.errorResponse, options);
                if (options.oneofs)
                    object.data = "errorResponse";
            }
            return object;
        };

        /**
         * Converts this WebSocketServerData to JSON.
         * @function toJSON
         * @memberof maps.WebSocketServerData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WebSocketServerData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for WebSocketServerData
         * @function getTypeUrl
         * @memberof maps.WebSocketServerData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebSocketServerData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.WebSocketServerData";
        };

        return WebSocketServerData;
    })();

    /**
     * CampaignDataset enum.
     * @name maps.CampaignDataset
     * @enum {number}
     * @property {number} UNKNOWN_CAMPAIGN_DATASET=0 UNKNOWN_CAMPAIGN_DATASET value
     * @property {number} BASIC_CAMPAIGN_DATA=1 BASIC_CAMPAIGN_DATA value
     * @property {number} CAMPAIGN_MEMBERS_DATA=2 CAMPAIGN_MEMBERS_DATA value
     * @property {number} CAMPAIGN_MAPS_DATA=3 CAMPAIGN_MAPS_DATA value
     */
    maps.CampaignDataset = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CAMPAIGN_DATASET"] = 0;
        values[valuesById[1] = "BASIC_CAMPAIGN_DATA"] = 1;
        values[valuesById[2] = "CAMPAIGN_MEMBERS_DATA"] = 2;
        values[valuesById[3] = "CAMPAIGN_MAPS_DATA"] = 3;
        return values;
    })();

    maps.CampaignPermissions = (function() {

        /**
         * Properties of a CampaignPermissions.
         * @memberof maps
         * @interface ICampaignPermissions
         * @property {boolean|null} [canRead] CampaignPermissions canRead
         * @property {boolean|null} [canWrite] CampaignPermissions canWrite
         * @property {boolean|null} [canDelete] CampaignPermissions canDelete
         * @property {boolean|null} [canChangeActiveStatus] CampaignPermissions canChangeActiveStatus
         * @property {boolean|null} [canChangeOwner] CampaignPermissions canChangeOwner
         * @property {boolean|null} [canReadMembers] CampaignPermissions canReadMembers
         * @property {boolean|null} [canCreateMembers] CampaignPermissions canCreateMembers
         * @property {boolean|null} [canDeleteMembers] CampaignPermissions canDeleteMembers
         * @property {boolean|null} [canImpersonateMembers] CampaignPermissions canImpersonateMembers
         * @property {boolean|null} [canCreateTokens] CampaignPermissions canCreateTokens
         * @property {common.IFieldPermissions|null} [publicFieldAccess] CampaignPermissions publicFieldAccess
         * @property {common.IFieldPermissions|null} [protectedFieldAccess] CampaignPermissions protectedFieldAccess
         * @property {common.IFieldPermissions|null} [secretFieldAccess] CampaignPermissions secretFieldAccess
         */

        /**
         * Constructs a new CampaignPermissions.
         * @memberof maps
         * @classdesc Represents a CampaignPermissions.
         * @implements ICampaignPermissions
         * @constructor
         * @param {maps.ICampaignPermissions=} [properties] Properties to set
         */
        function CampaignPermissions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CampaignPermissions canRead.
         * @member {boolean} canRead
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canRead = false;

        /**
         * CampaignPermissions canWrite.
         * @member {boolean} canWrite
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canWrite = false;

        /**
         * CampaignPermissions canDelete.
         * @member {boolean} canDelete
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canDelete = false;

        /**
         * CampaignPermissions canChangeActiveStatus.
         * @member {boolean} canChangeActiveStatus
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canChangeActiveStatus = false;

        /**
         * CampaignPermissions canChangeOwner.
         * @member {boolean} canChangeOwner
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canChangeOwner = false;

        /**
         * CampaignPermissions canReadMembers.
         * @member {boolean} canReadMembers
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canReadMembers = false;

        /**
         * CampaignPermissions canCreateMembers.
         * @member {boolean} canCreateMembers
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canCreateMembers = false;

        /**
         * CampaignPermissions canDeleteMembers.
         * @member {boolean} canDeleteMembers
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canDeleteMembers = false;

        /**
         * CampaignPermissions canImpersonateMembers.
         * @member {boolean} canImpersonateMembers
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canImpersonateMembers = false;

        /**
         * CampaignPermissions canCreateTokens.
         * @member {boolean} canCreateTokens
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.canCreateTokens = false;

        /**
         * CampaignPermissions publicFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} publicFieldAccess
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.publicFieldAccess = null;

        /**
         * CampaignPermissions protectedFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} protectedFieldAccess
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.protectedFieldAccess = null;

        /**
         * CampaignPermissions secretFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} secretFieldAccess
         * @memberof maps.CampaignPermissions
         * @instance
         */
        CampaignPermissions.prototype.secretFieldAccess = null;

        /**
         * Creates a new CampaignPermissions instance using the specified properties.
         * @function create
         * @memberof maps.CampaignPermissions
         * @static
         * @param {maps.ICampaignPermissions=} [properties] Properties to set
         * @returns {maps.CampaignPermissions} CampaignPermissions instance
         */
        CampaignPermissions.create = function create(properties) {
            return new CampaignPermissions(properties);
        };

        /**
         * Encodes the specified CampaignPermissions message. Does not implicitly {@link maps.CampaignPermissions.verify|verify} messages.
         * @function encode
         * @memberof maps.CampaignPermissions
         * @static
         * @param {maps.ICampaignPermissions} message CampaignPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignPermissions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canRead != null && Object.hasOwnProperty.call(message, "canRead"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canRead);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canWrite);
            if (message.canDelete != null && Object.hasOwnProperty.call(message, "canDelete"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.canDelete);
            if (message.canChangeActiveStatus != null && Object.hasOwnProperty.call(message, "canChangeActiveStatus"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canChangeActiveStatus);
            if (message.canChangeOwner != null && Object.hasOwnProperty.call(message, "canChangeOwner"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.canChangeOwner);
            if (message.canReadMembers != null && Object.hasOwnProperty.call(message, "canReadMembers"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.canReadMembers);
            if (message.canCreateMembers != null && Object.hasOwnProperty.call(message, "canCreateMembers"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.canCreateMembers);
            if (message.canDeleteMembers != null && Object.hasOwnProperty.call(message, "canDeleteMembers"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.canDeleteMembers);
            if (message.canImpersonateMembers != null && Object.hasOwnProperty.call(message, "canImpersonateMembers"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.canImpersonateMembers);
            if (message.canCreateTokens != null && Object.hasOwnProperty.call(message, "canCreateTokens"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.canCreateTokens);
            if (message.publicFieldAccess != null && Object.hasOwnProperty.call(message, "publicFieldAccess"))
                $root.common.FieldPermissions.encode(message.publicFieldAccess, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.protectedFieldAccess != null && Object.hasOwnProperty.call(message, "protectedFieldAccess"))
                $root.common.FieldPermissions.encode(message.protectedFieldAccess, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.secretFieldAccess != null && Object.hasOwnProperty.call(message, "secretFieldAccess"))
                $root.common.FieldPermissions.encode(message.secretFieldAccess, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CampaignPermissions message, length delimited. Does not implicitly {@link maps.CampaignPermissions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.CampaignPermissions
         * @static
         * @param {maps.ICampaignPermissions} message CampaignPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignPermissions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CampaignPermissions message from the specified reader or buffer.
         * @function decode
         * @memberof maps.CampaignPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.CampaignPermissions} CampaignPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignPermissions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignPermissions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.canRead = reader.bool();
                        break;
                    }
                case 2: {
                        message.canWrite = reader.bool();
                        break;
                    }
                case 3: {
                        message.canDelete = reader.bool();
                        break;
                    }
                case 10: {
                        message.canChangeActiveStatus = reader.bool();
                        break;
                    }
                case 11: {
                        message.canChangeOwner = reader.bool();
                        break;
                    }
                case 12: {
                        message.canReadMembers = reader.bool();
                        break;
                    }
                case 13: {
                        message.canCreateMembers = reader.bool();
                        break;
                    }
                case 14: {
                        message.canDeleteMembers = reader.bool();
                        break;
                    }
                case 15: {
                        message.canImpersonateMembers = reader.bool();
                        break;
                    }
                case 16: {
                        message.canCreateTokens = reader.bool();
                        break;
                    }
                case 50: {
                        message.publicFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.protectedFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 52: {
                        message.secretFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CampaignPermissions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.CampaignPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.CampaignPermissions} CampaignPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignPermissions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CampaignPermissions message.
         * @function verify
         * @memberof maps.CampaignPermissions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CampaignPermissions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                if (typeof message.canRead !== "boolean")
                    return "canRead: boolean expected";
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                if (typeof message.canDelete !== "boolean")
                    return "canDelete: boolean expected";
            if (message.canChangeActiveStatus != null && message.hasOwnProperty("canChangeActiveStatus"))
                if (typeof message.canChangeActiveStatus !== "boolean")
                    return "canChangeActiveStatus: boolean expected";
            if (message.canChangeOwner != null && message.hasOwnProperty("canChangeOwner"))
                if (typeof message.canChangeOwner !== "boolean")
                    return "canChangeOwner: boolean expected";
            if (message.canReadMembers != null && message.hasOwnProperty("canReadMembers"))
                if (typeof message.canReadMembers !== "boolean")
                    return "canReadMembers: boolean expected";
            if (message.canCreateMembers != null && message.hasOwnProperty("canCreateMembers"))
                if (typeof message.canCreateMembers !== "boolean")
                    return "canCreateMembers: boolean expected";
            if (message.canDeleteMembers != null && message.hasOwnProperty("canDeleteMembers"))
                if (typeof message.canDeleteMembers !== "boolean")
                    return "canDeleteMembers: boolean expected";
            if (message.canImpersonateMembers != null && message.hasOwnProperty("canImpersonateMembers"))
                if (typeof message.canImpersonateMembers !== "boolean")
                    return "canImpersonateMembers: boolean expected";
            if (message.canCreateTokens != null && message.hasOwnProperty("canCreateTokens"))
                if (typeof message.canCreateTokens !== "boolean")
                    return "canCreateTokens: boolean expected";
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.publicFieldAccess);
                if (error)
                    return "publicFieldAccess." + error;
            }
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.protectedFieldAccess);
                if (error)
                    return "protectedFieldAccess." + error;
            }
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.secretFieldAccess);
                if (error)
                    return "secretFieldAccess." + error;
            }
            return null;
        };

        /**
         * Creates a CampaignPermissions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.CampaignPermissions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.CampaignPermissions} CampaignPermissions
         */
        CampaignPermissions.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.CampaignPermissions)
                return object;
            let message = new $root.maps.CampaignPermissions();
            if (object.canRead != null)
                message.canRead = Boolean(object.canRead);
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            if (object.canDelete != null)
                message.canDelete = Boolean(object.canDelete);
            if (object.canChangeActiveStatus != null)
                message.canChangeActiveStatus = Boolean(object.canChangeActiveStatus);
            if (object.canChangeOwner != null)
                message.canChangeOwner = Boolean(object.canChangeOwner);
            if (object.canReadMembers != null)
                message.canReadMembers = Boolean(object.canReadMembers);
            if (object.canCreateMembers != null)
                message.canCreateMembers = Boolean(object.canCreateMembers);
            if (object.canDeleteMembers != null)
                message.canDeleteMembers = Boolean(object.canDeleteMembers);
            if (object.canImpersonateMembers != null)
                message.canImpersonateMembers = Boolean(object.canImpersonateMembers);
            if (object.canCreateTokens != null)
                message.canCreateTokens = Boolean(object.canCreateTokens);
            if (object.publicFieldAccess != null) {
                if (typeof object.publicFieldAccess !== "object")
                    throw TypeError(".maps.CampaignPermissions.publicFieldAccess: object expected");
                message.publicFieldAccess = $root.common.FieldPermissions.fromObject(object.publicFieldAccess);
            }
            if (object.protectedFieldAccess != null) {
                if (typeof object.protectedFieldAccess !== "object")
                    throw TypeError(".maps.CampaignPermissions.protectedFieldAccess: object expected");
                message.protectedFieldAccess = $root.common.FieldPermissions.fromObject(object.protectedFieldAccess);
            }
            if (object.secretFieldAccess != null) {
                if (typeof object.secretFieldAccess !== "object")
                    throw TypeError(".maps.CampaignPermissions.secretFieldAccess: object expected");
                message.secretFieldAccess = $root.common.FieldPermissions.fromObject(object.secretFieldAccess);
            }
            return message;
        };

        /**
         * Creates a plain object from a CampaignPermissions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.CampaignPermissions
         * @static
         * @param {maps.CampaignPermissions} message CampaignPermissions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CampaignPermissions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.canRead = false;
                object.canWrite = false;
                object.canDelete = false;
                object.canChangeActiveStatus = false;
                object.canChangeOwner = false;
                object.canReadMembers = false;
                object.canCreateMembers = false;
                object.canDeleteMembers = false;
                object.canImpersonateMembers = false;
                object.canCreateTokens = false;
                object.publicFieldAccess = null;
                object.protectedFieldAccess = null;
                object.secretFieldAccess = null;
            }
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                object.canRead = message.canRead;
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                object.canDelete = message.canDelete;
            if (message.canChangeActiveStatus != null && message.hasOwnProperty("canChangeActiveStatus"))
                object.canChangeActiveStatus = message.canChangeActiveStatus;
            if (message.canChangeOwner != null && message.hasOwnProperty("canChangeOwner"))
                object.canChangeOwner = message.canChangeOwner;
            if (message.canReadMembers != null && message.hasOwnProperty("canReadMembers"))
                object.canReadMembers = message.canReadMembers;
            if (message.canCreateMembers != null && message.hasOwnProperty("canCreateMembers"))
                object.canCreateMembers = message.canCreateMembers;
            if (message.canDeleteMembers != null && message.hasOwnProperty("canDeleteMembers"))
                object.canDeleteMembers = message.canDeleteMembers;
            if (message.canImpersonateMembers != null && message.hasOwnProperty("canImpersonateMembers"))
                object.canImpersonateMembers = message.canImpersonateMembers;
            if (message.canCreateTokens != null && message.hasOwnProperty("canCreateTokens"))
                object.canCreateTokens = message.canCreateTokens;
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess"))
                object.publicFieldAccess = $root.common.FieldPermissions.toObject(message.publicFieldAccess, options);
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess"))
                object.protectedFieldAccess = $root.common.FieldPermissions.toObject(message.protectedFieldAccess, options);
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess"))
                object.secretFieldAccess = $root.common.FieldPermissions.toObject(message.secretFieldAccess, options);
            return object;
        };

        /**
         * Converts this CampaignPermissions to JSON.
         * @function toJSON
         * @memberof maps.CampaignPermissions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CampaignPermissions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CampaignPermissions
         * @function getTypeUrl
         * @memberof maps.CampaignPermissions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CampaignPermissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.CampaignPermissions";
        };

        return CampaignPermissions;
    })();

    maps.Campaign = (function() {

        /**
         * Properties of a Campaign.
         * @memberof maps
         * @interface ICampaign
         * @property {string|null} [id] Campaign id
         * @property {string|null} [name] Campaign name
         * @property {string|null} [ownerId] Campaign ownerId
         * @property {boolean|null} [active] Campaign active
         * @property {string|null} [description] Campaign description
         * @property {Array.<common.IUserRole>|null} [memberRoles] Campaign memberRoles
         * @property {common.Role|null} [role] Campaign role
         * @property {Array.<maps.IMap>|null} [maps] Campaign maps
         * @property {maps.ICampaignPermissions|null} [permissions] Campaign permissions
         */

        /**
         * Constructs a new Campaign.
         * @memberof maps
         * @classdesc Represents a Campaign.
         * @implements ICampaign
         * @constructor
         * @param {maps.ICampaign=} [properties] Properties to set
         */
        function Campaign(properties) {
            this.memberRoles = [];
            this.maps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Campaign id.
         * @member {string} id
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.id = "";

        /**
         * Campaign name.
         * @member {string} name
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.name = "";

        /**
         * Campaign ownerId.
         * @member {string} ownerId
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.ownerId = "";

        /**
         * Campaign active.
         * @member {boolean} active
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.active = false;

        /**
         * Campaign description.
         * @member {string} description
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.description = "";

        /**
         * Campaign memberRoles.
         * @member {Array.<common.IUserRole>} memberRoles
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.memberRoles = $util.emptyArray;

        /**
         * Campaign role.
         * @member {common.Role} role
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.role = 0;

        /**
         * Campaign maps.
         * @member {Array.<maps.IMap>} maps
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.maps = $util.emptyArray;

        /**
         * Campaign permissions.
         * @member {maps.ICampaignPermissions|null|undefined} permissions
         * @memberof maps.Campaign
         * @instance
         */
        Campaign.prototype.permissions = null;

        /**
         * Creates a new Campaign instance using the specified properties.
         * @function create
         * @memberof maps.Campaign
         * @static
         * @param {maps.ICampaign=} [properties] Properties to set
         * @returns {maps.Campaign} Campaign instance
         */
        Campaign.create = function create(properties) {
            return new Campaign(properties);
        };

        /**
         * Encodes the specified Campaign message. Does not implicitly {@link maps.Campaign.verify|verify} messages.
         * @function encode
         * @memberof maps.Campaign
         * @static
         * @param {maps.ICampaign} message Campaign message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Campaign.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ownerId);
            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.active);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.description);
            if (message.memberRoles != null && message.memberRoles.length)
                for (let i = 0; i < message.memberRoles.length; ++i)
                    $root.common.UserRole.encode(message.memberRoles[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 19, wireType 0 =*/152).int32(message.role);
            if (message.maps != null && message.maps.length)
                for (let i = 0; i < message.maps.length; ++i)
                    $root.maps.Map.encode(message.maps[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                $root.maps.CampaignPermissions.encode(message.permissions, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Campaign message, length delimited. Does not implicitly {@link maps.Campaign.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.Campaign
         * @static
         * @param {maps.ICampaign} message Campaign message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Campaign.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Campaign message from the specified reader or buffer.
         * @function decode
         * @memberof maps.Campaign
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.Campaign} Campaign
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Campaign.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.Campaign();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.ownerId = reader.string();
                        break;
                    }
                case 16: {
                        message.active = reader.bool();
                        break;
                    }
                case 17: {
                        message.description = reader.string();
                        break;
                    }
                case 18: {
                        if (!(message.memberRoles && message.memberRoles.length))
                            message.memberRoles = [];
                        message.memberRoles.push($root.common.UserRole.decode(reader, reader.uint32()));
                        break;
                    }
                case 19: {
                        message.role = reader.int32();
                        break;
                    }
                case 20: {
                        if (!(message.maps && message.maps.length))
                            message.maps = [];
                        message.maps.push($root.maps.Map.decode(reader, reader.uint32()));
                        break;
                    }
                case 21: {
                        message.permissions = $root.maps.CampaignPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Campaign message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.Campaign
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.Campaign} Campaign
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Campaign.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Campaign message.
         * @function verify
         * @memberof maps.Campaign
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Campaign.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                if (!$util.isString(message.ownerId))
                    return "ownerId: string expected";
            if (message.active != null && message.hasOwnProperty("active"))
                if (typeof message.active !== "boolean")
                    return "active: boolean expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.memberRoles != null && message.hasOwnProperty("memberRoles")) {
                if (!Array.isArray(message.memberRoles))
                    return "memberRoles: array expected";
                for (let i = 0; i < message.memberRoles.length; ++i) {
                    let error = $root.common.UserRole.verify(message.memberRoles[i]);
                    if (error)
                        return "memberRoles." + error;
                }
            }
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.maps != null && message.hasOwnProperty("maps")) {
                if (!Array.isArray(message.maps))
                    return "maps: array expected";
                for (let i = 0; i < message.maps.length; ++i) {
                    let error = $root.maps.Map.verify(message.maps[i]);
                    if (error)
                        return "maps." + error;
                }
            }
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                let error = $root.maps.CampaignPermissions.verify(message.permissions);
                if (error)
                    return "permissions." + error;
            }
            return null;
        };

        /**
         * Creates a Campaign message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.Campaign
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.Campaign} Campaign
         */
        Campaign.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.Campaign)
                return object;
            let message = new $root.maps.Campaign();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownerId != null)
                message.ownerId = String(object.ownerId);
            if (object.active != null)
                message.active = Boolean(object.active);
            if (object.description != null)
                message.description = String(object.description);
            if (object.memberRoles) {
                if (!Array.isArray(object.memberRoles))
                    throw TypeError(".maps.Campaign.memberRoles: array expected");
                message.memberRoles = [];
                for (let i = 0; i < object.memberRoles.length; ++i) {
                    if (typeof object.memberRoles[i] !== "object")
                        throw TypeError(".maps.Campaign.memberRoles: object expected");
                    message.memberRoles[i] = $root.common.UserRole.fromObject(object.memberRoles[i]);
                }
            }
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "UNKNOWN_ROLE":
            case 0:
                message.role = 0;
                break;
            case "OWNER":
            case 1:
                message.role = 1;
                break;
            case "GM":
            case 2:
                message.role = 2;
                break;
            case "TRUSTED_PLAYER":
            case 3:
                message.role = 3;
                break;
            case "PLAYER":
            case 4:
                message.role = 4;
                break;
            case "OBSERVER":
            case 5:
                message.role = 5;
                break;
            }
            if (object.maps) {
                if (!Array.isArray(object.maps))
                    throw TypeError(".maps.Campaign.maps: array expected");
                message.maps = [];
                for (let i = 0; i < object.maps.length; ++i) {
                    if (typeof object.maps[i] !== "object")
                        throw TypeError(".maps.Campaign.maps: object expected");
                    message.maps[i] = $root.maps.Map.fromObject(object.maps[i]);
                }
            }
            if (object.permissions != null) {
                if (typeof object.permissions !== "object")
                    throw TypeError(".maps.Campaign.permissions: object expected");
                message.permissions = $root.maps.CampaignPermissions.fromObject(object.permissions);
            }
            return message;
        };

        /**
         * Creates a plain object from a Campaign message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.Campaign
         * @static
         * @param {maps.Campaign} message Campaign
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Campaign.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.memberRoles = [];
                object.maps = [];
            }
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownerId = "";
                object.active = false;
                object.description = "";
                object.role = options.enums === String ? "UNKNOWN_ROLE" : 0;
                object.permissions = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                object.ownerId = message.ownerId;
            if (message.active != null && message.hasOwnProperty("active"))
                object.active = message.active;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.memberRoles && message.memberRoles.length) {
                object.memberRoles = [];
                for (let j = 0; j < message.memberRoles.length; ++j)
                    object.memberRoles[j] = $root.common.UserRole.toObject(message.memberRoles[j], options);
            }
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.common.Role[message.role] === undefined ? message.role : $root.common.Role[message.role] : message.role;
            if (message.maps && message.maps.length) {
                object.maps = [];
                for (let j = 0; j < message.maps.length; ++j)
                    object.maps[j] = $root.maps.Map.toObject(message.maps[j], options);
            }
            if (message.permissions != null && message.hasOwnProperty("permissions"))
                object.permissions = $root.maps.CampaignPermissions.toObject(message.permissions, options);
            return object;
        };

        /**
         * Converts this Campaign to JSON.
         * @function toJSON
         * @memberof maps.Campaign
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Campaign.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Campaign
         * @function getTypeUrl
         * @memberof maps.Campaign
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Campaign.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.Campaign";
        };

        return Campaign;
    })();

    maps.CampaignMember = (function() {

        /**
         * Properties of a CampaignMember.
         * @memberof maps
         * @interface ICampaignMember
         * @property {string|null} [name] CampaignMember name
         * @property {string|null} [campaignId] CampaignMember campaignId
         * @property {string|null} [userId] CampaignMember userId
         * @property {boolean|null} [isRealUser] CampaignMember isRealUser
         * @property {string|null} [loginUrl] CampaignMember loginUrl
         * @property {common.Role|null} [role] CampaignMember role
         */

        /**
         * Constructs a new CampaignMember.
         * @memberof maps
         * @classdesc Represents a CampaignMember.
         * @implements ICampaignMember
         * @constructor
         * @param {maps.ICampaignMember=} [properties] Properties to set
         */
        function CampaignMember(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CampaignMember name.
         * @member {string} name
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.name = "";

        /**
         * CampaignMember campaignId.
         * @member {string} campaignId
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.campaignId = "";

        /**
         * CampaignMember userId.
         * @member {string} userId
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.userId = "";

        /**
         * CampaignMember isRealUser.
         * @member {boolean} isRealUser
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.isRealUser = false;

        /**
         * CampaignMember loginUrl.
         * @member {string} loginUrl
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.loginUrl = "";

        /**
         * CampaignMember role.
         * @member {common.Role} role
         * @memberof maps.CampaignMember
         * @instance
         */
        CampaignMember.prototype.role = 0;

        /**
         * Creates a new CampaignMember instance using the specified properties.
         * @function create
         * @memberof maps.CampaignMember
         * @static
         * @param {maps.ICampaignMember=} [properties] Properties to set
         * @returns {maps.CampaignMember} CampaignMember instance
         */
        CampaignMember.create = function create(properties) {
            return new CampaignMember(properties);
        };

        /**
         * Encodes the specified CampaignMember message. Does not implicitly {@link maps.CampaignMember.verify|verify} messages.
         * @function encode
         * @memberof maps.CampaignMember
         * @static
         * @param {maps.ICampaignMember} message CampaignMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignMember.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.campaignId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.userId);
            if (message.isRealUser != null && Object.hasOwnProperty.call(message, "isRealUser"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isRealUser);
            if (message.loginUrl != null && Object.hasOwnProperty.call(message, "loginUrl"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.loginUrl);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.role);
            return writer;
        };

        /**
         * Encodes the specified CampaignMember message, length delimited. Does not implicitly {@link maps.CampaignMember.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.CampaignMember
         * @static
         * @param {maps.ICampaignMember} message CampaignMember message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignMember.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CampaignMember message from the specified reader or buffer.
         * @function decode
         * @memberof maps.CampaignMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.CampaignMember} CampaignMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignMember.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignMember();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 16: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 17: {
                        message.userId = reader.string();
                        break;
                    }
                case 18: {
                        message.isRealUser = reader.bool();
                        break;
                    }
                case 19: {
                        message.loginUrl = reader.string();
                        break;
                    }
                case 20: {
                        message.role = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CampaignMember message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.CampaignMember
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.CampaignMember} CampaignMember
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignMember.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CampaignMember message.
         * @function verify
         * @memberof maps.CampaignMember
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CampaignMember.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.isRealUser != null && message.hasOwnProperty("isRealUser"))
                if (typeof message.isRealUser !== "boolean")
                    return "isRealUser: boolean expected";
            if (message.loginUrl != null && message.hasOwnProperty("loginUrl"))
                if (!$util.isString(message.loginUrl))
                    return "loginUrl: string expected";
            if (message.role != null && message.hasOwnProperty("role"))
                switch (message.role) {
                default:
                    return "role: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a CampaignMember message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.CampaignMember
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.CampaignMember} CampaignMember
         */
        CampaignMember.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.CampaignMember)
                return object;
            let message = new $root.maps.CampaignMember();
            if (object.name != null)
                message.name = String(object.name);
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.isRealUser != null)
                message.isRealUser = Boolean(object.isRealUser);
            if (object.loginUrl != null)
                message.loginUrl = String(object.loginUrl);
            switch (object.role) {
            default:
                if (typeof object.role === "number") {
                    message.role = object.role;
                    break;
                }
                break;
            case "UNKNOWN_ROLE":
            case 0:
                message.role = 0;
                break;
            case "OWNER":
            case 1:
                message.role = 1;
                break;
            case "GM":
            case 2:
                message.role = 2;
                break;
            case "TRUSTED_PLAYER":
            case 3:
                message.role = 3;
                break;
            case "PLAYER":
            case 4:
                message.role = 4;
                break;
            case "OBSERVER":
            case 5:
                message.role = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a CampaignMember message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.CampaignMember
         * @static
         * @param {maps.CampaignMember} message CampaignMember
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CampaignMember.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.campaignId = "";
                object.userId = "";
                object.isRealUser = false;
                object.loginUrl = "";
                object.role = options.enums === String ? "UNKNOWN_ROLE" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.isRealUser != null && message.hasOwnProperty("isRealUser"))
                object.isRealUser = message.isRealUser;
            if (message.loginUrl != null && message.hasOwnProperty("loginUrl"))
                object.loginUrl = message.loginUrl;
            if (message.role != null && message.hasOwnProperty("role"))
                object.role = options.enums === String ? $root.common.Role[message.role] === undefined ? message.role : $root.common.Role[message.role] : message.role;
            return object;
        };

        /**
         * Converts this CampaignMember to JSON.
         * @function toJSON
         * @memberof maps.CampaignMember
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CampaignMember.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CampaignMember
         * @function getTypeUrl
         * @memberof maps.CampaignMember
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CampaignMember.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.CampaignMember";
        };

        return CampaignMember;
    })();

    maps.CampaignEvent = (function() {

        /**
         * Properties of a CampaignEvent.
         * @memberof maps
         * @interface ICampaignEvent
         * @property {string|null} [id] CampaignEvent id
         * @property {string|null} [traceId] CampaignEvent traceId
         * @property {string|null} [clientRequestId] CampaignEvent clientRequestId
         * @property {string|null} [campaignId] CampaignEvent campaignId
         * @property {maps.CampaignEvent.ICampaignDetailsUpdate|null} [campaignDetailsUpdate] CampaignEvent campaignDetailsUpdate
         * @property {maps.CampaignEvent.IPermissionsUpdate|null} [permissionsUpdate] CampaignEvent permissionsUpdate
         * @property {maps.CampaignEvent.ICampaignMapsUpdate|null} [campaignMapsUpdate] CampaignEvent campaignMapsUpdate
         * @property {maps.CampaignEvent.IMapDetailsUpdate|null} [mapDetailsUpdate] CampaignEvent mapDetailsUpdate
         * @property {maps.CampaignEvent.IMapZonesUpdate|null} [mapZonesUpdate] CampaignEvent mapZonesUpdate
         * @property {maps.CampaignEvent.IMapZoneDetailsUpdate|null} [mapZoneDetailsUpdate] CampaignEvent mapZoneDetailsUpdate
         * @property {maps.CampaignEvent.IMapZoneMaskPatch|null} [mapZoneExploredPatch] CampaignEvent mapZoneExploredPatch
         * @property {maps.CampaignEvent.IMapZoneMaskPatch|null} [mapZoneOverrideHiddenPatch] CampaignEvent mapZoneOverrideHiddenPatch
         * @property {maps.CampaignEvent.IMapZoneMaskPatch|null} [mapZoneOverrideVisiblePatch] CampaignEvent mapZoneOverrideVisiblePatch
         * @property {maps.CampaignEvent.IMapObjectUpdate|null} [mapObjectUpdate] CampaignEvent mapObjectUpdate
         */

        /**
         * Constructs a new CampaignEvent.
         * @memberof maps
         * @classdesc Represents a CampaignEvent.
         * @implements ICampaignEvent
         * @constructor
         * @param {maps.ICampaignEvent=} [properties] Properties to set
         */
        function CampaignEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CampaignEvent id.
         * @member {string} id
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.id = "";

        /**
         * CampaignEvent traceId.
         * @member {string} traceId
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.traceId = "";

        /**
         * CampaignEvent clientRequestId.
         * @member {string} clientRequestId
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.clientRequestId = "";

        /**
         * CampaignEvent campaignId.
         * @member {string} campaignId
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.campaignId = "";

        /**
         * CampaignEvent campaignDetailsUpdate.
         * @member {maps.CampaignEvent.ICampaignDetailsUpdate|null|undefined} campaignDetailsUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.campaignDetailsUpdate = null;

        /**
         * CampaignEvent permissionsUpdate.
         * @member {maps.CampaignEvent.IPermissionsUpdate|null|undefined} permissionsUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.permissionsUpdate = null;

        /**
         * CampaignEvent campaignMapsUpdate.
         * @member {maps.CampaignEvent.ICampaignMapsUpdate|null|undefined} campaignMapsUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.campaignMapsUpdate = null;

        /**
         * CampaignEvent mapDetailsUpdate.
         * @member {maps.CampaignEvent.IMapDetailsUpdate|null|undefined} mapDetailsUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapDetailsUpdate = null;

        /**
         * CampaignEvent mapZonesUpdate.
         * @member {maps.CampaignEvent.IMapZonesUpdate|null|undefined} mapZonesUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapZonesUpdate = null;

        /**
         * CampaignEvent mapZoneDetailsUpdate.
         * @member {maps.CampaignEvent.IMapZoneDetailsUpdate|null|undefined} mapZoneDetailsUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapZoneDetailsUpdate = null;

        /**
         * CampaignEvent mapZoneExploredPatch.
         * @member {maps.CampaignEvent.IMapZoneMaskPatch|null|undefined} mapZoneExploredPatch
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapZoneExploredPatch = null;

        /**
         * CampaignEvent mapZoneOverrideHiddenPatch.
         * @member {maps.CampaignEvent.IMapZoneMaskPatch|null|undefined} mapZoneOverrideHiddenPatch
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapZoneOverrideHiddenPatch = null;

        /**
         * CampaignEvent mapZoneOverrideVisiblePatch.
         * @member {maps.CampaignEvent.IMapZoneMaskPatch|null|undefined} mapZoneOverrideVisiblePatch
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapZoneOverrideVisiblePatch = null;

        /**
         * CampaignEvent mapObjectUpdate.
         * @member {maps.CampaignEvent.IMapObjectUpdate|null|undefined} mapObjectUpdate
         * @memberof maps.CampaignEvent
         * @instance
         */
        CampaignEvent.prototype.mapObjectUpdate = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CampaignEvent data.
         * @member {"campaignDetailsUpdate"|"permissionsUpdate"|"campaignMapsUpdate"|"mapDetailsUpdate"|"mapZonesUpdate"|"mapZoneDetailsUpdate"|"mapZoneExploredPatch"|"mapZoneOverrideHiddenPatch"|"mapZoneOverrideVisiblePatch"|"mapObjectUpdate"|undefined} data
         * @memberof maps.CampaignEvent
         * @instance
         */
        Object.defineProperty(CampaignEvent.prototype, "data", {
            get: $util.oneOfGetter($oneOfFields = ["campaignDetailsUpdate", "permissionsUpdate", "campaignMapsUpdate", "mapDetailsUpdate", "mapZonesUpdate", "mapZoneDetailsUpdate", "mapZoneExploredPatch", "mapZoneOverrideHiddenPatch", "mapZoneOverrideVisiblePatch", "mapObjectUpdate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CampaignEvent instance using the specified properties.
         * @function create
         * @memberof maps.CampaignEvent
         * @static
         * @param {maps.ICampaignEvent=} [properties] Properties to set
         * @returns {maps.CampaignEvent} CampaignEvent instance
         */
        CampaignEvent.create = function create(properties) {
            return new CampaignEvent(properties);
        };

        /**
         * Encodes the specified CampaignEvent message. Does not implicitly {@link maps.CampaignEvent.verify|verify} messages.
         * @function encode
         * @memberof maps.CampaignEvent
         * @static
         * @param {maps.ICampaignEvent} message CampaignEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.traceId != null && Object.hasOwnProperty.call(message, "traceId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.traceId);
            if (message.clientRequestId != null && Object.hasOwnProperty.call(message, "clientRequestId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientRequestId);
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.campaignId);
            if (message.campaignDetailsUpdate != null && Object.hasOwnProperty.call(message, "campaignDetailsUpdate"))
                $root.maps.CampaignEvent.CampaignDetailsUpdate.encode(message.campaignDetailsUpdate, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            if (message.permissionsUpdate != null && Object.hasOwnProperty.call(message, "permissionsUpdate"))
                $root.maps.CampaignEvent.PermissionsUpdate.encode(message.permissionsUpdate, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
            if (message.campaignMapsUpdate != null && Object.hasOwnProperty.call(message, "campaignMapsUpdate"))
                $root.maps.CampaignEvent.CampaignMapsUpdate.encode(message.campaignMapsUpdate, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
            if (message.mapDetailsUpdate != null && Object.hasOwnProperty.call(message, "mapDetailsUpdate"))
                $root.maps.CampaignEvent.MapDetailsUpdate.encode(message.mapDetailsUpdate, writer.uint32(/* id 104, wireType 2 =*/834).fork()).ldelim();
            if (message.mapZonesUpdate != null && Object.hasOwnProperty.call(message, "mapZonesUpdate"))
                $root.maps.CampaignEvent.MapZonesUpdate.encode(message.mapZonesUpdate, writer.uint32(/* id 105, wireType 2 =*/842).fork()).ldelim();
            if (message.mapZoneDetailsUpdate != null && Object.hasOwnProperty.call(message, "mapZoneDetailsUpdate"))
                $root.maps.CampaignEvent.MapZoneDetailsUpdate.encode(message.mapZoneDetailsUpdate, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
            if (message.mapZoneExploredPatch != null && Object.hasOwnProperty.call(message, "mapZoneExploredPatch"))
                $root.maps.CampaignEvent.MapZoneMaskPatch.encode(message.mapZoneExploredPatch, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
            if (message.mapZoneOverrideHiddenPatch != null && Object.hasOwnProperty.call(message, "mapZoneOverrideHiddenPatch"))
                $root.maps.CampaignEvent.MapZoneMaskPatch.encode(message.mapZoneOverrideHiddenPatch, writer.uint32(/* id 108, wireType 2 =*/866).fork()).ldelim();
            if (message.mapZoneOverrideVisiblePatch != null && Object.hasOwnProperty.call(message, "mapZoneOverrideVisiblePatch"))
                $root.maps.CampaignEvent.MapZoneMaskPatch.encode(message.mapZoneOverrideVisiblePatch, writer.uint32(/* id 109, wireType 2 =*/874).fork()).ldelim();
            if (message.mapObjectUpdate != null && Object.hasOwnProperty.call(message, "mapObjectUpdate"))
                $root.maps.CampaignEvent.MapObjectUpdate.encode(message.mapObjectUpdate, writer.uint32(/* id 110, wireType 2 =*/882).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CampaignEvent message, length delimited. Does not implicitly {@link maps.CampaignEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.CampaignEvent
         * @static
         * @param {maps.ICampaignEvent} message CampaignEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CampaignEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CampaignEvent message from the specified reader or buffer.
         * @function decode
         * @memberof maps.CampaignEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.CampaignEvent} CampaignEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.traceId = reader.string();
                        break;
                    }
                case 3: {
                        message.clientRequestId = reader.string();
                        break;
                    }
                case 4: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 101: {
                        message.campaignDetailsUpdate = $root.maps.CampaignEvent.CampaignDetailsUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 102: {
                        message.permissionsUpdate = $root.maps.CampaignEvent.PermissionsUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 103: {
                        message.campaignMapsUpdate = $root.maps.CampaignEvent.CampaignMapsUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 104: {
                        message.mapDetailsUpdate = $root.maps.CampaignEvent.MapDetailsUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 105: {
                        message.mapZonesUpdate = $root.maps.CampaignEvent.MapZonesUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 106: {
                        message.mapZoneDetailsUpdate = $root.maps.CampaignEvent.MapZoneDetailsUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 107: {
                        message.mapZoneExploredPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.decode(reader, reader.uint32());
                        break;
                    }
                case 108: {
                        message.mapZoneOverrideHiddenPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.decode(reader, reader.uint32());
                        break;
                    }
                case 109: {
                        message.mapZoneOverrideVisiblePatch = $root.maps.CampaignEvent.MapZoneMaskPatch.decode(reader, reader.uint32());
                        break;
                    }
                case 110: {
                        message.mapObjectUpdate = $root.maps.CampaignEvent.MapObjectUpdate.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CampaignEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.CampaignEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.CampaignEvent} CampaignEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CampaignEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CampaignEvent message.
         * @function verify
         * @memberof maps.CampaignEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CampaignEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                if (!$util.isString(message.traceId))
                    return "traceId: string expected";
            if (message.clientRequestId != null && message.hasOwnProperty("clientRequestId"))
                if (!$util.isString(message.clientRequestId))
                    return "clientRequestId: string expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.campaignDetailsUpdate != null && message.hasOwnProperty("campaignDetailsUpdate")) {
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.CampaignDetailsUpdate.verify(message.campaignDetailsUpdate);
                    if (error)
                        return "campaignDetailsUpdate." + error;
                }
            }
            if (message.permissionsUpdate != null && message.hasOwnProperty("permissionsUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.PermissionsUpdate.verify(message.permissionsUpdate);
                    if (error)
                        return "permissionsUpdate." + error;
                }
            }
            if (message.campaignMapsUpdate != null && message.hasOwnProperty("campaignMapsUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.CampaignMapsUpdate.verify(message.campaignMapsUpdate);
                    if (error)
                        return "campaignMapsUpdate." + error;
                }
            }
            if (message.mapDetailsUpdate != null && message.hasOwnProperty("mapDetailsUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapDetailsUpdate.verify(message.mapDetailsUpdate);
                    if (error)
                        return "mapDetailsUpdate." + error;
                }
            }
            if (message.mapZonesUpdate != null && message.hasOwnProperty("mapZonesUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapZonesUpdate.verify(message.mapZonesUpdate);
                    if (error)
                        return "mapZonesUpdate." + error;
                }
            }
            if (message.mapZoneDetailsUpdate != null && message.hasOwnProperty("mapZoneDetailsUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapZoneDetailsUpdate.verify(message.mapZoneDetailsUpdate);
                    if (error)
                        return "mapZoneDetailsUpdate." + error;
                }
            }
            if (message.mapZoneExploredPatch != null && message.hasOwnProperty("mapZoneExploredPatch")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapZoneMaskPatch.verify(message.mapZoneExploredPatch);
                    if (error)
                        return "mapZoneExploredPatch." + error;
                }
            }
            if (message.mapZoneOverrideHiddenPatch != null && message.hasOwnProperty("mapZoneOverrideHiddenPatch")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapZoneMaskPatch.verify(message.mapZoneOverrideHiddenPatch);
                    if (error)
                        return "mapZoneOverrideHiddenPatch." + error;
                }
            }
            if (message.mapZoneOverrideVisiblePatch != null && message.hasOwnProperty("mapZoneOverrideVisiblePatch")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapZoneMaskPatch.verify(message.mapZoneOverrideVisiblePatch);
                    if (error)
                        return "mapZoneOverrideVisiblePatch." + error;
                }
            }
            if (message.mapObjectUpdate != null && message.hasOwnProperty("mapObjectUpdate")) {
                if (properties.data === 1)
                    return "data: multiple values";
                properties.data = 1;
                {
                    let error = $root.maps.CampaignEvent.MapObjectUpdate.verify(message.mapObjectUpdate);
                    if (error)
                        return "mapObjectUpdate." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CampaignEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.CampaignEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.CampaignEvent} CampaignEvent
         */
        CampaignEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.CampaignEvent)
                return object;
            let message = new $root.maps.CampaignEvent();
            if (object.id != null)
                message.id = String(object.id);
            if (object.traceId != null)
                message.traceId = String(object.traceId);
            if (object.clientRequestId != null)
                message.clientRequestId = String(object.clientRequestId);
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.campaignDetailsUpdate != null) {
                if (typeof object.campaignDetailsUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.campaignDetailsUpdate: object expected");
                message.campaignDetailsUpdate = $root.maps.CampaignEvent.CampaignDetailsUpdate.fromObject(object.campaignDetailsUpdate);
            }
            if (object.permissionsUpdate != null) {
                if (typeof object.permissionsUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.permissionsUpdate: object expected");
                message.permissionsUpdate = $root.maps.CampaignEvent.PermissionsUpdate.fromObject(object.permissionsUpdate);
            }
            if (object.campaignMapsUpdate != null) {
                if (typeof object.campaignMapsUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.campaignMapsUpdate: object expected");
                message.campaignMapsUpdate = $root.maps.CampaignEvent.CampaignMapsUpdate.fromObject(object.campaignMapsUpdate);
            }
            if (object.mapDetailsUpdate != null) {
                if (typeof object.mapDetailsUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.mapDetailsUpdate: object expected");
                message.mapDetailsUpdate = $root.maps.CampaignEvent.MapDetailsUpdate.fromObject(object.mapDetailsUpdate);
            }
            if (object.mapZonesUpdate != null) {
                if (typeof object.mapZonesUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.mapZonesUpdate: object expected");
                message.mapZonesUpdate = $root.maps.CampaignEvent.MapZonesUpdate.fromObject(object.mapZonesUpdate);
            }
            if (object.mapZoneDetailsUpdate != null) {
                if (typeof object.mapZoneDetailsUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.mapZoneDetailsUpdate: object expected");
                message.mapZoneDetailsUpdate = $root.maps.CampaignEvent.MapZoneDetailsUpdate.fromObject(object.mapZoneDetailsUpdate);
            }
            if (object.mapZoneExploredPatch != null) {
                if (typeof object.mapZoneExploredPatch !== "object")
                    throw TypeError(".maps.CampaignEvent.mapZoneExploredPatch: object expected");
                message.mapZoneExploredPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.fromObject(object.mapZoneExploredPatch);
            }
            if (object.mapZoneOverrideHiddenPatch != null) {
                if (typeof object.mapZoneOverrideHiddenPatch !== "object")
                    throw TypeError(".maps.CampaignEvent.mapZoneOverrideHiddenPatch: object expected");
                message.mapZoneOverrideHiddenPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.fromObject(object.mapZoneOverrideHiddenPatch);
            }
            if (object.mapZoneOverrideVisiblePatch != null) {
                if (typeof object.mapZoneOverrideVisiblePatch !== "object")
                    throw TypeError(".maps.CampaignEvent.mapZoneOverrideVisiblePatch: object expected");
                message.mapZoneOverrideVisiblePatch = $root.maps.CampaignEvent.MapZoneMaskPatch.fromObject(object.mapZoneOverrideVisiblePatch);
            }
            if (object.mapObjectUpdate != null) {
                if (typeof object.mapObjectUpdate !== "object")
                    throw TypeError(".maps.CampaignEvent.mapObjectUpdate: object expected");
                message.mapObjectUpdate = $root.maps.CampaignEvent.MapObjectUpdate.fromObject(object.mapObjectUpdate);
            }
            return message;
        };

        /**
         * Creates a plain object from a CampaignEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.CampaignEvent
         * @static
         * @param {maps.CampaignEvent} message CampaignEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CampaignEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.traceId = "";
                object.clientRequestId = "";
                object.campaignId = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.traceId != null && message.hasOwnProperty("traceId"))
                object.traceId = message.traceId;
            if (message.clientRequestId != null && message.hasOwnProperty("clientRequestId"))
                object.clientRequestId = message.clientRequestId;
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.campaignDetailsUpdate != null && message.hasOwnProperty("campaignDetailsUpdate")) {
                object.campaignDetailsUpdate = $root.maps.CampaignEvent.CampaignDetailsUpdate.toObject(message.campaignDetailsUpdate, options);
                if (options.oneofs)
                    object.data = "campaignDetailsUpdate";
            }
            if (message.permissionsUpdate != null && message.hasOwnProperty("permissionsUpdate")) {
                object.permissionsUpdate = $root.maps.CampaignEvent.PermissionsUpdate.toObject(message.permissionsUpdate, options);
                if (options.oneofs)
                    object.data = "permissionsUpdate";
            }
            if (message.campaignMapsUpdate != null && message.hasOwnProperty("campaignMapsUpdate")) {
                object.campaignMapsUpdate = $root.maps.CampaignEvent.CampaignMapsUpdate.toObject(message.campaignMapsUpdate, options);
                if (options.oneofs)
                    object.data = "campaignMapsUpdate";
            }
            if (message.mapDetailsUpdate != null && message.hasOwnProperty("mapDetailsUpdate")) {
                object.mapDetailsUpdate = $root.maps.CampaignEvent.MapDetailsUpdate.toObject(message.mapDetailsUpdate, options);
                if (options.oneofs)
                    object.data = "mapDetailsUpdate";
            }
            if (message.mapZonesUpdate != null && message.hasOwnProperty("mapZonesUpdate")) {
                object.mapZonesUpdate = $root.maps.CampaignEvent.MapZonesUpdate.toObject(message.mapZonesUpdate, options);
                if (options.oneofs)
                    object.data = "mapZonesUpdate";
            }
            if (message.mapZoneDetailsUpdate != null && message.hasOwnProperty("mapZoneDetailsUpdate")) {
                object.mapZoneDetailsUpdate = $root.maps.CampaignEvent.MapZoneDetailsUpdate.toObject(message.mapZoneDetailsUpdate, options);
                if (options.oneofs)
                    object.data = "mapZoneDetailsUpdate";
            }
            if (message.mapZoneExploredPatch != null && message.hasOwnProperty("mapZoneExploredPatch")) {
                object.mapZoneExploredPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.toObject(message.mapZoneExploredPatch, options);
                if (options.oneofs)
                    object.data = "mapZoneExploredPatch";
            }
            if (message.mapZoneOverrideHiddenPatch != null && message.hasOwnProperty("mapZoneOverrideHiddenPatch")) {
                object.mapZoneOverrideHiddenPatch = $root.maps.CampaignEvent.MapZoneMaskPatch.toObject(message.mapZoneOverrideHiddenPatch, options);
                if (options.oneofs)
                    object.data = "mapZoneOverrideHiddenPatch";
            }
            if (message.mapZoneOverrideVisiblePatch != null && message.hasOwnProperty("mapZoneOverrideVisiblePatch")) {
                object.mapZoneOverrideVisiblePatch = $root.maps.CampaignEvent.MapZoneMaskPatch.toObject(message.mapZoneOverrideVisiblePatch, options);
                if (options.oneofs)
                    object.data = "mapZoneOverrideVisiblePatch";
            }
            if (message.mapObjectUpdate != null && message.hasOwnProperty("mapObjectUpdate")) {
                object.mapObjectUpdate = $root.maps.CampaignEvent.MapObjectUpdate.toObject(message.mapObjectUpdate, options);
                if (options.oneofs)
                    object.data = "mapObjectUpdate";
            }
            return object;
        };

        /**
         * Converts this CampaignEvent to JSON.
         * @function toJSON
         * @memberof maps.CampaignEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CampaignEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CampaignEvent
         * @function getTypeUrl
         * @memberof maps.CampaignEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CampaignEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.CampaignEvent";
        };

        CampaignEvent.CampaignDetailsUpdate = (function() {

            /**
             * Properties of a CampaignDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @interface ICampaignDetailsUpdate
             */

            /**
             * Constructs a new CampaignDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a CampaignDetailsUpdate.
             * @implements ICampaignDetailsUpdate
             * @constructor
             * @param {maps.CampaignEvent.ICampaignDetailsUpdate=} [properties] Properties to set
             */
            function CampaignDetailsUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CampaignDetailsUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignDetailsUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.CampaignDetailsUpdate} CampaignDetailsUpdate instance
             */
            CampaignDetailsUpdate.create = function create(properties) {
                return new CampaignDetailsUpdate(properties);
            };

            /**
             * Encodes the specified CampaignDetailsUpdate message. Does not implicitly {@link maps.CampaignEvent.CampaignDetailsUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignDetailsUpdate} message CampaignDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignDetailsUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CampaignDetailsUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.CampaignDetailsUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignDetailsUpdate} message CampaignDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignDetailsUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CampaignDetailsUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.CampaignDetailsUpdate} CampaignDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignDetailsUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.CampaignDetailsUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CampaignDetailsUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.CampaignDetailsUpdate} CampaignDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignDetailsUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CampaignDetailsUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CampaignDetailsUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CampaignDetailsUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.CampaignDetailsUpdate} CampaignDetailsUpdate
             */
            CampaignDetailsUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.CampaignDetailsUpdate)
                    return object;
                return new $root.maps.CampaignEvent.CampaignDetailsUpdate();
            };

            /**
             * Creates a plain object from a CampaignDetailsUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.CampaignDetailsUpdate} message CampaignDetailsUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CampaignDetailsUpdate.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CampaignDetailsUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CampaignDetailsUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CampaignDetailsUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.CampaignDetailsUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CampaignDetailsUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.CampaignDetailsUpdate";
            };

            return CampaignDetailsUpdate;
        })();

        CampaignEvent.PermissionsUpdate = (function() {

            /**
             * Properties of a PermissionsUpdate.
             * @memberof maps.CampaignEvent
             * @interface IPermissionsUpdate
             */

            /**
             * Constructs a new PermissionsUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a PermissionsUpdate.
             * @implements IPermissionsUpdate
             * @constructor
             * @param {maps.CampaignEvent.IPermissionsUpdate=} [properties] Properties to set
             */
            function PermissionsUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new PermissionsUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {maps.CampaignEvent.IPermissionsUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.PermissionsUpdate} PermissionsUpdate instance
             */
            PermissionsUpdate.create = function create(properties) {
                return new PermissionsUpdate(properties);
            };

            /**
             * Encodes the specified PermissionsUpdate message. Does not implicitly {@link maps.CampaignEvent.PermissionsUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {maps.CampaignEvent.IPermissionsUpdate} message PermissionsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PermissionsUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified PermissionsUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.PermissionsUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {maps.CampaignEvent.IPermissionsUpdate} message PermissionsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PermissionsUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PermissionsUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.PermissionsUpdate} PermissionsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PermissionsUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.PermissionsUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PermissionsUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.PermissionsUpdate} PermissionsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PermissionsUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PermissionsUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PermissionsUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a PermissionsUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.PermissionsUpdate} PermissionsUpdate
             */
            PermissionsUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.PermissionsUpdate)
                    return object;
                return new $root.maps.CampaignEvent.PermissionsUpdate();
            };

            /**
             * Creates a plain object from a PermissionsUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {maps.CampaignEvent.PermissionsUpdate} message PermissionsUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PermissionsUpdate.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PermissionsUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PermissionsUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PermissionsUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.PermissionsUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PermissionsUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.PermissionsUpdate";
            };

            return PermissionsUpdate;
        })();

        CampaignEvent.CampaignMapsUpdate = (function() {

            /**
             * Properties of a CampaignMapsUpdate.
             * @memberof maps.CampaignEvent
             * @interface ICampaignMapsUpdate
             * @property {string|null} [campaignId] CampaignMapsUpdate campaignId
             */

            /**
             * Constructs a new CampaignMapsUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a CampaignMapsUpdate.
             * @implements ICampaignMapsUpdate
             * @constructor
             * @param {maps.CampaignEvent.ICampaignMapsUpdate=} [properties] Properties to set
             */
            function CampaignMapsUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CampaignMapsUpdate campaignId.
             * @member {string} campaignId
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @instance
             */
            CampaignMapsUpdate.prototype.campaignId = "";

            /**
             * Creates a new CampaignMapsUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignMapsUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.CampaignMapsUpdate} CampaignMapsUpdate instance
             */
            CampaignMapsUpdate.create = function create(properties) {
                return new CampaignMapsUpdate(properties);
            };

            /**
             * Encodes the specified CampaignMapsUpdate message. Does not implicitly {@link maps.CampaignEvent.CampaignMapsUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignMapsUpdate} message CampaignMapsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignMapsUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
                return writer;
            };

            /**
             * Encodes the specified CampaignMapsUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.CampaignMapsUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {maps.CampaignEvent.ICampaignMapsUpdate} message CampaignMapsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignMapsUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CampaignMapsUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.CampaignMapsUpdate} CampaignMapsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignMapsUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.CampaignMapsUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.campaignId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CampaignMapsUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.CampaignMapsUpdate} CampaignMapsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignMapsUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CampaignMapsUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CampaignMapsUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                    if (!$util.isString(message.campaignId))
                        return "campaignId: string expected";
                return null;
            };

            /**
             * Creates a CampaignMapsUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.CampaignMapsUpdate} CampaignMapsUpdate
             */
            CampaignMapsUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.CampaignMapsUpdate)
                    return object;
                let message = new $root.maps.CampaignEvent.CampaignMapsUpdate();
                if (object.campaignId != null)
                    message.campaignId = String(object.campaignId);
                return message;
            };

            /**
             * Creates a plain object from a CampaignMapsUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {maps.CampaignEvent.CampaignMapsUpdate} message CampaignMapsUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CampaignMapsUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.campaignId = "";
                if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                    object.campaignId = message.campaignId;
                return object;
            };

            /**
             * Converts this CampaignMapsUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CampaignMapsUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CampaignMapsUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.CampaignMapsUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CampaignMapsUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.CampaignMapsUpdate";
            };

            return CampaignMapsUpdate;
        })();

        CampaignEvent.MapDetailsUpdate = (function() {

            /**
             * Properties of a MapDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @interface IMapDetailsUpdate
             * @property {string|null} [mapId] MapDetailsUpdate mapId
             */

            /**
             * Constructs a new MapDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a MapDetailsUpdate.
             * @implements IMapDetailsUpdate
             * @constructor
             * @param {maps.CampaignEvent.IMapDetailsUpdate=} [properties] Properties to set
             */
            function MapDetailsUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapDetailsUpdate mapId.
             * @member {string} mapId
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @instance
             */
            MapDetailsUpdate.prototype.mapId = "";

            /**
             * Creates a new MapDetailsUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapDetailsUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.MapDetailsUpdate} MapDetailsUpdate instance
             */
            MapDetailsUpdate.create = function create(properties) {
                return new MapDetailsUpdate(properties);
            };

            /**
             * Encodes the specified MapDetailsUpdate message. Does not implicitly {@link maps.CampaignEvent.MapDetailsUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapDetailsUpdate} message MapDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapDetailsUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
                return writer;
            };

            /**
             * Encodes the specified MapDetailsUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.MapDetailsUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapDetailsUpdate} message MapDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapDetailsUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapDetailsUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.MapDetailsUpdate} MapDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapDetailsUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.MapDetailsUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mapId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapDetailsUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.MapDetailsUpdate} MapDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapDetailsUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapDetailsUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapDetailsUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    if (!$util.isString(message.mapId))
                        return "mapId: string expected";
                return null;
            };

            /**
             * Creates a MapDetailsUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.MapDetailsUpdate} MapDetailsUpdate
             */
            MapDetailsUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.MapDetailsUpdate)
                    return object;
                let message = new $root.maps.CampaignEvent.MapDetailsUpdate();
                if (object.mapId != null)
                    message.mapId = String(object.mapId);
                return message;
            };

            /**
             * Creates a plain object from a MapDetailsUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.MapDetailsUpdate} message MapDetailsUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapDetailsUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.mapId = "";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    object.mapId = message.mapId;
                return object;
            };

            /**
             * Converts this MapDetailsUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapDetailsUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MapDetailsUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.MapDetailsUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MapDetailsUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.MapDetailsUpdate";
            };

            return MapDetailsUpdate;
        })();

        CampaignEvent.MapZonesUpdate = (function() {

            /**
             * Properties of a MapZonesUpdate.
             * @memberof maps.CampaignEvent
             * @interface IMapZonesUpdate
             * @property {string|null} [mapId] MapZonesUpdate mapId
             */

            /**
             * Constructs a new MapZonesUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a MapZonesUpdate.
             * @implements IMapZonesUpdate
             * @constructor
             * @param {maps.CampaignEvent.IMapZonesUpdate=} [properties] Properties to set
             */
            function MapZonesUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapZonesUpdate mapId.
             * @member {string} mapId
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @instance
             */
            MapZonesUpdate.prototype.mapId = "";

            /**
             * Creates a new MapZonesUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZonesUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.MapZonesUpdate} MapZonesUpdate instance
             */
            MapZonesUpdate.create = function create(properties) {
                return new MapZonesUpdate(properties);
            };

            /**
             * Encodes the specified MapZonesUpdate message. Does not implicitly {@link maps.CampaignEvent.MapZonesUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZonesUpdate} message MapZonesUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZonesUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
                return writer;
            };

            /**
             * Encodes the specified MapZonesUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.MapZonesUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZonesUpdate} message MapZonesUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZonesUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapZonesUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.MapZonesUpdate} MapZonesUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZonesUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.MapZonesUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mapId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapZonesUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.MapZonesUpdate} MapZonesUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZonesUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapZonesUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapZonesUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    if (!$util.isString(message.mapId))
                        return "mapId: string expected";
                return null;
            };

            /**
             * Creates a MapZonesUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.MapZonesUpdate} MapZonesUpdate
             */
            MapZonesUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.MapZonesUpdate)
                    return object;
                let message = new $root.maps.CampaignEvent.MapZonesUpdate();
                if (object.mapId != null)
                    message.mapId = String(object.mapId);
                return message;
            };

            /**
             * Creates a plain object from a MapZonesUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {maps.CampaignEvent.MapZonesUpdate} message MapZonesUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapZonesUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.mapId = "";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    object.mapId = message.mapId;
                return object;
            };

            /**
             * Converts this MapZonesUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapZonesUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MapZonesUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.MapZonesUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MapZonesUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.MapZonesUpdate";
            };

            return MapZonesUpdate;
        })();

        CampaignEvent.MapZoneDetailsUpdate = (function() {

            /**
             * Properties of a MapZoneDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @interface IMapZoneDetailsUpdate
             * @property {string|null} [mapId] MapZoneDetailsUpdate mapId
             * @property {string|null} [mapZoneId] MapZoneDetailsUpdate mapZoneId
             */

            /**
             * Constructs a new MapZoneDetailsUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a MapZoneDetailsUpdate.
             * @implements IMapZoneDetailsUpdate
             * @constructor
             * @param {maps.CampaignEvent.IMapZoneDetailsUpdate=} [properties] Properties to set
             */
            function MapZoneDetailsUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapZoneDetailsUpdate mapId.
             * @member {string} mapId
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @instance
             */
            MapZoneDetailsUpdate.prototype.mapId = "";

            /**
             * MapZoneDetailsUpdate mapZoneId.
             * @member {string} mapZoneId
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @instance
             */
            MapZoneDetailsUpdate.prototype.mapZoneId = "";

            /**
             * Creates a new MapZoneDetailsUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZoneDetailsUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.MapZoneDetailsUpdate} MapZoneDetailsUpdate instance
             */
            MapZoneDetailsUpdate.create = function create(properties) {
                return new MapZoneDetailsUpdate(properties);
            };

            /**
             * Encodes the specified MapZoneDetailsUpdate message. Does not implicitly {@link maps.CampaignEvent.MapZoneDetailsUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZoneDetailsUpdate} message MapZoneDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZoneDetailsUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
                if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.mapZoneId);
                return writer;
            };

            /**
             * Encodes the specified MapZoneDetailsUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.MapZoneDetailsUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.IMapZoneDetailsUpdate} message MapZoneDetailsUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZoneDetailsUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapZoneDetailsUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.MapZoneDetailsUpdate} MapZoneDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZoneDetailsUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.MapZoneDetailsUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mapId = reader.string();
                            break;
                        }
                    case 2: {
                            message.mapZoneId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapZoneDetailsUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.MapZoneDetailsUpdate} MapZoneDetailsUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZoneDetailsUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapZoneDetailsUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapZoneDetailsUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    if (!$util.isString(message.mapId))
                        return "mapId: string expected";
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    if (!$util.isString(message.mapZoneId))
                        return "mapZoneId: string expected";
                return null;
            };

            /**
             * Creates a MapZoneDetailsUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.MapZoneDetailsUpdate} MapZoneDetailsUpdate
             */
            MapZoneDetailsUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.MapZoneDetailsUpdate)
                    return object;
                let message = new $root.maps.CampaignEvent.MapZoneDetailsUpdate();
                if (object.mapId != null)
                    message.mapId = String(object.mapId);
                if (object.mapZoneId != null)
                    message.mapZoneId = String(object.mapZoneId);
                return message;
            };

            /**
             * Creates a plain object from a MapZoneDetailsUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {maps.CampaignEvent.MapZoneDetailsUpdate} message MapZoneDetailsUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapZoneDetailsUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.mapId = "";
                    object.mapZoneId = "";
                }
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    object.mapId = message.mapId;
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    object.mapZoneId = message.mapZoneId;
                return object;
            };

            /**
             * Converts this MapZoneDetailsUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapZoneDetailsUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MapZoneDetailsUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.MapZoneDetailsUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MapZoneDetailsUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.MapZoneDetailsUpdate";
            };

            return MapZoneDetailsUpdate;
        })();

        CampaignEvent.MapZoneMaskPatch = (function() {

            /**
             * Properties of a MapZoneMaskPatch.
             * @memberof maps.CampaignEvent
             * @interface IMapZoneMaskPatch
             * @property {string|null} [mapId] MapZoneMaskPatch mapId
             * @property {string|null} [mapZoneId] MapZoneMaskPatch mapZoneId
             * @property {geometry.IMaskPatch|null} [patchData] MapZoneMaskPatch patchData
             */

            /**
             * Constructs a new MapZoneMaskPatch.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a MapZoneMaskPatch.
             * @implements IMapZoneMaskPatch
             * @constructor
             * @param {maps.CampaignEvent.IMapZoneMaskPatch=} [properties] Properties to set
             */
            function MapZoneMaskPatch(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapZoneMaskPatch mapId.
             * @member {string} mapId
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @instance
             */
            MapZoneMaskPatch.prototype.mapId = "";

            /**
             * MapZoneMaskPatch mapZoneId.
             * @member {string} mapZoneId
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @instance
             */
            MapZoneMaskPatch.prototype.mapZoneId = "";

            /**
             * MapZoneMaskPatch patchData.
             * @member {geometry.IMaskPatch|null|undefined} patchData
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @instance
             */
            MapZoneMaskPatch.prototype.patchData = null;

            /**
             * Creates a new MapZoneMaskPatch instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {maps.CampaignEvent.IMapZoneMaskPatch=} [properties] Properties to set
             * @returns {maps.CampaignEvent.MapZoneMaskPatch} MapZoneMaskPatch instance
             */
            MapZoneMaskPatch.create = function create(properties) {
                return new MapZoneMaskPatch(properties);
            };

            /**
             * Encodes the specified MapZoneMaskPatch message. Does not implicitly {@link maps.CampaignEvent.MapZoneMaskPatch.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {maps.CampaignEvent.IMapZoneMaskPatch} message MapZoneMaskPatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZoneMaskPatch.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapId);
                if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.mapZoneId);
                if (message.patchData != null && Object.hasOwnProperty.call(message, "patchData"))
                    $root.geometry.MaskPatch.encode(message.patchData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MapZoneMaskPatch message, length delimited. Does not implicitly {@link maps.CampaignEvent.MapZoneMaskPatch.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {maps.CampaignEvent.IMapZoneMaskPatch} message MapZoneMaskPatch message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapZoneMaskPatch.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapZoneMaskPatch message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.MapZoneMaskPatch} MapZoneMaskPatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZoneMaskPatch.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.MapZoneMaskPatch();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mapId = reader.string();
                            break;
                        }
                    case 2: {
                            message.mapZoneId = reader.string();
                            break;
                        }
                    case 3: {
                            message.patchData = $root.geometry.MaskPatch.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapZoneMaskPatch message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.MapZoneMaskPatch} MapZoneMaskPatch
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapZoneMaskPatch.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapZoneMaskPatch message.
             * @function verify
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapZoneMaskPatch.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    if (!$util.isString(message.mapId))
                        return "mapId: string expected";
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    if (!$util.isString(message.mapZoneId))
                        return "mapZoneId: string expected";
                if (message.patchData != null && message.hasOwnProperty("patchData")) {
                    let error = $root.geometry.MaskPatch.verify(message.patchData);
                    if (error)
                        return "patchData." + error;
                }
                return null;
            };

            /**
             * Creates a MapZoneMaskPatch message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.MapZoneMaskPatch} MapZoneMaskPatch
             */
            MapZoneMaskPatch.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.MapZoneMaskPatch)
                    return object;
                let message = new $root.maps.CampaignEvent.MapZoneMaskPatch();
                if (object.mapId != null)
                    message.mapId = String(object.mapId);
                if (object.mapZoneId != null)
                    message.mapZoneId = String(object.mapZoneId);
                if (object.patchData != null) {
                    if (typeof object.patchData !== "object")
                        throw TypeError(".maps.CampaignEvent.MapZoneMaskPatch.patchData: object expected");
                    message.patchData = $root.geometry.MaskPatch.fromObject(object.patchData);
                }
                return message;
            };

            /**
             * Creates a plain object from a MapZoneMaskPatch message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {maps.CampaignEvent.MapZoneMaskPatch} message MapZoneMaskPatch
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapZoneMaskPatch.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.mapId = "";
                    object.mapZoneId = "";
                    object.patchData = null;
                }
                if (message.mapId != null && message.hasOwnProperty("mapId"))
                    object.mapId = message.mapId;
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    object.mapZoneId = message.mapZoneId;
                if (message.patchData != null && message.hasOwnProperty("patchData"))
                    object.patchData = $root.geometry.MaskPatch.toObject(message.patchData, options);
                return object;
            };

            /**
             * Converts this MapZoneMaskPatch to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapZoneMaskPatch.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MapZoneMaskPatch
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.MapZoneMaskPatch
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MapZoneMaskPatch.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.MapZoneMaskPatch";
            };

            return MapZoneMaskPatch;
        })();

        CampaignEvent.MapObjectUpdate = (function() {

            /**
             * Properties of a MapObjectUpdate.
             * @memberof maps.CampaignEvent
             * @interface IMapObjectUpdate
             * @property {string|null} [mapZoneId] MapObjectUpdate mapZoneId
             * @property {string|null} [mapObjectId] MapObjectUpdate mapObjectId
             */

            /**
             * Constructs a new MapObjectUpdate.
             * @memberof maps.CampaignEvent
             * @classdesc Represents a MapObjectUpdate.
             * @implements IMapObjectUpdate
             * @constructor
             * @param {maps.CampaignEvent.IMapObjectUpdate=} [properties] Properties to set
             */
            function MapObjectUpdate(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MapObjectUpdate mapZoneId.
             * @member {string} mapZoneId
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @instance
             */
            MapObjectUpdate.prototype.mapZoneId = "";

            /**
             * MapObjectUpdate mapObjectId.
             * @member {string} mapObjectId
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @instance
             */
            MapObjectUpdate.prototype.mapObjectId = "";

            /**
             * Creates a new MapObjectUpdate instance using the specified properties.
             * @function create
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {maps.CampaignEvent.IMapObjectUpdate=} [properties] Properties to set
             * @returns {maps.CampaignEvent.MapObjectUpdate} MapObjectUpdate instance
             */
            MapObjectUpdate.create = function create(properties) {
                return new MapObjectUpdate(properties);
            };

            /**
             * Encodes the specified MapObjectUpdate message. Does not implicitly {@link maps.CampaignEvent.MapObjectUpdate.verify|verify} messages.
             * @function encode
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {maps.CampaignEvent.IMapObjectUpdate} message MapObjectUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapObjectUpdate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.mapZoneId);
                if (message.mapObjectId != null && Object.hasOwnProperty.call(message, "mapObjectId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.mapObjectId);
                return writer;
            };

            /**
             * Encodes the specified MapObjectUpdate message, length delimited. Does not implicitly {@link maps.CampaignEvent.MapObjectUpdate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {maps.CampaignEvent.IMapObjectUpdate} message MapObjectUpdate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MapObjectUpdate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MapObjectUpdate message from the specified reader or buffer.
             * @function decode
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.CampaignEvent.MapObjectUpdate} MapObjectUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapObjectUpdate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.CampaignEvent.MapObjectUpdate();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.mapZoneId = reader.string();
                            break;
                        }
                    case 2: {
                            message.mapObjectId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MapObjectUpdate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.CampaignEvent.MapObjectUpdate} MapObjectUpdate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MapObjectUpdate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MapObjectUpdate message.
             * @function verify
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MapObjectUpdate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    if (!$util.isString(message.mapZoneId))
                        return "mapZoneId: string expected";
                if (message.mapObjectId != null && message.hasOwnProperty("mapObjectId"))
                    if (!$util.isString(message.mapObjectId))
                        return "mapObjectId: string expected";
                return null;
            };

            /**
             * Creates a MapObjectUpdate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.CampaignEvent.MapObjectUpdate} MapObjectUpdate
             */
            MapObjectUpdate.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.CampaignEvent.MapObjectUpdate)
                    return object;
                let message = new $root.maps.CampaignEvent.MapObjectUpdate();
                if (object.mapZoneId != null)
                    message.mapZoneId = String(object.mapZoneId);
                if (object.mapObjectId != null)
                    message.mapObjectId = String(object.mapObjectId);
                return message;
            };

            /**
             * Creates a plain object from a MapObjectUpdate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {maps.CampaignEvent.MapObjectUpdate} message MapObjectUpdate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MapObjectUpdate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.mapZoneId = "";
                    object.mapObjectId = "";
                }
                if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                    object.mapZoneId = message.mapZoneId;
                if (message.mapObjectId != null && message.hasOwnProperty("mapObjectId"))
                    object.mapObjectId = message.mapObjectId;
                return object;
            };

            /**
             * Converts this MapObjectUpdate to JSON.
             * @function toJSON
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MapObjectUpdate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MapObjectUpdate
             * @function getTypeUrl
             * @memberof maps.CampaignEvent.MapObjectUpdate
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MapObjectUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.CampaignEvent.MapObjectUpdate";
            };

            return MapObjectUpdate;
        })();

        return CampaignEvent;
    })();

    /**
     * MapLayer enum.
     * @name maps.MapLayer
     * @enum {number}
     * @property {number} DEFAULT_MAP_LAYER=0 DEFAULT_MAP_LAYER value
     */
    maps.MapLayer = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT_MAP_LAYER"] = 0;
        return values;
    })();

    /**
     * IconType enum.
     * @name maps.IconType
     * @enum {number}
     * @property {number} UNKNOWN_ICON=0 UNKNOWN_ICON value
     * @property {number} STAR_ICON=1 STAR_ICON value
     * @property {number} CIRCLE_OUTLINE_ICON=2 CIRCLE_OUTLINE_ICON value
     * @property {number} CIRCLE_FILLED_ICON=3 CIRCLE_FILLED_ICON value
     * @property {number} HEART_ICON=4 HEART_ICON value
     * @property {number} TREASURE_ICON=5 TREASURE_ICON value
     * @property {number} SKULL_ICON=6 SKULL_ICON value
     * @property {number} SCROLL_ICON=7 SCROLL_ICON value
     */
    maps.IconType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ICON"] = 0;
        values[valuesById[1] = "STAR_ICON"] = 1;
        values[valuesById[2] = "CIRCLE_OUTLINE_ICON"] = 2;
        values[valuesById[3] = "CIRCLE_FILLED_ICON"] = 3;
        values[valuesById[4] = "HEART_ICON"] = 4;
        values[valuesById[5] = "TREASURE_ICON"] = 5;
        values[valuesById[6] = "SKULL_ICON"] = 6;
        values[valuesById[7] = "SCROLL_ICON"] = 7;
        return values;
    })();

    /**
     * IconColor enum.
     * @name maps.IconColor
     * @enum {number}
     * @property {number} UNKNOWN_ICON_COLOR=0 UNKNOWN_ICON_COLOR value
     * @property {number} RED_ICON_COLOR=1 RED_ICON_COLOR value
     * @property {number} GREEN_ICON_COLOR=2 GREEN_ICON_COLOR value
     * @property {number} BLUE_ICON_COLOR=3 BLUE_ICON_COLOR value
     * @property {number} BLACK_ICON_COLOR=4 BLACK_ICON_COLOR value
     * @property {number} GRAY_ICON_COLOR=5 GRAY_ICON_COLOR value
     * @property {number} WHITE_ICON_COLOR=6 WHITE_ICON_COLOR value
     */
    maps.IconColor = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_ICON_COLOR"] = 0;
        values[valuesById[1] = "RED_ICON_COLOR"] = 1;
        values[valuesById[2] = "GREEN_ICON_COLOR"] = 2;
        values[valuesById[3] = "BLUE_ICON_COLOR"] = 3;
        values[valuesById[4] = "BLACK_ICON_COLOR"] = 4;
        values[valuesById[5] = "GRAY_ICON_COLOR"] = 5;
        values[valuesById[6] = "WHITE_ICON_COLOR"] = 6;
        return values;
    })();

    /**
     * Faction enum.
     * @name maps.Faction
     * @enum {number}
     * @property {number} UNKNOWN_FACTION=0 UNKNOWN_FACTION value
     * @property {number} PLAYER_FACTION=1 PLAYER_FACTION value
     * @property {number} ALLY_FACTION=2 ALLY_FACTION value
     * @property {number} NEUTRAL_FACTION=3 NEUTRAL_FACTION value
     * @property {number} HOSTILE_FACTION=4 HOSTILE_FACTION value
     * @property {number} MYSTERY_FACTION=5 MYSTERY_FACTION value
     */
    maps.Faction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_FACTION"] = 0;
        values[valuesById[1] = "PLAYER_FACTION"] = 1;
        values[valuesById[2] = "ALLY_FACTION"] = 2;
        values[valuesById[3] = "NEUTRAL_FACTION"] = 3;
        values[valuesById[4] = "HOSTILE_FACTION"] = 4;
        values[valuesById[5] = "MYSTERY_FACTION"] = 5;
        return values;
    })();

    /**
     * BorderStyle enum.
     * @name maps.BorderStyle
     * @enum {number}
     * @property {number} UNKNOWN_BORDER_STYLE=0 UNKNOWN_BORDER_STYLE value
     * @property {number} RING_BORDER_STYLE=1 RING_BORDER_STYLE value
     */
    maps.BorderStyle = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_BORDER_STYLE"] = 0;
        values[valuesById[1] = "RING_BORDER_STYLE"] = 1;
        return values;
    })();

    maps.BorderDetails = (function() {

        /**
         * Properties of a BorderDetails.
         * @memberof maps
         * @interface IBorderDetails
         * @property {maps.BorderStyle|null} [style] BorderDetails style
         * @property {string|null} [color] BorderDetails color
         */

        /**
         * Constructs a new BorderDetails.
         * @memberof maps
         * @classdesc Represents a BorderDetails.
         * @implements IBorderDetails
         * @constructor
         * @param {maps.IBorderDetails=} [properties] Properties to set
         */
        function BorderDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BorderDetails style.
         * @member {maps.BorderStyle} style
         * @memberof maps.BorderDetails
         * @instance
         */
        BorderDetails.prototype.style = 0;

        /**
         * BorderDetails color.
         * @member {string} color
         * @memberof maps.BorderDetails
         * @instance
         */
        BorderDetails.prototype.color = "";

        /**
         * Creates a new BorderDetails instance using the specified properties.
         * @function create
         * @memberof maps.BorderDetails
         * @static
         * @param {maps.IBorderDetails=} [properties] Properties to set
         * @returns {maps.BorderDetails} BorderDetails instance
         */
        BorderDetails.create = function create(properties) {
            return new BorderDetails(properties);
        };

        /**
         * Encodes the specified BorderDetails message. Does not implicitly {@link maps.BorderDetails.verify|verify} messages.
         * @function encode
         * @memberof maps.BorderDetails
         * @static
         * @param {maps.IBorderDetails} message BorderDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BorderDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.style);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.color);
            return writer;
        };

        /**
         * Encodes the specified BorderDetails message, length delimited. Does not implicitly {@link maps.BorderDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.BorderDetails
         * @static
         * @param {maps.IBorderDetails} message BorderDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BorderDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BorderDetails message from the specified reader or buffer.
         * @function decode
         * @memberof maps.BorderDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.BorderDetails} BorderDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BorderDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.BorderDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.style = reader.int32();
                        break;
                    }
                case 2: {
                        message.color = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BorderDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.BorderDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.BorderDetails} BorderDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BorderDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BorderDetails message.
         * @function verify
         * @memberof maps.BorderDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BorderDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.style != null && message.hasOwnProperty("style"))
                switch (message.style) {
                default:
                    return "style: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            return null;
        };

        /**
         * Creates a BorderDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.BorderDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.BorderDetails} BorderDetails
         */
        BorderDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.BorderDetails)
                return object;
            let message = new $root.maps.BorderDetails();
            switch (object.style) {
            default:
                if (typeof object.style === "number") {
                    message.style = object.style;
                    break;
                }
                break;
            case "UNKNOWN_BORDER_STYLE":
            case 0:
                message.style = 0;
                break;
            case "RING_BORDER_STYLE":
            case 1:
                message.style = 1;
                break;
            }
            if (object.color != null)
                message.color = String(object.color);
            return message;
        };

        /**
         * Creates a plain object from a BorderDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.BorderDetails
         * @static
         * @param {maps.BorderDetails} message BorderDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BorderDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.style = options.enums === String ? "UNKNOWN_BORDER_STYLE" : 0;
                object.color = "";
            }
            if (message.style != null && message.hasOwnProperty("style"))
                object.style = options.enums === String ? $root.maps.BorderStyle[message.style] === undefined ? message.style : $root.maps.BorderStyle[message.style] : message.style;
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            return object;
        };

        /**
         * Converts this BorderDetails to JSON.
         * @function toJSON
         * @memberof maps.BorderDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BorderDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BorderDetails
         * @function getTypeUrl
         * @memberof maps.BorderDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BorderDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.BorderDetails";
        };

        return BorderDetails;
    })();

    maps.MapObjectPermissions = (function() {

        /**
         * Properties of a MapObjectPermissions.
         * @memberof maps
         * @interface IMapObjectPermissions
         * @property {boolean|null} [canRead] MapObjectPermissions canRead
         * @property {boolean|null} [canWrite] MapObjectPermissions canWrite
         * @property {boolean|null} [canDelete] MapObjectPermissions canDelete
         * @property {boolean|null} [canChangeFaction] MapObjectPermissions canChangeFaction
         * @property {boolean|null} [canChangeIsSecret] MapObjectPermissions canChangeIsSecret
         * @property {boolean|null} [canChangeStatus] MapObjectPermissions canChangeStatus
         * @property {boolean|null} [canChangeLocation] MapObjectPermissions canChangeLocation
         * @property {common.IFieldPermissions|null} [publicFieldAccess] MapObjectPermissions publicFieldAccess
         * @property {common.IFieldPermissions|null} [protectedFieldAccess] MapObjectPermissions protectedFieldAccess
         * @property {common.IFieldPermissions|null} [secretFieldAccess] MapObjectPermissions secretFieldAccess
         */

        /**
         * Constructs a new MapObjectPermissions.
         * @memberof maps
         * @classdesc Represents a MapObjectPermissions.
         * @implements IMapObjectPermissions
         * @constructor
         * @param {maps.IMapObjectPermissions=} [properties] Properties to set
         */
        function MapObjectPermissions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapObjectPermissions canRead.
         * @member {boolean} canRead
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canRead = false;

        /**
         * MapObjectPermissions canWrite.
         * @member {boolean} canWrite
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canWrite = false;

        /**
         * MapObjectPermissions canDelete.
         * @member {boolean} canDelete
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canDelete = false;

        /**
         * MapObjectPermissions canChangeFaction.
         * @member {boolean} canChangeFaction
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canChangeFaction = false;

        /**
         * MapObjectPermissions canChangeIsSecret.
         * @member {boolean} canChangeIsSecret
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canChangeIsSecret = false;

        /**
         * MapObjectPermissions canChangeStatus.
         * @member {boolean} canChangeStatus
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canChangeStatus = false;

        /**
         * MapObjectPermissions canChangeLocation.
         * @member {boolean} canChangeLocation
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.canChangeLocation = false;

        /**
         * MapObjectPermissions publicFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} publicFieldAccess
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.publicFieldAccess = null;

        /**
         * MapObjectPermissions protectedFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} protectedFieldAccess
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.protectedFieldAccess = null;

        /**
         * MapObjectPermissions secretFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} secretFieldAccess
         * @memberof maps.MapObjectPermissions
         * @instance
         */
        MapObjectPermissions.prototype.secretFieldAccess = null;

        /**
         * Creates a new MapObjectPermissions instance using the specified properties.
         * @function create
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {maps.IMapObjectPermissions=} [properties] Properties to set
         * @returns {maps.MapObjectPermissions} MapObjectPermissions instance
         */
        MapObjectPermissions.create = function create(properties) {
            return new MapObjectPermissions(properties);
        };

        /**
         * Encodes the specified MapObjectPermissions message. Does not implicitly {@link maps.MapObjectPermissions.verify|verify} messages.
         * @function encode
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {maps.IMapObjectPermissions} message MapObjectPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectPermissions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canRead != null && Object.hasOwnProperty.call(message, "canRead"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canRead);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canWrite);
            if (message.canDelete != null && Object.hasOwnProperty.call(message, "canDelete"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.canDelete);
            if (message.canChangeFaction != null && Object.hasOwnProperty.call(message, "canChangeFaction"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canChangeFaction);
            if (message.canChangeIsSecret != null && Object.hasOwnProperty.call(message, "canChangeIsSecret"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.canChangeIsSecret);
            if (message.canChangeStatus != null && Object.hasOwnProperty.call(message, "canChangeStatus"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.canChangeStatus);
            if (message.canChangeLocation != null && Object.hasOwnProperty.call(message, "canChangeLocation"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.canChangeLocation);
            if (message.publicFieldAccess != null && Object.hasOwnProperty.call(message, "publicFieldAccess"))
                $root.common.FieldPermissions.encode(message.publicFieldAccess, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.protectedFieldAccess != null && Object.hasOwnProperty.call(message, "protectedFieldAccess"))
                $root.common.FieldPermissions.encode(message.protectedFieldAccess, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.secretFieldAccess != null && Object.hasOwnProperty.call(message, "secretFieldAccess"))
                $root.common.FieldPermissions.encode(message.secretFieldAccess, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapObjectPermissions message, length delimited. Does not implicitly {@link maps.MapObjectPermissions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {maps.IMapObjectPermissions} message MapObjectPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObjectPermissions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapObjectPermissions message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapObjectPermissions} MapObjectPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectPermissions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObjectPermissions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.canRead = reader.bool();
                        break;
                    }
                case 2: {
                        message.canWrite = reader.bool();
                        break;
                    }
                case 3: {
                        message.canDelete = reader.bool();
                        break;
                    }
                case 10: {
                        message.canChangeFaction = reader.bool();
                        break;
                    }
                case 11: {
                        message.canChangeIsSecret = reader.bool();
                        break;
                    }
                case 12: {
                        message.canChangeStatus = reader.bool();
                        break;
                    }
                case 13: {
                        message.canChangeLocation = reader.bool();
                        break;
                    }
                case 50: {
                        message.publicFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.protectedFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 52: {
                        message.secretFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapObjectPermissions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapObjectPermissions} MapObjectPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObjectPermissions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapObjectPermissions message.
         * @function verify
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapObjectPermissions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                if (typeof message.canRead !== "boolean")
                    return "canRead: boolean expected";
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                if (typeof message.canDelete !== "boolean")
                    return "canDelete: boolean expected";
            if (message.canChangeFaction != null && message.hasOwnProperty("canChangeFaction"))
                if (typeof message.canChangeFaction !== "boolean")
                    return "canChangeFaction: boolean expected";
            if (message.canChangeIsSecret != null && message.hasOwnProperty("canChangeIsSecret"))
                if (typeof message.canChangeIsSecret !== "boolean")
                    return "canChangeIsSecret: boolean expected";
            if (message.canChangeStatus != null && message.hasOwnProperty("canChangeStatus"))
                if (typeof message.canChangeStatus !== "boolean")
                    return "canChangeStatus: boolean expected";
            if (message.canChangeLocation != null && message.hasOwnProperty("canChangeLocation"))
                if (typeof message.canChangeLocation !== "boolean")
                    return "canChangeLocation: boolean expected";
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.publicFieldAccess);
                if (error)
                    return "publicFieldAccess." + error;
            }
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.protectedFieldAccess);
                if (error)
                    return "protectedFieldAccess." + error;
            }
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.secretFieldAccess);
                if (error)
                    return "secretFieldAccess." + error;
            }
            return null;
        };

        /**
         * Creates a MapObjectPermissions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapObjectPermissions} MapObjectPermissions
         */
        MapObjectPermissions.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapObjectPermissions)
                return object;
            let message = new $root.maps.MapObjectPermissions();
            if (object.canRead != null)
                message.canRead = Boolean(object.canRead);
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            if (object.canDelete != null)
                message.canDelete = Boolean(object.canDelete);
            if (object.canChangeFaction != null)
                message.canChangeFaction = Boolean(object.canChangeFaction);
            if (object.canChangeIsSecret != null)
                message.canChangeIsSecret = Boolean(object.canChangeIsSecret);
            if (object.canChangeStatus != null)
                message.canChangeStatus = Boolean(object.canChangeStatus);
            if (object.canChangeLocation != null)
                message.canChangeLocation = Boolean(object.canChangeLocation);
            if (object.publicFieldAccess != null) {
                if (typeof object.publicFieldAccess !== "object")
                    throw TypeError(".maps.MapObjectPermissions.publicFieldAccess: object expected");
                message.publicFieldAccess = $root.common.FieldPermissions.fromObject(object.publicFieldAccess);
            }
            if (object.protectedFieldAccess != null) {
                if (typeof object.protectedFieldAccess !== "object")
                    throw TypeError(".maps.MapObjectPermissions.protectedFieldAccess: object expected");
                message.protectedFieldAccess = $root.common.FieldPermissions.fromObject(object.protectedFieldAccess);
            }
            if (object.secretFieldAccess != null) {
                if (typeof object.secretFieldAccess !== "object")
                    throw TypeError(".maps.MapObjectPermissions.secretFieldAccess: object expected");
                message.secretFieldAccess = $root.common.FieldPermissions.fromObject(object.secretFieldAccess);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapObjectPermissions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {maps.MapObjectPermissions} message MapObjectPermissions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapObjectPermissions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.canRead = false;
                object.canWrite = false;
                object.canDelete = false;
                object.canChangeFaction = false;
                object.canChangeIsSecret = false;
                object.canChangeStatus = false;
                object.canChangeLocation = false;
                object.publicFieldAccess = null;
                object.protectedFieldAccess = null;
                object.secretFieldAccess = null;
            }
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                object.canRead = message.canRead;
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                object.canDelete = message.canDelete;
            if (message.canChangeFaction != null && message.hasOwnProperty("canChangeFaction"))
                object.canChangeFaction = message.canChangeFaction;
            if (message.canChangeIsSecret != null && message.hasOwnProperty("canChangeIsSecret"))
                object.canChangeIsSecret = message.canChangeIsSecret;
            if (message.canChangeStatus != null && message.hasOwnProperty("canChangeStatus"))
                object.canChangeStatus = message.canChangeStatus;
            if (message.canChangeLocation != null && message.hasOwnProperty("canChangeLocation"))
                object.canChangeLocation = message.canChangeLocation;
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess"))
                object.publicFieldAccess = $root.common.FieldPermissions.toObject(message.publicFieldAccess, options);
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess"))
                object.protectedFieldAccess = $root.common.FieldPermissions.toObject(message.protectedFieldAccess, options);
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess"))
                object.secretFieldAccess = $root.common.FieldPermissions.toObject(message.secretFieldAccess, options);
            return object;
        };

        /**
         * Converts this MapObjectPermissions to JSON.
         * @function toJSON
         * @memberof maps.MapObjectPermissions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapObjectPermissions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapObjectPermissions
         * @function getTypeUrl
         * @memberof maps.MapObjectPermissions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapObjectPermissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapObjectPermissions";
        };

        return MapObjectPermissions;
    })();

    maps.MapObject = (function() {

        /**
         * Properties of a MapObject.
         * @memberof maps
         * @interface IMapObject
         * @property {string|null} [id] MapObject id
         * @property {string|null} [name] MapObject name
         * @property {string|null} [ownerId] MapObject ownerId
         * @property {string|null} [campaignId] MapObject campaignId
         * @property {string|null} [mapZoneId] MapObject mapZoneId
         * @property {Array.<common.IAccessGrant>|null} [accessGrants] MapObject accessGrants
         * @property {maps.MapLayer|null} [layer] MapObject layer
         * @property {maps.IMapObjectPermissions|null} [permissions] MapObject permissions
         * @property {maps.MapObject.IWall|null} [wall] MapObject wall
         * @property {maps.MapObject.IVisibility|null} [visibility] MapObject visibility
         * @property {maps.MapObject.IToken|null} [token] MapObject token
         * @property {maps.MapObject.IPin|null} [pin] MapObject pin
         */

        /**
         * Constructs a new MapObject.
         * @memberof maps
         * @classdesc Represents a MapObject.
         * @implements IMapObject
         * @constructor
         * @param {maps.IMapObject=} [properties] Properties to set
         */
        function MapObject(properties) {
            this.accessGrants = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapObject id.
         * @member {string} id
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.id = "";

        /**
         * MapObject name.
         * @member {string} name
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.name = "";

        /**
         * MapObject ownerId.
         * @member {string} ownerId
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.ownerId = "";

        /**
         * MapObject campaignId.
         * @member {string} campaignId
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.campaignId = "";

        /**
         * MapObject mapZoneId.
         * @member {string} mapZoneId
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.mapZoneId = "";

        /**
         * MapObject accessGrants.
         * @member {Array.<common.IAccessGrant>} accessGrants
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.accessGrants = $util.emptyArray;

        /**
         * MapObject layer.
         * @member {maps.MapLayer} layer
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.layer = 0;

        /**
         * MapObject permissions.
         * @member {maps.IMapObjectPermissions|null|undefined} permissions
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.permissions = null;

        /**
         * MapObject wall.
         * @member {maps.MapObject.IWall|null|undefined} wall
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.wall = null;

        /**
         * MapObject visibility.
         * @member {maps.MapObject.IVisibility|null|undefined} visibility
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.visibility = null;

        /**
         * MapObject token.
         * @member {maps.MapObject.IToken|null|undefined} token
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.token = null;

        /**
         * MapObject pin.
         * @member {maps.MapObject.IPin|null|undefined} pin
         * @memberof maps.MapObject
         * @instance
         */
        MapObject.prototype.pin = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MapObject objectDetails.
         * @member {"wall"|"visibility"|"token"|"pin"|undefined} objectDetails
         * @memberof maps.MapObject
         * @instance
         */
        Object.defineProperty(MapObject.prototype, "objectDetails", {
            get: $util.oneOfGetter($oneOfFields = ["wall", "visibility", "token", "pin"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MapObject instance using the specified properties.
         * @function create
         * @memberof maps.MapObject
         * @static
         * @param {maps.IMapObject=} [properties] Properties to set
         * @returns {maps.MapObject} MapObject instance
         */
        MapObject.create = function create(properties) {
            return new MapObject(properties);
        };

        /**
         * Encodes the specified MapObject message. Does not implicitly {@link maps.MapObject.verify|verify} messages.
         * @function encode
         * @memberof maps.MapObject
         * @static
         * @param {maps.IMapObject} message MapObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ownerId);
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.campaignId);
            if (message.mapZoneId != null && Object.hasOwnProperty.call(message, "mapZoneId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.mapZoneId);
            if (message.accessGrants != null && message.accessGrants.length)
                for (let i = 0; i < message.accessGrants.length; ++i)
                    $root.common.AccessGrant.encode(message.accessGrants[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.layer != null && Object.hasOwnProperty.call(message, "layer"))
                writer.uint32(/* id 19, wireType 0 =*/152).int32(message.layer);
            if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                $root.maps.MapObjectPermissions.encode(message.permissions, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.wall != null && Object.hasOwnProperty.call(message, "wall"))
                $root.maps.MapObject.Wall.encode(message.wall, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
            if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                $root.maps.MapObject.Visibility.encode(message.visibility, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                $root.maps.MapObject.Token.encode(message.token, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
            if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
                $root.maps.MapObject.Pin.encode(message.pin, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapObject message, length delimited. Does not implicitly {@link maps.MapObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapObject
         * @static
         * @param {maps.IMapObject} message MapObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapObject message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapObject} MapObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.ownerId = reader.string();
                        break;
                    }
                case 16: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 17: {
                        message.mapZoneId = reader.string();
                        break;
                    }
                case 18: {
                        if (!(message.accessGrants && message.accessGrants.length))
                            message.accessGrants = [];
                        message.accessGrants.push($root.common.AccessGrant.decode(reader, reader.uint32()));
                        break;
                    }
                case 19: {
                        message.layer = reader.int32();
                        break;
                    }
                case 20: {
                        message.permissions = $root.maps.MapObjectPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 100: {
                        message.wall = $root.maps.MapObject.Wall.decode(reader, reader.uint32());
                        break;
                    }
                case 101: {
                        message.visibility = $root.maps.MapObject.Visibility.decode(reader, reader.uint32());
                        break;
                    }
                case 102: {
                        message.token = $root.maps.MapObject.Token.decode(reader, reader.uint32());
                        break;
                    }
                case 103: {
                        message.pin = $root.maps.MapObject.Pin.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapObject} MapObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapObject message.
         * @function verify
         * @memberof maps.MapObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                if (!$util.isString(message.ownerId))
                    return "ownerId: string expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                if (!$util.isString(message.mapZoneId))
                    return "mapZoneId: string expected";
            if (message.accessGrants != null && message.hasOwnProperty("accessGrants")) {
                if (!Array.isArray(message.accessGrants))
                    return "accessGrants: array expected";
                for (let i = 0; i < message.accessGrants.length; ++i) {
                    let error = $root.common.AccessGrant.verify(message.accessGrants[i]);
                    if (error)
                        return "accessGrants." + error;
                }
            }
            if (message.layer != null && message.hasOwnProperty("layer"))
                switch (message.layer) {
                default:
                    return "layer: enum value expected";
                case 0:
                    break;
                }
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                let error = $root.maps.MapObjectPermissions.verify(message.permissions);
                if (error)
                    return "permissions." + error;
            }
            if (message.wall != null && message.hasOwnProperty("wall")) {
                properties.objectDetails = 1;
                {
                    let error = $root.maps.MapObject.Wall.verify(message.wall);
                    if (error)
                        return "wall." + error;
                }
            }
            if (message.visibility != null && message.hasOwnProperty("visibility")) {
                if (properties.objectDetails === 1)
                    return "objectDetails: multiple values";
                properties.objectDetails = 1;
                {
                    let error = $root.maps.MapObject.Visibility.verify(message.visibility);
                    if (error)
                        return "visibility." + error;
                }
            }
            if (message.token != null && message.hasOwnProperty("token")) {
                if (properties.objectDetails === 1)
                    return "objectDetails: multiple values";
                properties.objectDetails = 1;
                {
                    let error = $root.maps.MapObject.Token.verify(message.token);
                    if (error)
                        return "token." + error;
                }
            }
            if (message.pin != null && message.hasOwnProperty("pin")) {
                if (properties.objectDetails === 1)
                    return "objectDetails: multiple values";
                properties.objectDetails = 1;
                {
                    let error = $root.maps.MapObject.Pin.verify(message.pin);
                    if (error)
                        return "pin." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MapObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapObject} MapObject
         */
        MapObject.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapObject)
                return object;
            let message = new $root.maps.MapObject();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownerId != null)
                message.ownerId = String(object.ownerId);
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.mapZoneId != null)
                message.mapZoneId = String(object.mapZoneId);
            if (object.accessGrants) {
                if (!Array.isArray(object.accessGrants))
                    throw TypeError(".maps.MapObject.accessGrants: array expected");
                message.accessGrants = [];
                for (let i = 0; i < object.accessGrants.length; ++i) {
                    if (typeof object.accessGrants[i] !== "object")
                        throw TypeError(".maps.MapObject.accessGrants: object expected");
                    message.accessGrants[i] = $root.common.AccessGrant.fromObject(object.accessGrants[i]);
                }
            }
            switch (object.layer) {
            default:
                if (typeof object.layer === "number") {
                    message.layer = object.layer;
                    break;
                }
                break;
            case "DEFAULT_MAP_LAYER":
            case 0:
                message.layer = 0;
                break;
            }
            if (object.permissions != null) {
                if (typeof object.permissions !== "object")
                    throw TypeError(".maps.MapObject.permissions: object expected");
                message.permissions = $root.maps.MapObjectPermissions.fromObject(object.permissions);
            }
            if (object.wall != null) {
                if (typeof object.wall !== "object")
                    throw TypeError(".maps.MapObject.wall: object expected");
                message.wall = $root.maps.MapObject.Wall.fromObject(object.wall);
            }
            if (object.visibility != null) {
                if (typeof object.visibility !== "object")
                    throw TypeError(".maps.MapObject.visibility: object expected");
                message.visibility = $root.maps.MapObject.Visibility.fromObject(object.visibility);
            }
            if (object.token != null) {
                if (typeof object.token !== "object")
                    throw TypeError(".maps.MapObject.token: object expected");
                message.token = $root.maps.MapObject.Token.fromObject(object.token);
            }
            if (object.pin != null) {
                if (typeof object.pin !== "object")
                    throw TypeError(".maps.MapObject.pin: object expected");
                message.pin = $root.maps.MapObject.Pin.fromObject(object.pin);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapObject
         * @static
         * @param {maps.MapObject} message MapObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.accessGrants = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownerId = "";
                object.campaignId = "";
                object.mapZoneId = "";
                object.layer = options.enums === String ? "DEFAULT_MAP_LAYER" : 0;
                object.permissions = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                object.ownerId = message.ownerId;
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.mapZoneId != null && message.hasOwnProperty("mapZoneId"))
                object.mapZoneId = message.mapZoneId;
            if (message.accessGrants && message.accessGrants.length) {
                object.accessGrants = [];
                for (let j = 0; j < message.accessGrants.length; ++j)
                    object.accessGrants[j] = $root.common.AccessGrant.toObject(message.accessGrants[j], options);
            }
            if (message.layer != null && message.hasOwnProperty("layer"))
                object.layer = options.enums === String ? $root.maps.MapLayer[message.layer] === undefined ? message.layer : $root.maps.MapLayer[message.layer] : message.layer;
            if (message.permissions != null && message.hasOwnProperty("permissions"))
                object.permissions = $root.maps.MapObjectPermissions.toObject(message.permissions, options);
            if (message.wall != null && message.hasOwnProperty("wall")) {
                object.wall = $root.maps.MapObject.Wall.toObject(message.wall, options);
                if (options.oneofs)
                    object.objectDetails = "wall";
            }
            if (message.visibility != null && message.hasOwnProperty("visibility")) {
                object.visibility = $root.maps.MapObject.Visibility.toObject(message.visibility, options);
                if (options.oneofs)
                    object.objectDetails = "visibility";
            }
            if (message.token != null && message.hasOwnProperty("token")) {
                object.token = $root.maps.MapObject.Token.toObject(message.token, options);
                if (options.oneofs)
                    object.objectDetails = "token";
            }
            if (message.pin != null && message.hasOwnProperty("pin")) {
                object.pin = $root.maps.MapObject.Pin.toObject(message.pin, options);
                if (options.oneofs)
                    object.objectDetails = "pin";
            }
            return object;
        };

        /**
         * Converts this MapObject to JSON.
         * @function toJSON
         * @memberof maps.MapObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapObject
         * @function getTypeUrl
         * @memberof maps.MapObject
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapObject.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapObject";
        };

        MapObject.Wall = (function() {

            /**
             * Properties of a Wall.
             * @memberof maps.MapObject
             * @interface IWall
             */

            /**
             * Constructs a new Wall.
             * @memberof maps.MapObject
             * @classdesc Represents a Wall.
             * @implements IWall
             * @constructor
             * @param {maps.MapObject.IWall=} [properties] Properties to set
             */
            function Wall(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Wall instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.Wall
             * @static
             * @param {maps.MapObject.IWall=} [properties] Properties to set
             * @returns {maps.MapObject.Wall} Wall instance
             */
            Wall.create = function create(properties) {
                return new Wall(properties);
            };

            /**
             * Encodes the specified Wall message. Does not implicitly {@link maps.MapObject.Wall.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.Wall
             * @static
             * @param {maps.MapObject.IWall} message Wall message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Wall.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Wall message, length delimited. Does not implicitly {@link maps.MapObject.Wall.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.Wall
             * @static
             * @param {maps.MapObject.IWall} message Wall message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Wall.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Wall message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.Wall
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.Wall} Wall
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Wall.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.Wall();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Wall message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.Wall
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.Wall} Wall
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Wall.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Wall message.
             * @function verify
             * @memberof maps.MapObject.Wall
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Wall.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Wall message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.Wall
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.Wall} Wall
             */
            Wall.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.Wall)
                    return object;
                return new $root.maps.MapObject.Wall();
            };

            /**
             * Creates a plain object from a Wall message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.Wall
             * @static
             * @param {maps.MapObject.Wall} message Wall
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Wall.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Wall to JSON.
             * @function toJSON
             * @memberof maps.MapObject.Wall
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Wall.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Wall
             * @function getTypeUrl
             * @memberof maps.MapObject.Wall
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Wall.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.Wall";
            };

            return Wall;
        })();

        MapObject.Visibility = (function() {

            /**
             * Properties of a Visibility.
             * @memberof maps.MapObject
             * @interface IVisibility
             */

            /**
             * Constructs a new Visibility.
             * @memberof maps.MapObject
             * @classdesc Represents a Visibility.
             * @implements IVisibility
             * @constructor
             * @param {maps.MapObject.IVisibility=} [properties] Properties to set
             */
            function Visibility(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Visibility instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {maps.MapObject.IVisibility=} [properties] Properties to set
             * @returns {maps.MapObject.Visibility} Visibility instance
             */
            Visibility.create = function create(properties) {
                return new Visibility(properties);
            };

            /**
             * Encodes the specified Visibility message. Does not implicitly {@link maps.MapObject.Visibility.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {maps.MapObject.IVisibility} message Visibility message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Visibility.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified Visibility message, length delimited. Does not implicitly {@link maps.MapObject.Visibility.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {maps.MapObject.IVisibility} message Visibility message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Visibility.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Visibility message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.Visibility} Visibility
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Visibility.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.Visibility();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Visibility message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.Visibility} Visibility
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Visibility.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Visibility message.
             * @function verify
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Visibility.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Visibility message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.Visibility} Visibility
             */
            Visibility.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.Visibility)
                    return object;
                return new $root.maps.MapObject.Visibility();
            };

            /**
             * Creates a plain object from a Visibility message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {maps.MapObject.Visibility} message Visibility
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Visibility.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Visibility to JSON.
             * @function toJSON
             * @memberof maps.MapObject.Visibility
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Visibility.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Visibility
             * @function getTypeUrl
             * @memberof maps.MapObject.Visibility
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Visibility.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.Visibility";
            };

            return Visibility;
        })();

        MapObject.Token = (function() {

            /**
             * Properties of a Token.
             * @memberof maps.MapObject
             * @interface IToken
             * @property {geometry.IGridLocation|null} [position] Token position
             * @property {number|null} [rotation] Token rotation
             * @property {number|null} [gridWidth] Token gridWidth
             * @property {number|null} [gridHeight] Token gridHeight
             * @property {number|null} [imageScale] Token imageScale
             * @property {string|null} [imageId] Token imageId
             * @property {maps.IMapImage|null} [image] Token image
             * @property {string|null} [smallImageId] Token smallImageId
             * @property {maps.IMapImage|null} [smallImage] Token smallImage
             * @property {string|null} [portraitImageId] Token portraitImageId
             * @property {maps.IMapImage|null} [portraitImage] Token portraitImage
             * @property {string|null} [shortName] Token shortName
             * @property {boolean|null} [isSecret] Token isSecret
             * @property {maps.Faction|null} [faction] Token faction
             * @property {string|null} [annotation] Token annotation
             * @property {maps.IBorderDetails|null} [border] Token border
             * @property {string|null} [description] Token description
             */

            /**
             * Constructs a new Token.
             * @memberof maps.MapObject
             * @classdesc Represents a Token.
             * @implements IToken
             * @constructor
             * @param {maps.MapObject.IToken=} [properties] Properties to set
             */
            function Token(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Token position.
             * @member {geometry.IGridLocation|null|undefined} position
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.position = null;

            /**
             * Token rotation.
             * @member {number} rotation
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.rotation = 0;

            /**
             * Token gridWidth.
             * @member {number} gridWidth
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.gridWidth = 0;

            /**
             * Token gridHeight.
             * @member {number} gridHeight
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.gridHeight = 0;

            /**
             * Token imageScale.
             * @member {number} imageScale
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.imageScale = 0;

            /**
             * Token imageId.
             * @member {string} imageId
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.imageId = "";

            /**
             * Token image.
             * @member {maps.IMapImage|null|undefined} image
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.image = null;

            /**
             * Token smallImageId.
             * @member {string} smallImageId
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.smallImageId = "";

            /**
             * Token smallImage.
             * @member {maps.IMapImage|null|undefined} smallImage
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.smallImage = null;

            /**
             * Token portraitImageId.
             * @member {string} portraitImageId
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.portraitImageId = "";

            /**
             * Token portraitImage.
             * @member {maps.IMapImage|null|undefined} portraitImage
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.portraitImage = null;

            /**
             * Token shortName.
             * @member {string} shortName
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.shortName = "";

            /**
             * Token isSecret.
             * @member {boolean} isSecret
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.isSecret = false;

            /**
             * Token faction.
             * @member {maps.Faction} faction
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.faction = 0;

            /**
             * Token annotation.
             * @member {string} annotation
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.annotation = "";

            /**
             * Token border.
             * @member {maps.IBorderDetails|null|undefined} border
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.border = null;

            /**
             * Token description.
             * @member {string} description
             * @memberof maps.MapObject.Token
             * @instance
             */
            Token.prototype.description = "";

            /**
             * Creates a new Token instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.Token
             * @static
             * @param {maps.MapObject.IToken=} [properties] Properties to set
             * @returns {maps.MapObject.Token} Token instance
             */
            Token.create = function create(properties) {
                return new Token(properties);
            };

            /**
             * Encodes the specified Token message. Does not implicitly {@link maps.MapObject.Token.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.Token
             * @static
             * @param {maps.MapObject.IToken} message Token message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Token.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    $root.geometry.GridLocation.encode(message.position, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.rotation != null && Object.hasOwnProperty.call(message, "rotation"))
                    writer.uint32(/* id 2, wireType 5 =*/21).float(message.rotation);
                if (message.gridWidth != null && Object.hasOwnProperty.call(message, "gridWidth"))
                    writer.uint32(/* id 3, wireType 5 =*/29).float(message.gridWidth);
                if (message.gridHeight != null && Object.hasOwnProperty.call(message, "gridHeight"))
                    writer.uint32(/* id 4, wireType 5 =*/37).float(message.gridHeight);
                if (message.imageScale != null && Object.hasOwnProperty.call(message, "imageScale"))
                    writer.uint32(/* id 5, wireType 5 =*/45).float(message.imageScale);
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageId);
                if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                    $root.maps.MapImage.encode(message.image, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.smallImageId != null && Object.hasOwnProperty.call(message, "smallImageId"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.smallImageId);
                if (message.smallImage != null && Object.hasOwnProperty.call(message, "smallImage"))
                    $root.maps.MapImage.encode(message.smallImage, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.portraitImageId != null && Object.hasOwnProperty.call(message, "portraitImageId"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.portraitImageId);
                if (message.portraitImage != null && Object.hasOwnProperty.call(message, "portraitImage"))
                    $root.maps.MapImage.encode(message.portraitImage, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.shortName != null && Object.hasOwnProperty.call(message, "shortName"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.shortName);
                if (message.isSecret != null && Object.hasOwnProperty.call(message, "isSecret"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isSecret);
                if (message.faction != null && Object.hasOwnProperty.call(message, "faction"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.faction);
                if (message.annotation != null && Object.hasOwnProperty.call(message, "annotation"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.annotation);
                if (message.border != null && Object.hasOwnProperty.call(message, "border"))
                    $root.maps.BorderDetails.encode(message.border, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.description);
                return writer;
            };

            /**
             * Encodes the specified Token message, length delimited. Does not implicitly {@link maps.MapObject.Token.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.Token
             * @static
             * @param {maps.MapObject.IToken} message Token message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Token.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Token message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.Token
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.Token} Token
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Token.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.Token();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.position = $root.geometry.GridLocation.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.rotation = reader.float();
                            break;
                        }
                    case 3: {
                            message.gridWidth = reader.float();
                            break;
                        }
                    case 4: {
                            message.gridHeight = reader.float();
                            break;
                        }
                    case 5: {
                            message.imageScale = reader.float();
                            break;
                        }
                    case 6: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 7: {
                            message.image = $root.maps.MapImage.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.smallImageId = reader.string();
                            break;
                        }
                    case 9: {
                            message.smallImage = $root.maps.MapImage.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.portraitImageId = reader.string();
                            break;
                        }
                    case 11: {
                            message.portraitImage = $root.maps.MapImage.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.shortName = reader.string();
                            break;
                        }
                    case 13: {
                            message.isSecret = reader.bool();
                            break;
                        }
                    case 14: {
                            message.faction = reader.int32();
                            break;
                        }
                    case 15: {
                            message.annotation = reader.string();
                            break;
                        }
                    case 16: {
                            message.border = $root.maps.BorderDetails.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Token message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.Token
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.Token} Token
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Token.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Token message.
             * @function verify
             * @memberof maps.MapObject.Token
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Token.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.position != null && message.hasOwnProperty("position")) {
                    let error = $root.geometry.GridLocation.verify(message.position);
                    if (error)
                        return "position." + error;
                }
                if (message.rotation != null && message.hasOwnProperty("rotation"))
                    if (typeof message.rotation !== "number")
                        return "rotation: number expected";
                if (message.gridWidth != null && message.hasOwnProperty("gridWidth"))
                    if (typeof message.gridWidth !== "number")
                        return "gridWidth: number expected";
                if (message.gridHeight != null && message.hasOwnProperty("gridHeight"))
                    if (typeof message.gridHeight !== "number")
                        return "gridHeight: number expected";
                if (message.imageScale != null && message.hasOwnProperty("imageScale"))
                    if (typeof message.imageScale !== "number")
                        return "imageScale: number expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.image != null && message.hasOwnProperty("image")) {
                    let error = $root.maps.MapImage.verify(message.image);
                    if (error)
                        return "image." + error;
                }
                if (message.smallImageId != null && message.hasOwnProperty("smallImageId"))
                    if (!$util.isString(message.smallImageId))
                        return "smallImageId: string expected";
                if (message.smallImage != null && message.hasOwnProperty("smallImage")) {
                    let error = $root.maps.MapImage.verify(message.smallImage);
                    if (error)
                        return "smallImage." + error;
                }
                if (message.portraitImageId != null && message.hasOwnProperty("portraitImageId"))
                    if (!$util.isString(message.portraitImageId))
                        return "portraitImageId: string expected";
                if (message.portraitImage != null && message.hasOwnProperty("portraitImage")) {
                    let error = $root.maps.MapImage.verify(message.portraitImage);
                    if (error)
                        return "portraitImage." + error;
                }
                if (message.shortName != null && message.hasOwnProperty("shortName"))
                    if (!$util.isString(message.shortName))
                        return "shortName: string expected";
                if (message.isSecret != null && message.hasOwnProperty("isSecret"))
                    if (typeof message.isSecret !== "boolean")
                        return "isSecret: boolean expected";
                if (message.faction != null && message.hasOwnProperty("faction"))
                    switch (message.faction) {
                    default:
                        return "faction: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.annotation != null && message.hasOwnProperty("annotation"))
                    if (!$util.isString(message.annotation))
                        return "annotation: string expected";
                if (message.border != null && message.hasOwnProperty("border")) {
                    let error = $root.maps.BorderDetails.verify(message.border);
                    if (error)
                        return "border." + error;
                }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a Token message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.Token
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.Token} Token
             */
            Token.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.Token)
                    return object;
                let message = new $root.maps.MapObject.Token();
                if (object.position != null) {
                    if (typeof object.position !== "object")
                        throw TypeError(".maps.MapObject.Token.position: object expected");
                    message.position = $root.geometry.GridLocation.fromObject(object.position);
                }
                if (object.rotation != null)
                    message.rotation = Number(object.rotation);
                if (object.gridWidth != null)
                    message.gridWidth = Number(object.gridWidth);
                if (object.gridHeight != null)
                    message.gridHeight = Number(object.gridHeight);
                if (object.imageScale != null)
                    message.imageScale = Number(object.imageScale);
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.image != null) {
                    if (typeof object.image !== "object")
                        throw TypeError(".maps.MapObject.Token.image: object expected");
                    message.image = $root.maps.MapImage.fromObject(object.image);
                }
                if (object.smallImageId != null)
                    message.smallImageId = String(object.smallImageId);
                if (object.smallImage != null) {
                    if (typeof object.smallImage !== "object")
                        throw TypeError(".maps.MapObject.Token.smallImage: object expected");
                    message.smallImage = $root.maps.MapImage.fromObject(object.smallImage);
                }
                if (object.portraitImageId != null)
                    message.portraitImageId = String(object.portraitImageId);
                if (object.portraitImage != null) {
                    if (typeof object.portraitImage !== "object")
                        throw TypeError(".maps.MapObject.Token.portraitImage: object expected");
                    message.portraitImage = $root.maps.MapImage.fromObject(object.portraitImage);
                }
                if (object.shortName != null)
                    message.shortName = String(object.shortName);
                if (object.isSecret != null)
                    message.isSecret = Boolean(object.isSecret);
                switch (object.faction) {
                default:
                    if (typeof object.faction === "number") {
                        message.faction = object.faction;
                        break;
                    }
                    break;
                case "UNKNOWN_FACTION":
                case 0:
                    message.faction = 0;
                    break;
                case "PLAYER_FACTION":
                case 1:
                    message.faction = 1;
                    break;
                case "ALLY_FACTION":
                case 2:
                    message.faction = 2;
                    break;
                case "NEUTRAL_FACTION":
                case 3:
                    message.faction = 3;
                    break;
                case "HOSTILE_FACTION":
                case 4:
                    message.faction = 4;
                    break;
                case "MYSTERY_FACTION":
                case 5:
                    message.faction = 5;
                    break;
                }
                if (object.annotation != null)
                    message.annotation = String(object.annotation);
                if (object.border != null) {
                    if (typeof object.border !== "object")
                        throw TypeError(".maps.MapObject.Token.border: object expected");
                    message.border = $root.maps.BorderDetails.fromObject(object.border);
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a Token message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.Token
             * @static
             * @param {maps.MapObject.Token} message Token
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Token.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.position = null;
                    object.rotation = 0;
                    object.gridWidth = 0;
                    object.gridHeight = 0;
                    object.imageScale = 0;
                    object.imageId = "";
                    object.image = null;
                    object.smallImageId = "";
                    object.smallImage = null;
                    object.portraitImageId = "";
                    object.portraitImage = null;
                    object.shortName = "";
                    object.isSecret = false;
                    object.faction = options.enums === String ? "UNKNOWN_FACTION" : 0;
                    object.annotation = "";
                    object.border = null;
                    object.description = "";
                }
                if (message.position != null && message.hasOwnProperty("position"))
                    object.position = $root.geometry.GridLocation.toObject(message.position, options);
                if (message.rotation != null && message.hasOwnProperty("rotation"))
                    object.rotation = options.json && !isFinite(message.rotation) ? String(message.rotation) : message.rotation;
                if (message.gridWidth != null && message.hasOwnProperty("gridWidth"))
                    object.gridWidth = options.json && !isFinite(message.gridWidth) ? String(message.gridWidth) : message.gridWidth;
                if (message.gridHeight != null && message.hasOwnProperty("gridHeight"))
                    object.gridHeight = options.json && !isFinite(message.gridHeight) ? String(message.gridHeight) : message.gridHeight;
                if (message.imageScale != null && message.hasOwnProperty("imageScale"))
                    object.imageScale = options.json && !isFinite(message.imageScale) ? String(message.imageScale) : message.imageScale;
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                if (message.image != null && message.hasOwnProperty("image"))
                    object.image = $root.maps.MapImage.toObject(message.image, options);
                if (message.smallImageId != null && message.hasOwnProperty("smallImageId"))
                    object.smallImageId = message.smallImageId;
                if (message.smallImage != null && message.hasOwnProperty("smallImage"))
                    object.smallImage = $root.maps.MapImage.toObject(message.smallImage, options);
                if (message.portraitImageId != null && message.hasOwnProperty("portraitImageId"))
                    object.portraitImageId = message.portraitImageId;
                if (message.portraitImage != null && message.hasOwnProperty("portraitImage"))
                    object.portraitImage = $root.maps.MapImage.toObject(message.portraitImage, options);
                if (message.shortName != null && message.hasOwnProperty("shortName"))
                    object.shortName = message.shortName;
                if (message.isSecret != null && message.hasOwnProperty("isSecret"))
                    object.isSecret = message.isSecret;
                if (message.faction != null && message.hasOwnProperty("faction"))
                    object.faction = options.enums === String ? $root.maps.Faction[message.faction] === undefined ? message.faction : $root.maps.Faction[message.faction] : message.faction;
                if (message.annotation != null && message.hasOwnProperty("annotation"))
                    object.annotation = message.annotation;
                if (message.border != null && message.hasOwnProperty("border"))
                    object.border = $root.maps.BorderDetails.toObject(message.border, options);
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this Token to JSON.
             * @function toJSON
             * @memberof maps.MapObject.Token
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Token.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Token
             * @function getTypeUrl
             * @memberof maps.MapObject.Token
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Token.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.Token";
            };

            return Token;
        })();

        MapObject.Pin = (function() {

            /**
             * Properties of a Pin.
             * @memberof maps.MapObject
             * @interface IPin
             * @property {geometry.IGridLocation|null} [position] Pin position
             * @property {common.VisibilityLevel|null} [visibility] Pin visibility
             * @property {string|null} [annotation] Pin annotation
             * @property {maps.IconType|null} [iconType] Pin iconType
             * @property {maps.IconColor|null} [iconColor] Pin iconColor
             * @property {maps.MapObject.IPinDetailText|null} [text] Pin text
             * @property {maps.MapObject.IPinDetailLink|null} [link] Pin link
             * @property {maps.MapObject.IPinDetailImage|null} [image] Pin image
             */

            /**
             * Constructs a new Pin.
             * @memberof maps.MapObject
             * @classdesc Represents a Pin.
             * @implements IPin
             * @constructor
             * @param {maps.MapObject.IPin=} [properties] Properties to set
             */
            function Pin(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Pin position.
             * @member {geometry.IGridLocation|null|undefined} position
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.position = null;

            /**
             * Pin visibility.
             * @member {common.VisibilityLevel} visibility
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.visibility = 0;

            /**
             * Pin annotation.
             * @member {string} annotation
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.annotation = "";

            /**
             * Pin iconType.
             * @member {maps.IconType} iconType
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.iconType = 0;

            /**
             * Pin iconColor.
             * @member {maps.IconColor} iconColor
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.iconColor = 0;

            /**
             * Pin text.
             * @member {maps.MapObject.IPinDetailText|null|undefined} text
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.text = null;

            /**
             * Pin link.
             * @member {maps.MapObject.IPinDetailLink|null|undefined} link
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.link = null;

            /**
             * Pin image.
             * @member {maps.MapObject.IPinDetailImage|null|undefined} image
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Pin.prototype.image = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * Pin detail.
             * @member {"text"|"link"|"image"|undefined} detail
             * @memberof maps.MapObject.Pin
             * @instance
             */
            Object.defineProperty(Pin.prototype, "detail", {
                get: $util.oneOfGetter($oneOfFields = ["text", "link", "image"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new Pin instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.Pin
             * @static
             * @param {maps.MapObject.IPin=} [properties] Properties to set
             * @returns {maps.MapObject.Pin} Pin instance
             */
            Pin.create = function create(properties) {
                return new Pin(properties);
            };

            /**
             * Encodes the specified Pin message. Does not implicitly {@link maps.MapObject.Pin.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.Pin
             * @static
             * @param {maps.MapObject.IPin} message Pin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    $root.geometry.GridLocation.encode(message.position, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.visibility);
                if (message.annotation != null && Object.hasOwnProperty.call(message, "annotation"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.annotation);
                if (message.iconType != null && Object.hasOwnProperty.call(message, "iconType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.iconType);
                if (message.iconColor != null && Object.hasOwnProperty.call(message, "iconColor"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.iconColor);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    $root.maps.MapObject.PinDetailText.encode(message.text, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.link != null && Object.hasOwnProperty.call(message, "link"))
                    $root.maps.MapObject.PinDetailLink.encode(message.link, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                    $root.maps.MapObject.PinDetailImage.encode(message.image, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified Pin message, length delimited. Does not implicitly {@link maps.MapObject.Pin.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.Pin
             * @static
             * @param {maps.MapObject.IPin} message Pin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pin.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Pin message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.Pin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.Pin} Pin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.Pin();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.position = $root.geometry.GridLocation.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.visibility = reader.int32();
                            break;
                        }
                    case 3: {
                            message.annotation = reader.string();
                            break;
                        }
                    case 4: {
                            message.iconType = reader.int32();
                            break;
                        }
                    case 5: {
                            message.iconColor = reader.int32();
                            break;
                        }
                    case 20: {
                            message.text = $root.maps.MapObject.PinDetailText.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.link = $root.maps.MapObject.PinDetailLink.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.image = $root.maps.MapObject.PinDetailImage.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Pin message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.Pin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.Pin} Pin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pin.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Pin message.
             * @function verify
             * @memberof maps.MapObject.Pin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Pin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.position != null && message.hasOwnProperty("position")) {
                    let error = $root.geometry.GridLocation.verify(message.position);
                    if (error)
                        return "position." + error;
                }
                if (message.visibility != null && message.hasOwnProperty("visibility"))
                    switch (message.visibility) {
                    default:
                        return "visibility: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.annotation != null && message.hasOwnProperty("annotation"))
                    if (!$util.isString(message.annotation))
                        return "annotation: string expected";
                if (message.iconType != null && message.hasOwnProperty("iconType"))
                    switch (message.iconType) {
                    default:
                        return "iconType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        break;
                    }
                if (message.iconColor != null && message.hasOwnProperty("iconColor"))
                    switch (message.iconColor) {
                    default:
                        return "iconColor: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.text != null && message.hasOwnProperty("text")) {
                    properties.detail = 1;
                    {
                        let error = $root.maps.MapObject.PinDetailText.verify(message.text);
                        if (error)
                            return "text." + error;
                    }
                }
                if (message.link != null && message.hasOwnProperty("link")) {
                    if (properties.detail === 1)
                        return "detail: multiple values";
                    properties.detail = 1;
                    {
                        let error = $root.maps.MapObject.PinDetailLink.verify(message.link);
                        if (error)
                            return "link." + error;
                    }
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    if (properties.detail === 1)
                        return "detail: multiple values";
                    properties.detail = 1;
                    {
                        let error = $root.maps.MapObject.PinDetailImage.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Pin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.Pin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.Pin} Pin
             */
            Pin.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.Pin)
                    return object;
                let message = new $root.maps.MapObject.Pin();
                if (object.position != null) {
                    if (typeof object.position !== "object")
                        throw TypeError(".maps.MapObject.Pin.position: object expected");
                    message.position = $root.geometry.GridLocation.fromObject(object.position);
                }
                switch (object.visibility) {
                default:
                    if (typeof object.visibility === "number") {
                        message.visibility = object.visibility;
                        break;
                    }
                    break;
                case "UNKNOWN_VISIBILITY_LEVEL":
                case 0:
                    message.visibility = 0;
                    break;
                case "SECRET":
                case 1:
                    message.visibility = 1;
                    break;
                case "PROTECTED":
                case 2:
                    message.visibility = 2;
                    break;
                case "PUBLIC":
                case 3:
                    message.visibility = 3;
                    break;
                }
                if (object.annotation != null)
                    message.annotation = String(object.annotation);
                switch (object.iconType) {
                default:
                    if (typeof object.iconType === "number") {
                        message.iconType = object.iconType;
                        break;
                    }
                    break;
                case "UNKNOWN_ICON":
                case 0:
                    message.iconType = 0;
                    break;
                case "STAR_ICON":
                case 1:
                    message.iconType = 1;
                    break;
                case "CIRCLE_OUTLINE_ICON":
                case 2:
                    message.iconType = 2;
                    break;
                case "CIRCLE_FILLED_ICON":
                case 3:
                    message.iconType = 3;
                    break;
                case "HEART_ICON":
                case 4:
                    message.iconType = 4;
                    break;
                case "TREASURE_ICON":
                case 5:
                    message.iconType = 5;
                    break;
                case "SKULL_ICON":
                case 6:
                    message.iconType = 6;
                    break;
                case "SCROLL_ICON":
                case 7:
                    message.iconType = 7;
                    break;
                }
                switch (object.iconColor) {
                default:
                    if (typeof object.iconColor === "number") {
                        message.iconColor = object.iconColor;
                        break;
                    }
                    break;
                case "UNKNOWN_ICON_COLOR":
                case 0:
                    message.iconColor = 0;
                    break;
                case "RED_ICON_COLOR":
                case 1:
                    message.iconColor = 1;
                    break;
                case "GREEN_ICON_COLOR":
                case 2:
                    message.iconColor = 2;
                    break;
                case "BLUE_ICON_COLOR":
                case 3:
                    message.iconColor = 3;
                    break;
                case "BLACK_ICON_COLOR":
                case 4:
                    message.iconColor = 4;
                    break;
                case "GRAY_ICON_COLOR":
                case 5:
                    message.iconColor = 5;
                    break;
                case "WHITE_ICON_COLOR":
                case 6:
                    message.iconColor = 6;
                    break;
                }
                if (object.text != null) {
                    if (typeof object.text !== "object")
                        throw TypeError(".maps.MapObject.Pin.text: object expected");
                    message.text = $root.maps.MapObject.PinDetailText.fromObject(object.text);
                }
                if (object.link != null) {
                    if (typeof object.link !== "object")
                        throw TypeError(".maps.MapObject.Pin.link: object expected");
                    message.link = $root.maps.MapObject.PinDetailLink.fromObject(object.link);
                }
                if (object.image != null) {
                    if (typeof object.image !== "object")
                        throw TypeError(".maps.MapObject.Pin.image: object expected");
                    message.image = $root.maps.MapObject.PinDetailImage.fromObject(object.image);
                }
                return message;
            };

            /**
             * Creates a plain object from a Pin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.Pin
             * @static
             * @param {maps.MapObject.Pin} message Pin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.position = null;
                    object.visibility = options.enums === String ? "UNKNOWN_VISIBILITY_LEVEL" : 0;
                    object.annotation = "";
                    object.iconType = options.enums === String ? "UNKNOWN_ICON" : 0;
                    object.iconColor = options.enums === String ? "UNKNOWN_ICON_COLOR" : 0;
                }
                if (message.position != null && message.hasOwnProperty("position"))
                    object.position = $root.geometry.GridLocation.toObject(message.position, options);
                if (message.visibility != null && message.hasOwnProperty("visibility"))
                    object.visibility = options.enums === String ? $root.common.VisibilityLevel[message.visibility] === undefined ? message.visibility : $root.common.VisibilityLevel[message.visibility] : message.visibility;
                if (message.annotation != null && message.hasOwnProperty("annotation"))
                    object.annotation = message.annotation;
                if (message.iconType != null && message.hasOwnProperty("iconType"))
                    object.iconType = options.enums === String ? $root.maps.IconType[message.iconType] === undefined ? message.iconType : $root.maps.IconType[message.iconType] : message.iconType;
                if (message.iconColor != null && message.hasOwnProperty("iconColor"))
                    object.iconColor = options.enums === String ? $root.maps.IconColor[message.iconColor] === undefined ? message.iconColor : $root.maps.IconColor[message.iconColor] : message.iconColor;
                if (message.text != null && message.hasOwnProperty("text")) {
                    object.text = $root.maps.MapObject.PinDetailText.toObject(message.text, options);
                    if (options.oneofs)
                        object.detail = "text";
                }
                if (message.link != null && message.hasOwnProperty("link")) {
                    object.link = $root.maps.MapObject.PinDetailLink.toObject(message.link, options);
                    if (options.oneofs)
                        object.detail = "link";
                }
                if (message.image != null && message.hasOwnProperty("image")) {
                    object.image = $root.maps.MapObject.PinDetailImage.toObject(message.image, options);
                    if (options.oneofs)
                        object.detail = "image";
                }
                return object;
            };

            /**
             * Converts this Pin to JSON.
             * @function toJSON
             * @memberof maps.MapObject.Pin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Pin
             * @function getTypeUrl
             * @memberof maps.MapObject.Pin
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Pin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.Pin";
            };

            return Pin;
        })();

        MapObject.PinDetailText = (function() {

            /**
             * Properties of a PinDetailText.
             * @memberof maps.MapObject
             * @interface IPinDetailText
             * @property {string|null} [text] PinDetailText text
             */

            /**
             * Constructs a new PinDetailText.
             * @memberof maps.MapObject
             * @classdesc Represents a PinDetailText.
             * @implements IPinDetailText
             * @constructor
             * @param {maps.MapObject.IPinDetailText=} [properties] Properties to set
             */
            function PinDetailText(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PinDetailText text.
             * @member {string} text
             * @memberof maps.MapObject.PinDetailText
             * @instance
             */
            PinDetailText.prototype.text = "";

            /**
             * Creates a new PinDetailText instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {maps.MapObject.IPinDetailText=} [properties] Properties to set
             * @returns {maps.MapObject.PinDetailText} PinDetailText instance
             */
            PinDetailText.create = function create(properties) {
                return new PinDetailText(properties);
            };

            /**
             * Encodes the specified PinDetailText message. Does not implicitly {@link maps.MapObject.PinDetailText.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {maps.MapObject.IPinDetailText} message PinDetailText message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailText.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                return writer;
            };

            /**
             * Encodes the specified PinDetailText message, length delimited. Does not implicitly {@link maps.MapObject.PinDetailText.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {maps.MapObject.IPinDetailText} message PinDetailText message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailText.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PinDetailText message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.PinDetailText} PinDetailText
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailText.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.PinDetailText();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.text = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PinDetailText message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.PinDetailText} PinDetailText
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailText.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PinDetailText message.
             * @function verify
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PinDetailText.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a PinDetailText message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.PinDetailText} PinDetailText
             */
            PinDetailText.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.PinDetailText)
                    return object;
                let message = new $root.maps.MapObject.PinDetailText();
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a PinDetailText message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {maps.MapObject.PinDetailText} message PinDetailText
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PinDetailText.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.text = "";
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this PinDetailText to JSON.
             * @function toJSON
             * @memberof maps.MapObject.PinDetailText
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PinDetailText.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PinDetailText
             * @function getTypeUrl
             * @memberof maps.MapObject.PinDetailText
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PinDetailText.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.PinDetailText";
            };

            return PinDetailText;
        })();

        MapObject.PinDetailLink = (function() {

            /**
             * Properties of a PinDetailLink.
             * @memberof maps.MapObject
             * @interface IPinDetailLink
             * @property {string|null} [url] PinDetailLink url
             * @property {string|null} [target] PinDetailLink target
             */

            /**
             * Constructs a new PinDetailLink.
             * @memberof maps.MapObject
             * @classdesc Represents a PinDetailLink.
             * @implements IPinDetailLink
             * @constructor
             * @param {maps.MapObject.IPinDetailLink=} [properties] Properties to set
             */
            function PinDetailLink(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PinDetailLink url.
             * @member {string} url
             * @memberof maps.MapObject.PinDetailLink
             * @instance
             */
            PinDetailLink.prototype.url = "";

            /**
             * PinDetailLink target.
             * @member {string} target
             * @memberof maps.MapObject.PinDetailLink
             * @instance
             */
            PinDetailLink.prototype.target = "";

            /**
             * Creates a new PinDetailLink instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {maps.MapObject.IPinDetailLink=} [properties] Properties to set
             * @returns {maps.MapObject.PinDetailLink} PinDetailLink instance
             */
            PinDetailLink.create = function create(properties) {
                return new PinDetailLink(properties);
            };

            /**
             * Encodes the specified PinDetailLink message. Does not implicitly {@link maps.MapObject.PinDetailLink.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {maps.MapObject.IPinDetailLink} message PinDetailLink message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailLink.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.target);
                return writer;
            };

            /**
             * Encodes the specified PinDetailLink message, length delimited. Does not implicitly {@link maps.MapObject.PinDetailLink.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {maps.MapObject.IPinDetailLink} message PinDetailLink message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailLink.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PinDetailLink message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.PinDetailLink} PinDetailLink
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailLink.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.PinDetailLink();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.url = reader.string();
                            break;
                        }
                    case 2: {
                            message.target = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PinDetailLink message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.PinDetailLink} PinDetailLink
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailLink.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PinDetailLink message.
             * @function verify
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PinDetailLink.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.url != null && message.hasOwnProperty("url"))
                    if (!$util.isString(message.url))
                        return "url: string expected";
                if (message.target != null && message.hasOwnProperty("target"))
                    if (!$util.isString(message.target))
                        return "target: string expected";
                return null;
            };

            /**
             * Creates a PinDetailLink message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.PinDetailLink} PinDetailLink
             */
            PinDetailLink.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.PinDetailLink)
                    return object;
                let message = new $root.maps.MapObject.PinDetailLink();
                if (object.url != null)
                    message.url = String(object.url);
                if (object.target != null)
                    message.target = String(object.target);
                return message;
            };

            /**
             * Creates a plain object from a PinDetailLink message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {maps.MapObject.PinDetailLink} message PinDetailLink
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PinDetailLink.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.url = "";
                    object.target = "";
                }
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                if (message.target != null && message.hasOwnProperty("target"))
                    object.target = message.target;
                return object;
            };

            /**
             * Converts this PinDetailLink to JSON.
             * @function toJSON
             * @memberof maps.MapObject.PinDetailLink
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PinDetailLink.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PinDetailLink
             * @function getTypeUrl
             * @memberof maps.MapObject.PinDetailLink
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PinDetailLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.PinDetailLink";
            };

            return PinDetailLink;
        })();

        MapObject.PinDetailImage = (function() {

            /**
             * Properties of a PinDetailImage.
             * @memberof maps.MapObject
             * @interface IPinDetailImage
             * @property {string|null} [imageId] PinDetailImage imageId
             * @property {string|null} [altText] PinDetailImage altText
             */

            /**
             * Constructs a new PinDetailImage.
             * @memberof maps.MapObject
             * @classdesc Represents a PinDetailImage.
             * @implements IPinDetailImage
             * @constructor
             * @param {maps.MapObject.IPinDetailImage=} [properties] Properties to set
             */
            function PinDetailImage(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PinDetailImage imageId.
             * @member {string} imageId
             * @memberof maps.MapObject.PinDetailImage
             * @instance
             */
            PinDetailImage.prototype.imageId = "";

            /**
             * PinDetailImage altText.
             * @member {string} altText
             * @memberof maps.MapObject.PinDetailImage
             * @instance
             */
            PinDetailImage.prototype.altText = "";

            /**
             * Creates a new PinDetailImage instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {maps.MapObject.IPinDetailImage=} [properties] Properties to set
             * @returns {maps.MapObject.PinDetailImage} PinDetailImage instance
             */
            PinDetailImage.create = function create(properties) {
                return new PinDetailImage(properties);
            };

            /**
             * Encodes the specified PinDetailImage message. Does not implicitly {@link maps.MapObject.PinDetailImage.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {maps.MapObject.IPinDetailImage} message PinDetailImage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailImage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
                if (message.altText != null && Object.hasOwnProperty.call(message, "altText"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.altText);
                return writer;
            };

            /**
             * Encodes the specified PinDetailImage message, length delimited. Does not implicitly {@link maps.MapObject.PinDetailImage.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {maps.MapObject.IPinDetailImage} message PinDetailImage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PinDetailImage.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PinDetailImage message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.PinDetailImage} PinDetailImage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailImage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.PinDetailImage();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 2: {
                            message.altText = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PinDetailImage message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.PinDetailImage} PinDetailImage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PinDetailImage.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PinDetailImage message.
             * @function verify
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PinDetailImage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.altText != null && message.hasOwnProperty("altText"))
                    if (!$util.isString(message.altText))
                        return "altText: string expected";
                return null;
            };

            /**
             * Creates a PinDetailImage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.PinDetailImage} PinDetailImage
             */
            PinDetailImage.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.PinDetailImage)
                    return object;
                let message = new $root.maps.MapObject.PinDetailImage();
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.altText != null)
                    message.altText = String(object.altText);
                return message;
            };

            /**
             * Creates a plain object from a PinDetailImage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {maps.MapObject.PinDetailImage} message PinDetailImage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PinDetailImage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.imageId = "";
                    object.altText = "";
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                if (message.altText != null && message.hasOwnProperty("altText"))
                    object.altText = message.altText;
                return object;
            };

            /**
             * Converts this PinDetailImage to JSON.
             * @function toJSON
             * @memberof maps.MapObject.PinDetailImage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PinDetailImage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PinDetailImage
             * @function getTypeUrl
             * @memberof maps.MapObject.PinDetailImage
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PinDetailImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.PinDetailImage";
            };

            return PinDetailImage;
        })();

        MapObject.InformationDetailZoneLocation = (function() {

            /**
             * Properties of an InformationDetailZoneLocation.
             * @memberof maps.MapObject
             * @interface IInformationDetailZoneLocation
             */

            /**
             * Constructs a new InformationDetailZoneLocation.
             * @memberof maps.MapObject
             * @classdesc Represents an InformationDetailZoneLocation.
             * @implements IInformationDetailZoneLocation
             * @constructor
             * @param {maps.MapObject.IInformationDetailZoneLocation=} [properties] Properties to set
             */
            function InformationDetailZoneLocation(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new InformationDetailZoneLocation instance using the specified properties.
             * @function create
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {maps.MapObject.IInformationDetailZoneLocation=} [properties] Properties to set
             * @returns {maps.MapObject.InformationDetailZoneLocation} InformationDetailZoneLocation instance
             */
            InformationDetailZoneLocation.create = function create(properties) {
                return new InformationDetailZoneLocation(properties);
            };

            /**
             * Encodes the specified InformationDetailZoneLocation message. Does not implicitly {@link maps.MapObject.InformationDetailZoneLocation.verify|verify} messages.
             * @function encode
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {maps.MapObject.IInformationDetailZoneLocation} message InformationDetailZoneLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InformationDetailZoneLocation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified InformationDetailZoneLocation message, length delimited. Does not implicitly {@link maps.MapObject.InformationDetailZoneLocation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {maps.MapObject.IInformationDetailZoneLocation} message InformationDetailZoneLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InformationDetailZoneLocation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InformationDetailZoneLocation message from the specified reader or buffer.
             * @function decode
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {maps.MapObject.InformationDetailZoneLocation} InformationDetailZoneLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InformationDetailZoneLocation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapObject.InformationDetailZoneLocation();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InformationDetailZoneLocation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {maps.MapObject.InformationDetailZoneLocation} InformationDetailZoneLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InformationDetailZoneLocation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InformationDetailZoneLocation message.
             * @function verify
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InformationDetailZoneLocation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an InformationDetailZoneLocation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {maps.MapObject.InformationDetailZoneLocation} InformationDetailZoneLocation
             */
            InformationDetailZoneLocation.fromObject = function fromObject(object) {
                if (object instanceof $root.maps.MapObject.InformationDetailZoneLocation)
                    return object;
                return new $root.maps.MapObject.InformationDetailZoneLocation();
            };

            /**
             * Creates a plain object from an InformationDetailZoneLocation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {maps.MapObject.InformationDetailZoneLocation} message InformationDetailZoneLocation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InformationDetailZoneLocation.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this InformationDetailZoneLocation to JSON.
             * @function toJSON
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InformationDetailZoneLocation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for InformationDetailZoneLocation
             * @function getTypeUrl
             * @memberof maps.MapObject.InformationDetailZoneLocation
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            InformationDetailZoneLocation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/maps.MapObject.InformationDetailZoneLocation";
            };

            return InformationDetailZoneLocation;
        })();

        return MapObject;
    })();

    maps.MapImage = (function() {

        /**
         * Properties of a MapImage.
         * @memberof maps
         * @interface IMapImage
         * @property {string|null} [id] MapImage id
         * @property {string|null} [name] MapImage name
         * @property {string|null} [ownerId] MapImage ownerId
         * @property {string|null} [ownerType] MapImage ownerType
         * @property {Array.<string>|null} [categories] MapImage categories
         * @property {string|null} [url] MapImage url
         * @property {string|null} [thumbnailUrl] MapImage thumbnailUrl
         * @property {number|null} [width] MapImage width
         * @property {number|null} [height] MapImage height
         */

        /**
         * Constructs a new MapImage.
         * @memberof maps
         * @classdesc Represents a MapImage.
         * @implements IMapImage
         * @constructor
         * @param {maps.IMapImage=} [properties] Properties to set
         */
        function MapImage(properties) {
            this.categories = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapImage id.
         * @member {string} id
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.id = "";

        /**
         * MapImage name.
         * @member {string} name
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.name = "";

        /**
         * MapImage ownerId.
         * @member {string} ownerId
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.ownerId = "";

        /**
         * MapImage ownerType.
         * @member {string} ownerType
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.ownerType = "";

        /**
         * MapImage categories.
         * @member {Array.<string>} categories
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.categories = $util.emptyArray;

        /**
         * MapImage url.
         * @member {string} url
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.url = "";

        /**
         * MapImage thumbnailUrl.
         * @member {string} thumbnailUrl
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.thumbnailUrl = "";

        /**
         * MapImage width.
         * @member {number} width
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.width = 0;

        /**
         * MapImage height.
         * @member {number} height
         * @memberof maps.MapImage
         * @instance
         */
        MapImage.prototype.height = 0;

        /**
         * Creates a new MapImage instance using the specified properties.
         * @function create
         * @memberof maps.MapImage
         * @static
         * @param {maps.IMapImage=} [properties] Properties to set
         * @returns {maps.MapImage} MapImage instance
         */
        MapImage.create = function create(properties) {
            return new MapImage(properties);
        };

        /**
         * Encodes the specified MapImage message. Does not implicitly {@link maps.MapImage.verify|verify} messages.
         * @function encode
         * @memberof maps.MapImage
         * @static
         * @param {maps.IMapImage} message MapImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapImage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownerId);
            if (message.ownerType != null && Object.hasOwnProperty.call(message, "ownerType"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ownerType);
            if (message.categories != null && message.categories.length)
                for (let i = 0; i < message.categories.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.categories[i]);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.height);
            if (message.thumbnailUrl != null && Object.hasOwnProperty.call(message, "thumbnailUrl"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.thumbnailUrl);
            return writer;
        };

        /**
         * Encodes the specified MapImage message, length delimited. Does not implicitly {@link maps.MapImage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapImage
         * @static
         * @param {maps.IMapImage} message MapImage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapImage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapImage message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapImage} MapImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapImage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapImage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.ownerId = reader.string();
                        break;
                    }
                case 4: {
                        message.ownerType = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.categories && message.categories.length))
                            message.categories = [];
                        message.categories.push(reader.string());
                        break;
                    }
                case 6: {
                        message.url = reader.string();
                        break;
                    }
                case 9: {
                        message.thumbnailUrl = reader.string();
                        break;
                    }
                case 7: {
                        message.width = reader.int32();
                        break;
                    }
                case 8: {
                        message.height = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapImage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapImage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapImage} MapImage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapImage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapImage message.
         * @function verify
         * @memberof maps.MapImage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapImage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                if (!$util.isString(message.ownerId))
                    return "ownerId: string expected";
            if (message.ownerType != null && message.hasOwnProperty("ownerType"))
                if (!$util.isString(message.ownerType))
                    return "ownerType: string expected";
            if (message.categories != null && message.hasOwnProperty("categories")) {
                if (!Array.isArray(message.categories))
                    return "categories: array expected";
                for (let i = 0; i < message.categories.length; ++i)
                    if (!$util.isString(message.categories[i]))
                        return "categories: string[] expected";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                if (!$util.isString(message.thumbnailUrl))
                    return "thumbnailUrl: string expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a MapImage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapImage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapImage} MapImage
         */
        MapImage.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapImage)
                return object;
            let message = new $root.maps.MapImage();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownerId != null)
                message.ownerId = String(object.ownerId);
            if (object.ownerType != null)
                message.ownerType = String(object.ownerType);
            if (object.categories) {
                if (!Array.isArray(object.categories))
                    throw TypeError(".maps.MapImage.categories: array expected");
                message.categories = [];
                for (let i = 0; i < object.categories.length; ++i)
                    message.categories[i] = String(object.categories[i]);
            }
            if (object.url != null)
                message.url = String(object.url);
            if (object.thumbnailUrl != null)
                message.thumbnailUrl = String(object.thumbnailUrl);
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from a MapImage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapImage
         * @static
         * @param {maps.MapImage} message MapImage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapImage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.categories = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownerId = "";
                object.ownerType = "";
                object.url = "";
                object.width = 0;
                object.height = 0;
                object.thumbnailUrl = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                object.ownerId = message.ownerId;
            if (message.ownerType != null && message.hasOwnProperty("ownerType"))
                object.ownerType = message.ownerType;
            if (message.categories && message.categories.length) {
                object.categories = [];
                for (let j = 0; j < message.categories.length; ++j)
                    object.categories[j] = message.categories[j];
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.thumbnailUrl != null && message.hasOwnProperty("thumbnailUrl"))
                object.thumbnailUrl = message.thumbnailUrl;
            return object;
        };

        /**
         * Converts this MapImage to JSON.
         * @function toJSON
         * @memberof maps.MapImage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapImage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapImage
         * @function getTypeUrl
         * @memberof maps.MapImage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapImage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapImage";
        };

        return MapImage;
    })();

    maps.MapImageBinary = (function() {

        /**
         * Properties of a MapImageBinary.
         * @memberof maps
         * @interface IMapImageBinary
         * @property {string|null} [id] MapImageBinary id
         * @property {string|null} [name] MapImageBinary name
         * @property {string|null} [ownerId] MapImageBinary ownerId
         * @property {string|null} [ownerType] MapImageBinary ownerType
         * @property {Array.<string>|null} [categories] MapImageBinary categories
         * @property {string|null} [contentType] MapImageBinary contentType
         * @property {Uint8Array|null} [content] MapImageBinary content
         * @property {number|null} [width] MapImageBinary width
         * @property {number|null} [height] MapImageBinary height
         */

        /**
         * Constructs a new MapImageBinary.
         * @memberof maps
         * @classdesc Represents a MapImageBinary.
         * @implements IMapImageBinary
         * @constructor
         * @param {maps.IMapImageBinary=} [properties] Properties to set
         */
        function MapImageBinary(properties) {
            this.categories = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapImageBinary id.
         * @member {string} id
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.id = "";

        /**
         * MapImageBinary name.
         * @member {string} name
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.name = "";

        /**
         * MapImageBinary ownerId.
         * @member {string} ownerId
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.ownerId = "";

        /**
         * MapImageBinary ownerType.
         * @member {string} ownerType
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.ownerType = "";

        /**
         * MapImageBinary categories.
         * @member {Array.<string>} categories
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.categories = $util.emptyArray;

        /**
         * MapImageBinary contentType.
         * @member {string} contentType
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.contentType = "";

        /**
         * MapImageBinary content.
         * @member {Uint8Array} content
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.content = $util.newBuffer([]);

        /**
         * MapImageBinary width.
         * @member {number} width
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.width = 0;

        /**
         * MapImageBinary height.
         * @member {number} height
         * @memberof maps.MapImageBinary
         * @instance
         */
        MapImageBinary.prototype.height = 0;

        /**
         * Creates a new MapImageBinary instance using the specified properties.
         * @function create
         * @memberof maps.MapImageBinary
         * @static
         * @param {maps.IMapImageBinary=} [properties] Properties to set
         * @returns {maps.MapImageBinary} MapImageBinary instance
         */
        MapImageBinary.create = function create(properties) {
            return new MapImageBinary(properties);
        };

        /**
         * Encodes the specified MapImageBinary message. Does not implicitly {@link maps.MapImageBinary.verify|verify} messages.
         * @function encode
         * @memberof maps.MapImageBinary
         * @static
         * @param {maps.IMapImageBinary} message MapImageBinary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapImageBinary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownerId);
            if (message.ownerType != null && Object.hasOwnProperty.call(message, "ownerType"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.ownerType);
            if (message.categories != null && message.categories.length)
                for (let i = 0; i < message.categories.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.categories[i]);
            if (message.contentType != null && Object.hasOwnProperty.call(message, "contentType"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.contentType);
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.content);
            if (message.width != null && Object.hasOwnProperty.call(message, "width"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.width);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified MapImageBinary message, length delimited. Does not implicitly {@link maps.MapImageBinary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapImageBinary
         * @static
         * @param {maps.IMapImageBinary} message MapImageBinary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapImageBinary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapImageBinary message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapImageBinary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapImageBinary} MapImageBinary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapImageBinary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapImageBinary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.ownerId = reader.string();
                        break;
                    }
                case 4: {
                        message.ownerType = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.categories && message.categories.length))
                            message.categories = [];
                        message.categories.push(reader.string());
                        break;
                    }
                case 6: {
                        message.contentType = reader.string();
                        break;
                    }
                case 7: {
                        message.content = reader.bytes();
                        break;
                    }
                case 8: {
                        message.width = reader.int32();
                        break;
                    }
                case 9: {
                        message.height = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapImageBinary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapImageBinary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapImageBinary} MapImageBinary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapImageBinary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapImageBinary message.
         * @function verify
         * @memberof maps.MapImageBinary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapImageBinary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                if (!$util.isString(message.ownerId))
                    return "ownerId: string expected";
            if (message.ownerType != null && message.hasOwnProperty("ownerType"))
                if (!$util.isString(message.ownerType))
                    return "ownerType: string expected";
            if (message.categories != null && message.hasOwnProperty("categories")) {
                if (!Array.isArray(message.categories))
                    return "categories: array expected";
                for (let i = 0; i < message.categories.length; ++i)
                    if (!$util.isString(message.categories[i]))
                        return "categories: string[] expected";
            }
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                if (!$util.isString(message.contentType))
                    return "contentType: string expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!(message.content && typeof message.content.length === "number" || $util.isString(message.content)))
                    return "content: buffer expected";
            if (message.width != null && message.hasOwnProperty("width"))
                if (!$util.isInteger(message.width))
                    return "width: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a MapImageBinary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapImageBinary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapImageBinary} MapImageBinary
         */
        MapImageBinary.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapImageBinary)
                return object;
            let message = new $root.maps.MapImageBinary();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownerId != null)
                message.ownerId = String(object.ownerId);
            if (object.ownerType != null)
                message.ownerType = String(object.ownerType);
            if (object.categories) {
                if (!Array.isArray(object.categories))
                    throw TypeError(".maps.MapImageBinary.categories: array expected");
                message.categories = [];
                for (let i = 0; i < object.categories.length; ++i)
                    message.categories[i] = String(object.categories[i]);
            }
            if (object.contentType != null)
                message.contentType = String(object.contentType);
            if (object.content != null)
                if (typeof object.content === "string")
                    $util.base64.decode(object.content, message.content = $util.newBuffer($util.base64.length(object.content)), 0);
                else if (object.content.length >= 0)
                    message.content = object.content;
            if (object.width != null)
                message.width = object.width | 0;
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from a MapImageBinary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapImageBinary
         * @static
         * @param {maps.MapImageBinary} message MapImageBinary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapImageBinary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.categories = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownerId = "";
                object.ownerType = "";
                object.contentType = "";
                if (options.bytes === String)
                    object.content = "";
                else {
                    object.content = [];
                    if (options.bytes !== Array)
                        object.content = $util.newBuffer(object.content);
                }
                object.width = 0;
                object.height = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownerId != null && message.hasOwnProperty("ownerId"))
                object.ownerId = message.ownerId;
            if (message.ownerType != null && message.hasOwnProperty("ownerType"))
                object.ownerType = message.ownerType;
            if (message.categories && message.categories.length) {
                object.categories = [];
                for (let j = 0; j < message.categories.length; ++j)
                    object.categories[j] = message.categories[j];
            }
            if (message.contentType != null && message.hasOwnProperty("contentType"))
                object.contentType = message.contentType;
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = options.bytes === String ? $util.base64.encode(message.content, 0, message.content.length) : options.bytes === Array ? Array.prototype.slice.call(message.content) : message.content;
            if (message.width != null && message.hasOwnProperty("width"))
                object.width = message.width;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this MapImageBinary to JSON.
         * @function toJSON
         * @memberof maps.MapImageBinary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapImageBinary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapImageBinary
         * @function getTypeUrl
         * @memberof maps.MapImageBinary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapImageBinary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapImageBinary";
        };

        return MapImageBinary;
    })();

    /**
     * MapZoneDataset enum.
     * @name maps.MapZoneDataset
     * @enum {number}
     * @property {number} UNKNOWN_MAP_ZONE_DATASET=0 UNKNOWN_MAP_ZONE_DATASET value
     * @property {number} BASIC_MAP_ZONE_DATA=1 BASIC_MAP_ZONE_DATA value
     * @property {number} MAP_ZONE_BOUNDS_DATA=2 MAP_ZONE_BOUNDS_DATA value
     * @property {number} MAP_ZONE_VISIBILITY_DATA=3 MAP_ZONE_VISIBILITY_DATA value
     */
    maps.MapZoneDataset = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_MAP_ZONE_DATASET"] = 0;
        values[valuesById[1] = "BASIC_MAP_ZONE_DATA"] = 1;
        values[valuesById[2] = "MAP_ZONE_BOUNDS_DATA"] = 2;
        values[valuesById[3] = "MAP_ZONE_VISIBILITY_DATA"] = 3;
        return values;
    })();

    /**
     * MapZoneGridType enum.
     * @name maps.MapZoneGridType
     * @enum {number}
     * @property {number} UNKNOWN_GRID_TYPE=0 UNKNOWN_GRID_TYPE value
     * @property {number} RECT=1 RECT value
     * @property {number} HEX=2 HEX value
     */
    maps.MapZoneGridType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_GRID_TYPE"] = 0;
        values[valuesById[1] = "RECT"] = 1;
        values[valuesById[2] = "HEX"] = 2;
        return values;
    })();

    /**
     * CellSizeUnitType enum.
     * @name maps.CellSizeUnitType
     * @enum {number}
     * @property {number} UNKNOWN_CELL_SIZE_UNIT_TYPE=0 UNKNOWN_CELL_SIZE_UNIT_TYPE value
     * @property {number} FEET=1 FEET value
     */
    maps.CellSizeUnitType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_CELL_SIZE_UNIT_TYPE"] = 0;
        values[valuesById[1] = "FEET"] = 1;
        return values;
    })();

    /**
     * VisibilityLevel enum.
     * @name maps.VisibilityLevel
     * @enum {number}
     * @property {number} UNKNOWN_VISIBILITY_LEVEL=0 UNKNOWN_VISIBILITY_LEVEL value
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} OUT_OF_BOUNDS=2 OUT_OF_BOUNDS value
     * @property {number} MASKED=3 MASKED value
     * @property {number} KNOWN=4 KNOWN value
     * @property {number} EDGE=5 EDGE value
     * @property {number} DIM=6 DIM value
     * @property {number} DARKVISION=7 DARKVISION value
     * @property {number} VISIBLE=8 VISIBLE value
     */
    maps.VisibilityLevel = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_VISIBILITY_LEVEL"] = 0;
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "OUT_OF_BOUNDS"] = 2;
        values[valuesById[3] = "MASKED"] = 3;
        values[valuesById[4] = "KNOWN"] = 4;
        values[valuesById[5] = "EDGE"] = 5;
        values[valuesById[6] = "DIM"] = 6;
        values[valuesById[7] = "DARKVISION"] = 7;
        values[valuesById[8] = "VISIBLE"] = 8;
        return values;
    })();

    maps.BoundaryDetails = (function() {

        /**
         * Properties of a BoundaryDetails.
         * @memberof maps
         * @interface IBoundaryDetails
         * @property {geometry.IMask|null} [mask] BoundaryDetails mask
         */

        /**
         * Constructs a new BoundaryDetails.
         * @memberof maps
         * @classdesc Represents a BoundaryDetails.
         * @implements IBoundaryDetails
         * @constructor
         * @param {maps.IBoundaryDetails=} [properties] Properties to set
         */
        function BoundaryDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BoundaryDetails mask.
         * @member {geometry.IMask|null|undefined} mask
         * @memberof maps.BoundaryDetails
         * @instance
         */
        BoundaryDetails.prototype.mask = null;

        /**
         * Creates a new BoundaryDetails instance using the specified properties.
         * @function create
         * @memberof maps.BoundaryDetails
         * @static
         * @param {maps.IBoundaryDetails=} [properties] Properties to set
         * @returns {maps.BoundaryDetails} BoundaryDetails instance
         */
        BoundaryDetails.create = function create(properties) {
            return new BoundaryDetails(properties);
        };

        /**
         * Encodes the specified BoundaryDetails message. Does not implicitly {@link maps.BoundaryDetails.verify|verify} messages.
         * @function encode
         * @memberof maps.BoundaryDetails
         * @static
         * @param {maps.IBoundaryDetails} message BoundaryDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoundaryDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mask != null && Object.hasOwnProperty.call(message, "mask"))
                $root.geometry.Mask.encode(message.mask, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BoundaryDetails message, length delimited. Does not implicitly {@link maps.BoundaryDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.BoundaryDetails
         * @static
         * @param {maps.IBoundaryDetails} message BoundaryDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoundaryDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BoundaryDetails message from the specified reader or buffer.
         * @function decode
         * @memberof maps.BoundaryDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.BoundaryDetails} BoundaryDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoundaryDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.BoundaryDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mask = $root.geometry.Mask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BoundaryDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.BoundaryDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.BoundaryDetails} BoundaryDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoundaryDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BoundaryDetails message.
         * @function verify
         * @memberof maps.BoundaryDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BoundaryDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mask != null && message.hasOwnProperty("mask")) {
                let error = $root.geometry.Mask.verify(message.mask);
                if (error)
                    return "mask." + error;
            }
            return null;
        };

        /**
         * Creates a BoundaryDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.BoundaryDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.BoundaryDetails} BoundaryDetails
         */
        BoundaryDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.BoundaryDetails)
                return object;
            let message = new $root.maps.BoundaryDetails();
            if (object.mask != null) {
                if (typeof object.mask !== "object")
                    throw TypeError(".maps.BoundaryDetails.mask: object expected");
                message.mask = $root.geometry.Mask.fromObject(object.mask);
            }
            return message;
        };

        /**
         * Creates a plain object from a BoundaryDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.BoundaryDetails
         * @static
         * @param {maps.BoundaryDetails} message BoundaryDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoundaryDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mask = null;
            if (message.mask != null && message.hasOwnProperty("mask"))
                object.mask = $root.geometry.Mask.toObject(message.mask, options);
            return object;
        };

        /**
         * Converts this BoundaryDetails to JSON.
         * @function toJSON
         * @memberof maps.BoundaryDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoundaryDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for BoundaryDetails
         * @function getTypeUrl
         * @memberof maps.BoundaryDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BoundaryDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.BoundaryDetails";
        };

        return BoundaryDetails;
    })();

    maps.VisibilityMasks = (function() {

        /**
         * Properties of a VisibilityMasks.
         * @memberof maps
         * @interface IVisibilityMasks
         * @property {geometry.IMask|null} [exploredMask] VisibilityMasks exploredMask
         * @property {geometry.IMask|null} [overrideHiddenMask] VisibilityMasks overrideHiddenMask
         * @property {geometry.IMask|null} [overrideVisibleMask] VisibilityMasks overrideVisibleMask
         */

        /**
         * Constructs a new VisibilityMasks.
         * @memberof maps
         * @classdesc Represents a VisibilityMasks.
         * @implements IVisibilityMasks
         * @constructor
         * @param {maps.IVisibilityMasks=} [properties] Properties to set
         */
        function VisibilityMasks(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VisibilityMasks exploredMask.
         * @member {geometry.IMask|null|undefined} exploredMask
         * @memberof maps.VisibilityMasks
         * @instance
         */
        VisibilityMasks.prototype.exploredMask = null;

        /**
         * VisibilityMasks overrideHiddenMask.
         * @member {geometry.IMask|null|undefined} overrideHiddenMask
         * @memberof maps.VisibilityMasks
         * @instance
         */
        VisibilityMasks.prototype.overrideHiddenMask = null;

        /**
         * VisibilityMasks overrideVisibleMask.
         * @member {geometry.IMask|null|undefined} overrideVisibleMask
         * @memberof maps.VisibilityMasks
         * @instance
         */
        VisibilityMasks.prototype.overrideVisibleMask = null;

        /**
         * Creates a new VisibilityMasks instance using the specified properties.
         * @function create
         * @memberof maps.VisibilityMasks
         * @static
         * @param {maps.IVisibilityMasks=} [properties] Properties to set
         * @returns {maps.VisibilityMasks} VisibilityMasks instance
         */
        VisibilityMasks.create = function create(properties) {
            return new VisibilityMasks(properties);
        };

        /**
         * Encodes the specified VisibilityMasks message. Does not implicitly {@link maps.VisibilityMasks.verify|verify} messages.
         * @function encode
         * @memberof maps.VisibilityMasks
         * @static
         * @param {maps.IVisibilityMasks} message VisibilityMasks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VisibilityMasks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.exploredMask != null && Object.hasOwnProperty.call(message, "exploredMask"))
                $root.geometry.Mask.encode(message.exploredMask, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.overrideHiddenMask != null && Object.hasOwnProperty.call(message, "overrideHiddenMask"))
                $root.geometry.Mask.encode(message.overrideHiddenMask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.overrideVisibleMask != null && Object.hasOwnProperty.call(message, "overrideVisibleMask"))
                $root.geometry.Mask.encode(message.overrideVisibleMask, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified VisibilityMasks message, length delimited. Does not implicitly {@link maps.VisibilityMasks.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.VisibilityMasks
         * @static
         * @param {maps.IVisibilityMasks} message VisibilityMasks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VisibilityMasks.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VisibilityMasks message from the specified reader or buffer.
         * @function decode
         * @memberof maps.VisibilityMasks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.VisibilityMasks} VisibilityMasks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VisibilityMasks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.VisibilityMasks();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.exploredMask = $root.geometry.Mask.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.overrideHiddenMask = $root.geometry.Mask.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.overrideVisibleMask = $root.geometry.Mask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VisibilityMasks message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.VisibilityMasks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.VisibilityMasks} VisibilityMasks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VisibilityMasks.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VisibilityMasks message.
         * @function verify
         * @memberof maps.VisibilityMasks
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VisibilityMasks.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.exploredMask != null && message.hasOwnProperty("exploredMask")) {
                let error = $root.geometry.Mask.verify(message.exploredMask);
                if (error)
                    return "exploredMask." + error;
            }
            if (message.overrideHiddenMask != null && message.hasOwnProperty("overrideHiddenMask")) {
                let error = $root.geometry.Mask.verify(message.overrideHiddenMask);
                if (error)
                    return "overrideHiddenMask." + error;
            }
            if (message.overrideVisibleMask != null && message.hasOwnProperty("overrideVisibleMask")) {
                let error = $root.geometry.Mask.verify(message.overrideVisibleMask);
                if (error)
                    return "overrideVisibleMask." + error;
            }
            return null;
        };

        /**
         * Creates a VisibilityMasks message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.VisibilityMasks
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.VisibilityMasks} VisibilityMasks
         */
        VisibilityMasks.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.VisibilityMasks)
                return object;
            let message = new $root.maps.VisibilityMasks();
            if (object.exploredMask != null) {
                if (typeof object.exploredMask !== "object")
                    throw TypeError(".maps.VisibilityMasks.exploredMask: object expected");
                message.exploredMask = $root.geometry.Mask.fromObject(object.exploredMask);
            }
            if (object.overrideHiddenMask != null) {
                if (typeof object.overrideHiddenMask !== "object")
                    throw TypeError(".maps.VisibilityMasks.overrideHiddenMask: object expected");
                message.overrideHiddenMask = $root.geometry.Mask.fromObject(object.overrideHiddenMask);
            }
            if (object.overrideVisibleMask != null) {
                if (typeof object.overrideVisibleMask !== "object")
                    throw TypeError(".maps.VisibilityMasks.overrideVisibleMask: object expected");
                message.overrideVisibleMask = $root.geometry.Mask.fromObject(object.overrideVisibleMask);
            }
            return message;
        };

        /**
         * Creates a plain object from a VisibilityMasks message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.VisibilityMasks
         * @static
         * @param {maps.VisibilityMasks} message VisibilityMasks
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VisibilityMasks.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.exploredMask = null;
                object.overrideHiddenMask = null;
                object.overrideVisibleMask = null;
            }
            if (message.exploredMask != null && message.hasOwnProperty("exploredMask"))
                object.exploredMask = $root.geometry.Mask.toObject(message.exploredMask, options);
            if (message.overrideHiddenMask != null && message.hasOwnProperty("overrideHiddenMask"))
                object.overrideHiddenMask = $root.geometry.Mask.toObject(message.overrideHiddenMask, options);
            if (message.overrideVisibleMask != null && message.hasOwnProperty("overrideVisibleMask"))
                object.overrideVisibleMask = $root.geometry.Mask.toObject(message.overrideVisibleMask, options);
            return object;
        };

        /**
         * Converts this VisibilityMasks to JSON.
         * @function toJSON
         * @memberof maps.VisibilityMasks
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VisibilityMasks.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VisibilityMasks
         * @function getTypeUrl
         * @memberof maps.VisibilityMasks
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VisibilityMasks.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.VisibilityMasks";
        };

        return VisibilityMasks;
    })();

    maps.RectGridProperties = (function() {

        /**
         * Properties of a RectGridProperties.
         * @memberof maps
         * @interface IRectGridProperties
         * @property {common.IRect2D|null} [bounds] RectGridProperties bounds
         * @property {common.IPoint2D|null} [anchor] RectGridProperties anchor
         * @property {number|null} [cellLenXPx] RectGridProperties cellLenXPx
         * @property {number|null} [cellLenYPx] RectGridProperties cellLenYPx
         * @property {number|null} [cellSize] RectGridProperties cellSize
         * @property {maps.CellSizeUnitType|null} [cellSizeUnits] RectGridProperties cellSizeUnits
         */

        /**
         * Constructs a new RectGridProperties.
         * @memberof maps
         * @classdesc Represents a RectGridProperties.
         * @implements IRectGridProperties
         * @constructor
         * @param {maps.IRectGridProperties=} [properties] Properties to set
         */
        function RectGridProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RectGridProperties bounds.
         * @member {common.IRect2D|null|undefined} bounds
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.bounds = null;

        /**
         * RectGridProperties anchor.
         * @member {common.IPoint2D|null|undefined} anchor
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.anchor = null;

        /**
         * RectGridProperties cellLenXPx.
         * @member {number} cellLenXPx
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.cellLenXPx = 0;

        /**
         * RectGridProperties cellLenYPx.
         * @member {number} cellLenYPx
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.cellLenYPx = 0;

        /**
         * RectGridProperties cellSize.
         * @member {number} cellSize
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.cellSize = 0;

        /**
         * RectGridProperties cellSizeUnits.
         * @member {maps.CellSizeUnitType} cellSizeUnits
         * @memberof maps.RectGridProperties
         * @instance
         */
        RectGridProperties.prototype.cellSizeUnits = 0;

        /**
         * Creates a new RectGridProperties instance using the specified properties.
         * @function create
         * @memberof maps.RectGridProperties
         * @static
         * @param {maps.IRectGridProperties=} [properties] Properties to set
         * @returns {maps.RectGridProperties} RectGridProperties instance
         */
        RectGridProperties.create = function create(properties) {
            return new RectGridProperties(properties);
        };

        /**
         * Encodes the specified RectGridProperties message. Does not implicitly {@link maps.RectGridProperties.verify|verify} messages.
         * @function encode
         * @memberof maps.RectGridProperties
         * @static
         * @param {maps.IRectGridProperties} message RectGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RectGridProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.bounds != null && Object.hasOwnProperty.call(message, "bounds"))
                $root.common.Rect2D.encode(message.bounds, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.anchor != null && Object.hasOwnProperty.call(message, "anchor"))
                $root.common.Point2D.encode(message.anchor, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.cellLenXPx != null && Object.hasOwnProperty.call(message, "cellLenXPx"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.cellLenXPx);
            if (message.cellLenYPx != null && Object.hasOwnProperty.call(message, "cellLenYPx"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.cellLenYPx);
            if (message.cellSize != null && Object.hasOwnProperty.call(message, "cellSize"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.cellSize);
            if (message.cellSizeUnits != null && Object.hasOwnProperty.call(message, "cellSizeUnits"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.cellSizeUnits);
            return writer;
        };

        /**
         * Encodes the specified RectGridProperties message, length delimited. Does not implicitly {@link maps.RectGridProperties.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.RectGridProperties
         * @static
         * @param {maps.IRectGridProperties} message RectGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RectGridProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RectGridProperties message from the specified reader or buffer.
         * @function decode
         * @memberof maps.RectGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.RectGridProperties} RectGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RectGridProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.RectGridProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.bounds = $root.common.Rect2D.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.anchor = $root.common.Point2D.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.cellLenXPx = reader.float();
                        break;
                    }
                case 4: {
                        message.cellLenYPx = reader.float();
                        break;
                    }
                case 5: {
                        message.cellSize = reader.float();
                        break;
                    }
                case 6: {
                        message.cellSizeUnits = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RectGridProperties message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.RectGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.RectGridProperties} RectGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RectGridProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RectGridProperties message.
         * @function verify
         * @memberof maps.RectGridProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RectGridProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.bounds != null && message.hasOwnProperty("bounds")) {
                let error = $root.common.Rect2D.verify(message.bounds);
                if (error)
                    return "bounds." + error;
            }
            if (message.anchor != null && message.hasOwnProperty("anchor")) {
                let error = $root.common.Point2D.verify(message.anchor);
                if (error)
                    return "anchor." + error;
            }
            if (message.cellLenXPx != null && message.hasOwnProperty("cellLenXPx"))
                if (typeof message.cellLenXPx !== "number")
                    return "cellLenXPx: number expected";
            if (message.cellLenYPx != null && message.hasOwnProperty("cellLenYPx"))
                if (typeof message.cellLenYPx !== "number")
                    return "cellLenYPx: number expected";
            if (message.cellSize != null && message.hasOwnProperty("cellSize"))
                if (typeof message.cellSize !== "number")
                    return "cellSize: number expected";
            if (message.cellSizeUnits != null && message.hasOwnProperty("cellSizeUnits"))
                switch (message.cellSizeUnits) {
                default:
                    return "cellSizeUnits: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a RectGridProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.RectGridProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.RectGridProperties} RectGridProperties
         */
        RectGridProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.RectGridProperties)
                return object;
            let message = new $root.maps.RectGridProperties();
            if (object.bounds != null) {
                if (typeof object.bounds !== "object")
                    throw TypeError(".maps.RectGridProperties.bounds: object expected");
                message.bounds = $root.common.Rect2D.fromObject(object.bounds);
            }
            if (object.anchor != null) {
                if (typeof object.anchor !== "object")
                    throw TypeError(".maps.RectGridProperties.anchor: object expected");
                message.anchor = $root.common.Point2D.fromObject(object.anchor);
            }
            if (object.cellLenXPx != null)
                message.cellLenXPx = Number(object.cellLenXPx);
            if (object.cellLenYPx != null)
                message.cellLenYPx = Number(object.cellLenYPx);
            if (object.cellSize != null)
                message.cellSize = Number(object.cellSize);
            switch (object.cellSizeUnits) {
            default:
                if (typeof object.cellSizeUnits === "number") {
                    message.cellSizeUnits = object.cellSizeUnits;
                    break;
                }
                break;
            case "UNKNOWN_CELL_SIZE_UNIT_TYPE":
            case 0:
                message.cellSizeUnits = 0;
                break;
            case "FEET":
            case 1:
                message.cellSizeUnits = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a RectGridProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.RectGridProperties
         * @static
         * @param {maps.RectGridProperties} message RectGridProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RectGridProperties.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.bounds = null;
                object.anchor = null;
                object.cellLenXPx = 0;
                object.cellLenYPx = 0;
                object.cellSize = 0;
                object.cellSizeUnits = options.enums === String ? "UNKNOWN_CELL_SIZE_UNIT_TYPE" : 0;
            }
            if (message.bounds != null && message.hasOwnProperty("bounds"))
                object.bounds = $root.common.Rect2D.toObject(message.bounds, options);
            if (message.anchor != null && message.hasOwnProperty("anchor"))
                object.anchor = $root.common.Point2D.toObject(message.anchor, options);
            if (message.cellLenXPx != null && message.hasOwnProperty("cellLenXPx"))
                object.cellLenXPx = options.json && !isFinite(message.cellLenXPx) ? String(message.cellLenXPx) : message.cellLenXPx;
            if (message.cellLenYPx != null && message.hasOwnProperty("cellLenYPx"))
                object.cellLenYPx = options.json && !isFinite(message.cellLenYPx) ? String(message.cellLenYPx) : message.cellLenYPx;
            if (message.cellSize != null && message.hasOwnProperty("cellSize"))
                object.cellSize = options.json && !isFinite(message.cellSize) ? String(message.cellSize) : message.cellSize;
            if (message.cellSizeUnits != null && message.hasOwnProperty("cellSizeUnits"))
                object.cellSizeUnits = options.enums === String ? $root.maps.CellSizeUnitType[message.cellSizeUnits] === undefined ? message.cellSizeUnits : $root.maps.CellSizeUnitType[message.cellSizeUnits] : message.cellSizeUnits;
            return object;
        };

        /**
         * Converts this RectGridProperties to JSON.
         * @function toJSON
         * @memberof maps.RectGridProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RectGridProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RectGridProperties
         * @function getTypeUrl
         * @memberof maps.RectGridProperties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RectGridProperties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.RectGridProperties";
        };

        return RectGridProperties;
    })();

    maps.HexGridProperties = (function() {

        /**
         * Properties of a HexGridProperties.
         * @memberof maps
         * @interface IHexGridProperties
         */

        /**
         * Constructs a new HexGridProperties.
         * @memberof maps
         * @classdesc Represents a HexGridProperties.
         * @implements IHexGridProperties
         * @constructor
         * @param {maps.IHexGridProperties=} [properties] Properties to set
         */
        function HexGridProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new HexGridProperties instance using the specified properties.
         * @function create
         * @memberof maps.HexGridProperties
         * @static
         * @param {maps.IHexGridProperties=} [properties] Properties to set
         * @returns {maps.HexGridProperties} HexGridProperties instance
         */
        HexGridProperties.create = function create(properties) {
            return new HexGridProperties(properties);
        };

        /**
         * Encodes the specified HexGridProperties message. Does not implicitly {@link maps.HexGridProperties.verify|verify} messages.
         * @function encode
         * @memberof maps.HexGridProperties
         * @static
         * @param {maps.IHexGridProperties} message HexGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HexGridProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified HexGridProperties message, length delimited. Does not implicitly {@link maps.HexGridProperties.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.HexGridProperties
         * @static
         * @param {maps.IHexGridProperties} message HexGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HexGridProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HexGridProperties message from the specified reader or buffer.
         * @function decode
         * @memberof maps.HexGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.HexGridProperties} HexGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HexGridProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.HexGridProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HexGridProperties message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.HexGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.HexGridProperties} HexGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HexGridProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HexGridProperties message.
         * @function verify
         * @memberof maps.HexGridProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HexGridProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a HexGridProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.HexGridProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.HexGridProperties} HexGridProperties
         */
        HexGridProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.HexGridProperties)
                return object;
            return new $root.maps.HexGridProperties();
        };

        /**
         * Creates a plain object from a HexGridProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.HexGridProperties
         * @static
         * @param {maps.HexGridProperties} message HexGridProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HexGridProperties.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this HexGridProperties to JSON.
         * @function toJSON
         * @memberof maps.HexGridProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HexGridProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for HexGridProperties
         * @function getTypeUrl
         * @memberof maps.HexGridProperties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        HexGridProperties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.HexGridProperties";
        };

        return HexGridProperties;
    })();

    maps.GridlessGridProperties = (function() {

        /**
         * Properties of a GridlessGridProperties.
         * @memberof maps
         * @interface IGridlessGridProperties
         */

        /**
         * Constructs a new GridlessGridProperties.
         * @memberof maps
         * @classdesc Represents a GridlessGridProperties.
         * @implements IGridlessGridProperties
         * @constructor
         * @param {maps.IGridlessGridProperties=} [properties] Properties to set
         */
        function GridlessGridProperties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GridlessGridProperties instance using the specified properties.
         * @function create
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {maps.IGridlessGridProperties=} [properties] Properties to set
         * @returns {maps.GridlessGridProperties} GridlessGridProperties instance
         */
        GridlessGridProperties.create = function create(properties) {
            return new GridlessGridProperties(properties);
        };

        /**
         * Encodes the specified GridlessGridProperties message. Does not implicitly {@link maps.GridlessGridProperties.verify|verify} messages.
         * @function encode
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {maps.IGridlessGridProperties} message GridlessGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridlessGridProperties.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GridlessGridProperties message, length delimited. Does not implicitly {@link maps.GridlessGridProperties.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {maps.IGridlessGridProperties} message GridlessGridProperties message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridlessGridProperties.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridlessGridProperties message from the specified reader or buffer.
         * @function decode
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.GridlessGridProperties} GridlessGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridlessGridProperties.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.GridlessGridProperties();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridlessGridProperties message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.GridlessGridProperties} GridlessGridProperties
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridlessGridProperties.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridlessGridProperties message.
         * @function verify
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridlessGridProperties.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GridlessGridProperties message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.GridlessGridProperties} GridlessGridProperties
         */
        GridlessGridProperties.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.GridlessGridProperties)
                return object;
            return new $root.maps.GridlessGridProperties();
        };

        /**
         * Creates a plain object from a GridlessGridProperties message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {maps.GridlessGridProperties} message GridlessGridProperties
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridlessGridProperties.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GridlessGridProperties to JSON.
         * @function toJSON
         * @memberof maps.GridlessGridProperties
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridlessGridProperties.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridlessGridProperties
         * @function getTypeUrl
         * @memberof maps.GridlessGridProperties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridlessGridProperties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.GridlessGridProperties";
        };

        return GridlessGridProperties;
    })();

    maps.MapZonePermissions = (function() {

        /**
         * Properties of a MapZonePermissions.
         * @memberof maps
         * @interface IMapZonePermissions
         * @property {boolean|null} [canRead] MapZonePermissions canRead
         * @property {boolean|null} [canWrite] MapZonePermissions canWrite
         * @property {boolean|null} [canDelete] MapZonePermissions canDelete
         * @property {boolean|null} [canChangePublicStatus] MapZonePermissions canChangePublicStatus
         * @property {boolean|null} [canChangeMetadata] MapZonePermissions canChangeMetadata
         * @property {boolean|null} [canChangeVisibility] MapZonePermissions canChangeVisibility
         * @property {boolean|null} [canAddMapObjects] MapZonePermissions canAddMapObjects
         * @property {common.IFieldPermissions|null} [publicFieldAccess] MapZonePermissions publicFieldAccess
         * @property {common.IFieldPermissions|null} [protectedFieldAccess] MapZonePermissions protectedFieldAccess
         * @property {common.IFieldPermissions|null} [secretFieldAccess] MapZonePermissions secretFieldAccess
         */

        /**
         * Constructs a new MapZonePermissions.
         * @memberof maps
         * @classdesc Represents a MapZonePermissions.
         * @implements IMapZonePermissions
         * @constructor
         * @param {maps.IMapZonePermissions=} [properties] Properties to set
         */
        function MapZonePermissions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapZonePermissions canRead.
         * @member {boolean} canRead
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canRead = false;

        /**
         * MapZonePermissions canWrite.
         * @member {boolean} canWrite
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canWrite = false;

        /**
         * MapZonePermissions canDelete.
         * @member {boolean} canDelete
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canDelete = false;

        /**
         * MapZonePermissions canChangePublicStatus.
         * @member {boolean} canChangePublicStatus
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canChangePublicStatus = false;

        /**
         * MapZonePermissions canChangeMetadata.
         * @member {boolean} canChangeMetadata
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canChangeMetadata = false;

        /**
         * MapZonePermissions canChangeVisibility.
         * @member {boolean} canChangeVisibility
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canChangeVisibility = false;

        /**
         * MapZonePermissions canAddMapObjects.
         * @member {boolean} canAddMapObjects
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.canAddMapObjects = false;

        /**
         * MapZonePermissions publicFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} publicFieldAccess
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.publicFieldAccess = null;

        /**
         * MapZonePermissions protectedFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} protectedFieldAccess
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.protectedFieldAccess = null;

        /**
         * MapZonePermissions secretFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} secretFieldAccess
         * @memberof maps.MapZonePermissions
         * @instance
         */
        MapZonePermissions.prototype.secretFieldAccess = null;

        /**
         * Creates a new MapZonePermissions instance using the specified properties.
         * @function create
         * @memberof maps.MapZonePermissions
         * @static
         * @param {maps.IMapZonePermissions=} [properties] Properties to set
         * @returns {maps.MapZonePermissions} MapZonePermissions instance
         */
        MapZonePermissions.create = function create(properties) {
            return new MapZonePermissions(properties);
        };

        /**
         * Encodes the specified MapZonePermissions message. Does not implicitly {@link maps.MapZonePermissions.verify|verify} messages.
         * @function encode
         * @memberof maps.MapZonePermissions
         * @static
         * @param {maps.IMapZonePermissions} message MapZonePermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapZonePermissions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canRead != null && Object.hasOwnProperty.call(message, "canRead"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canRead);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canWrite);
            if (message.canDelete != null && Object.hasOwnProperty.call(message, "canDelete"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.canDelete);
            if (message.canChangePublicStatus != null && Object.hasOwnProperty.call(message, "canChangePublicStatus"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canChangePublicStatus);
            if (message.canChangeMetadata != null && Object.hasOwnProperty.call(message, "canChangeMetadata"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.canChangeMetadata);
            if (message.canChangeVisibility != null && Object.hasOwnProperty.call(message, "canChangeVisibility"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.canChangeVisibility);
            if (message.canAddMapObjects != null && Object.hasOwnProperty.call(message, "canAddMapObjects"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.canAddMapObjects);
            if (message.publicFieldAccess != null && Object.hasOwnProperty.call(message, "publicFieldAccess"))
                $root.common.FieldPermissions.encode(message.publicFieldAccess, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.protectedFieldAccess != null && Object.hasOwnProperty.call(message, "protectedFieldAccess"))
                $root.common.FieldPermissions.encode(message.protectedFieldAccess, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.secretFieldAccess != null && Object.hasOwnProperty.call(message, "secretFieldAccess"))
                $root.common.FieldPermissions.encode(message.secretFieldAccess, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapZonePermissions message, length delimited. Does not implicitly {@link maps.MapZonePermissions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapZonePermissions
         * @static
         * @param {maps.IMapZonePermissions} message MapZonePermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapZonePermissions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapZonePermissions message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapZonePermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapZonePermissions} MapZonePermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapZonePermissions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapZonePermissions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.canRead = reader.bool();
                        break;
                    }
                case 2: {
                        message.canWrite = reader.bool();
                        break;
                    }
                case 3: {
                        message.canDelete = reader.bool();
                        break;
                    }
                case 10: {
                        message.canChangePublicStatus = reader.bool();
                        break;
                    }
                case 11: {
                        message.canChangeMetadata = reader.bool();
                        break;
                    }
                case 12: {
                        message.canChangeVisibility = reader.bool();
                        break;
                    }
                case 13: {
                        message.canAddMapObjects = reader.bool();
                        break;
                    }
                case 50: {
                        message.publicFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.protectedFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 52: {
                        message.secretFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapZonePermissions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapZonePermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapZonePermissions} MapZonePermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapZonePermissions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapZonePermissions message.
         * @function verify
         * @memberof maps.MapZonePermissions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapZonePermissions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                if (typeof message.canRead !== "boolean")
                    return "canRead: boolean expected";
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                if (typeof message.canDelete !== "boolean")
                    return "canDelete: boolean expected";
            if (message.canChangePublicStatus != null && message.hasOwnProperty("canChangePublicStatus"))
                if (typeof message.canChangePublicStatus !== "boolean")
                    return "canChangePublicStatus: boolean expected";
            if (message.canChangeMetadata != null && message.hasOwnProperty("canChangeMetadata"))
                if (typeof message.canChangeMetadata !== "boolean")
                    return "canChangeMetadata: boolean expected";
            if (message.canChangeVisibility != null && message.hasOwnProperty("canChangeVisibility"))
                if (typeof message.canChangeVisibility !== "boolean")
                    return "canChangeVisibility: boolean expected";
            if (message.canAddMapObjects != null && message.hasOwnProperty("canAddMapObjects"))
                if (typeof message.canAddMapObjects !== "boolean")
                    return "canAddMapObjects: boolean expected";
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.publicFieldAccess);
                if (error)
                    return "publicFieldAccess." + error;
            }
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.protectedFieldAccess);
                if (error)
                    return "protectedFieldAccess." + error;
            }
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.secretFieldAccess);
                if (error)
                    return "secretFieldAccess." + error;
            }
            return null;
        };

        /**
         * Creates a MapZonePermissions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapZonePermissions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapZonePermissions} MapZonePermissions
         */
        MapZonePermissions.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapZonePermissions)
                return object;
            let message = new $root.maps.MapZonePermissions();
            if (object.canRead != null)
                message.canRead = Boolean(object.canRead);
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            if (object.canDelete != null)
                message.canDelete = Boolean(object.canDelete);
            if (object.canChangePublicStatus != null)
                message.canChangePublicStatus = Boolean(object.canChangePublicStatus);
            if (object.canChangeMetadata != null)
                message.canChangeMetadata = Boolean(object.canChangeMetadata);
            if (object.canChangeVisibility != null)
                message.canChangeVisibility = Boolean(object.canChangeVisibility);
            if (object.canAddMapObjects != null)
                message.canAddMapObjects = Boolean(object.canAddMapObjects);
            if (object.publicFieldAccess != null) {
                if (typeof object.publicFieldAccess !== "object")
                    throw TypeError(".maps.MapZonePermissions.publicFieldAccess: object expected");
                message.publicFieldAccess = $root.common.FieldPermissions.fromObject(object.publicFieldAccess);
            }
            if (object.protectedFieldAccess != null) {
                if (typeof object.protectedFieldAccess !== "object")
                    throw TypeError(".maps.MapZonePermissions.protectedFieldAccess: object expected");
                message.protectedFieldAccess = $root.common.FieldPermissions.fromObject(object.protectedFieldAccess);
            }
            if (object.secretFieldAccess != null) {
                if (typeof object.secretFieldAccess !== "object")
                    throw TypeError(".maps.MapZonePermissions.secretFieldAccess: object expected");
                message.secretFieldAccess = $root.common.FieldPermissions.fromObject(object.secretFieldAccess);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapZonePermissions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapZonePermissions
         * @static
         * @param {maps.MapZonePermissions} message MapZonePermissions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapZonePermissions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.canRead = false;
                object.canWrite = false;
                object.canDelete = false;
                object.canChangePublicStatus = false;
                object.canChangeMetadata = false;
                object.canChangeVisibility = false;
                object.canAddMapObjects = false;
                object.publicFieldAccess = null;
                object.protectedFieldAccess = null;
                object.secretFieldAccess = null;
            }
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                object.canRead = message.canRead;
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                object.canDelete = message.canDelete;
            if (message.canChangePublicStatus != null && message.hasOwnProperty("canChangePublicStatus"))
                object.canChangePublicStatus = message.canChangePublicStatus;
            if (message.canChangeMetadata != null && message.hasOwnProperty("canChangeMetadata"))
                object.canChangeMetadata = message.canChangeMetadata;
            if (message.canChangeVisibility != null && message.hasOwnProperty("canChangeVisibility"))
                object.canChangeVisibility = message.canChangeVisibility;
            if (message.canAddMapObjects != null && message.hasOwnProperty("canAddMapObjects"))
                object.canAddMapObjects = message.canAddMapObjects;
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess"))
                object.publicFieldAccess = $root.common.FieldPermissions.toObject(message.publicFieldAccess, options);
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess"))
                object.protectedFieldAccess = $root.common.FieldPermissions.toObject(message.protectedFieldAccess, options);
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess"))
                object.secretFieldAccess = $root.common.FieldPermissions.toObject(message.secretFieldAccess, options);
            return object;
        };

        /**
         * Converts this MapZonePermissions to JSON.
         * @function toJSON
         * @memberof maps.MapZonePermissions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapZonePermissions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapZonePermissions
         * @function getTypeUrl
         * @memberof maps.MapZonePermissions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapZonePermissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapZonePermissions";
        };

        return MapZonePermissions;
    })();

    maps.MapZone = (function() {

        /**
         * Properties of a MapZone.
         * @memberof maps
         * @interface IMapZone
         * @property {string|null} [id] MapZone id
         * @property {string|null} [name] MapZone name
         * @property {string|null} [mapId] MapZone mapId
         * @property {string|null} [publicName] MapZone publicName
         * @property {boolean|null} ["public"] MapZone public
         * @property {string|null} [sourceTemplateId] MapZone sourceTemplateId
         * @property {boolean|null} [modifiedFromSourceTemplate] MapZone modifiedFromSourceTemplate
         * @property {maps.IMapImage|null} [backgroundImage] MapZone backgroundImage
         * @property {geometry.IGridConfiguration|null} [gridConfiguration] MapZone gridConfiguration
         * @property {maps.IVisibilityMasks|null} [visibility] MapZone visibility
         * @property {maps.IBoundaryDetails|null} [boundaries] MapZone boundaries
         * @property {Array.<maps.IMapObject>|null} [objects] MapZone objects
         * @property {maps.IMapZonePermissions|null} [permissions] MapZone permissions
         */

        /**
         * Constructs a new MapZone.
         * @memberof maps
         * @classdesc Represents a MapZone.
         * @implements IMapZone
         * @constructor
         * @param {maps.IMapZone=} [properties] Properties to set
         */
        function MapZone(properties) {
            this.objects = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapZone id.
         * @member {string} id
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.id = "";

        /**
         * MapZone name.
         * @member {string} name
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.name = "";

        /**
         * MapZone mapId.
         * @member {string} mapId
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.mapId = "";

        /**
         * MapZone publicName.
         * @member {string} publicName
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.publicName = "";

        /**
         * MapZone public.
         * @member {boolean} public
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype["public"] = false;

        /**
         * MapZone sourceTemplateId.
         * @member {string} sourceTemplateId
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.sourceTemplateId = "";

        /**
         * MapZone modifiedFromSourceTemplate.
         * @member {boolean} modifiedFromSourceTemplate
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.modifiedFromSourceTemplate = false;

        /**
         * MapZone backgroundImage.
         * @member {maps.IMapImage|null|undefined} backgroundImage
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.backgroundImage = null;

        /**
         * MapZone gridConfiguration.
         * @member {geometry.IGridConfiguration|null|undefined} gridConfiguration
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.gridConfiguration = null;

        /**
         * MapZone visibility.
         * @member {maps.IVisibilityMasks|null|undefined} visibility
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.visibility = null;

        /**
         * MapZone boundaries.
         * @member {maps.IBoundaryDetails|null|undefined} boundaries
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.boundaries = null;

        /**
         * MapZone objects.
         * @member {Array.<maps.IMapObject>} objects
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.objects = $util.emptyArray;

        /**
         * MapZone permissions.
         * @member {maps.IMapZonePermissions|null|undefined} permissions
         * @memberof maps.MapZone
         * @instance
         */
        MapZone.prototype.permissions = null;

        /**
         * Creates a new MapZone instance using the specified properties.
         * @function create
         * @memberof maps.MapZone
         * @static
         * @param {maps.IMapZone=} [properties] Properties to set
         * @returns {maps.MapZone} MapZone instance
         */
        MapZone.create = function create(properties) {
            return new MapZone(properties);
        };

        /**
         * Encodes the specified MapZone message. Does not implicitly {@link maps.MapZone.verify|verify} messages.
         * @function encode
         * @memberof maps.MapZone
         * @static
         * @param {maps.IMapZone} message MapZone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapZone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.mapId != null && Object.hasOwnProperty.call(message, "mapId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.mapId);
            if (message.publicName != null && Object.hasOwnProperty.call(message, "publicName"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.publicName);
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                writer.uint32(/* id 19, wireType 0 =*/152).bool(message["public"]);
            if (message.sourceTemplateId != null && Object.hasOwnProperty.call(message, "sourceTemplateId"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.sourceTemplateId);
            if (message.modifiedFromSourceTemplate != null && Object.hasOwnProperty.call(message, "modifiedFromSourceTemplate"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.modifiedFromSourceTemplate);
            if (message.backgroundImage != null && Object.hasOwnProperty.call(message, "backgroundImage"))
                $root.maps.MapImage.encode(message.backgroundImage, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.gridConfiguration != null && Object.hasOwnProperty.call(message, "gridConfiguration"))
                $root.geometry.GridConfiguration.encode(message.gridConfiguration, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.visibility != null && Object.hasOwnProperty.call(message, "visibility"))
                $root.maps.VisibilityMasks.encode(message.visibility, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.boundaries != null && Object.hasOwnProperty.call(message, "boundaries"))
                $root.maps.BoundaryDetails.encode(message.boundaries, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.objects != null && message.objects.length)
                for (let i = 0; i < message.objects.length; ++i)
                    $root.maps.MapObject.encode(message.objects[i], writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                $root.maps.MapZonePermissions.encode(message.permissions, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapZone message, length delimited. Does not implicitly {@link maps.MapZone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapZone
         * @static
         * @param {maps.IMapZone} message MapZone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapZone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapZone message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapZone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapZone} MapZone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapZone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapZone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 16: {
                        message.mapId = reader.string();
                        break;
                    }
                case 18: {
                        message.publicName = reader.string();
                        break;
                    }
                case 19: {
                        message["public"] = reader.bool();
                        break;
                    }
                case 20: {
                        message.sourceTemplateId = reader.string();
                        break;
                    }
                case 21: {
                        message.modifiedFromSourceTemplate = reader.bool();
                        break;
                    }
                case 22: {
                        message.backgroundImage = $root.maps.MapImage.decode(reader, reader.uint32());
                        break;
                    }
                case 23: {
                        message.gridConfiguration = $root.geometry.GridConfiguration.decode(reader, reader.uint32());
                        break;
                    }
                case 26: {
                        message.visibility = $root.maps.VisibilityMasks.decode(reader, reader.uint32());
                        break;
                    }
                case 27: {
                        message.boundaries = $root.maps.BoundaryDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 28: {
                        if (!(message.objects && message.objects.length))
                            message.objects = [];
                        message.objects.push($root.maps.MapObject.decode(reader, reader.uint32()));
                        break;
                    }
                case 29: {
                        message.permissions = $root.maps.MapZonePermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapZone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapZone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapZone} MapZone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapZone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapZone message.
         * @function verify
         * @memberof maps.MapZone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapZone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                if (!$util.isString(message.mapId))
                    return "mapId: string expected";
            if (message.publicName != null && message.hasOwnProperty("publicName"))
                if (!$util.isString(message.publicName))
                    return "publicName: string expected";
            if (message["public"] != null && message.hasOwnProperty("public"))
                if (typeof message["public"] !== "boolean")
                    return "public: boolean expected";
            if (message.sourceTemplateId != null && message.hasOwnProperty("sourceTemplateId"))
                if (!$util.isString(message.sourceTemplateId))
                    return "sourceTemplateId: string expected";
            if (message.modifiedFromSourceTemplate != null && message.hasOwnProperty("modifiedFromSourceTemplate"))
                if (typeof message.modifiedFromSourceTemplate !== "boolean")
                    return "modifiedFromSourceTemplate: boolean expected";
            if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage")) {
                let error = $root.maps.MapImage.verify(message.backgroundImage);
                if (error)
                    return "backgroundImage." + error;
            }
            if (message.gridConfiguration != null && message.hasOwnProperty("gridConfiguration")) {
                let error = $root.geometry.GridConfiguration.verify(message.gridConfiguration);
                if (error)
                    return "gridConfiguration." + error;
            }
            if (message.visibility != null && message.hasOwnProperty("visibility")) {
                let error = $root.maps.VisibilityMasks.verify(message.visibility);
                if (error)
                    return "visibility." + error;
            }
            if (message.boundaries != null && message.hasOwnProperty("boundaries")) {
                let error = $root.maps.BoundaryDetails.verify(message.boundaries);
                if (error)
                    return "boundaries." + error;
            }
            if (message.objects != null && message.hasOwnProperty("objects")) {
                if (!Array.isArray(message.objects))
                    return "objects: array expected";
                for (let i = 0; i < message.objects.length; ++i) {
                    let error = $root.maps.MapObject.verify(message.objects[i]);
                    if (error)
                        return "objects." + error;
                }
            }
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                let error = $root.maps.MapZonePermissions.verify(message.permissions);
                if (error)
                    return "permissions." + error;
            }
            return null;
        };

        /**
         * Creates a MapZone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapZone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapZone} MapZone
         */
        MapZone.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapZone)
                return object;
            let message = new $root.maps.MapZone();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mapId != null)
                message.mapId = String(object.mapId);
            if (object.publicName != null)
                message.publicName = String(object.publicName);
            if (object["public"] != null)
                message["public"] = Boolean(object["public"]);
            if (object.sourceTemplateId != null)
                message.sourceTemplateId = String(object.sourceTemplateId);
            if (object.modifiedFromSourceTemplate != null)
                message.modifiedFromSourceTemplate = Boolean(object.modifiedFromSourceTemplate);
            if (object.backgroundImage != null) {
                if (typeof object.backgroundImage !== "object")
                    throw TypeError(".maps.MapZone.backgroundImage: object expected");
                message.backgroundImage = $root.maps.MapImage.fromObject(object.backgroundImage);
            }
            if (object.gridConfiguration != null) {
                if (typeof object.gridConfiguration !== "object")
                    throw TypeError(".maps.MapZone.gridConfiguration: object expected");
                message.gridConfiguration = $root.geometry.GridConfiguration.fromObject(object.gridConfiguration);
            }
            if (object.visibility != null) {
                if (typeof object.visibility !== "object")
                    throw TypeError(".maps.MapZone.visibility: object expected");
                message.visibility = $root.maps.VisibilityMasks.fromObject(object.visibility);
            }
            if (object.boundaries != null) {
                if (typeof object.boundaries !== "object")
                    throw TypeError(".maps.MapZone.boundaries: object expected");
                message.boundaries = $root.maps.BoundaryDetails.fromObject(object.boundaries);
            }
            if (object.objects) {
                if (!Array.isArray(object.objects))
                    throw TypeError(".maps.MapZone.objects: array expected");
                message.objects = [];
                for (let i = 0; i < object.objects.length; ++i) {
                    if (typeof object.objects[i] !== "object")
                        throw TypeError(".maps.MapZone.objects: object expected");
                    message.objects[i] = $root.maps.MapObject.fromObject(object.objects[i]);
                }
            }
            if (object.permissions != null) {
                if (typeof object.permissions !== "object")
                    throw TypeError(".maps.MapZone.permissions: object expected");
                message.permissions = $root.maps.MapZonePermissions.fromObject(object.permissions);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapZone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapZone
         * @static
         * @param {maps.MapZone} message MapZone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapZone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.objects = [];
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.mapId = "";
                object.publicName = "";
                object["public"] = false;
                object.sourceTemplateId = "";
                object.modifiedFromSourceTemplate = false;
                object.backgroundImage = null;
                object.gridConfiguration = null;
                object.visibility = null;
                object.boundaries = null;
                object.permissions = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mapId != null && message.hasOwnProperty("mapId"))
                object.mapId = message.mapId;
            if (message.publicName != null && message.hasOwnProperty("publicName"))
                object.publicName = message.publicName;
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = message["public"];
            if (message.sourceTemplateId != null && message.hasOwnProperty("sourceTemplateId"))
                object.sourceTemplateId = message.sourceTemplateId;
            if (message.modifiedFromSourceTemplate != null && message.hasOwnProperty("modifiedFromSourceTemplate"))
                object.modifiedFromSourceTemplate = message.modifiedFromSourceTemplate;
            if (message.backgroundImage != null && message.hasOwnProperty("backgroundImage"))
                object.backgroundImage = $root.maps.MapImage.toObject(message.backgroundImage, options);
            if (message.gridConfiguration != null && message.hasOwnProperty("gridConfiguration"))
                object.gridConfiguration = $root.geometry.GridConfiguration.toObject(message.gridConfiguration, options);
            if (message.visibility != null && message.hasOwnProperty("visibility"))
                object.visibility = $root.maps.VisibilityMasks.toObject(message.visibility, options);
            if (message.boundaries != null && message.hasOwnProperty("boundaries"))
                object.boundaries = $root.maps.BoundaryDetails.toObject(message.boundaries, options);
            if (message.objects && message.objects.length) {
                object.objects = [];
                for (let j = 0; j < message.objects.length; ++j)
                    object.objects[j] = $root.maps.MapObject.toObject(message.objects[j], options);
            }
            if (message.permissions != null && message.hasOwnProperty("permissions"))
                object.permissions = $root.maps.MapZonePermissions.toObject(message.permissions, options);
            return object;
        };

        /**
         * Converts this MapZone to JSON.
         * @function toJSON
         * @memberof maps.MapZone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapZone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapZone
         * @function getTypeUrl
         * @memberof maps.MapZone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapZone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapZone";
        };

        return MapZone;
    })();

    /**
     * MapDataset enum.
     * @name maps.MapDataset
     * @enum {number}
     * @property {number} UNKNOWN_MAP_DATASET=0 UNKNOWN_MAP_DATASET value
     * @property {number} BASIC_MAP_DATA=1 BASIC_MAP_DATA value
     * @property {number} MAP_ZONES_DATA=2 MAP_ZONES_DATA value
     */
    maps.MapDataset = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_MAP_DATASET"] = 0;
        values[valuesById[1] = "BASIC_MAP_DATA"] = 1;
        values[valuesById[2] = "MAP_ZONES_DATA"] = 2;
        return values;
    })();

    maps.MapPermissions = (function() {

        /**
         * Properties of a MapPermissions.
         * @memberof maps
         * @interface IMapPermissions
         * @property {boolean|null} [canRead] MapPermissions canRead
         * @property {boolean|null} [canWrite] MapPermissions canWrite
         * @property {boolean|null} [canDelete] MapPermissions canDelete
         * @property {boolean|null} [canChangePublicStatus] MapPermissions canChangePublicStatus
         * @property {boolean|null} [canReadZones] MapPermissions canReadZones
         * @property {boolean|null} [canCreateZones] MapPermissions canCreateZones
         * @property {boolean|null} [canDeleteZones] MapPermissions canDeleteZones
         * @property {common.IFieldPermissions|null} [publicFieldAccess] MapPermissions publicFieldAccess
         * @property {common.IFieldPermissions|null} [protectedFieldAccess] MapPermissions protectedFieldAccess
         * @property {common.IFieldPermissions|null} [secretFieldAccess] MapPermissions secretFieldAccess
         */

        /**
         * Constructs a new MapPermissions.
         * @memberof maps
         * @classdesc Represents a MapPermissions.
         * @implements IMapPermissions
         * @constructor
         * @param {maps.IMapPermissions=} [properties] Properties to set
         */
        function MapPermissions(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MapPermissions canRead.
         * @member {boolean} canRead
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canRead = false;

        /**
         * MapPermissions canWrite.
         * @member {boolean} canWrite
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canWrite = false;

        /**
         * MapPermissions canDelete.
         * @member {boolean} canDelete
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canDelete = false;

        /**
         * MapPermissions canChangePublicStatus.
         * @member {boolean} canChangePublicStatus
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canChangePublicStatus = false;

        /**
         * MapPermissions canReadZones.
         * @member {boolean} canReadZones
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canReadZones = false;

        /**
         * MapPermissions canCreateZones.
         * @member {boolean} canCreateZones
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canCreateZones = false;

        /**
         * MapPermissions canDeleteZones.
         * @member {boolean} canDeleteZones
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.canDeleteZones = false;

        /**
         * MapPermissions publicFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} publicFieldAccess
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.publicFieldAccess = null;

        /**
         * MapPermissions protectedFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} protectedFieldAccess
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.protectedFieldAccess = null;

        /**
         * MapPermissions secretFieldAccess.
         * @member {common.IFieldPermissions|null|undefined} secretFieldAccess
         * @memberof maps.MapPermissions
         * @instance
         */
        MapPermissions.prototype.secretFieldAccess = null;

        /**
         * Creates a new MapPermissions instance using the specified properties.
         * @function create
         * @memberof maps.MapPermissions
         * @static
         * @param {maps.IMapPermissions=} [properties] Properties to set
         * @returns {maps.MapPermissions} MapPermissions instance
         */
        MapPermissions.create = function create(properties) {
            return new MapPermissions(properties);
        };

        /**
         * Encodes the specified MapPermissions message. Does not implicitly {@link maps.MapPermissions.verify|verify} messages.
         * @function encode
         * @memberof maps.MapPermissions
         * @static
         * @param {maps.IMapPermissions} message MapPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapPermissions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.canRead != null && Object.hasOwnProperty.call(message, "canRead"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.canRead);
            if (message.canWrite != null && Object.hasOwnProperty.call(message, "canWrite"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.canWrite);
            if (message.canDelete != null && Object.hasOwnProperty.call(message, "canDelete"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.canDelete);
            if (message.canChangePublicStatus != null && Object.hasOwnProperty.call(message, "canChangePublicStatus"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.canChangePublicStatus);
            if (message.canReadZones != null && Object.hasOwnProperty.call(message, "canReadZones"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.canReadZones);
            if (message.canCreateZones != null && Object.hasOwnProperty.call(message, "canCreateZones"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.canCreateZones);
            if (message.canDeleteZones != null && Object.hasOwnProperty.call(message, "canDeleteZones"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.canDeleteZones);
            if (message.publicFieldAccess != null && Object.hasOwnProperty.call(message, "publicFieldAccess"))
                $root.common.FieldPermissions.encode(message.publicFieldAccess, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.protectedFieldAccess != null && Object.hasOwnProperty.call(message, "protectedFieldAccess"))
                $root.common.FieldPermissions.encode(message.protectedFieldAccess, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.secretFieldAccess != null && Object.hasOwnProperty.call(message, "secretFieldAccess"))
                $root.common.FieldPermissions.encode(message.secretFieldAccess, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MapPermissions message, length delimited. Does not implicitly {@link maps.MapPermissions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.MapPermissions
         * @static
         * @param {maps.IMapPermissions} message MapPermissions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MapPermissions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MapPermissions message from the specified reader or buffer.
         * @function decode
         * @memberof maps.MapPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.MapPermissions} MapPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapPermissions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.MapPermissions();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.canRead = reader.bool();
                        break;
                    }
                case 2: {
                        message.canWrite = reader.bool();
                        break;
                    }
                case 3: {
                        message.canDelete = reader.bool();
                        break;
                    }
                case 10: {
                        message.canChangePublicStatus = reader.bool();
                        break;
                    }
                case 11: {
                        message.canReadZones = reader.bool();
                        break;
                    }
                case 12: {
                        message.canCreateZones = reader.bool();
                        break;
                    }
                case 13: {
                        message.canDeleteZones = reader.bool();
                        break;
                    }
                case 50: {
                        message.publicFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 51: {
                        message.protectedFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                case 52: {
                        message.secretFieldAccess = $root.common.FieldPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MapPermissions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.MapPermissions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.MapPermissions} MapPermissions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MapPermissions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MapPermissions message.
         * @function verify
         * @memberof maps.MapPermissions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MapPermissions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                if (typeof message.canRead !== "boolean")
                    return "canRead: boolean expected";
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                if (typeof message.canWrite !== "boolean")
                    return "canWrite: boolean expected";
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                if (typeof message.canDelete !== "boolean")
                    return "canDelete: boolean expected";
            if (message.canChangePublicStatus != null && message.hasOwnProperty("canChangePublicStatus"))
                if (typeof message.canChangePublicStatus !== "boolean")
                    return "canChangePublicStatus: boolean expected";
            if (message.canReadZones != null && message.hasOwnProperty("canReadZones"))
                if (typeof message.canReadZones !== "boolean")
                    return "canReadZones: boolean expected";
            if (message.canCreateZones != null && message.hasOwnProperty("canCreateZones"))
                if (typeof message.canCreateZones !== "boolean")
                    return "canCreateZones: boolean expected";
            if (message.canDeleteZones != null && message.hasOwnProperty("canDeleteZones"))
                if (typeof message.canDeleteZones !== "boolean")
                    return "canDeleteZones: boolean expected";
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.publicFieldAccess);
                if (error)
                    return "publicFieldAccess." + error;
            }
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.protectedFieldAccess);
                if (error)
                    return "protectedFieldAccess." + error;
            }
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess")) {
                let error = $root.common.FieldPermissions.verify(message.secretFieldAccess);
                if (error)
                    return "secretFieldAccess." + error;
            }
            return null;
        };

        /**
         * Creates a MapPermissions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.MapPermissions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.MapPermissions} MapPermissions
         */
        MapPermissions.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.MapPermissions)
                return object;
            let message = new $root.maps.MapPermissions();
            if (object.canRead != null)
                message.canRead = Boolean(object.canRead);
            if (object.canWrite != null)
                message.canWrite = Boolean(object.canWrite);
            if (object.canDelete != null)
                message.canDelete = Boolean(object.canDelete);
            if (object.canChangePublicStatus != null)
                message.canChangePublicStatus = Boolean(object.canChangePublicStatus);
            if (object.canReadZones != null)
                message.canReadZones = Boolean(object.canReadZones);
            if (object.canCreateZones != null)
                message.canCreateZones = Boolean(object.canCreateZones);
            if (object.canDeleteZones != null)
                message.canDeleteZones = Boolean(object.canDeleteZones);
            if (object.publicFieldAccess != null) {
                if (typeof object.publicFieldAccess !== "object")
                    throw TypeError(".maps.MapPermissions.publicFieldAccess: object expected");
                message.publicFieldAccess = $root.common.FieldPermissions.fromObject(object.publicFieldAccess);
            }
            if (object.protectedFieldAccess != null) {
                if (typeof object.protectedFieldAccess !== "object")
                    throw TypeError(".maps.MapPermissions.protectedFieldAccess: object expected");
                message.protectedFieldAccess = $root.common.FieldPermissions.fromObject(object.protectedFieldAccess);
            }
            if (object.secretFieldAccess != null) {
                if (typeof object.secretFieldAccess !== "object")
                    throw TypeError(".maps.MapPermissions.secretFieldAccess: object expected");
                message.secretFieldAccess = $root.common.FieldPermissions.fromObject(object.secretFieldAccess);
            }
            return message;
        };

        /**
         * Creates a plain object from a MapPermissions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.MapPermissions
         * @static
         * @param {maps.MapPermissions} message MapPermissions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MapPermissions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.canRead = false;
                object.canWrite = false;
                object.canDelete = false;
                object.canChangePublicStatus = false;
                object.canReadZones = false;
                object.canCreateZones = false;
                object.canDeleteZones = false;
                object.publicFieldAccess = null;
                object.protectedFieldAccess = null;
                object.secretFieldAccess = null;
            }
            if (message.canRead != null && message.hasOwnProperty("canRead"))
                object.canRead = message.canRead;
            if (message.canWrite != null && message.hasOwnProperty("canWrite"))
                object.canWrite = message.canWrite;
            if (message.canDelete != null && message.hasOwnProperty("canDelete"))
                object.canDelete = message.canDelete;
            if (message.canChangePublicStatus != null && message.hasOwnProperty("canChangePublicStatus"))
                object.canChangePublicStatus = message.canChangePublicStatus;
            if (message.canReadZones != null && message.hasOwnProperty("canReadZones"))
                object.canReadZones = message.canReadZones;
            if (message.canCreateZones != null && message.hasOwnProperty("canCreateZones"))
                object.canCreateZones = message.canCreateZones;
            if (message.canDeleteZones != null && message.hasOwnProperty("canDeleteZones"))
                object.canDeleteZones = message.canDeleteZones;
            if (message.publicFieldAccess != null && message.hasOwnProperty("publicFieldAccess"))
                object.publicFieldAccess = $root.common.FieldPermissions.toObject(message.publicFieldAccess, options);
            if (message.protectedFieldAccess != null && message.hasOwnProperty("protectedFieldAccess"))
                object.protectedFieldAccess = $root.common.FieldPermissions.toObject(message.protectedFieldAccess, options);
            if (message.secretFieldAccess != null && message.hasOwnProperty("secretFieldAccess"))
                object.secretFieldAccess = $root.common.FieldPermissions.toObject(message.secretFieldAccess, options);
            return object;
        };

        /**
         * Converts this MapPermissions to JSON.
         * @function toJSON
         * @memberof maps.MapPermissions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MapPermissions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MapPermissions
         * @function getTypeUrl
         * @memberof maps.MapPermissions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MapPermissions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.MapPermissions";
        };

        return MapPermissions;
    })();

    maps.Map = (function() {

        /**
         * Properties of a Map.
         * @memberof maps
         * @interface IMap
         * @property {string|null} [id] Map id
         * @property {string|null} [name] Map name
         * @property {string|null} [campaignId] Map campaignId
         * @property {string|null} [publicName] Map publicName
         * @property {boolean|null} ["public"] Map public
         * @property {string|null} [sourceTemplateId] Map sourceTemplateId
         * @property {boolean|null} [modifiedFromSourceTemplate] Map modifiedFromSourceTemplate
         * @property {Array.<common.IAccessGrant>|null} [accessGrants] Map accessGrants
         * @property {common.AccessLevel|null} [accessLevel] Map accessLevel
         * @property {Array.<maps.IMapZone>|null} [zones] Map zones
         * @property {maps.IMapPermissions|null} [permissions] Map permissions
         */

        /**
         * Constructs a new Map.
         * @memberof maps
         * @classdesc Represents a Map.
         * @implements IMap
         * @constructor
         * @param {maps.IMap=} [properties] Properties to set
         */
        function Map(properties) {
            this.accessGrants = [];
            this.zones = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Map id.
         * @member {string} id
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.id = "";

        /**
         * Map name.
         * @member {string} name
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.name = "";

        /**
         * Map campaignId.
         * @member {string} campaignId
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.campaignId = "";

        /**
         * Map publicName.
         * @member {string} publicName
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.publicName = "";

        /**
         * Map public.
         * @member {boolean} public
         * @memberof maps.Map
         * @instance
         */
        Map.prototype["public"] = false;

        /**
         * Map sourceTemplateId.
         * @member {string} sourceTemplateId
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.sourceTemplateId = "";

        /**
         * Map modifiedFromSourceTemplate.
         * @member {boolean} modifiedFromSourceTemplate
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.modifiedFromSourceTemplate = false;

        /**
         * Map accessGrants.
         * @member {Array.<common.IAccessGrant>} accessGrants
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.accessGrants = $util.emptyArray;

        /**
         * Map accessLevel.
         * @member {common.AccessLevel} accessLevel
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.accessLevel = 0;

        /**
         * Map zones.
         * @member {Array.<maps.IMapZone>} zones
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.zones = $util.emptyArray;

        /**
         * Map permissions.
         * @member {maps.IMapPermissions|null|undefined} permissions
         * @memberof maps.Map
         * @instance
         */
        Map.prototype.permissions = null;

        /**
         * Creates a new Map instance using the specified properties.
         * @function create
         * @memberof maps.Map
         * @static
         * @param {maps.IMap=} [properties] Properties to set
         * @returns {maps.Map} Map instance
         */
        Map.create = function create(properties) {
            return new Map(properties);
        };

        /**
         * Encodes the specified Map message. Does not implicitly {@link maps.Map.verify|verify} messages.
         * @function encode
         * @memberof maps.Map
         * @static
         * @param {maps.IMap} message Map message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Map.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.campaignId);
            if (message.publicName != null && Object.hasOwnProperty.call(message, "publicName"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.publicName);
            if (message["public"] != null && Object.hasOwnProperty.call(message, "public"))
                writer.uint32(/* id 19, wireType 0 =*/152).bool(message["public"]);
            if (message.sourceTemplateId != null && Object.hasOwnProperty.call(message, "sourceTemplateId"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.sourceTemplateId);
            if (message.modifiedFromSourceTemplate != null && Object.hasOwnProperty.call(message, "modifiedFromSourceTemplate"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.modifiedFromSourceTemplate);
            if (message.accessGrants != null && message.accessGrants.length)
                for (let i = 0; i < message.accessGrants.length; ++i)
                    $root.common.AccessGrant.encode(message.accessGrants[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.accessLevel != null && Object.hasOwnProperty.call(message, "accessLevel"))
                writer.uint32(/* id 24, wireType 0 =*/192).int32(message.accessLevel);
            if (message.zones != null && message.zones.length)
                for (let i = 0; i < message.zones.length; ++i)
                    $root.maps.MapZone.encode(message.zones[i], writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.permissions != null && Object.hasOwnProperty.call(message, "permissions"))
                $root.maps.MapPermissions.encode(message.permissions, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Map message, length delimited. Does not implicitly {@link maps.Map.verify|verify} messages.
         * @function encodeDelimited
         * @memberof maps.Map
         * @static
         * @param {maps.IMap} message Map message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Map.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Map message from the specified reader or buffer.
         * @function decode
         * @memberof maps.Map
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {maps.Map} Map
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Map.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.maps.Map();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 17: {
                        message.campaignId = reader.string();
                        break;
                    }
                case 18: {
                        message.publicName = reader.string();
                        break;
                    }
                case 19: {
                        message["public"] = reader.bool();
                        break;
                    }
                case 20: {
                        message.sourceTemplateId = reader.string();
                        break;
                    }
                case 21: {
                        message.modifiedFromSourceTemplate = reader.bool();
                        break;
                    }
                case 23: {
                        if (!(message.accessGrants && message.accessGrants.length))
                            message.accessGrants = [];
                        message.accessGrants.push($root.common.AccessGrant.decode(reader, reader.uint32()));
                        break;
                    }
                case 24: {
                        message.accessLevel = reader.int32();
                        break;
                    }
                case 25: {
                        if (!(message.zones && message.zones.length))
                            message.zones = [];
                        message.zones.push($root.maps.MapZone.decode(reader, reader.uint32()));
                        break;
                    }
                case 26: {
                        message.permissions = $root.maps.MapPermissions.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Map message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof maps.Map
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {maps.Map} Map
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Map.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Map message.
         * @function verify
         * @memberof maps.Map
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Map.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                if (!$util.isString(message.campaignId))
                    return "campaignId: string expected";
            if (message.publicName != null && message.hasOwnProperty("publicName"))
                if (!$util.isString(message.publicName))
                    return "publicName: string expected";
            if (message["public"] != null && message.hasOwnProperty("public"))
                if (typeof message["public"] !== "boolean")
                    return "public: boolean expected";
            if (message.sourceTemplateId != null && message.hasOwnProperty("sourceTemplateId"))
                if (!$util.isString(message.sourceTemplateId))
                    return "sourceTemplateId: string expected";
            if (message.modifiedFromSourceTemplate != null && message.hasOwnProperty("modifiedFromSourceTemplate"))
                if (typeof message.modifiedFromSourceTemplate !== "boolean")
                    return "modifiedFromSourceTemplate: boolean expected";
            if (message.accessGrants != null && message.hasOwnProperty("accessGrants")) {
                if (!Array.isArray(message.accessGrants))
                    return "accessGrants: array expected";
                for (let i = 0; i < message.accessGrants.length; ++i) {
                    let error = $root.common.AccessGrant.verify(message.accessGrants[i]);
                    if (error)
                        return "accessGrants." + error;
                }
            }
            if (message.accessLevel != null && message.hasOwnProperty("accessLevel"))
                switch (message.accessLevel) {
                default:
                    return "accessLevel: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.zones != null && message.hasOwnProperty("zones")) {
                if (!Array.isArray(message.zones))
                    return "zones: array expected";
                for (let i = 0; i < message.zones.length; ++i) {
                    let error = $root.maps.MapZone.verify(message.zones[i]);
                    if (error)
                        return "zones." + error;
                }
            }
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                let error = $root.maps.MapPermissions.verify(message.permissions);
                if (error)
                    return "permissions." + error;
            }
            return null;
        };

        /**
         * Creates a Map message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof maps.Map
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {maps.Map} Map
         */
        Map.fromObject = function fromObject(object) {
            if (object instanceof $root.maps.Map)
                return object;
            let message = new $root.maps.Map();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.campaignId != null)
                message.campaignId = String(object.campaignId);
            if (object.publicName != null)
                message.publicName = String(object.publicName);
            if (object["public"] != null)
                message["public"] = Boolean(object["public"]);
            if (object.sourceTemplateId != null)
                message.sourceTemplateId = String(object.sourceTemplateId);
            if (object.modifiedFromSourceTemplate != null)
                message.modifiedFromSourceTemplate = Boolean(object.modifiedFromSourceTemplate);
            if (object.accessGrants) {
                if (!Array.isArray(object.accessGrants))
                    throw TypeError(".maps.Map.accessGrants: array expected");
                message.accessGrants = [];
                for (let i = 0; i < object.accessGrants.length; ++i) {
                    if (typeof object.accessGrants[i] !== "object")
                        throw TypeError(".maps.Map.accessGrants: object expected");
                    message.accessGrants[i] = $root.common.AccessGrant.fromObject(object.accessGrants[i]);
                }
            }
            switch (object.accessLevel) {
            default:
                if (typeof object.accessLevel === "number") {
                    message.accessLevel = object.accessLevel;
                    break;
                }
                break;
            case "UNKNOWN_ACCESS_LEVEL":
            case 0:
                message.accessLevel = 0;
                break;
            case "CONTROL":
            case 1:
                message.accessLevel = 1;
                break;
            case "VIEW_ALL":
            case 2:
                message.accessLevel = 2;
                break;
            case "VIEW_PROTECTED":
            case 3:
                message.accessLevel = 3;
                break;
            case "VIEW_PUBLIC":
            case 4:
                message.accessLevel = 4;
                break;
            }
            if (object.zones) {
                if (!Array.isArray(object.zones))
                    throw TypeError(".maps.Map.zones: array expected");
                message.zones = [];
                for (let i = 0; i < object.zones.length; ++i) {
                    if (typeof object.zones[i] !== "object")
                        throw TypeError(".maps.Map.zones: object expected");
                    message.zones[i] = $root.maps.MapZone.fromObject(object.zones[i]);
                }
            }
            if (object.permissions != null) {
                if (typeof object.permissions !== "object")
                    throw TypeError(".maps.Map.permissions: object expected");
                message.permissions = $root.maps.MapPermissions.fromObject(object.permissions);
            }
            return message;
        };

        /**
         * Creates a plain object from a Map message. Also converts values to other types if specified.
         * @function toObject
         * @memberof maps.Map
         * @static
         * @param {maps.Map} message Map
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Map.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.accessGrants = [];
                object.zones = [];
            }
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.campaignId = "";
                object.publicName = "";
                object["public"] = false;
                object.sourceTemplateId = "";
                object.modifiedFromSourceTemplate = false;
                object.accessLevel = options.enums === String ? "UNKNOWN_ACCESS_LEVEL" : 0;
                object.permissions = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                object.campaignId = message.campaignId;
            if (message.publicName != null && message.hasOwnProperty("publicName"))
                object.publicName = message.publicName;
            if (message["public"] != null && message.hasOwnProperty("public"))
                object["public"] = message["public"];
            if (message.sourceTemplateId != null && message.hasOwnProperty("sourceTemplateId"))
                object.sourceTemplateId = message.sourceTemplateId;
            if (message.modifiedFromSourceTemplate != null && message.hasOwnProperty("modifiedFromSourceTemplate"))
                object.modifiedFromSourceTemplate = message.modifiedFromSourceTemplate;
            if (message.accessGrants && message.accessGrants.length) {
                object.accessGrants = [];
                for (let j = 0; j < message.accessGrants.length; ++j)
                    object.accessGrants[j] = $root.common.AccessGrant.toObject(message.accessGrants[j], options);
            }
            if (message.accessLevel != null && message.hasOwnProperty("accessLevel"))
                object.accessLevel = options.enums === String ? $root.common.AccessLevel[message.accessLevel] === undefined ? message.accessLevel : $root.common.AccessLevel[message.accessLevel] : message.accessLevel;
            if (message.zones && message.zones.length) {
                object.zones = [];
                for (let j = 0; j < message.zones.length; ++j)
                    object.zones[j] = $root.maps.MapZone.toObject(message.zones[j], options);
            }
            if (message.permissions != null && message.hasOwnProperty("permissions"))
                object.permissions = $root.maps.MapPermissions.toObject(message.permissions, options);
            return object;
        };

        /**
         * Converts this Map to JSON.
         * @function toJSON
         * @memberof maps.Map
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Map.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Map
         * @function getTypeUrl
         * @memberof maps.Map
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Map.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/maps.Map";
        };

        return Map;
    })();

    return maps;
})();

export const users = $root.users = (() => {

    /**
     * Namespace users.
     * @exports users
     * @namespace
     */
    const users = {};

    users.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof users
         * @interface ILoginRequest
         * @property {string|null} [emailAddress] LoginRequest emailAddress
         * @property {string|null} [password] LoginRequest password
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof users
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {users.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest emailAddress.
         * @member {string} emailAddress
         * @memberof users.LoginRequest
         * @instance
         */
        LoginRequest.prototype.emailAddress = "";

        /**
         * LoginRequest password.
         * @member {string} password
         * @memberof users.LoginRequest
         * @instance
         */
        LoginRequest.prototype.password = "";

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof users.LoginRequest
         * @static
         * @param {users.ILoginRequest=} [properties] Properties to set
         * @returns {users.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link users.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof users.LoginRequest
         * @static
         * @param {users.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailAddress);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link users.LoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.LoginRequest
         * @static
         * @param {users.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof users.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.LoginRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.emailAddress = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginRequest message.
         * @function verify
         * @memberof users.LoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.LoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.users.LoginRequest)
                return object;
            let message = new $root.users.LoginRequest();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.LoginRequest
         * @static
         * @param {users.LoginRequest} message LoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.emailAddress = "";
                object.password = "";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof users.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginRequest
         * @function getTypeUrl
         * @memberof users.LoginRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.LoginRequest";
        };

        return LoginRequest;
    })();

    users.ChangeEmailAddressRequest = (function() {

        /**
         * Properties of a ChangeEmailAddressRequest.
         * @memberof users
         * @interface IChangeEmailAddressRequest
         * @property {string|null} [userId] ChangeEmailAddressRequest userId
         * @property {string|null} [emailAddress] ChangeEmailAddressRequest emailAddress
         * @property {boolean|null} [updateUsernameToMatch] ChangeEmailAddressRequest updateUsernameToMatch
         */

        /**
         * Constructs a new ChangeEmailAddressRequest.
         * @memberof users
         * @classdesc Represents a ChangeEmailAddressRequest.
         * @implements IChangeEmailAddressRequest
         * @constructor
         * @param {users.IChangeEmailAddressRequest=} [properties] Properties to set
         */
        function ChangeEmailAddressRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeEmailAddressRequest userId.
         * @member {string} userId
         * @memberof users.ChangeEmailAddressRequest
         * @instance
         */
        ChangeEmailAddressRequest.prototype.userId = "";

        /**
         * ChangeEmailAddressRequest emailAddress.
         * @member {string} emailAddress
         * @memberof users.ChangeEmailAddressRequest
         * @instance
         */
        ChangeEmailAddressRequest.prototype.emailAddress = "";

        /**
         * ChangeEmailAddressRequest updateUsernameToMatch.
         * @member {boolean} updateUsernameToMatch
         * @memberof users.ChangeEmailAddressRequest
         * @instance
         */
        ChangeEmailAddressRequest.prototype.updateUsernameToMatch = false;

        /**
         * Creates a new ChangeEmailAddressRequest instance using the specified properties.
         * @function create
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {users.IChangeEmailAddressRequest=} [properties] Properties to set
         * @returns {users.ChangeEmailAddressRequest} ChangeEmailAddressRequest instance
         */
        ChangeEmailAddressRequest.create = function create(properties) {
            return new ChangeEmailAddressRequest(properties);
        };

        /**
         * Encodes the specified ChangeEmailAddressRequest message. Does not implicitly {@link users.ChangeEmailAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {users.IChangeEmailAddressRequest} message ChangeEmailAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.emailAddress);
            if (message.updateUsernameToMatch != null && Object.hasOwnProperty.call(message, "updateUsernameToMatch"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.updateUsernameToMatch);
            return writer;
        };

        /**
         * Encodes the specified ChangeEmailAddressRequest message, length delimited. Does not implicitly {@link users.ChangeEmailAddressRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {users.IChangeEmailAddressRequest} message ChangeEmailAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeEmailAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangeEmailAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.ChangeEmailAddressRequest} ChangeEmailAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.ChangeEmailAddressRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.userId = reader.string();
                        break;
                    }
                case 16: {
                        message.emailAddress = reader.string();
                        break;
                    }
                case 17: {
                        message.updateUsernameToMatch = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangeEmailAddressRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.ChangeEmailAddressRequest} ChangeEmailAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeEmailAddressRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangeEmailAddressRequest message.
         * @function verify
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangeEmailAddressRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.updateUsernameToMatch != null && message.hasOwnProperty("updateUsernameToMatch"))
                if (typeof message.updateUsernameToMatch !== "boolean")
                    return "updateUsernameToMatch: boolean expected";
            return null;
        };

        /**
         * Creates a ChangeEmailAddressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.ChangeEmailAddressRequest} ChangeEmailAddressRequest
         */
        ChangeEmailAddressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.users.ChangeEmailAddressRequest)
                return object;
            let message = new $root.users.ChangeEmailAddressRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.updateUsernameToMatch != null)
                message.updateUsernameToMatch = Boolean(object.updateUsernameToMatch);
            return message;
        };

        /**
         * Creates a plain object from a ChangeEmailAddressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {users.ChangeEmailAddressRequest} message ChangeEmailAddressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangeEmailAddressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.emailAddress = "";
                object.updateUsernameToMatch = false;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.updateUsernameToMatch != null && message.hasOwnProperty("updateUsernameToMatch"))
                object.updateUsernameToMatch = message.updateUsernameToMatch;
            return object;
        };

        /**
         * Converts this ChangeEmailAddressRequest to JSON.
         * @function toJSON
         * @memberof users.ChangeEmailAddressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangeEmailAddressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ChangeEmailAddressRequest
         * @function getTypeUrl
         * @memberof users.ChangeEmailAddressRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeEmailAddressRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.ChangeEmailAddressRequest";
        };

        return ChangeEmailAddressRequest;
    })();

    users.UpdateUserProfileRequest = (function() {

        /**
         * Properties of an UpdateUserProfileRequest.
         * @memberof users
         * @interface IUpdateUserProfileRequest
         * @property {users.IUser|null} [user] UpdateUserProfileRequest user
         */

        /**
         * Constructs a new UpdateUserProfileRequest.
         * @memberof users
         * @classdesc Represents an UpdateUserProfileRequest.
         * @implements IUpdateUserProfileRequest
         * @constructor
         * @param {users.IUpdateUserProfileRequest=} [properties] Properties to set
         */
        function UpdateUserProfileRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserProfileRequest user.
         * @member {users.IUser|null|undefined} user
         * @memberof users.UpdateUserProfileRequest
         * @instance
         */
        UpdateUserProfileRequest.prototype.user = null;

        /**
         * Creates a new UpdateUserProfileRequest instance using the specified properties.
         * @function create
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {users.IUpdateUserProfileRequest=} [properties] Properties to set
         * @returns {users.UpdateUserProfileRequest} UpdateUserProfileRequest instance
         */
        UpdateUserProfileRequest.create = function create(properties) {
            return new UpdateUserProfileRequest(properties);
        };

        /**
         * Encodes the specified UpdateUserProfileRequest message. Does not implicitly {@link users.UpdateUserProfileRequest.verify|verify} messages.
         * @function encode
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {users.IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserProfileRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.users.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateUserProfileRequest message, length delimited. Does not implicitly {@link users.UpdateUserProfileRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {users.IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateUserProfileRequest message from the specified reader or buffer.
         * @function decode
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.UpdateUserProfileRequest} UpdateUserProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserProfileRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.UpdateUserProfileRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.user = $root.users.User.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateUserProfileRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.UpdateUserProfileRequest} UpdateUserProfileRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateUserProfileRequest message.
         * @function verify
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateUserProfileRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.users.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateUserProfileRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.UpdateUserProfileRequest} UpdateUserProfileRequest
         */
        UpdateUserProfileRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.users.UpdateUserProfileRequest)
                return object;
            let message = new $root.users.UpdateUserProfileRequest();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".users.UpdateUserProfileRequest.user: object expected");
                message.user = $root.users.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateUserProfileRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {users.UpdateUserProfileRequest} message UpdateUserProfileRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserProfileRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.users.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this UpdateUserProfileRequest to JSON.
         * @function toJSON
         * @memberof users.UpdateUserProfileRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserProfileRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpdateUserProfileRequest
         * @function getTypeUrl
         * @memberof users.UpdateUserProfileRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.UpdateUserProfileRequest";
        };

        return UpdateUserProfileRequest;
    })();

    users.LogoutRequest = (function() {

        /**
         * Properties of a LogoutRequest.
         * @memberof users
         * @interface ILogoutRequest
         */

        /**
         * Constructs a new LogoutRequest.
         * @memberof users
         * @classdesc Represents a LogoutRequest.
         * @implements ILogoutRequest
         * @constructor
         * @param {users.ILogoutRequest=} [properties] Properties to set
         */
        function LogoutRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LogoutRequest instance using the specified properties.
         * @function create
         * @memberof users.LogoutRequest
         * @static
         * @param {users.ILogoutRequest=} [properties] Properties to set
         * @returns {users.LogoutRequest} LogoutRequest instance
         */
        LogoutRequest.create = function create(properties) {
            return new LogoutRequest(properties);
        };

        /**
         * Encodes the specified LogoutRequest message. Does not implicitly {@link users.LogoutRequest.verify|verify} messages.
         * @function encode
         * @memberof users.LogoutRequest
         * @static
         * @param {users.ILogoutRequest} message LogoutRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LogoutRequest message, length delimited. Does not implicitly {@link users.LogoutRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.LogoutRequest
         * @static
         * @param {users.ILogoutRequest} message LogoutRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LogoutRequest message from the specified reader or buffer.
         * @function decode
         * @memberof users.LogoutRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.LogoutRequest} LogoutRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.LogoutRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LogoutRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.LogoutRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.LogoutRequest} LogoutRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LogoutRequest message.
         * @function verify
         * @memberof users.LogoutRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LogoutRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LogoutRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.LogoutRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.LogoutRequest} LogoutRequest
         */
        LogoutRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.users.LogoutRequest)
                return object;
            return new $root.users.LogoutRequest();
        };

        /**
         * Creates a plain object from a LogoutRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.LogoutRequest
         * @static
         * @param {users.LogoutRequest} message LogoutRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LogoutRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LogoutRequest to JSON.
         * @function toJSON
         * @memberof users.LogoutRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LogoutRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LogoutRequest
         * @function getTypeUrl
         * @memberof users.LogoutRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LogoutRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.LogoutRequest";
        };

        return LogoutRequest;
    })();

    users.LogoutResponse = (function() {

        /**
         * Properties of a LogoutResponse.
         * @memberof users
         * @interface ILogoutResponse
         */

        /**
         * Constructs a new LogoutResponse.
         * @memberof users
         * @classdesc Represents a LogoutResponse.
         * @implements ILogoutResponse
         * @constructor
         * @param {users.ILogoutResponse=} [properties] Properties to set
         */
        function LogoutResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LogoutResponse instance using the specified properties.
         * @function create
         * @memberof users.LogoutResponse
         * @static
         * @param {users.ILogoutResponse=} [properties] Properties to set
         * @returns {users.LogoutResponse} LogoutResponse instance
         */
        LogoutResponse.create = function create(properties) {
            return new LogoutResponse(properties);
        };

        /**
         * Encodes the specified LogoutResponse message. Does not implicitly {@link users.LogoutResponse.verify|verify} messages.
         * @function encode
         * @memberof users.LogoutResponse
         * @static
         * @param {users.ILogoutResponse} message LogoutResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LogoutResponse message, length delimited. Does not implicitly {@link users.LogoutResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.LogoutResponse
         * @static
         * @param {users.ILogoutResponse} message LogoutResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LogoutResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LogoutResponse message from the specified reader or buffer.
         * @function decode
         * @memberof users.LogoutResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.LogoutResponse} LogoutResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.LogoutResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LogoutResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.LogoutResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.LogoutResponse} LogoutResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LogoutResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LogoutResponse message.
         * @function verify
         * @memberof users.LogoutResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LogoutResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LogoutResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.LogoutResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.LogoutResponse} LogoutResponse
         */
        LogoutResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.users.LogoutResponse)
                return object;
            return new $root.users.LogoutResponse();
        };

        /**
         * Creates a plain object from a LogoutResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.LogoutResponse
         * @static
         * @param {users.LogoutResponse} message LogoutResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LogoutResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LogoutResponse to JSON.
         * @function toJSON
         * @memberof users.LogoutResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LogoutResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LogoutResponse
         * @function getTypeUrl
         * @memberof users.LogoutResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LogoutResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.LogoutResponse";
        };

        return LogoutResponse;
    })();

    /**
     * UserType enum.
     * @name users.UserType
     * @enum {number}
     * @property {number} UNKNOWN_USER_TYPE=0 UNKNOWN_USER_TYPE value
     * @property {number} NORMAL=1 NORMAL value
     * @property {number} PLAYER=2 PLAYER value
     */
    users.UserType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_USER_TYPE"] = 0;
        values[valuesById[1] = "NORMAL"] = 1;
        values[valuesById[2] = "PLAYER"] = 2;
        return values;
    })();

    /**
     * InactivityTimeout enum.
     * @name users.InactivityTimeout
     * @enum {number}
     * @property {number} UNKNOWN_INACTIVITY_TIMEOUT=0 UNKNOWN_INACTIVITY_TIMEOUT value
     * @property {number} INACTIVITY_TIMEOUT_NEVER=1 INACTIVITY_TIMEOUT_NEVER value
     * @property {number} INACTIVITY_TIMEOUT_20_MIN=2 INACTIVITY_TIMEOUT_20_MIN value
     * @property {number} INACTIVITY_TIMEOUT_1_HR=3 INACTIVITY_TIMEOUT_1_HR value
     * @property {number} INACTIVITY_TIMEOUT_8_HR=4 INACTIVITY_TIMEOUT_8_HR value
     * @property {number} INACTIVITY_TIMEOUT_24_HR=5 INACTIVITY_TIMEOUT_24_HR value
     * @property {number} INACTIVITY_TIMEOUT_1_WK=6 INACTIVITY_TIMEOUT_1_WK value
     */
    users.InactivityTimeout = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_INACTIVITY_TIMEOUT"] = 0;
        values[valuesById[1] = "INACTIVITY_TIMEOUT_NEVER"] = 1;
        values[valuesById[2] = "INACTIVITY_TIMEOUT_20_MIN"] = 2;
        values[valuesById[3] = "INACTIVITY_TIMEOUT_1_HR"] = 3;
        values[valuesById[4] = "INACTIVITY_TIMEOUT_8_HR"] = 4;
        values[valuesById[5] = "INACTIVITY_TIMEOUT_24_HR"] = 5;
        values[valuesById[6] = "INACTIVITY_TIMEOUT_1_WK"] = 6;
        return values;
    })();

    /**
     * PronounSet enum.
     * @name users.PronounSet
     * @enum {number}
     * @property {number} UNKNOWN_PRONOUNS=0 UNKNOWN_PRONOUNS value
     * @property {number} THEY_THEM_PRONOUNS=1 THEY_THEM_PRONOUNS value
     * @property {number} SHE_HER_PRONOUNS=2 SHE_HER_PRONOUNS value
     * @property {number} HE_HIM_PRONOUNS=3 HE_HIM_PRONOUNS value
     */
    users.PronounSet = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_PRONOUNS"] = 0;
        values[valuesById[1] = "THEY_THEM_PRONOUNS"] = 1;
        values[valuesById[2] = "SHE_HER_PRONOUNS"] = 2;
        values[valuesById[3] = "HE_HIM_PRONOUNS"] = 3;
        return values;
    })();

    users.User = (function() {

        /**
         * Properties of a User.
         * @memberof users
         * @interface IUser
         * @property {string|null} [id] User id
         * @property {string|null} [name] User name
         * @property {string|null} [emailAddress] User emailAddress
         * @property {string|null} [username] User username
         * @property {google.protobuf.IStringValue|null} [password] User password
         * @property {users.UserType|null} [userType] User userType
         * @property {google.protobuf.IStringValue|null} [registrationCode] User registrationCode
         * @property {users.IUserSettings|null} [settings] User settings
         * @property {users.IEmailConfirmationStatus|null} [emailConfirmationStatus] User emailConfirmationStatus
         */

        /**
         * Constructs a new User.
         * @memberof users
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {users.IUser=} [properties] Properties to set
         */
        function User(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User id.
         * @member {string} id
         * @memberof users.User
         * @instance
         */
        User.prototype.id = "";

        /**
         * User name.
         * @member {string} name
         * @memberof users.User
         * @instance
         */
        User.prototype.name = "";

        /**
         * User emailAddress.
         * @member {string} emailAddress
         * @memberof users.User
         * @instance
         */
        User.prototype.emailAddress = "";

        /**
         * User username.
         * @member {string} username
         * @memberof users.User
         * @instance
         */
        User.prototype.username = "";

        /**
         * User password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof users.User
         * @instance
         */
        User.prototype.password = null;

        /**
         * User userType.
         * @member {users.UserType} userType
         * @memberof users.User
         * @instance
         */
        User.prototype.userType = 0;

        /**
         * User registrationCode.
         * @member {google.protobuf.IStringValue|null|undefined} registrationCode
         * @memberof users.User
         * @instance
         */
        User.prototype.registrationCode = null;

        /**
         * User settings.
         * @member {users.IUserSettings|null|undefined} settings
         * @memberof users.User
         * @instance
         */
        User.prototype.settings = null;

        /**
         * User emailConfirmationStatus.
         * @member {users.IEmailConfirmationStatus|null|undefined} emailConfirmationStatus
         * @memberof users.User
         * @instance
         */
        User.prototype.emailConfirmationStatus = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * User optionalSettings.
         * @member {"settings"|undefined} optionalSettings
         * @memberof users.User
         * @instance
         */
        Object.defineProperty(User.prototype, "optionalSettings", {
            get: $util.oneOfGetter($oneOfFields = ["settings"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * User optionalEmailConfirmationStatus.
         * @member {"emailConfirmationStatus"|undefined} optionalEmailConfirmationStatus
         * @memberof users.User
         * @instance
         */
        Object.defineProperty(User.prototype, "optionalEmailConfirmationStatus", {
            get: $util.oneOfGetter($oneOfFields = ["emailConfirmationStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof users.User
         * @static
         * @param {users.IUser=} [properties] Properties to set
         * @returns {users.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link users.User.verify|verify} messages.
         * @function encode
         * @memberof users.User
         * @static
         * @param {users.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.emailAddress);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                $root.google.protobuf.StringValue.encode(message.password, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.userType);
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.username);
            if (message.registrationCode != null && Object.hasOwnProperty.call(message, "registrationCode"))
                $root.google.protobuf.StringValue.encode(message.registrationCode, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                $root.users.UserSettings.encode(message.settings, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.emailConfirmationStatus != null && Object.hasOwnProperty.call(message, "emailConfirmationStatus"))
                $root.users.EmailConfirmationStatus.encode(message.emailConfirmationStatus, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified User message, length delimited. Does not implicitly {@link users.User.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.User
         * @static
         * @param {users.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof users.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.User();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 16: {
                        message.emailAddress = reader.string();
                        break;
                    }
                case 19: {
                        message.username = reader.string();
                        break;
                    }
                case 17: {
                        message.password = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    }
                case 18: {
                        message.userType = reader.int32();
                        break;
                    }
                case 20: {
                        message.registrationCode = $root.google.protobuf.StringValue.decode(reader, reader.uint32());
                        break;
                    }
                case 21: {
                        message.settings = $root.users.UserSettings.decode(reader, reader.uint32());
                        break;
                    }
                case 22: {
                        message.emailConfirmationStatus = $root.users.EmailConfirmationStatus.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a User message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a User message.
         * @function verify
         * @memberof users.User
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        User.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password")) {
                let error = $root.google.protobuf.StringValue.verify(message.password);
                if (error)
                    return "password." + error;
            }
            if (message.userType != null && message.hasOwnProperty("userType"))
                switch (message.userType) {
                default:
                    return "userType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.registrationCode != null && message.hasOwnProperty("registrationCode")) {
                let error = $root.google.protobuf.StringValue.verify(message.registrationCode);
                if (error)
                    return "registrationCode." + error;
            }
            if (message.settings != null && message.hasOwnProperty("settings")) {
                properties.optionalSettings = 1;
                {
                    let error = $root.users.UserSettings.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
            }
            if (message.emailConfirmationStatus != null && message.hasOwnProperty("emailConfirmationStatus")) {
                properties.optionalEmailConfirmationStatus = 1;
                {
                    let error = $root.users.EmailConfirmationStatus.verify(message.emailConfirmationStatus);
                    if (error)
                        return "emailConfirmationStatus." + error;
                }
            }
            return null;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.users.User)
                return object;
            let message = new $root.users.User();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null) {
                if (typeof object.password !== "object")
                    throw TypeError(".users.User.password: object expected");
                message.password = $root.google.protobuf.StringValue.fromObject(object.password);
            }
            switch (object.userType) {
            default:
                if (typeof object.userType === "number") {
                    message.userType = object.userType;
                    break;
                }
                break;
            case "UNKNOWN_USER_TYPE":
            case 0:
                message.userType = 0;
                break;
            case "NORMAL":
            case 1:
                message.userType = 1;
                break;
            case "PLAYER":
            case 2:
                message.userType = 2;
                break;
            }
            if (object.registrationCode != null) {
                if (typeof object.registrationCode !== "object")
                    throw TypeError(".users.User.registrationCode: object expected");
                message.registrationCode = $root.google.protobuf.StringValue.fromObject(object.registrationCode);
            }
            if (object.settings != null) {
                if (typeof object.settings !== "object")
                    throw TypeError(".users.User.settings: object expected");
                message.settings = $root.users.UserSettings.fromObject(object.settings);
            }
            if (object.emailConfirmationStatus != null) {
                if (typeof object.emailConfirmationStatus !== "object")
                    throw TypeError(".users.User.emailConfirmationStatus: object expected");
                message.emailConfirmationStatus = $root.users.EmailConfirmationStatus.fromObject(object.emailConfirmationStatus);
            }
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.User
         * @static
         * @param {users.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.emailAddress = "";
                object.password = null;
                object.userType = options.enums === String ? "UNKNOWN_USER_TYPE" : 0;
                object.username = "";
                object.registrationCode = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = $root.google.protobuf.StringValue.toObject(message.password, options);
            if (message.userType != null && message.hasOwnProperty("userType"))
                object.userType = options.enums === String ? $root.users.UserType[message.userType] === undefined ? message.userType : $root.users.UserType[message.userType] : message.userType;
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.registrationCode != null && message.hasOwnProperty("registrationCode"))
                object.registrationCode = $root.google.protobuf.StringValue.toObject(message.registrationCode, options);
            if (message.settings != null && message.hasOwnProperty("settings")) {
                object.settings = $root.users.UserSettings.toObject(message.settings, options);
                if (options.oneofs)
                    object.optionalSettings = "settings";
            }
            if (message.emailConfirmationStatus != null && message.hasOwnProperty("emailConfirmationStatus")) {
                object.emailConfirmationStatus = $root.users.EmailConfirmationStatus.toObject(message.emailConfirmationStatus, options);
                if (options.oneofs)
                    object.optionalEmailConfirmationStatus = "emailConfirmationStatus";
            }
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof users.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for User
         * @function getTypeUrl
         * @memberof users.User
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.User";
        };

        return User;
    })();

    users.Session = (function() {

        /**
         * Properties of a Session.
         * @memberof users
         * @interface ISession
         * @property {string|null} [id] Session id
         * @property {string|null} [secret] Session secret
         * @property {string|null} [userId] Session userId
         * @property {users.IUser|null} [user] Session user
         * @property {google.protobuf.ITimestamp|null} [expiresAt] Session expiresAt
         * @property {Object.<string,string>|null} [extensionData] Session extensionData
         */

        /**
         * Constructs a new Session.
         * @memberof users
         * @classdesc Represents a Session.
         * @implements ISession
         * @constructor
         * @param {users.ISession=} [properties] Properties to set
         */
        function Session(properties) {
            this.extensionData = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Session id.
         * @member {string} id
         * @memberof users.Session
         * @instance
         */
        Session.prototype.id = "";

        /**
         * Session secret.
         * @member {string} secret
         * @memberof users.Session
         * @instance
         */
        Session.prototype.secret = "";

        /**
         * Session userId.
         * @member {string} userId
         * @memberof users.Session
         * @instance
         */
        Session.prototype.userId = "";

        /**
         * Session user.
         * @member {users.IUser|null|undefined} user
         * @memberof users.Session
         * @instance
         */
        Session.prototype.user = null;

        /**
         * Session expiresAt.
         * @member {google.protobuf.ITimestamp|null|undefined} expiresAt
         * @memberof users.Session
         * @instance
         */
        Session.prototype.expiresAt = null;

        /**
         * Session extensionData.
         * @member {Object.<string,string>} extensionData
         * @memberof users.Session
         * @instance
         */
        Session.prototype.extensionData = $util.emptyObject;

        /**
         * Creates a new Session instance using the specified properties.
         * @function create
         * @memberof users.Session
         * @static
         * @param {users.ISession=} [properties] Properties to set
         * @returns {users.Session} Session instance
         */
        Session.create = function create(properties) {
            return new Session(properties);
        };

        /**
         * Encodes the specified Session message. Does not implicitly {@link users.Session.verify|verify} messages.
         * @function encode
         * @memberof users.Session
         * @static
         * @param {users.ISession} message Session message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Session.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.secret);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.userId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.users.User.encode(message.user, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.expiresAt != null && Object.hasOwnProperty.call(message, "expiresAt"))
                $root.google.protobuf.Timestamp.encode(message.expiresAt, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.extensionData != null && Object.hasOwnProperty.call(message, "extensionData"))
                for (let keys = Object.keys(message.extensionData), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 20, wireType 2 =*/162).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.extensionData[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Session message, length delimited. Does not implicitly {@link users.Session.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.Session
         * @static
         * @param {users.ISession} message Session message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Session.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Session message from the specified reader or buffer.
         * @function decode
         * @memberof users.Session
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.Session} Session
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Session.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.Session(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 16: {
                        message.secret = reader.string();
                        break;
                    }
                case 17: {
                        message.userId = reader.string();
                        break;
                    }
                case 18: {
                        message.user = $root.users.User.decode(reader, reader.uint32());
                        break;
                    }
                case 19: {
                        message.expiresAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                        break;
                    }
                case 20: {
                        if (message.extensionData === $util.emptyObject)
                            message.extensionData = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.extensionData[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Session message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.Session
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.Session} Session
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Session.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Session message.
         * @function verify
         * @memberof users.Session
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Session.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.secret != null && message.hasOwnProperty("secret"))
                if (!$util.isString(message.secret))
                    return "secret: string expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.users.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt")) {
                let error = $root.google.protobuf.Timestamp.verify(message.expiresAt);
                if (error)
                    return "expiresAt." + error;
            }
            if (message.extensionData != null && message.hasOwnProperty("extensionData")) {
                if (!$util.isObject(message.extensionData))
                    return "extensionData: object expected";
                let key = Object.keys(message.extensionData);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.extensionData[key[i]]))
                        return "extensionData: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a Session message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.Session
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.Session} Session
         */
        Session.fromObject = function fromObject(object) {
            if (object instanceof $root.users.Session)
                return object;
            let message = new $root.users.Session();
            if (object.id != null)
                message.id = String(object.id);
            if (object.secret != null)
                message.secret = String(object.secret);
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".users.Session.user: object expected");
                message.user = $root.users.User.fromObject(object.user);
            }
            if (object.expiresAt != null) {
                if (typeof object.expiresAt !== "object")
                    throw TypeError(".users.Session.expiresAt: object expected");
                message.expiresAt = $root.google.protobuf.Timestamp.fromObject(object.expiresAt);
            }
            if (object.extensionData) {
                if (typeof object.extensionData !== "object")
                    throw TypeError(".users.Session.extensionData: object expected");
                message.extensionData = {};
                for (let keys = Object.keys(object.extensionData), i = 0; i < keys.length; ++i)
                    message.extensionData[keys[i]] = String(object.extensionData[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Session message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.Session
         * @static
         * @param {users.Session} message Session
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Session.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.extensionData = {};
            if (options.defaults) {
                object.id = "";
                object.secret = "";
                object.userId = "";
                object.user = null;
                object.expiresAt = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.secret != null && message.hasOwnProperty("secret"))
                object.secret = message.secret;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.users.User.toObject(message.user, options);
            if (message.expiresAt != null && message.hasOwnProperty("expiresAt"))
                object.expiresAt = $root.google.protobuf.Timestamp.toObject(message.expiresAt, options);
            let keys2;
            if (message.extensionData && (keys2 = Object.keys(message.extensionData)).length) {
                object.extensionData = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.extensionData[keys2[j]] = message.extensionData[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this Session to JSON.
         * @function toJSON
         * @memberof users.Session
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Session.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Session
         * @function getTypeUrl
         * @memberof users.Session
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Session.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.Session";
        };

        return Session;
    })();

    users.UserSettings = (function() {

        /**
         * Properties of a UserSettings.
         * @memberof users
         * @interface IUserSettings
         * @property {users.InactivityTimeout|null} [inactivityTimeout] UserSettings inactivityTimeout
         * @property {users.PronounSet|null} [pronouns] UserSettings pronouns
         */

        /**
         * Constructs a new UserSettings.
         * @memberof users
         * @classdesc Represents a UserSettings.
         * @implements IUserSettings
         * @constructor
         * @param {users.IUserSettings=} [properties] Properties to set
         */
        function UserSettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserSettings inactivityTimeout.
         * @member {users.InactivityTimeout} inactivityTimeout
         * @memberof users.UserSettings
         * @instance
         */
        UserSettings.prototype.inactivityTimeout = 0;

        /**
         * UserSettings pronouns.
         * @member {users.PronounSet} pronouns
         * @memberof users.UserSettings
         * @instance
         */
        UserSettings.prototype.pronouns = 0;

        /**
         * Creates a new UserSettings instance using the specified properties.
         * @function create
         * @memberof users.UserSettings
         * @static
         * @param {users.IUserSettings=} [properties] Properties to set
         * @returns {users.UserSettings} UserSettings instance
         */
        UserSettings.create = function create(properties) {
            return new UserSettings(properties);
        };

        /**
         * Encodes the specified UserSettings message. Does not implicitly {@link users.UserSettings.verify|verify} messages.
         * @function encode
         * @memberof users.UserSettings
         * @static
         * @param {users.IUserSettings} message UserSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSettings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inactivityTimeout != null && Object.hasOwnProperty.call(message, "inactivityTimeout"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.inactivityTimeout);
            if (message.pronouns != null && Object.hasOwnProperty.call(message, "pronouns"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pronouns);
            return writer;
        };

        /**
         * Encodes the specified UserSettings message, length delimited. Does not implicitly {@link users.UserSettings.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.UserSettings
         * @static
         * @param {users.IUserSettings} message UserSettings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserSettings.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserSettings message from the specified reader or buffer.
         * @function decode
         * @memberof users.UserSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.UserSettings} UserSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSettings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.UserSettings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.inactivityTimeout = reader.int32();
                        break;
                    }
                case 2: {
                        message.pronouns = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserSettings message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.UserSettings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.UserSettings} UserSettings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserSettings.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserSettings message.
         * @function verify
         * @memberof users.UserSettings
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserSettings.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inactivityTimeout != null && message.hasOwnProperty("inactivityTimeout"))
                switch (message.inactivityTimeout) {
                default:
                    return "inactivityTimeout: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.pronouns != null && message.hasOwnProperty("pronouns"))
                switch (message.pronouns) {
                default:
                    return "pronouns: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a UserSettings message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.UserSettings
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.UserSettings} UserSettings
         */
        UserSettings.fromObject = function fromObject(object) {
            if (object instanceof $root.users.UserSettings)
                return object;
            let message = new $root.users.UserSettings();
            switch (object.inactivityTimeout) {
            default:
                if (typeof object.inactivityTimeout === "number") {
                    message.inactivityTimeout = object.inactivityTimeout;
                    break;
                }
                break;
            case "UNKNOWN_INACTIVITY_TIMEOUT":
            case 0:
                message.inactivityTimeout = 0;
                break;
            case "INACTIVITY_TIMEOUT_NEVER":
            case 1:
                message.inactivityTimeout = 1;
                break;
            case "INACTIVITY_TIMEOUT_20_MIN":
            case 2:
                message.inactivityTimeout = 2;
                break;
            case "INACTIVITY_TIMEOUT_1_HR":
            case 3:
                message.inactivityTimeout = 3;
                break;
            case "INACTIVITY_TIMEOUT_8_HR":
            case 4:
                message.inactivityTimeout = 4;
                break;
            case "INACTIVITY_TIMEOUT_24_HR":
            case 5:
                message.inactivityTimeout = 5;
                break;
            case "INACTIVITY_TIMEOUT_1_WK":
            case 6:
                message.inactivityTimeout = 6;
                break;
            }
            switch (object.pronouns) {
            default:
                if (typeof object.pronouns === "number") {
                    message.pronouns = object.pronouns;
                    break;
                }
                break;
            case "UNKNOWN_PRONOUNS":
            case 0:
                message.pronouns = 0;
                break;
            case "THEY_THEM_PRONOUNS":
            case 1:
                message.pronouns = 1;
                break;
            case "SHE_HER_PRONOUNS":
            case 2:
                message.pronouns = 2;
                break;
            case "HE_HIM_PRONOUNS":
            case 3:
                message.pronouns = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a UserSettings message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.UserSettings
         * @static
         * @param {users.UserSettings} message UserSettings
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserSettings.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.inactivityTimeout = options.enums === String ? "UNKNOWN_INACTIVITY_TIMEOUT" : 0;
                object.pronouns = options.enums === String ? "UNKNOWN_PRONOUNS" : 0;
            }
            if (message.inactivityTimeout != null && message.hasOwnProperty("inactivityTimeout"))
                object.inactivityTimeout = options.enums === String ? $root.users.InactivityTimeout[message.inactivityTimeout] === undefined ? message.inactivityTimeout : $root.users.InactivityTimeout[message.inactivityTimeout] : message.inactivityTimeout;
            if (message.pronouns != null && message.hasOwnProperty("pronouns"))
                object.pronouns = options.enums === String ? $root.users.PronounSet[message.pronouns] === undefined ? message.pronouns : $root.users.PronounSet[message.pronouns] : message.pronouns;
            return object;
        };

        /**
         * Converts this UserSettings to JSON.
         * @function toJSON
         * @memberof users.UserSettings
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserSettings.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserSettings
         * @function getTypeUrl
         * @memberof users.UserSettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.UserSettings";
        };

        return UserSettings;
    })();

    users.EmailConfirmationStatus = (function() {

        /**
         * Properties of an EmailConfirmationStatus.
         * @memberof users
         * @interface IEmailConfirmationStatus
         * @property {boolean|null} [isConfirmed] EmailConfirmationStatus isConfirmed
         */

        /**
         * Constructs a new EmailConfirmationStatus.
         * @memberof users
         * @classdesc Represents an EmailConfirmationStatus.
         * @implements IEmailConfirmationStatus
         * @constructor
         * @param {users.IEmailConfirmationStatus=} [properties] Properties to set
         */
        function EmailConfirmationStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailConfirmationStatus isConfirmed.
         * @member {boolean} isConfirmed
         * @memberof users.EmailConfirmationStatus
         * @instance
         */
        EmailConfirmationStatus.prototype.isConfirmed = false;

        /**
         * Creates a new EmailConfirmationStatus instance using the specified properties.
         * @function create
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {users.IEmailConfirmationStatus=} [properties] Properties to set
         * @returns {users.EmailConfirmationStatus} EmailConfirmationStatus instance
         */
        EmailConfirmationStatus.create = function create(properties) {
            return new EmailConfirmationStatus(properties);
        };

        /**
         * Encodes the specified EmailConfirmationStatus message. Does not implicitly {@link users.EmailConfirmationStatus.verify|verify} messages.
         * @function encode
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {users.IEmailConfirmationStatus} message EmailConfirmationStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailConfirmationStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.isConfirmed != null && Object.hasOwnProperty.call(message, "isConfirmed"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isConfirmed);
            return writer;
        };

        /**
         * Encodes the specified EmailConfirmationStatus message, length delimited. Does not implicitly {@link users.EmailConfirmationStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {users.IEmailConfirmationStatus} message EmailConfirmationStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailConfirmationStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailConfirmationStatus message from the specified reader or buffer.
         * @function decode
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.EmailConfirmationStatus} EmailConfirmationStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailConfirmationStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.EmailConfirmationStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.isConfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailConfirmationStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.EmailConfirmationStatus} EmailConfirmationStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailConfirmationStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailConfirmationStatus message.
         * @function verify
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailConfirmationStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.isConfirmed != null && message.hasOwnProperty("isConfirmed"))
                if (typeof message.isConfirmed !== "boolean")
                    return "isConfirmed: boolean expected";
            return null;
        };

        /**
         * Creates an EmailConfirmationStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.EmailConfirmationStatus} EmailConfirmationStatus
         */
        EmailConfirmationStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.users.EmailConfirmationStatus)
                return object;
            let message = new $root.users.EmailConfirmationStatus();
            if (object.isConfirmed != null)
                message.isConfirmed = Boolean(object.isConfirmed);
            return message;
        };

        /**
         * Creates a plain object from an EmailConfirmationStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {users.EmailConfirmationStatus} message EmailConfirmationStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailConfirmationStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.isConfirmed = false;
            if (message.isConfirmed != null && message.hasOwnProperty("isConfirmed"))
                object.isConfirmed = message.isConfirmed;
            return object;
        };

        /**
         * Converts this EmailConfirmationStatus to JSON.
         * @function toJSON
         * @memberof users.EmailConfirmationStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailConfirmationStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for EmailConfirmationStatus
         * @function getTypeUrl
         * @memberof users.EmailConfirmationStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailConfirmationStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.EmailConfirmationStatus";
        };

        return EmailConfirmationStatus;
    })();

    users.Authentication = (function() {

        /**
         * Properties of an Authentication.
         * @memberof users
         * @interface IAuthentication
         */

        /**
         * Constructs a new Authentication.
         * @memberof users
         * @classdesc Represents an Authentication.
         * @implements IAuthentication
         * @constructor
         * @param {users.IAuthentication=} [properties] Properties to set
         */
        function Authentication(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Authentication instance using the specified properties.
         * @function create
         * @memberof users.Authentication
         * @static
         * @param {users.IAuthentication=} [properties] Properties to set
         * @returns {users.Authentication} Authentication instance
         */
        Authentication.create = function create(properties) {
            return new Authentication(properties);
        };

        /**
         * Encodes the specified Authentication message. Does not implicitly {@link users.Authentication.verify|verify} messages.
         * @function encode
         * @memberof users.Authentication
         * @static
         * @param {users.IAuthentication} message Authentication message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authentication.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Authentication message, length delimited. Does not implicitly {@link users.Authentication.verify|verify} messages.
         * @function encodeDelimited
         * @memberof users.Authentication
         * @static
         * @param {users.IAuthentication} message Authentication message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Authentication.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Authentication message from the specified reader or buffer.
         * @function decode
         * @memberof users.Authentication
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {users.Authentication} Authentication
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authentication.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.users.Authentication();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Authentication message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof users.Authentication
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {users.Authentication} Authentication
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Authentication.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Authentication message.
         * @function verify
         * @memberof users.Authentication
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Authentication.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an Authentication message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof users.Authentication
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {users.Authentication} Authentication
         */
        Authentication.fromObject = function fromObject(object) {
            if (object instanceof $root.users.Authentication)
                return object;
            return new $root.users.Authentication();
        };

        /**
         * Creates a plain object from an Authentication message. Also converts values to other types if specified.
         * @function toObject
         * @memberof users.Authentication
         * @static
         * @param {users.Authentication} message Authentication
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Authentication.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Authentication to JSON.
         * @function toJSON
         * @memberof users.Authentication
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Authentication.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Authentication
         * @function getTypeUrl
         * @memberof users.Authentication
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Authentication.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/users.Authentication";
        };

        return Authentication;
    })();

    return users;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.DoubleValue = (function() {

            /**
             * Properties of a DoubleValue.
             * @memberof google.protobuf
             * @interface IDoubleValue
             * @property {number|null} [value] DoubleValue value
             */

            /**
             * Constructs a new DoubleValue.
             * @memberof google.protobuf
             * @classdesc Represents a DoubleValue.
             * @implements IDoubleValue
             * @constructor
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             */
            function DoubleValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DoubleValue value.
             * @member {number} value
             * @memberof google.protobuf.DoubleValue
             * @instance
             */
            DoubleValue.prototype.value = 0;

            /**
             * Creates a new DoubleValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             * @returns {google.protobuf.DoubleValue} DoubleValue instance
             */
            DoubleValue.create = function create(properties) {
                return new DoubleValue(properties);
            };

            /**
             * Encodes the specified DoubleValue message. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
                return writer;
            };

            /**
             * Encodes the specified DoubleValue message, length delimited. Does not implicitly {@link google.protobuf.DoubleValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.IDoubleValue} message DoubleValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DoubleValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DoubleValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DoubleValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.DoubleValue} DoubleValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DoubleValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DoubleValue message.
             * @function verify
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DoubleValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };

            /**
             * Creates a DoubleValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DoubleValue} DoubleValue
             */
            DoubleValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DoubleValue)
                    return object;
                let message = new $root.google.protobuf.DoubleValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a DoubleValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {google.protobuf.DoubleValue} message DoubleValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DoubleValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this DoubleValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.DoubleValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DoubleValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DoubleValue
             * @function getTypeUrl
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            /**
             * Properties of a FloatValue.
             * @memberof google.protobuf
             * @interface IFloatValue
             * @property {number|null} [value] FloatValue value
             */

            /**
             * Constructs a new FloatValue.
             * @memberof google.protobuf
             * @classdesc Represents a FloatValue.
             * @implements IFloatValue
             * @constructor
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             */
            function FloatValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FloatValue value.
             * @member {number} value
             * @memberof google.protobuf.FloatValue
             * @instance
             */
            FloatValue.prototype.value = 0;

            /**
             * Creates a new FloatValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             * @returns {google.protobuf.FloatValue} FloatValue instance
             */
            FloatValue.create = function create(properties) {
                return new FloatValue(properties);
            };

            /**
             * Encodes the specified FloatValue message. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 5 =*/13).float(message.value);
                return writer;
            };

            /**
             * Encodes the specified FloatValue message, length delimited. Does not implicitly {@link google.protobuf.FloatValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.IFloatValue} message FloatValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FloatValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FloatValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.float();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FloatValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.FloatValue} FloatValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FloatValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FloatValue message.
             * @function verify
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FloatValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "number")
                        return "value: number expected";
                return null;
            };

            /**
             * Creates a FloatValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FloatValue} FloatValue
             */
            FloatValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FloatValue)
                    return object;
                let message = new $root.google.protobuf.FloatValue();
                if (object.value != null)
                    message.value = Number(object.value);
                return message;
            };

            /**
             * Creates a plain object from a FloatValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {google.protobuf.FloatValue} message FloatValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FloatValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
                return object;
            };

            /**
             * Converts this FloatValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.FloatValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FloatValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FloatValue
             * @function getTypeUrl
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            /**
             * Properties of an Int64Value.
             * @memberof google.protobuf
             * @interface IInt64Value
             * @property {number|Long|null} [value] Int64Value value
             */

            /**
             * Constructs a new Int64Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int64Value.
             * @implements IInt64Value
             * @constructor
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             */
            function Int64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int64Value value.
             * @member {number|Long} value
             * @memberof google.protobuf.Int64Value
             * @instance
             */
            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Int64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.Int64Value} Int64Value instance
             */
            Int64Value.create = function create(properties) {
                return new Int64Value(properties);
            };

            /**
             * Encodes the specified Int64Value message. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
                return writer;
            };

            /**
             * Encodes the specified Int64Value message, length delimited. Does not implicitly {@link google.protobuf.Int64Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.IInt64Value} message Int64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int64Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Int64Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Int64Value} Int64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Int64Value message.
             * @function verify
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Int64Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                        return "value: integer|Long expected";
                return null;
            };

            /**
             * Creates an Int64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int64Value} Int64Value
             */
            Int64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int64Value)
                    return object;
                let message = new $root.google.protobuf.Int64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an Int64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {google.protobuf.Int64Value} message Int64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                return object;
            };

            /**
             * Converts this Int64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int64Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            /**
             * Properties of a UInt64Value.
             * @memberof google.protobuf
             * @interface IUInt64Value
             * @property {number|Long|null} [value] UInt64Value value
             */

            /**
             * Constructs a new UInt64Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt64Value.
             * @implements IUInt64Value
             * @constructor
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             */
            function UInt64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt64Value value.
             * @member {number|Long} value
             * @memberof google.protobuf.UInt64Value
             * @instance
             */
            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UInt64Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt64Value} UInt64Value instance
             */
            UInt64Value.create = function create(properties) {
                return new UInt64Value(properties);
            };

            /**
             * Encodes the specified UInt64Value message. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.value);
                return writer;
            };

            /**
             * Encodes the specified UInt64Value message, length delimited. Does not implicitly {@link google.protobuf.UInt64Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.IUInt64Value} message UInt64Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt64Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt64Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UInt64Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UInt64Value} UInt64Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt64Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UInt64Value message.
             * @function verify
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UInt64Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                        return "value: integer|Long expected";
                return null;
            };

            /**
             * Creates a UInt64Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt64Value} UInt64Value
             */
            UInt64Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt64Value)
                    return object;
                let message = new $root.google.protobuf.UInt64Value();
                if (object.value != null)
                    if ($util.Long)
                        (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                    else if (typeof object.value === "string")
                        message.value = parseInt(object.value, 10);
                    else if (typeof object.value === "number")
                        message.value = object.value;
                    else if (typeof object.value === "object")
                        message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a UInt64Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {google.protobuf.UInt64Value} message UInt64Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt64Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.value = options.longs === String ? "0" : 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value === "number")
                        object.value = options.longs === String ? String(message.value) : message.value;
                    else
                        object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
                return object;
            };

            /**
             * Converts this UInt64Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt64Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt64Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt64Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            /**
             * Properties of an Int32Value.
             * @memberof google.protobuf
             * @interface IInt32Value
             * @property {number|null} [value] Int32Value value
             */

            /**
             * Constructs a new Int32Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int32Value.
             * @implements IInt32Value
             * @constructor
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             */
            function Int32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int32Value value.
             * @member {number} value
             * @memberof google.protobuf.Int32Value
             * @instance
             */
            Int32Value.prototype.value = 0;

            /**
             * Creates a new Int32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.Int32Value} Int32Value instance
             */
            Int32Value.create = function create(properties) {
                return new Int32Value(properties);
            };

            /**
             * Encodes the specified Int32Value message. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.value);
                return writer;
            };

            /**
             * Encodes the specified Int32Value message, length delimited. Does not implicitly {@link google.protobuf.Int32Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.IInt32Value} message Int32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Int32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Int32Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Int32Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Int32Value} Int32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Int32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Int32Value message.
             * @function verify
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Int32Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value))
                        return "value: integer expected";
                return null;
            };

            /**
             * Creates an Int32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Int32Value} Int32Value
             */
            Int32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Int32Value)
                    return object;
                let message = new $root.google.protobuf.Int32Value();
                if (object.value != null)
                    message.value = object.value | 0;
                return message;
            };

            /**
             * Creates a plain object from an Int32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {google.protobuf.Int32Value} message Int32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Int32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this Int32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.Int32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Int32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Int32Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            /**
             * Properties of a UInt32Value.
             * @memberof google.protobuf
             * @interface IUInt32Value
             * @property {number|null} [value] UInt32Value value
             */

            /**
             * Constructs a new UInt32Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt32Value.
             * @implements IUInt32Value
             * @constructor
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             */
            function UInt32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt32Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt32Value
             * @instance
             */
            UInt32Value.prototype.value = 0;

            /**
             * Creates a new UInt32Value instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             * @returns {google.protobuf.UInt32Value} UInt32Value instance
             */
            UInt32Value.create = function create(properties) {
                return new UInt32Value(properties);
            };

            /**
             * Encodes the specified UInt32Value message. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.value);
                return writer;
            };

            /**
             * Encodes the specified UInt32Value message, length delimited. Does not implicitly {@link google.protobuf.UInt32Value.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.IUInt32Value} message UInt32Value message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UInt32Value.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UInt32Value();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.uint32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UInt32Value message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.UInt32Value} UInt32Value
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UInt32Value.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UInt32Value message.
             * @function verify
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UInt32Value.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isInteger(message.value))
                        return "value: integer expected";
                return null;
            };

            /**
             * Creates a UInt32Value message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UInt32Value} UInt32Value
             */
            UInt32Value.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UInt32Value)
                    return object;
                let message = new $root.google.protobuf.UInt32Value();
                if (object.value != null)
                    message.value = object.value >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a UInt32Value message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {google.protobuf.UInt32Value} message UInt32Value
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UInt32Value.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = 0;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this UInt32Value to JSON.
             * @function toJSON
             * @memberof google.protobuf.UInt32Value
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UInt32Value.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UInt32Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            /**
             * Properties of a BoolValue.
             * @memberof google.protobuf
             * @interface IBoolValue
             * @property {boolean|null} [value] BoolValue value
             */

            /**
             * Constructs a new BoolValue.
             * @memberof google.protobuf
             * @classdesc Represents a BoolValue.
             * @implements IBoolValue
             * @constructor
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             */
            function BoolValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BoolValue value.
             * @member {boolean} value
             * @memberof google.protobuf.BoolValue
             * @instance
             */
            BoolValue.prototype.value = false;

            /**
             * Creates a new BoolValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             * @returns {google.protobuf.BoolValue} BoolValue instance
             */
            BoolValue.create = function create(properties) {
                return new BoolValue(properties);
            };

            /**
             * Encodes the specified BoolValue message. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.value);
                return writer;
            };

            /**
             * Encodes the specified BoolValue message, length delimited. Does not implicitly {@link google.protobuf.BoolValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.IBoolValue} message BoolValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BoolValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BoolValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BoolValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.BoolValue} BoolValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BoolValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BoolValue message.
             * @function verify
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BoolValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (typeof message.value !== "boolean")
                        return "value: boolean expected";
                return null;
            };

            /**
             * Creates a BoolValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BoolValue} BoolValue
             */
            BoolValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BoolValue)
                    return object;
                let message = new $root.google.protobuf.BoolValue();
                if (object.value != null)
                    message.value = Boolean(object.value);
                return message;
            };

            /**
             * Creates a plain object from a BoolValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {google.protobuf.BoolValue} message BoolValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BoolValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = false;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this BoolValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BoolValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BoolValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BoolValue
             * @function getTypeUrl
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            /**
             * Properties of a StringValue.
             * @memberof google.protobuf
             * @interface IStringValue
             * @property {string|null} [value] StringValue value
             */

            /**
             * Constructs a new StringValue.
             * @memberof google.protobuf
             * @classdesc Represents a StringValue.
             * @implements IStringValue
             * @constructor
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             */
            function StringValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StringValue value.
             * @member {string} value
             * @memberof google.protobuf.StringValue
             * @instance
             */
            StringValue.prototype.value = "";

            /**
             * Creates a new StringValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             * @returns {google.protobuf.StringValue} StringValue instance
             */
            StringValue.create = function create(properties) {
                return new StringValue(properties);
            };

            /**
             * Encodes the specified StringValue message. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified StringValue message, length delimited. Does not implicitly {@link google.protobuf.StringValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.IStringValue} message StringValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StringValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.StringValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StringValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.StringValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.StringValue} StringValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StringValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StringValue message.
             * @function verify
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StringValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates a StringValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.StringValue} StringValue
             */
            StringValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.StringValue)
                    return object;
                let message = new $root.google.protobuf.StringValue();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a StringValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.StringValue
             * @static
             * @param {google.protobuf.StringValue} message StringValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StringValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this StringValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.StringValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StringValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StringValue
             * @function getTypeUrl
             * @memberof google.protobuf.StringValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            /**
             * Properties of a BytesValue.
             * @memberof google.protobuf
             * @interface IBytesValue
             * @property {Uint8Array|null} [value] BytesValue value
             */

            /**
             * Constructs a new BytesValue.
             * @memberof google.protobuf
             * @classdesc Represents a BytesValue.
             * @implements IBytesValue
             * @constructor
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             */
            function BytesValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BytesValue value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.BytesValue
             * @instance
             */
            BytesValue.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new BytesValue instance using the specified properties.
             * @function create
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             * @returns {google.protobuf.BytesValue} BytesValue instance
             */
            BytesValue.create = function create(properties) {
                return new BytesValue(properties);
            };

            /**
             * Encodes the specified BytesValue message. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified BytesValue message, length delimited. Does not implicitly {@link google.protobuf.BytesValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.IBytesValue} message BytesValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BytesValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.BytesValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BytesValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.BytesValue} BytesValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BytesValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BytesValue message.
             * @function verify
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BytesValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates a BytesValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.BytesValue} BytesValue
             */
            BytesValue.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.BytesValue)
                    return object;
                let message = new $root.google.protobuf.BytesValue();
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from a BytesValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {google.protobuf.BytesValue} message BytesValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BytesValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this BytesValue to JSON.
             * @function toJSON
             * @memberof google.protobuf.BytesValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BytesValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BytesValue
             * @function getTypeUrl
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
